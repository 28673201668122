import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import { AppConfig } from '@vp/config/AppConfig';

Sentry.init({
  dsn: AppConfig.sentryDsn,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  environment: AppConfig.env,
  release: AppConfig.version,
  tracesSampleRate: 0.2,
  tracePropagationTargets: ['localhost', `https://${window.location.host}/api`],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
