import { FileUpload } from '@vp/manager/gallery/data/upload/context/FileUpload';
import { UploadState } from '@vp/manager/gallery/data/upload/state/UploadState';

export class ChunkingState extends UploadState {
  async process(): Promise<void> {
    const fileUpload = this.stateMachine.context.getCurrentFileUpload();

    if (this.requiresChunking(fileUpload)) {
      this.splitOnChunks(fileUpload);
    } else {
      fileUpload.addChunk(fileUpload.mediaFile.file);
    }

    this.stateMachine.changeState(this.stateMachine.uploadingState);
  }

  private requiresChunking(fileUpload: FileUpload): boolean {
    return fileUpload.mediaFile.file.size > this.stateMachine.context.chunkSize * 2;
  }

  private splitOnChunks(fileUpload: FileUpload): void {
    for (let chunkIndex = 0; chunkIndex < fileUpload.mediaFile.file.size / this.stateMachine.context.chunkSize; chunkIndex++) {
      const start = chunkIndex * this.stateMachine.context.chunkSize;
      const end = start + this.stateMachine.context.chunkSize;
      const blob = fileUpload.mediaFile.file.slice(start, end);

      fileUpload.addChunk(blob);
    }
  }
}
