import { Box, IconButton, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC, SyntheticEvent, useCallback } from 'react';

import PauseIcon from '@vp/assets/icons/PauseFilled.svg?react';
import PlayIcon from '@vp/assets/icons/PlayFilled.svg?react';
import { resolveSx } from '@vp/common/ui/util/resolveSx';

export interface VpPlayerPausePlayControlProps {
  playing: boolean;
  sx?: SxProps<Theme>;
  onPlayPauseClick?: () => void;
}

export const VpPlayerPausePlayControl: FC<VpPlayerPausePlayControlProps> = ({ playing, sx, onPlayPauseClick }) => {
  const onClick = useCallback(
    (event: SyntheticEvent) => {
      if (onPlayPauseClick) {
        event?.stopPropagation();
        onPlayPauseClick();
      }
    },
    [onPlayPauseClick],
  );

  return (
    <Box
      position="absolute"
      top="50%"
      left="50%"
      sx={theme => ({ transform: 'translate(-50%, -50%)', pointerEvents: 'auto', ...resolveSx(sx, theme) })}
      onClick={onClick}
    >
      <IconButton sx={{ fontSize: 32 }}>{playing ? <PauseIcon /> : <PlayIcon />}</IconButton>
    </Box>
  );
};
