import { computed, ReadonlySignal, signal, Signal } from '@preact/signals-react';
import { injectable } from 'inversify';

import { MediaGridItem } from '@vp/common/ui/component/VpMediaGrid';
import { ViewModel, ViewModelDispose, ViewModelInit } from '@vp/common/ui/ViewModel';
import { UploadMediaDto } from '@vp/manager/gallery/core/dto/UploadMediaDto';
import { UploadMediaType } from '@vp/manager/gallery/core/dto/UploadMediaType';
import { GalleryManagerPort } from '@vp/manager/gallery/core/interface/GalleryManagerPort';
import { GalleryManagerUploadHandle, UploadHandleSnapshot } from '@vp/manager/gallery/core/interface/GalleryManagerUploadHandle';
import { GalleryManagerSection } from '@vp/manager/gallery/ui/GalleryManagerSection';
import { GalleryManagerUploadProps } from '@vp/manager/gallery/ui/upload/GalleryManagerUpload';
import { ProfileManagerPort } from '@vp/manager/profile/core/interface/ProfileManagerPort';
import { AppNotificationService } from '@vp/notification/AppNotificationService';
import { ProfileModel } from '@vp/profile/core/model/ProfileModel';

@injectable()
export class GalleryManagerUploadViewModel extends ViewModel<GalleryManagerUploadProps> implements ViewModelInit, ViewModelDispose {
  readonly items: Signal<MediaGridItem[]> = signal([]);
  readonly uploadSnapshot: Signal<UploadHandleSnapshot | null> = signal(null);
  readonly profile: ReadonlySignal<ProfileModel | null> = this.profileManagerPort.active;
  readonly showLimits: ReadonlySignal<boolean> = computed(() => !this.profile.value?.premium);

  private files: Map<string, File> = new Map();
  private uploadHandle?: GalleryManagerUploadHandle;

  constructor(
    private readonly galleryManagerPort: GalleryManagerPort,
    private readonly profileManagerPort: ProfileManagerPort,
    private readonly notificationService: AppNotificationService,
  ) {
    super();
  }

  init(): void {
    this.initializeFiles();
    this.items.value = this.toGridItems();
  }

  dispose(): void {
    this.uploadHandle?.stop();
    this.revokeItemsUrl();
  }

  remove = (item: MediaGridItem): void => {
    this.revokeItemUrl(item);
    this.items.value = this.items.value.filter(({ id }) => item.id !== id);
    if (!this.items.value.length) this.closeUpload();
  };

  upload = (): void => {
    this.uploadHandle?.stop();
    this.uploadHandle = this.galleryManagerPort.upload(this.toDto());

    this.uploadHandle.subscribe(snapshot => {
      this.uploadSnapshot.value = snapshot;

      if (snapshot.state === 'completed') {
        this.closeUpload();
        this.showSuccessNotification();
      }

      if (snapshot.state === 'failed') {
        this.showErrorNotification(snapshot.error);
      }
    });
  };

  cancelUpload = (): void => {
    this.uploadHandle?.stop();
    this.uploadSnapshot.value = null;
  };

  private closeUpload(): void {
    this.props.value?.close();
  }

  private showSuccessNotification(): void {
    const name = this.props.value?.section === GalleryManagerSection.Photos ? 'Фото' : 'Видео';

    this.notificationService.enqueue({
      variant: 'success',
      message: `${name} были добавлены в профиль`,
    });
  }

  private showErrorNotification(message?: string): void {
    const name = this.props.value?.section === GalleryManagerSection.Photos ? 'фото' : 'видео';
    const secondaryMessage = message || `Не удалось загрузить ${name}`;

    this.notificationService.enqueue({
      variant: 'error',
      message: 'Что-то пошло не так',
      secondaryMessage,
    });
  }

  private toDto(): UploadMediaDto {
    const profileId = this.profile.value!.id;
    const type = this.props.value?.section === GalleryManagerSection.Photos ? UploadMediaType.Photos : UploadMediaType.Videos;
    const files = this.items.value.map(({ id }) => ({ id, file: this.files.get(id)! }));
    return new UploadMediaDto({ profileId, type, files });
  }

  private toGridItems(): MediaGridItem[] {
    const type = this.getType();
    const items: MediaGridItem[] = [];

    this.files.forEach((file, id) => {
      const item = this.toGridItem(id, type, file);
      items.push(item);
    });

    return items;
  }

  private toGridItem(id: string, type: MediaGridItem['type'], file: File): MediaGridItem {
    const url = type === 'photo' ? URL.createObjectURL(file) : '';
    return { id, type, hash: '', url };
  }

  private getType(): MediaGridItem['type'] {
    return this.props.value?.section === GalleryManagerSection.Photos ? 'photo' : 'video';
  }

  private revokeItemsUrl(): void {
    this.items.value.forEach(item => this.revokeItemUrl(item));
  }

  private revokeItemUrl(item: MediaGridItem): void {
    if (item.url) {
      URL.revokeObjectURL(item.url);
    }
  }

  private initializeFiles(): void {
    this.files = new Map();

    if (this.props.value?.files) {
      this.props.value.files.forEach(file => {
        this.files.set(Math.random().toString(36), file);
      });
    }
  }
}
