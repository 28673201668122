import { Box, Fade } from '@mui/material';
import { FC, Fragment } from 'react';

import { VpMediaGrid } from '@vp/common/ui/component/VpMediaGrid';
import { useSearchSection } from '@vp/common/ui/hook/useSearchSection';
import { useSearchSectionCheck } from '@vp/common/ui/hook/useSearchSectionCheck';
import { useViewModel } from '@vp/common/ui/hook/useViewModel';
import { ProfileModel } from '@vp/profile/core/model/ProfileModel';
import { GalleryTabs } from '@vp/profile/ui/gallery/GalleryTabs';
import { ProfileImageViewer } from '@vp/profile/ui/gallery/image/ProfileImageViewer';
import { ProfileGalleryMode, ProfileGalleryViewModel } from '@vp/profile/ui/gallery/ProfileGalleryViewModel';
import { ProfileVideoViewer } from '@vp/profile/ui/gallery/video/ProfileVideoViewer';
import { useSectionMinHeight } from '@vp/profile/ui/hook/useSectionMinHeight';

export interface ProfilePhotosProps {
  profile: ProfileModel;
}

export const ProfileGallery: FC<ProfilePhotosProps> = ({ profile }) => {
  const viewModel = useViewModel(ProfileGalleryViewModel, { profile });
  const minHeight = useSectionMinHeight();
  const mode = useSearchSection<ProfileGalleryMode>('m', ProfileGalleryMode.Images);

  useSearchSectionCheck('m', ProfileGalleryMode.Images);

  return (
    <Fragment>
      <Fade key={mode} in appear timeout={250} easing="ease-in-out">
        <Box sx={{ minHeight }}>
          {mode === ProfileGalleryMode.Images && <VpMediaGrid items={viewModel.photoItems.value} onClick={viewModel.handlePhotoClick} />}
          {mode === ProfileGalleryMode.Videos && <VpMediaGrid items={viewModel.videoItems.value} onClick={viewModel.handleVideoClick} />}
        </Box>
      </Fade>

      <GalleryTabs mode={mode} />

      <ProfileImageViewer
        open={viewModel.imageViewerShown.value}
        selectedPhotoId={viewModel.selectedPhoto.value?.id ?? ''}
        photos={profile.photos}
        close={viewModel.closeImageViewer}
      />

      <ProfileVideoViewer
        open={viewModel.videoViewerShown.value}
        video={viewModel.selectedVideo.value}
        close={viewModel.closeVideoViewer}
      />
    </Fragment>
  );
};
