import { CSSObject } from '@mui/styled-engine';

import { VpSpinnerProps } from '@vp/common/ui/component/spinner/VpSpinner';

interface Range {
  min: number;
  max: number;
  border: number;
}

interface Variant {
  props: VpSpinnerProps;
  style: CSSObject;
}

export class SpinnerBorderCalculator {
  constructor(private readonly ranges: Range[]) {}

  getBorder(diameter: number): number {
    for (const range of this.ranges) {
      if (diameter >= range.min && diameter < range.max) {
        return range.border;
      }
    }
    throw new Error('Diameter out of supported range');
  }
}

export class SpinnerResolver {
  constructor(private readonly borderCalculator: SpinnerBorderCalculator) {}

  resolveWith(diameter: number, border: number): CSSObject {
    const maskRadius = diameter / 2 - border;
    const maskImage = `radial-gradient(circle, transparent ${maskRadius}px, black ${maskRadius + 1}px)`;
    return { width: diameter, height: diameter, maskImage };
  }

  getVariantsRange(from: number, to: number): Variant[] {
    const variants: Variant[] = [];

    for (let size = from; size <= to; size++) {
      const border = this.borderCalculator.getBorder(size);
      const style = this.resolveWith(size, border);
      variants.push({ props: { size }, style });
    }

    return variants;
  }
}
