import { Container, ContainerModule, interfaces } from 'inversify';

export abstract class BaseModule {
  abstract readonly module: ContainerModule;
  protected readonly imports: BaseModule[] = [];

  initialize(container: Container): void {
    this.initializeImports(container);
  }

  import(Modules: interfaces.Newable<BaseModule>[], ...registry: Parameters<interfaces.ContainerModuleCallBack>): void {
    for (const Module of Modules) {
      const instance = new Module();
      instance.module.registry(...registry);
      this.imports.push(instance);
    }
  }

  private initializeImports(container: Container): void {
    for (const module of this.imports) {
      module.initialize?.(container);
    }
  }
}
