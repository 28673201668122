import { Box, FormControl, Stack, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { useForm } from 'react-hook-form';

import { VpFancyButton } from '@vp/common/ui/component/VpFancyButton';
import { VpInput } from '@vp/common/ui/component/VpInput';
import { BiographyManagerEditHeader } from '@vp/manager/biography/ui/edit/BiographyManagerEditHeader';

export interface EditModalFormValues {
  suggestedText: string;
}

interface BiographyManagerEditContentProps {
  close: () => void;
  submit: (values: EditModalFormValues) => void;
  text: string;
}

export const BiographyManagerEditContent: FC<BiographyManagerEditContentProps> = ({ close, submit, text }) => {
  const { spacing } = useTheme();
  const methods = useForm<EditModalFormValues>({
    defaultValues: { suggestedText: text },
    mode: 'onTouched',
  });

  return (
    <Stack width={1} height="100dvh" component="form" onSubmit={methods.handleSubmit(submit)}>
      <BiographyManagerEditHeader onClose={close} />
      <Box flexGrow={1} overflow="scroll" px={3} pt={1}>
        <FormControl fullWidth>
          <Typography component="label" htmlFor="form-suggested-text" variant="formLabelMedium" color="text.primary" mb={spacing(0.75)}>
            Описание
          </Typography>
          <VpInput
            {...methods.register('suggestedText', { required: true })}
            id="form-suggested-text"
            multiline
            placeholder="Напишите биографию человека, включая ключевые моменты его жизни, достижения и наследие, которое он оставил для будущих поколений."
          />
        </FormControl>

        <Stack p={1} mt={1}>
          <Typography variant="caption2Regular" color="text.disabled" align="center" sx={{ whiteSpace: 'pre-line' }}>
            Сгенерировано при помощи Искусственного Интеллекта
          </Typography>
        </Stack>
      </Box>

      <Box sx={{ width: 1, py: 2, display: 'flex', justifyContent: 'center', backgroundColor: 'background.300' }}>
        <VpFancyButton text="Применить" type="submit" />
      </Box>
    </Stack>
  );
};
