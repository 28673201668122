import { UploadMediaType } from '@vp/manager/gallery/core/dto/UploadMediaType';

export class RemoveMediaDto {
  readonly id!: string;
  readonly type!: UploadMediaType;
  readonly profileId!: string;

  constructor(args: RemoveMediaDto) {
    Object.assign(this, args);
  }
}
