import { FileUpload } from '@vp/manager/gallery/data/upload/context/FileUpload';
import { UploadState } from '@vp/manager/gallery/data/upload/state/UploadState';

type UploadInitializationDto = { upload_id: string; key: string };

export class InitializationState extends UploadState {
  async process(): Promise<void> {
    try {
      await this.initializeUpload();
      this.stateMachine.changeState(this.stateMachine.chunkingState);
    } catch (error) {
      this.handleError(error);
      this.stateMachine.changeState(this.stateMachine.failedState);
    }
  }

  private async initializeUpload(): Promise<void> {
    const fileUpload = this.stateMachine.context.getCurrentFileUpload();
    const url = `/api/multipart_uploads?${this.toSearchParams(fileUpload)}`;

    const { data } = await this.stateMachine.http.post<UploadInitializationDto>(url, {
      signal: this.stateMachine.controller.signal,
    });

    fileUpload.setUploadMeta(data.upload_id, data.key);
  }

  private toSearchParams(fileUpload: FileUpload): string {
    const search = new URLSearchParams();

    search.set('filename', fileUpload.mediaFile.file.name);
    search.set('profile_id', this.stateMachine.context.profileId);
    search.set('content_type', fileUpload.mediaFile.file.type);
    search.set('content_length', fileUpload.mediaFile.file.size.toString());

    return search.toString();
  }
}
