import { Axios } from 'axios';

import { Analytics } from '@vp/common/core/Analytics';
import { ErrorExtractor } from '@vp/common/data/ErrorExtractor';
import {
  GalleryManagerUploadHandle,
  UploadHandleSubscriber,
  UploadHandleUnsubscribe,
} from '@vp/manager/gallery/core/interface/GalleryManagerUploadHandle';
import { GalleryManagerDataMapper } from '@vp/manager/gallery/data/GalleryManagerDataMapper';
import { UploadContext } from '@vp/manager/gallery/data/upload/context/UploadContext';
import { BasicUploadingState } from '@vp/manager/gallery/data/upload/state/BasicUploadingState';
import { ChunkingState } from '@vp/manager/gallery/data/upload/state/ChunkingState';
import { CompletedState } from '@vp/manager/gallery/data/upload/state/CompletedState';
import { FailedState } from '@vp/manager/gallery/data/upload/state/FailedState';
import { FinalizingState } from '@vp/manager/gallery/data/upload/state/FinalizingState';
import { InitializationState } from '@vp/manager/gallery/data/upload/state/InitializationState';
import { InitialState } from '@vp/manager/gallery/data/upload/state/InitialState';
import { UploadingState } from '@vp/manager/gallery/data/upload/state/UploadingState';
import { UploadState } from '@vp/manager/gallery/data/upload/state/UploadState';

export class UploadStateMachine implements GalleryManagerUploadHandle {
  readonly initialState: UploadState = new InitialState(this);
  readonly initializationState: UploadState = new InitializationState(this);
  readonly chunkingState: UploadState = new ChunkingState(this);
  readonly uploadingState: UploadState = new UploadingState(this);
  readonly basicUploadingState: UploadState = new BasicUploadingState(this);
  readonly finalizingState: UploadState = new FinalizingState(this);
  readonly completedState: UploadState = new CompletedState(this);
  readonly failedState: UploadState = new FailedState(this);

  controller: AbortController = new AbortController();
  currentState?: UploadState;

  private readonly subscribers: Set<UploadHandleSubscriber> = new Set();

  constructor(
    readonly context: UploadContext,
    readonly http: Axios,
    readonly errorExtractor: ErrorExtractor,
    readonly mapper: GalleryManagerDataMapper,
    readonly analytics: Analytics,
  ) {}

  subscribe(subscriber: UploadHandleSubscriber): UploadHandleUnsubscribe {
    this.subscribers.add(subscriber);
    const snapshot = this.mapper.toUploadHandleSnapshot(this);
    subscriber(snapshot);
    return () => this.subscribers.delete(subscriber);
  }

  notify(): void {
    const snapshot = this.mapper.toUploadHandleSnapshot(this);
    this.subscribers.forEach(subscriber => subscriber(snapshot));
  }

  start(): void {
    this.stop();
    this.controller = new AbortController();
    this.changeState(this.initialState);
  }

  stop(): void {
    this.controller.abort();
    this.subscribers.clear();
    this.currentState = undefined;
    this.context.reset();
  }

  changeState(state: UploadState): void {
    this.currentState = state;
    this.currentState.onEnter();
    void this.currentState.process?.();
  }
}
