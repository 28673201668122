import { injectable } from 'inversify';

import { OperationResult, Success } from '@vp/common/core/OperationResult';
import { EventsManagerRepository } from '@vp/manager/events/core/interface/EventsManagerRepository';
import { EventsManagerState } from '@vp/manager/events/core/interface/EventsManagerState';

@injectable()
export class LoadEventsUsecase {
  constructor(
    private readonly eventsManarRepository: EventsManagerRepository,
    private readonly eventsManagerState: EventsManagerState,
  ) {}

  async execute(profileId: string, controller: AbortController): Promise<OperationResult> {
    this.eventsManagerState.loading();
    const result = await this.eventsManarRepository.loadEvents(profileId, controller);

    if (result instanceof Success) {
      this.eventsManagerState.completed('loaded', result.value);
    } else {
      this.eventsManagerState.completed('error');
    }

    return result as OperationResult;
  }
}
