import { Box, Link, Stack, Theme, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { CSSProperties, FC, useCallback, useMemo, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { useLinearGradient } from '@vp/common/ui/hook/useLinearGradient';
import { useRectOnResize } from '@vp/common/ui/hook/useRectOnResize';

export interface VpTab {
  id: string;
  name: string;
  url: string;
}

export interface VpTabsProps {
  tabs: VpTab[];
  activeTabId: string;
  sx?: SxProps<Theme>;
}

export const VpTabs: FC<VpTabsProps> = ({ tabs, activeTabId, sx }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [underlineWidth, setUnderlineWidth] = useState(0);
  const activeIndex = useMemo(() => tabs.findIndex(tab => tab.id === activeTabId) ?? 0, [activeTabId, tabs]);

  useRectOnResize(
    containerRef,
    useCallback(({ width }) => setUnderlineWidth(width / tabs.length), [tabs.length]),
  );

  return (
    <Box width={1} sx={sx}>
      <Stack ref={containerRef} position="relative" direction="row" width={1}>
        {tabs.map(tab => (
          <VpTab key={tab.id} tab={tab} isActive={tab.id === activeTabId} />
        ))}

        <VpTabUnderline width={underlineWidth} index={activeIndex} />
      </Stack>
    </Box>
  );
};

interface VpTabUnderlineProps {
  width: number;
  index: number;
}

const VpTabUnderline: FC<VpTabUnderlineProps> = ({ width, index }) => {
  const border = useBorder();
  const { palette } = useTheme();

  return (
    <Box
      component="span"
      style={{ width, transform: `translateX(${index * 100}%)`, '--vp-primary-color': palette.primary[500] } as CSSProperties}
      sx={theme => ({
        position: 'absolute',
        bottom: 0,
        height: 2,
        transition: 'transform 0.25s ease-in-out',
        ...theme.applyStyles('dark', {
          '&::before': { ...border, filter: 'blur(10px)', willChange: 'opacity' },
        }),
        '&::after': border,
      })}
    />
  );
};

export interface VpTabProps {
  tab: VpTab;
  isActive: boolean;
}

export const VpTab: FC<VpTabProps> = ({ tab, isActive }) => {
  const color = isActive ? 'primary.500' : 'text.disabled';

  return (
    <Link
      flex={1}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height={39}
      component={NavLink}
      to={tab.url}
      end
      sx={{ textDecoration: 'none' }}
    >
      <Typography variant="body2Medium" color={color} margin="auto" sx={{ transition: 'color 0.25s ease-in-out' }}>
        {tab.name}
      </Typography>
    </Link>
  );
};

const useBorder = (): CSSProperties => {
  const gradient = useLinearGradient({
    angle: '90deg',
    color: 'var(--vp-primary-color)',
    stops: [
      { percent: 0, opacity: 1 },
      { percent: 64, opacity: 0.5 },
      { percent: 100, opacity: 0 },
    ],
  });

  return useMemo(
    () =>
      ({
        content: '""',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        position: 'absolute',
        transition: '--vp-primary-color 0.25s ease-in-out',
        background: gradient,
        top: 0,
        left: 0,
        width: '100%',
        height: '2px',
      }) as CSSProperties,
    [gradient],
  );
};
