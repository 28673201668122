import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { CSSProperties, FC } from 'react';

import CloseIcon from '@vp/assets/icons/Close.svg?react';

export interface SubmitRecordHeaderProps {
  close: () => void;
  disableCloseIcon: boolean;
}

export const SubmitRecordHeader: FC<SubmitRecordHeaderProps> = ({ close, disableCloseIcon }) => {
  const { palette, spacing } = useTheme();

  const iconStyles: CSSProperties = {
    padding: 0,
    left: -5,
    opacity: disableCloseIcon ? 0.5 : 1,
    color: 'primary.500',
  };

  return (
    <Box
      sx={{
        position: 'sticky',
        top: 0,
        py: 2,
        backgroundColor: palette.background.default,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <IconButton
          onClick={close}
          disabled={disableCloseIcon}
          sx={{
            ...iconStyles,
            '&::before': {
              content: '""',
              position: 'absolute',
              top: spacing(-2),
              right: spacing(-3),
              bottom: spacing(-2),
              left: spacing(-2),
              background: 'transparent',
            },
          }}
        >
          <CloseIcon />
        </IconButton>

        <IconButton
          sx={{
            ...iconStyles,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            zIndex: -1,
            filter: 'blur(10px)',
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Typography
        variant="h6"
        color={'text.primary'}
        textAlign={'center'}
        sx={{
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        Оставить запись
      </Typography>
    </Box>
  );
};
