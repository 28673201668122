import { FC, Fragment } from 'react';
import { Outlet } from 'react-router-dom';

import { VpFallback } from '@vp/common/ui/component/VpFallback';
import { useViewModel } from '@vp/common/ui/hook/useViewModel';
import { ManagerGatewayViewModel } from '@vp/manager/gateway/ManagerGatewayViewModel';

export const ManagerGateway: FC = () => {
  const viewModel = useViewModel(ManagerGatewayViewModel);

  if (!viewModel.initialized.value) return null;

  return (
    <Fragment>
      {viewModel.hasError.value && <VpFallback title="Что-то пошло не так" />}
      {!viewModel.hasError.value && <Outlet />}
    </Fragment>
  );
};
