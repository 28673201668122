import { Box, Stack, Typography, useTheme } from '@mui/material';
import { FC, useCallback, useRef, useState } from 'react';

import { useRectOnResize } from '@vp/common/ui/hook/useRectOnResize';
import { ProfileModel } from '@vp/profile/core/model/ProfileModel';

export interface ProfileSettingsNavigationProps {
  profile: ProfileModel;
}

export const ProfileSettingsInfo: FC<ProfileSettingsNavigationProps> = ({ profile }) => {
  const containerRef = useRef<HTMLDivElement>(null!);
  const [marginTop, setMarginTop] = useState(0);
  const [top, setTop] = useState(0);
  const { profileSettingsMenuOffsetMobile, smallImageHeightMobile } = useTheme();

  const name = profile.name || 'Имя не указано';
  const dates = profile.birthDate && profile.deathDate ? `${profile.birthDate} - ${profile.deathDate}` : '';

  useRectOnResize(
    containerRef,
    useCallback(
      ({ height }) => {
        setMarginTop(-(height + profileSettingsMenuOffsetMobile));
        setTop(smallImageHeightMobile - height - profileSettingsMenuOffsetMobile);
      },
      [profileSettingsMenuOffsetMobile, smallImageHeightMobile],
    ),
  );

  return (
    <Box ref={containerRef} position="sticky" style={{ marginTop: `${marginTop}px`, top: `${top}px` }}>
      <Stack p={3} width={1} textAlign="center">
        <Typography variant="h3" color="text.secondary">
          {name}
        </Typography>

        <Typography variant="caption2Regular" color="text.disabled">
          {dates}
        </Typography>
      </Stack>
    </Box>
  );
};
