import { batch, signal, Signal } from '@preact/signals-react';
import { injectable } from 'inversify';

import { HonorManagerState, HonorManagerStatus } from '@vp/manager/honor/core/interface/HonorManagerState';
import { ProfileHonorRecordModel } from '@vp/profile/core/model/ProfileHonorRecordModel';

@injectable()
export class SignalsHonorManagerState implements HonorManagerState {
  readonly approvedRecords: Signal<ProfileHonorRecordModel[]> = signal([]);
  readonly pendingRecords: Signal<ProfileHonorRecordModel[]> = signal([]);
  readonly status: Signal<HonorManagerStatus> = signal('initial');

  private records: Map<string, ProfileHonorRecordModel> = new Map();

  completed(status: HonorManagerStatus): void {
    this.status.value = status;
  }

  loading(): void {
    this.status.value = 'loading';
  }

  setRecords(records: ProfileHonorRecordModel[]): void {
    this.records = new Map();
    this.updateRecords(records);
  }

  updateRecords(records: ProfileHonorRecordModel[]): void {
    for (const record of records) {
      this.records.set(record.id, record);
    }

    this.emitUpdate();
  }

  setRecord(record: ProfileHonorRecordModel): void {
    this.records.set(record.id, record);
    this.emitUpdate();
  }

  deleteRecords(ids: string[]): void {
    for (const id of ids) {
      this.records.delete(id);
    }

    this.emitUpdate();
  }

  reset(): void {
    this.setRecords([]);
    this.status.value = 'initial';
  }

  private emitUpdate(): void {
    const { approved, pending } = this.splitRecords();

    batch(() => {
      this.approvedRecords.value = approved;
      this.pendingRecords.value = pending;
    });
  }

  private splitRecords(): { approved: ProfileHonorRecordModel[]; pending: ProfileHonorRecordModel[] } {
    const approved: ProfileHonorRecordModel[] = [];
    const pending: ProfileHonorRecordModel[] = [];

    this.records.forEach(record => {
      if (record.approved) {
        approved.push(record);
      } else {
        pending.push(record);
      }
    });

    return { approved, pending };
  }
}
