import { RouteObject } from 'react-router';

import { AuthGuard } from '@vp/auth/ui/guard/AuthGuard';
import { AvatarManagerPage } from '@vp/manager/avatar/ui/AvatarManagerPage';
import { BiographyManagerPage } from '@vp/manager/biography/ui/BiographyManagerPage';
import { EventsManagerPage } from '@vp/manager/events/ui/EventsManagerPage';
import { GalleryManagerPage } from '@vp/manager/gallery/ui/GalleryManagerPage';
import { ManagerGateway } from '@vp/manager/gateway/ManagerGateway';
import { HonorManagerPage } from '@vp/manager/honor/ui/component/HonorManagerPage';
import { ProfileSettingsPage } from '@vp/manager/profile/ui/settings/ProfileSettingsPage';
import { ThemingManagerPage } from '@vp/manager/theming/ui/ThemingManagerPage';

export const ManagerRouter: RouteObject = {
  path: '/ps/:id?',
  element: (
    <AuthGuard>
      <ManagerGateway />
    </AuthGuard>
  ),
  children: [
    {
      index: true,
      element: <ProfileSettingsPage />,
    },
    {
      path: 'gallery',
      element: <GalleryManagerPage />,
    },
    {
      path: 'honor',
      element: <HonorManagerPage />,
    },
    {
      path: 'avatar',
      element: <AvatarManagerPage />,
    },
    {
      path: 'events',
      element: <EventsManagerPage />,
    },
    {
      path: 'bio',
      element: <BiographyManagerPage />,
    },
    {
      path: 'theming',
      element: <ThemingManagerPage />,
    },
  ],
};
