import { ImageList, ImageListItem, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, forwardRef, JSX, useCallback } from 'react';

import PlayIcon from '@vp/assets/icons/Play.svg?react';
import { VpIcon } from '@vp/common/ui/component/VpIcon';
import { VpImage } from '@vp/common/ui/component/VpImage';
import { resolveSx } from '@vp/common/ui/util/resolveSx';

export interface MediaGridItem {
  id: string;
  type: 'photo' | 'video';
  url: string;
  hash: string;
}

export interface VpMediaGridProps {
  items: MediaGridItem[];
  itemDecorator?: (item: MediaGridItem) => JSX.Element;
  sx?: SxProps<Theme>;
  onClick?: (item: MediaGridItem) => void;
}

export const VpMediaGrid = forwardRef<HTMLUListElement, VpMediaGridProps>(({ items, itemDecorator, sx, onClick }, ref) => {
  return (
    <ImageList ref={ref} cols={3} gap={4} sx={theme => ({ py: 2, m: 0, px: 3, overflow: 'hidden', ...resolveSx(sx, theme) })}>
      <AnimatePresence mode="popLayout">
        {items.map(item => (
          <motion.div key={item.id} exit={{ opacity: 0, width: 0 }} layout>
            <VpMediaGridItem item={item} itemDecorator={itemDecorator} onClick={onClick} />
          </motion.div>
        ))}
      </AnimatePresence>
    </ImageList>
  );
});

VpMediaGrid.displayName = 'VpMediaGrid';

interface VpMediaGridItemProps {
  item: MediaGridItem;
  itemDecorator?: (item: MediaGridItem) => JSX.Element;
  onClick?: (item: MediaGridItem) => void;
}

const VpMediaGridItem: FC<VpMediaGridItemProps> = ({ item, itemDecorator, onClick }) => {
  const isVideo = item.type === 'video';
  const paddingTop = isVideo ? '131.13%' : '100%';
  const onItemClick = useCallback(() => onClick?.(item), [item, onClick]);

  return (
    <ImageListItem sx={{ position: 'relative' }} style={{ paddingTop }} onClick={onItemClick}>
      <VpImage src={item.url} hash={item.hash} sx={{ position: 'absolute', inset: 0 }} />
      {isVideo && (
        <VpIcon Icon={PlayIcon} size={16} sx={theme => ({ position: 'absolute', top: 8, left: 8, zIndex: theme.zIndex.mediaControls })} />
      )}
      {itemDecorator && itemDecorator(item)}
    </ImageListItem>
  );
};
