import { FormControl, Stack, Typography } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { useForm, RegisterOptions } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { LoginFormData, LoginViewModel } from '@vp/auth/ui/login/LoginViewModel';
import { ViewPasswordIcon } from '@vp/auth/ui/register/ViewPasswordIcon';
import { EmailValidationOptions } from '@vp/auth/ui/validation/EmailValidationOptions';
import { VpFancyButton } from '@vp/common/ui/component/VpFancyButton';
import { VpInput } from '@vp/common/ui/component/VpInput';
import { useViewModel } from '@vp/common/ui/hook/useViewModel';

const PasswordValidationOptions: RegisterOptions<LoginFormData> = {
  required: true,
  maxLength: 24,
};

export const LoginForm: FC = () => {
  const viewModel = useViewModel(LoginViewModel);

  const { register, handleSubmit, formState } = useForm<LoginFormData>({
    defaultValues: { email: '', password: '' },
    mode: 'onTouched',
  });

  const [showPassword, setShowPassword] = useState(false);
  const passwordInputType = showPassword ? 'text' : 'password';

  const handleClickShowPassword = useCallback(() => setShowPassword(state => !state), []);

  return (
    <Stack component="form" width="100%" alignItems="center" mb={2} onSubmit={handleSubmit(viewModel.login)}>
      <FormControl sx={{ mb: 3 }} fullWidth error={!!formState.errors.email} disabled={viewModel.loading.value}>
        <Typography htmlFor="form-email" component="label" variant="formLabelMedium" color="text.primary" gutterBottom>
          Почта
        </Typography>
        <VpInput {...register('email', EmailValidationOptions)} autoComplete="email" id="form-email" placeholder="example@mail.com" />
        {formState.errors.email?.message && (
          <Typography variant="body2Regular" color="danger.500" mt={0.75}>
            {formState.errors.email.message}
          </Typography>
        )}
      </FormControl>

      <FormControl sx={{ mb: 4 }} fullWidth error={!!formState.errors.password} disabled={viewModel.loading.value}>
        <Typography htmlFor="form-password" component="label" variant="formLabelMedium" color="text.primary" gutterBottom>
          Пароль
        </Typography>
        <VpInput
          {...register('password', PasswordValidationOptions)}
          id="form-password"
          placeholder={'Введите ваш пароль'}
          type={passwordInputType}
          endAdornment={<ViewPasswordIcon showPassword={showPassword} onClick={handleClickShowPassword} />}
        />
        {formState.errors.password?.message && (
          <Typography variant="body2Regular" color="danger.500" mt={0.75}>
            {formState.errors.password.message}
          </Typography>
        )}

        <Typography
          component={Link}
          to="/auth/forgot-password"
          variant="body2Medium"
          color="primary.500"
          sx={{
            mt: 2,
            alignSelf: 'flex-start',
            textShadow: '0px 0px 32px rgba(255, 255, 255, 0.5), 0px 0px 128px rgba(255, 255, 255, 0.5)',
          }}
        >
          Забыли пароль?
        </Typography>
      </FormControl>

      <VpFancyButton text="Войти" type="submit" disabled={!formState.isValid} loading={viewModel.loading.value} />
    </Stack>
  );
};
