import { FC, PropsWithChildren } from 'react';

import { GuardViewModel } from '@vp/auth/ui/guard/GuardViewModel';
import { useViewModel } from '@vp/common/ui/hook/useViewModel';


export const AuthGuard: FC<PropsWithChildren> = ({ children }) => {
  const viewModel = useViewModel(GuardViewModel);
  if (!viewModel.authenticated.value) return null;
  return children;
};
