import { Box, Stack, SvgIcon, Typography } from '@mui/material';
import { FC } from 'react';

import UserMultipleGradient from '@vp/assets/icons/UserMultipleGradient.svg?react';

const Title = 'У вас пока нет управляемых профилей';
const Caption =
  'Вы можете приобрести наш специальный блистер с QR-кодом, который будет хранить тёплые воспоминания и важные моменты о ваших близких.';

export const NoProfiles: FC = () => {
  return (
    <Stack width={1} px={3} alignItems="center">
      <Box position="relative" mb={1.5} mt={3}>
        <SvgIcon sx={{ fontSize: 40, filter: 'blur(10px)' }}>
          <UserMultipleGradient />
        </SvgIcon>

        <SvgIcon sx={{ fontSize: 40, position: 'absolute', inset: 0 }}>
          <UserMultipleGradient />
        </SvgIcon>
      </Box>

      <Typography color="text.secondary" variant="h5" align="center" mb={1.5}>
        {Title}
      </Typography>

      <Typography color="text.secondaryDisabled" variant="body2Regular" align="center" mb={2}>
        {Caption}
      </Typography>
    </Stack>
  );
};
