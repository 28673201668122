import { Signal, signal } from '@preact/signals-react';
import { injectable } from 'inversify';

import { ProfileLoadingState, ProfileState } from '@vp/profile/core/interface/ProfileState';
import { ProfileModel } from '@vp/profile/core/model/ProfileModel';

@injectable()
export class SignalsProfileState implements ProfileState {
  readonly profile: Signal<ProfileModel> = signal({
    id: '',
    name: '',
    birthDate: '',
    deathDate: '',
    image: { id: '', hash: '', url: '' },
    bio: '',
    events: [],
    photos: [],
    videos: [],
    honorRecords: [],
    theme: { color: null, mode: 'dark' },
    slideshowAudio: '',
    active: true,
    premium: false,
  });

  readonly profileForActivation: Signal<string | null> = signal(null);
  readonly loadingState: Signal<ProfileLoadingState> = signal('loading');

  setProfile(profile: ProfileModel): void {
    this.profile.value = profile;
  }

  setLoadingState(state: ProfileLoadingState): void {
    this.loadingState.value = state;
  }

  storeProfileForActivation(id: string): void {
    this.profileForActivation.value = id;
  }

  resetProfileForActivation(): void {
    this.profileForActivation.value = null;
  }
}
