import { interfaces } from 'inversify';
import { useInjection } from 'inversify-react';
import { useEffect } from 'react';

import { ViewModel, ViewModelDispose, ViewModelInit } from '@vp/common/ui/ViewModel';

export function useViewModel<P extends object, T extends ViewModel<P>>(identifier: interfaces.Newable<T>, props?: P): T {
  const viewModel = useInjection<T>(identifier);
  viewModel.bindProps(props);

  useEffect(() => {
    initializeIfNeeded(viewModel);
    return (): void => disposeIfNeeded(viewModel);
  }, [viewModel]);

  return viewModel;
}

function initializeIfNeeded(viewModel: object): void {
  if (initializable(viewModel)) {
    viewModel.init();
  }
}

function initializable(viewModel: Partial<ViewModelInit>): viewModel is ViewModelInit {
  return typeof viewModel?.init === 'function';
}

function disposeIfNeeded(viewModel: object): void {
  if (disposable(viewModel)) {
    viewModel.dispose();
  }
}

function disposable(viewModel: Partial<ViewModelDispose>): viewModel is ViewModelDispose {
  return typeof viewModel?.dispose === 'function';
}
