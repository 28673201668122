import { ContainerModule } from 'inversify';

import { BaseModule } from '@vp/common/BaseModule';
import { ManagerGatewayViewModel } from '@vp/manager/gateway/ManagerGatewayViewModel';
import { ProfileManagerPort } from '@vp/manager/profile/core/interface/ProfileManagerPort';
import { ProfileManagerRepository } from '@vp/manager/profile/core/interface/ProfileManagerRepository';
import { ProfileManagerState } from '@vp/manager/profile/core/interface/ProfileManagerState';
import { ProfileManagerFacade } from '@vp/manager/profile/core/ProfileManagerFacade';
import { LoadProfilesUsecase } from '@vp/manager/profile/core/usecase/LoadProfilesUsecase';
import { RestProfileManagerRepository } from '@vp/manager/profile/data/RestProfileManagerRepository';
import { SignalsProfileManagerState } from '@vp/manager/profile/data/SignalsProfileManagerState';
import { ProfileSettingsViewModel } from '@vp/manager/profile/ui/settings/ProfileSettingsViewModel';

export class ProfileManagerModule extends BaseModule {
  readonly module: ContainerModule = new ContainerModule(bind => {
    bind(RestProfileManagerRepository).toSelf().inSingletonScope();
    bind(SignalsProfileManagerState).toSelf().inSingletonScope();
    bind(ProfileManagerFacade).toSelf().inSingletonScope();
    bind(LoadProfilesUsecase).toSelf().inSingletonScope();

    bind(ProfileSettingsViewModel).toSelf().inTransientScope();
    bind(ManagerGatewayViewModel).toSelf().inTransientScope();

    bind(ProfileManagerState)
      .toDynamicValue(context => context.container.get(SignalsProfileManagerState))
      .inSingletonScope();

    bind(ProfileManagerRepository)
      .toDynamicValue(context => context.container.get(RestProfileManagerRepository))
      .inSingletonScope();

    bind(ProfileManagerPort)
      .toDynamicValue(context => context.container.get(ProfileManagerFacade))
      .inSingletonScope();
  });
}
