import { injectable } from 'inversify';

@injectable()
export class SplashService {
  private readonly splashId: string = 'vp-splash';
  private readonly finishedClass: string = 'finished';
  private readonly hiddenClass: string = 'hidden';
  private readonly animationTime: number = 500;

  show(): void {
    const splash = this.getSplash();
    splash?.classList.remove(this.finishedClass);
    splash?.classList.remove(this.hiddenClass);
  }

  async hide(): Promise<void> {
    const splash = this.getSplash();
    if (!splash) return;

    /**
     * Add extra time to prevent blinking.
     */
    await this.sleep(this.animationTime);
    splash.classList.add(this.finishedClass);

    /**
     * Wait until the animation finishes.
     */
    await this.sleep(this.animationTime);
    splash.classList.add(this.hiddenClass);
  }

  private getSplash(): HTMLElement | null {
    return document.getElementById(this.splashId);
  }

  private async sleep(time: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, time));
  }
}
