import { Stack, Typography } from '@mui/material';
import { FC, memo } from 'react';

export type AuthPageDescriptionProps = {
  title: string;
  description: string;
};

export const AuthPageDescription: FC<AuthPageDescriptionProps> = memo(({ title, description }) => {
  return (
    <Stack spacing={1.5} alignItems="center" mb={4}>
      <Typography
        variant="h2"
        color="text.secondary"
        sx={{ textAlign: 'center', textShadow: '0px 0px 32px rgba(255, 255, 255, 0.5), 0px 0px 128px rgba(255, 255, 255, 0.5)' }}
      >
        {title}
      </Typography>

      <Typography variant="body1Regular" color="text.disabled" display="inline-block" textAlign="center" whiteSpace="pre-line">
        {description}
      </Typography>
    </Stack>
  );
});

AuthPageDescription.displayName = AuthPageDescription.name;
