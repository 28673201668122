import { Link, LinkProps } from '@mui/material';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

export interface SphinxLinkProps extends LinkProps {
  text: string;
  to: string;
}

export const VpLink: FC<SphinxLinkProps> = ({ text, to, ...props }) => {
  return (
    <Link variant="body1Bold" color="primary.main" underline="none" component={RouterLink} to={to} {...props}>
      {text}
    </Link>
  );
};
