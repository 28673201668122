import { Stack, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC, PropsWithChildren } from 'react';

import { resolveSx } from '@vp/common/ui/util/resolveSx';

export type VpPaperProps = PropsWithChildren & {
  sx?: SxProps<Theme>;
};

export const VpPaper: FC<VpPaperProps> = ({ sx, children }) => {
  return (
    <Stack
      sx={theme => ({
        p: 2,
        width: 1,
        borderRadius: '12px',
        backgroundColor: 'background.200',
        ...resolveSx(sx, theme),
      })}
    >
      {children}
    </Stack>
  );
};
