import { Box, Fade, Modal, useTheme } from '@mui/material';
import { FC } from 'react';

import { VpSystemThemeProvider } from '@vp/common/ui/component/VpSystemThemeProvider';
import { ProfilePhotoModel } from '@vp/profile/core/model/ProfilePhotoModel';
import { ImageViewerContent } from '@vp/profile/ui/gallery/image/ImageViewerContent';

export interface ProfileImageViewer {
  open: boolean;
  selectedPhotoId: string | null;
  photos: ProfilePhotoModel[];
  close: () => void;
}

export const ProfileImageViewer: FC<ProfileImageViewer> = ({ selectedPhotoId, photos, open, close }) => {
  const { transitions } = useTheme();
  const fadeDuration = transitions.duration.modalFadeDuration;

  return (
    <Modal open={open} aria-modal hideBackdrop>
      <Fade in={open} timeout={fadeDuration} easing="ease-in-out">
        <Box width={1} height={1}>
          <VpSystemThemeProvider>
            <ImageViewerContent {...{ selectedPhotoId, photos, close }} />
          </VpSystemThemeProvider>
        </Box>
      </Fade>
    </Modal>
  );
};
