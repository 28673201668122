import { ContainerModule } from 'inversify';

import { BaseModule } from '@vp/common/BaseModule';
import { GalleryManagerFacade } from '@vp/manager/gallery/core/GalleryManagerFacade';
import { GalleryManagerPort } from '@vp/manager/gallery/core/interface/GalleryManagerPort';
import { GalleryManagerRepository } from '@vp/manager/gallery/core/interface/GalleryManagerRepository';
import { RemoveMediaUsecase } from '@vp/manager/gallery/core/usecase/RemoveMediaUsecase';
import { UploadMediaUsecase } from '@vp/manager/gallery/core/usecase/UploadMediaUsecase';
import { GalleryManagerDataMapper } from '@vp/manager/gallery/data/GalleryManagerDataMapper';
import { RestGalleryManagerRepository } from '@vp/manager/gallery/data/RestGalleryManagerRepository';
import { GalleryManagerMapper } from '@vp/manager/gallery/ui/GalleryManagerMapper';
import { GalleryManagerViewModel } from '@vp/manager/gallery/ui/GalleryManagerViewModel';
import { GalleryManagerPreviewViewModel } from '@vp/manager/gallery/ui/preview/GalleryManagerPreviewViewModel';
import { GalleryManagerUploadViewModel } from '@vp/manager/gallery/ui/upload/GalleryManagerUploadViewModel';

export class GalleryManagerModule extends BaseModule {
  readonly module: ContainerModule = new ContainerModule(bind => {
    bind(GalleryManagerMapper).toSelf().inTransientScope();
    bind(GalleryManagerViewModel).toSelf().inTransientScope();
    bind(GalleryManagerUploadViewModel).toSelf().inTransientScope();
    bind(GalleryManagerPreviewViewModel).toSelf().inTransientScope();

    bind(RestGalleryManagerRepository).toSelf().inSingletonScope();
    bind(GalleryManagerDataMapper).toSelf().inSingletonScope();

    bind(GalleryManagerRepository)
      .toDynamicValue(context => context.container.get(RestGalleryManagerRepository))
      .inSingletonScope();

    bind(UploadMediaUsecase).toSelf().inSingletonScope();
    bind(RemoveMediaUsecase).toSelf().inSingletonScope();
    bind(GalleryManagerFacade).toSelf().inSingletonScope();

    bind(GalleryManagerPort)
      .toDynamicValue(context => context.container.get(GalleryManagerFacade))
      .inSingletonScope();
  });
}
