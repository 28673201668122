import { injectable } from 'inversify';

import { OperationResult, Success } from '@vp/common/core/OperationResult';
import { UpdateBiographyDto } from '@vp/manager/biography/core/dto/UpdateBiographyDto';
import { BiographyManagerRepository } from '@vp/manager/biography/core/interface/BiographyManagerRepository';
import { ProfileManagerState } from '@vp/manager/profile/core/interface/ProfileManagerState';

@injectable()
export class UpdateBiographyUsecase {
  constructor(
    private readonly biographyManagerRepository: BiographyManagerRepository,
    private readonly profileManagerState: ProfileManagerState,
  ) {}

  async execute(dto: UpdateBiographyDto, controller?: AbortController): Promise<OperationResult> {
    const result = await this.biographyManagerRepository.update(dto, controller);

    if (result instanceof Success) {
      this.profileManagerState.update(result.value);
    }

    return result as OperationResult;
  }
}
