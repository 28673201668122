import { Axios } from 'axios';
import { injectable } from 'inversify';

import { Failure, OperationResult, Success } from '@vp/common/core/OperationResult';
import { ErrorExtractor } from '@vp/common/data/ErrorExtractor';
import { ThemingManagerRepository } from '@vp/manager/theming/core/interface/ThemingManagerRepository';
import { UpdateThemeDto } from '@vp/manager/theming/data/dto/UpdateThemeDto';
import { ProfileModel } from '@vp/profile/core/model/ProfileModel';
import { ProfileThemeModel } from '@vp/profile/core/model/ProfileThemeModel';
import { ProfileResponseDto } from '@vp/profile/data/dto/ProfileResponseDto';
import { ProfileDataMapper } from '@vp/profile/data/ProfileDataMapper';

@injectable()
export class RestThemingManagerRepository implements ThemingManagerRepository {
  constructor(
    private readonly http: Axios,
    private readonly profileMapper: ProfileDataMapper,
    private readonly errorExtractor: ErrorExtractor,
  ) {}

  async update(profileId: string, theme: ProfileThemeModel, controller?: AbortController): Promise<OperationResult<ProfileModel>> {
    try {
      const dto = new UpdateThemeDto(theme);
      const { data } = await this.http.put<ProfileResponseDto>(`/api/profiles/${profileId}`, dto, { signal: controller?.signal });
      return Success.from(this.profileMapper.toProfile(data));
    } catch (error) {
      const message = this.errorExtractor.extract(error);
      return Failure.from(message);
    }
  }
}
