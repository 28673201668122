import { FormControl, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { VpInput } from '@vp/common/ui/component/VpInput';
import { BiographyFormValues } from '@vp/manager/biography/ui/BiographyManagerViewModel';

interface BiographyManagerFormNameProps {
  disabled: boolean;
}

export const BiographyManagerFormName: FC<BiographyManagerFormNameProps> = ({ disabled }) => {
  const { spacing } = useTheme();
  const { register, formState } = useFormContext<BiographyFormValues>();

  return (
    <FormControl disabled={disabled} fullWidth error={!!formState.errors.name}>
      <Typography htmlFor="form-name" component="label" variant="formLabelMedium" color="text.primary" mb={spacing(0.75)}>
        Имя
      </Typography>
      <VpInput
        {...register('name', { required: true, minLength: 4, maxLength: 50 })}
        id="form-name"
        placeholder="Например, Александр Петров"
      />
    </FormControl>
  );
};
