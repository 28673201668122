import { createTheme, Theme, ThemeProvider } from '@mui/system';
import { useInjection } from 'inversify-react';
import { FC, PropsWithChildren, useMemo } from 'react';

import { ProfilePort } from '@vp/profile/core/interface/ProfilePort';
import { ProfileThemeModel } from '@vp/profile/core/model/ProfileThemeModel';
import { paletteDark, paletteLight } from '@vp/theme/palette';
import { theme as defaultTheme } from '@vp/theme/theme';

export type ProfileThemeProviderProps = PropsWithChildren & { theme?: ProfileThemeModel };

export const ProfileThemeProvider: FC<ProfileThemeProviderProps> = ({ children, theme }) => {
  const profilePort = useInjection(ProfilePort);

  const profileTheme = useMemo(() => {
    const profileTheme = theme ?? profilePort.profile.value.theme;
    return themeFactory(profileTheme);
  }, [profilePort.profile.value.theme, theme]);

  return <ThemeProvider theme={profileTheme}>{children}</ThemeProvider>;
};

function themeFactory(model: ProfileThemeModel): Theme {
  const options = model.mode === 'dark' ? paletteDark : paletteLight;
  const palette = { ...defaultTheme.palette, ...options, mode: model.mode };
  return createTheme(defaultTheme, { palette });
}
