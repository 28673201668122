import { ContainerModule } from 'inversify';

import { BaseModule } from '@vp/common/BaseModule';
import { HonorSubmitFacade } from '@vp/honor/core/HonorSubmitFacade';
import { HonorSubmitPort } from '@vp/honor/core/interface/HonorSubmitPort';
import { HonorSubmitRepository } from '@vp/honor/core/interface/HonorSubmitRepository';
import { SubmitHonorRecordUsecase } from '@vp/honor/core/usecase/SubmitHonorRecordUsecase';
import { RestHonorSubmitRepository } from '@vp/honor/data/RestHonorSubmitRepository';
import { HonorSubmitViewModel } from '@vp/honor/ui/HonorSubmitViewModel';

export class HonorModule extends BaseModule {
  readonly module: ContainerModule = new ContainerModule(bind => {
    bind(SubmitHonorRecordUsecase).toSelf().inSingletonScope();
    bind(HonorSubmitFacade).toSelf().inSingletonScope();
    bind(RestHonorSubmitRepository).toSelf().inSingletonScope();

    bind(HonorSubmitViewModel).toSelf().inTransientScope();

    bind(HonorSubmitRepository)
      .toDynamicValue(context => context.container.get(RestHonorSubmitRepository))
      .inSingletonScope();

    bind(HonorSubmitPort)
      .toDynamicValue(context => context.container.get(HonorSubmitFacade))
      .inSingletonScope();
  });
}
