import { FC } from 'react';

import { VpTabs } from '@vp/common/ui/component/VpTabs';
import { useSearchSection } from '@vp/common/ui/hook/useSearchSection';
import { HonorManagerSection } from '@vp/manager/honor/ui/HonorManagerSection';

interface HonorManagerTabsProps {
  disableInteractions: boolean;
}

const Tabs = [
  { id: HonorManagerSection.Approved, name: 'Одобренные', url: `.?s=${HonorManagerSection.Approved}` },
  { id: HonorManagerSection.Pending, name: 'Ожидание', url: `.?s=${HonorManagerSection.Pending}` },
  { id: HonorManagerSection.YourRecord, name: 'Ваша запись', url: `.?s=${HonorManagerSection.YourRecord}` },
];

export const HonorManagerTabs: FC<HonorManagerTabsProps> = () => {
  const activeTab = useSearchSection('s', HonorManagerSection.Approved);

  return <VpTabs tabs={Tabs} activeTabId={activeTab} sx={{ px: 3 }} />;
};
