import { Stack } from '@mui/material';
import { FC, useCallback, useState } from 'react';

import { HonorManagerEditForm } from '@vp/manager/honor/ui/component/edit/HonorManagerEditForm';
import { HonorManagerEditHeader } from '@vp/manager/honor/ui/component/edit/HonorManagerEditHeader';
import { ProfileHonorRecordModel } from '@vp/profile/core/model/ProfileHonorRecordModel';

interface HonorManagerEditContentProps {
  close: () => void;
  handleEdit: (id: string, newText: string) => Promise<void>;
  record: ProfileHonorRecordModel;
  isRequestInProgress: boolean;
}

export const HonorManagerEditContent: FC<HonorManagerEditContentProps> = ({ close, handleEdit, record, isRequestInProgress }) => {
  const [lockInteractions, setLockInteractions] = useState(false);

  const handleClose = useCallback(() => {
    if (!lockInteractions) {
      setLockInteractions(true);
      close();
    }
  }, [close, lockInteractions]);

  return (
    <Stack
      sx={({ palette }) => ({
        height: '100dvh',
        backgroundColor: palette.background.default,
      })}
    >
      <HonorManagerEditHeader close={handleClose} />

      <HonorManagerEditForm
        lockInteractions={lockInteractions}
        record={record}
        handleEdit={handleEdit}
        isRequestInProgress={isRequestInProgress}
      />
    </Stack>
  );
};
