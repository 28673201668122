import { styled } from '@mui/material';
import { ChangeEventHandler, FC, useCallback } from 'react';

const VpHiddenFileInputRoot = styled('input')({
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const AcceptPropToType: Record<string, string> = {
  image: 'image/*,image/heic,image/heif',
  video: 'video/*',
  audio: 'audio/*',
};

export interface FileUploadProps {
  multiple?: boolean;
  accept: 'audio' | 'video' | 'image' | string;
  onFileUpload: (files: File[]) => void;
}

export const VpHiddenFileInput: FC<FileUploadProps> = ({ multiple = false, accept, onFileUpload }) => {
  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    event => {
      const files = Array.from(event.target.files ?? []);

      if (files.length) {
        onFileUpload?.(files);
        event.target.value = '';
      }
    },
    [onFileUpload],
  );

  return <VpHiddenFileInputRoot accept={AcceptPropToType[accept!] ?? accept} type="file" multiple={multiple} onChange={onChange} />;
};
