import { computed, ReadonlySignal } from '@preact/signals-react';
import { injectable } from 'inversify';

import { OperationResult } from '@vp/common/core/OperationResult';
import { ProfileManagerState } from '@vp/manager/profile/core/interface/ProfileManagerState';
import { ThemingManagerPort } from '@vp/manager/theming/core/interface/ThemingManagerPort';
import { UpdateProfileThemeUsecase } from '@vp/manager/theming/core/usecase/UpdateProfileThemeUsecase';
import { ProfileThemeModel } from '@vp/profile/core/model/ProfileThemeModel';

@injectable()
export class ThemingManagerFacade implements ThemingManagerPort {
  readonly theme: ReadonlySignal<ProfileThemeModel> = computed(() => this.resolveTheme());

  constructor(
    private readonly profileManagerState: ProfileManagerState,
    private readonly updateThemeUsecase: UpdateProfileThemeUsecase,
  ) {}

  async updateTheme(theme: ProfileThemeModel, controller?: AbortController): Promise<OperationResult> {
    return await this.updateThemeUsecase.execute(theme, controller);
  }

  private resolveTheme(): ProfileThemeModel {
    return this.profileManagerState.active.value?.theme ?? { mode: 'dark', color: null };
  }
}
