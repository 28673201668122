import { ReadonlySignal } from '@preact/signals-react';

import { ProfileModel } from '@vp/profile/core/model/ProfileModel';

export type ProfileLoadingState = 'initial' | 'loading' | 'success' | 'error';

export abstract class ProfileState {
  abstract readonly profile: ReadonlySignal<ProfileModel>;
  abstract readonly loadingState: ReadonlySignal<ProfileLoadingState>;
  abstract readonly profileForActivation: ReadonlySignal<string | null>;

  abstract setProfile(profile: ProfileModel): void;
  abstract setLoadingState(state: ProfileLoadingState): void;
  abstract storeProfileForActivation(id: string): void;
  abstract resetProfileForActivation(): void;
}
