import { useCallback, useEffect } from 'react';

export const useVisibilityPause = (pause: () => void): void => {
  const handleVisibilityChange = useCallback(() => document.hidden && pause(), [pause]);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return (): void => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, [handleVisibilityChange]);
};
