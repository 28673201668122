import { injectable } from 'inversify';

import { HonorSubmitRepository } from '@vp/honor/core/interface/HonorSubmitRepository';
import { HonorRecordModel } from '@vp/honor/core/model/HonorRecordModel';
import { ProfileHonorRecordModel } from '@vp/profile/core/model/ProfileHonorRecordModel';

@injectable()
export class SubmitHonorRecordUsecase {
  constructor(private readonly honorSubmitRepository: HonorSubmitRepository) {}

  async execute(record: HonorRecordModel, profileId: string, controller?: AbortController): Promise<ProfileHonorRecordModel> {
    return await this.honorSubmitRepository.submitHonorRecord(record, profileId, controller);
  }
}
