import { Stack, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC, useMemo } from 'react';

import { VpButton } from '@vp/common/ui/component/VpButton';

export interface VpLimitInfoProps {
  text: string;
  profileId: string;
  sx?: SxProps<Theme>;
}

export const VpLimitInfo: FC<VpLimitInfoProps> = ({ text, profileId, sx }) => {
  const linkProps = useMemo(() => {
    if (!profileId) return null;
    return { href: `https://впамяти.рф/premium_features?pi=${profileId}`, target: '_blank' };
  }, [profileId]);

  return (
    <Stack sx={sx} alignItems="center" gap={2}>
      <Typography variant="body2Regular" color="text.disabled" align="center" sx={{ whiteSpace: 'pre-line' }}>
        {text}
      </Typography>

      <VpButton status="primary" variant="soft" text="Снять ограничения" {...linkProps} size="small" />
    </Stack>
  );
};
