import { Box, Fade, Modal, useTheme } from '@mui/material';
import { FC, useCallback, useState } from 'react';

import { BiographyManagerEditContent, EditModalFormValues } from '@vp/manager/biography/ui/edit/BiographyManagerEditContent';

interface BiographyManagerEditModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: EditModalFormValues) => void;
  suggestedText: string | null;
}

export const BiographyManagerEditModal: FC<BiographyManagerEditModalProps> = ({ open, onClose, onSubmit, suggestedText }) => {
  const { transitions } = useTheme();
  const fadeDuration = transitions.duration.modalFadeDuration;

  const [displayedText, setDisplayedText] = useState<string | null>(null);

  const handleEntering = useCallback(() => {
    if (suggestedText) {
      setDisplayedText(suggestedText);
    }
  }, [suggestedText]);

  const handleExited = useCallback(() => {
    setDisplayedText(null);
  }, []);

  return (
    <Modal open={open} aria-modal hideBackdrop>
      <Fade in={open} timeout={fadeDuration} easing="ease-in-out" onEntering={handleEntering} onExited={handleExited}>
        <Box
          width={1}
          height={1}
          autoFocus
          tabIndex={0}
          position="relative"
          sx={({ palette }) => ({ overflowY: 'scroll', outline: 'none', backgroundColor: palette.background.default })}
        >
          {displayedText && <BiographyManagerEditContent close={onClose} submit={onSubmit} text={displayedText} />}
        </Box>
      </Fade>
    </Modal>
  );
};
