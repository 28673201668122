import { Button, SvgIcon, Typography } from '@mui/material';
import { FC, useCallback } from 'react';

import ChevronRight from '@vp/assets/icons/ChevronRight.svg?react';
import { AppMenuItemModel } from '@vp/menu/ui/AppMenuItemModel';

export interface AppMenuListItemProps {
  open?: boolean;
  item: AppMenuItemModel;
  onItemClick: (item: AppMenuItemModel) => void;
}

export const AppMenuListItem: FC<AppMenuListItemProps> = ({ open, item, onItemClick }) => {
  const { Icon, text } = item;
  const onClick = useCallback(() => onItemClick(item), [item, onItemClick]);

  return (
    <Button
      sx={theme => ({
        backgroundColor: 'transparent',
        textTransform: 'none',
        pt: 2.25,
        pb: `calc(${theme.spacing(2.25)} - 1px)`,
        pl: 3,
        pr: 2,
        width: 1,
        justifyContent: 'flex-start',
        borderRadius: 0,
        alignItems: 'center',
        color: theme.palette.text.secondary,
        borderBottom: '1px solid',
        borderColor: theme.palette.transparency.alpha16,
      })}
      onClick={onClick}
    >
      <SvgIcon sx={{ fontSize: 24, mr: 1 }}>
        <Icon />
      </SvgIcon>

      <Typography variant="body1Medium" mr="auto">
        {text}
      </Typography>

      <SvgIcon
        style={{ transform: open ? 'rotate(90deg)' : 'rotate(0deg)' }}
        sx={theme => ({
          fontSize: 24,
          color: theme.palette.text.disabled,
          transition: 'transform 0.25s ease-in-out',
        })}
      >
        <ChevronRight />
      </SvgIcon>
    </Button>
  );
};
