import { Axios } from 'axios';
import { injectable } from 'inversify';

import { AvatarManagerRepository } from '@vp/manager/avatar/core/interfaces/AvatarManagerRepository';
import { AvatarManagerDataMapper } from '@vp/manager/avatar/data/AvatarManagerDataMapper';
import { ProfileModel } from '@vp/profile/core/model/ProfileModel';
import { ProfileResponseDto } from '@vp/profile/data/dto/ProfileResponseDto';
import { ProfileDataMapper } from '@vp/profile/data/ProfileDataMapper';


@injectable()
export class RestAvatarManagerRepository implements AvatarManagerRepository {
  constructor(
    private readonly http: Axios,
    private readonly profileDataMapper: ProfileDataMapper,
    private readonly avatarManagerMapper: AvatarManagerDataMapper,
  ) { }

  async upload(id: string, avatarFile: File, controller?: AbortController): Promise<ProfileModel> {
    const formData = this.avatarManagerMapper.toUploadFormData(avatarFile);

    const { data } = await this.http.put<ProfileResponseDto>(`/api/profiles/${id}/upload`, formData, {
      signal: controller?.signal,
    });

    return this.profileDataMapper.toProfile(data);
  }

  async remove(profileId: string, avatarId: string, controller?: AbortController): Promise<ProfileModel> {
    const formData = this.avatarManagerMapper.toRemoveFormData(avatarId);

    const { data } = await this.http.put<ProfileResponseDto>(`/api/profiles/${profileId}/upload`, formData, {
      signal: controller?.signal,
    });

    return this.profileDataMapper.toProfile(data);
  }
}
