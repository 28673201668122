import { Stack, Typography, useTheme } from '@mui/material';
import { FC, Fragment, memo, useCallback, useEffect, useRef, useState } from 'react';

import { VpSystemThemeProvider } from '@vp/common/ui/component/VpSystemThemeProvider';
import { useRectOnResize } from '@vp/common/ui/hook/useRectOnResize';
import { ProfileNavigation } from '@vp/profile/ui/navigation/ProfileNavigation';

export interface ProfileInfoProps {
  name: string;
  birthDate: string;
  deathDate: string;
}

export const ProfileInfo: FC<ProfileInfoProps> = memo(({ name, deathDate, birthDate }) => {
  const navigationRef = useRef<HTMLDivElement>(null!);
  const [navigationTop, setNavigationTop] = useState(0);
  const [navigationHeight, setNavigationHeight] = useState(0);
  const [scrollProgress, setScrollProgress] = useState(0);
  const { smallImageHeightMobile, fullImageHeightMobile, palette } = useTheme();

  const heightDiff = fullImageHeightMobile - smallImageHeightMobile;
  const dates = birthDate && deathDate ? `${birthDate} - ${deathDate}` : '';

  useRectOnResize(
    navigationRef,
    useCallback(
      ({ height }) => {
        setNavigationHeight(height);
        setNavigationTop(smallImageHeightMobile - height);
      },
      [smallImageHeightMobile],
    ),
  );

  useEffect(() => {
    const root = document.getElementById('root')!;

    const onScroll = (): void => {
      const distance = Math.max(root.scrollTop - heightDiff, 0);
      const progress = Math.min(distance / navigationHeight, 1) * 100;
      setScrollProgress(progress);
    };

    onScroll();
    root.addEventListener('scroll', onScroll);

    return (): void => root.removeEventListener('scroll', onScroll);
  }, [heightDiff, navigationHeight]);

  return (
    <Stack
      ref={navigationRef}
      width={1}
      position="sticky"
      sx={theme => ({ zIndex: theme.zIndex.galleryProfileInfo })}
      style={{ top: `${navigationTop}px` }}
    >
      <VpSystemThemeProvider>
        <ProfileInfoContent name={name} dates={dates} />
      </VpSystemThemeProvider>

      {palette.mode === 'light' && (
        <Stack sx={{ position: 'absolute', inset: 0, pointerEvents: 'none' }} style={{ clipPath: `inset(${scrollProgress}% 0 0 0)` }}>
          <ProfileInfoContent name={name} dates={dates} />
        </Stack>
      )}
    </Stack>
  );
});

ProfileInfo.displayName = ProfileInfo.name;

const ProfileInfoContent: FC<{ name: string; dates: string }> = memo(({ name, dates }) => {
  return (
    <Fragment>
      <Stack p={3} width={1} textAlign="center">
        <Typography
          variant="h3"
          sx={theme => ({
            color: 'text.secondary',
            ...theme.applyStyles('light', {
              color: 'text.primary',
            }),
          })}
        >
          {name || 'Имя не указано'}
        </Typography>

        <Typography variant="caption2Regular" color="text.disabled">
          {dates}
        </Typography>
      </Stack>
      <ProfileNavigation />
    </Fragment>
  );
});

ProfileInfoContent.displayName = ProfileInfoContent.name;
