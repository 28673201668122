import { FC, memo } from 'react';

import DemoEvent from '@vp/assets/demo/DemoEvent.jpg';
import DemoPhoto1 from '@vp/assets/demo/DemoPhoto1.jpg';
import DemoPhoto2 from '@vp/assets/demo/DemoPhoto2.jpg';
import DemoPhoto3 from '@vp/assets/demo/DemoPhoto3.jpg';
import DemoPhoto4 from '@vp/assets/demo/DemoPhoto4.jpg';
import { MediaGridItem, VpMediaGrid } from '@vp/common/ui/component/VpMediaGrid';

const Items: MediaGridItem[] = [
  { id: '1', hash: 'LPEV+:D%IUj[00t7oft7xuj[ofRj', url: DemoEvent, type: 'photo' },
  { id: '2', hash: 'L7A,zkay00M{ayM{-;Rj4nRj~qt7', url: DemoPhoto1, type: 'photo' },
  { id: '3', hash: 'LHE{kND%00xuIURjt7RjD%xu%MRj', url: DemoPhoto2, type: 'photo' },
  { id: '4', hash: 'LMH_=B~qRjj[-;%MRjRjxuoft7t7', url: DemoPhoto3, type: 'photo' },
  { id: '5', hash: 'LGHLbv-;4n%M~q%MIUxu%Mt7D%ay', url: DemoPhoto4, type: 'photo' },
];

export const ProfileExampleGallery: FC = memo(() => {
  return <VpMediaGrid items={Items} sx={{ p: 0 }} />;
});

ProfileExampleGallery.displayName = ProfileExampleGallery.name;
