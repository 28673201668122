export class Success<T = undefined> {
  constructor(readonly value: T) {}

  static from<T>(value: T): Success<T> {
    return new Success(value);
  }

  static empty(): Success {
    return new Success(undefined);
  }
}

export class Failure {
  constructor(readonly message: string) {}

  static from(message: string): Failure {
    return new Failure(message);
  }
}

export type OperationResult<T = undefined> = Success<T> | Failure;
