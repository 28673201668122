import { signal, Signal } from '@preact/signals-react';
import { injectable } from 'inversify';

import { AuthPort } from '@vp/auth/core/interface/AuthPort';
import { ViewModel, ViewModelDispose } from '@vp/common/ui/ViewModel';
import { AppNotificationService } from '@vp/notification/AppNotificationService';
import { ProfilePort } from '@vp/profile/core/interface/ProfilePort';
import { RouterService } from '@vp/routing/RouterService';

export type RegisterFormData = {
  email: string;
  password: string;
  passwordConfirmation: string;
  termsAndCondition: boolean;
  rights: boolean;
};

@injectable()
export class RegisterViewModel extends ViewModel implements ViewModelDispose {
  readonly registering: Signal<boolean> = signal(false);
  private controller?: AbortController;

  constructor(
    private readonly authPort: AuthPort,
    private readonly profilePort: ProfilePort,
    private readonly routerService: RouterService,
    private readonly notificationService: AppNotificationService,
  ) {
    super();
  }

  register = async ({ email, password }: RegisterFormData): Promise<void> => {
    try {
      this.controller = new AbortController();
      this.registering.value = true;
      await this.authPort.register(email, password, this.controller);
      this.handleRegisterSuccess();
    } catch {
      this.showErrorNotification();
    } finally {
      this.registering.value = false;
    }
  };

  dispose(): void {
    this.controller?.abort();
  }

  private handleRegisterSuccess(): void {
    if (this.profilePort.profileForActivation.value) {
      void this.routerService.navigate(`/p/${this.profilePort.profileForActivation.value}`);
    } else {
      void this.routerService.navigate('/ps');
    }
  }

  private showErrorNotification(): void {
    this.notificationService.enqueue({
      variant: 'error',
      message: 'Ошибка при регистрации',
    });
  }
}
