export class TokensModel {
  constructor(
    readonly access: string,
    readonly refresh: string,
    readonly expiresIn: number,
  ) {}

  isExpired(): boolean {
    const expiration = new Date(this.expiresIn * 1000);
    return expiration.getTime() < Date.now();
  }
}
