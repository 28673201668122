import { UploadState } from '@vp/manager/gallery/data/upload/state/UploadState';
import { ImageResponseDto } from '@vp/profile/data/dto/ImageResponseDto';
import { VideoResponseDto } from '@vp/profile/data/dto/VideoResponseDto';

interface UploadResponseDto {
  videos?: VideoResponseDto;
  images?: ImageResponseDto;
}

export class FinalizingState extends UploadState {
  async process(): Promise<void> {
    try {
      await this.finalizeUpload();
      this.determineTransition();
    } catch (error) {
      this.handleError(error);
      this.stateMachine.changeState(this.stateMachine.failedState);
    }
  }

  private async finalizeUpload(): Promise<void> {
    const fileUpload = this.stateMachine.context.getCurrentFileUpload();
    const formData = this.stateMachine.mapper.toMultipartUploadFormData(fileUpload, this.stateMachine.context.profileId);

    const { data } = await this.stateMachine.http.put<UploadResponseDto>('/api/multipart_uploads', formData, {
      signal: this.stateMachine.controller.signal,
    });

    data.videos && this.stateMachine.context.addVideo(data.videos);
    data.images && this.stateMachine.context.addImage(data.images);
  }

  private determineTransition(): void {
    if (!this.stateMachine.context.isLastFile()) {
      this.stateMachine.context.nextFile();
      this.stateMachine.changeState(this.stateMachine.initializationState);
    } else {
      this.stateMachine.changeState(this.stateMachine.completedState);
    }
  }
}
