import { Box, Stack } from '@mui/material';
import { forwardRef, useEffect, useRef } from 'react';

import CloseIcon from '@vp/assets/icons/Close.svg?react';
import { VpIconButton } from '@vp/common/ui/component/VpIconButton';
import { VpImage } from '@vp/common/ui/component/VpImage';
import { VpMediaGradientOverlay } from '@vp/common/ui/component/VpMediaGradientOverlay';
import { ProfilePhotoModel } from '@vp/profile/core/model/ProfilePhotoModel';

export interface ImageViewerContentProps {
  selectedPhotoId: string | null;
  photos: ProfilePhotoModel[];
  close: () => void;
}

export const ImageViewerContent = forwardRef<HTMLElement, ImageViewerContentProps>(({ selectedPhotoId, photos, close }, ref) => {
  const images = useRef<HTMLElement[]>([]);

  useEffect(() => {
    const index = photos.findIndex(({ id }) => selectedPhotoId === id);
    if (index !== -1) images.current[index]?.scrollIntoView();
  }, [selectedPhotoId, photos]);

  return (
    <Box
      ref={ref}
      autoFocus
      tabIndex={0}
      width={1}
      height={1}
      position="relative"
      sx={({ palette }) => ({ overflowY: 'scroll', outline: 'none', backgroundColor: palette.background.default })}
    >
      <Stack gap={1}>
        {photos.map((photo, index) => (
          <VpMediaGradientOverlay key={photo.id} sx={{ height: '90svh' }}>
            <VpImage ref={el => void (images.current[index] = el!)} src={photo.url} hash={photo.hash} />
          </VpMediaGradientOverlay>
        ))}
      </Stack>

      <VpIconButton
        Icon={CloseIcon}
        sx={theme => ({ p: 1, position: 'fixed', top: 0, left: 0, zIndex: theme.zIndex.mediaControls })}
        onClick={close}
      />
    </Box>
  );
});

ImageViewerContent.displayName = 'ImageViewerContent';
