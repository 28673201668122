export const PasswordValidationOptions = {
  required: 'Пароль должен содержать от 8 до 24 символов',
  minLength: {
    value: 8,
    message: 'Пароль должен содержать не менее 8 символов',
  },
  maxLength: {
    value: 24,
    message: 'Пароль должен содержать не более 24 символов',
  },
  pattern: {
    value: /^[A-Za-z0-9!@#$%^&*()_+]+$/,
    message: 'Пароль должен содержать только латинские буквы, цифры или специальные символы',
  },
};
