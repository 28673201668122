import { Box, Stack, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC, PropsWithChildren } from 'react';

import { resolveSx } from '@vp/common/ui/util/resolveSx';

export interface VpHeaderProps extends PropsWithChildren {
  sx?: SxProps<Theme>;
}

export interface VpHeaderColumnProps extends PropsWithChildren {
  align?: 'left' | 'center' | 'right';
  flex?: number;
}

const AlignToJustifyContent = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

export const VpHeaderColumn: FC<VpHeaderColumnProps> = ({ flex, align = 'center', children }) => {
  flex ??= align === 'center' ? 2 : 1;
  return (
    <Box flex={flex} display="flex" alignItems="center" justifyContent={AlignToJustifyContent[align]}>
      {children}
    </Box>
  );
};

export const VpHeader: FC<VpHeaderProps> = ({ children, sx }) => {
  return (
    <Stack
      direction="row"
      sx={theme => ({
        width: 1,
        pt: 1,
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: theme.zIndex.header,
        ...resolveSx(sx, theme),
      })}
    >
      {children}
    </Stack>
  );
};
