import { useCallback, useState } from 'react';

import { ProfileHonorNotificationStatus } from '@vp/profile/ui/honor/ProfileHonor';

export interface NotificationState {
  onShowNotification: (status: ProfileHonorNotificationStatus) => void;
  onCloseNotification: () => void;
  showNotification: boolean;
  notificationStatus: ProfileHonorNotificationStatus;
}

export const useNotification = (): NotificationState => {
  const [showNotification, setShowNotification] = useState(false);
  const [notificationStatus, setNotificationStatus] = useState<ProfileHonorNotificationStatus>('info');

  const onShowNotification = useCallback((status: ProfileHonorNotificationStatus) => {
    if (status !== 'info') {
      setNotificationStatus(status);
      setShowNotification(true);
    }
  }, []);

  const onCloseNotification = useCallback(() => setShowNotification(false), []);

  return {
    onShowNotification,
    onCloseNotification,
    showNotification,
    notificationStatus,
  };
};
