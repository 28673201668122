import * as Sentry from '@sentry/browser';
import { injectable } from 'inversify';

import { Analytics } from '@vp/common/core/Analytics';

@injectable()
export class SentryAnalytics implements Analytics {
  setUser(user: { id: string; email: string }): void {
    Sentry.setUser(user);
  }

  trackError(error: unknown): void {
    if (error instanceof Error) {
      Sentry.captureException(error);
    } else {
      Sentry.captureMessage(error as string);
    }
  }

  clearUser(): void {
    Sentry.setUser(null);
  }
}
