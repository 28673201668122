import { computed, ReadonlySignal } from '@preact/signals-react';
import { injectable } from 'inversify';

import { DateFormatter } from '@vp/common/ui/DateFormatter';
import { TimeAgoFormatter } from '@vp/common/ui/TimeAgoFormatter';
import { ViewModel } from '@vp/common/ui/ViewModel';
import { ProfilePort } from '@vp/profile/core/interface/ProfilePort';
import { ProfileEventModel } from '@vp/profile/core/model/ProfileEventModel';
import { ProfileHonorRecordModel } from '@vp/profile/core/model/ProfileHonorRecordModel';
import { ProfileModel } from '@vp/profile/core/model/ProfileModel';
import { SlideshowState, SlideshowStatus } from '@vp/slideshow/core/interface/SlideshowState';

export enum ProfileSection {
  Bio = 'bio',
  Events = 'events',
  Gallery = 'gallery',
  Honor = 'honor',
}

@injectable()
export class ProfileViewModel extends ViewModel {
  readonly profile: ReadonlySignal<ProfileModel> = computed(() => this.toViewProfile(this.profilePort.profile.value));
  readonly slideshowStatus: ReadonlySignal<SlideshowStatus> = this.slideshowState.slideshowStatus;

  constructor(
    private readonly profilePort: ProfilePort,
    private readonly timeAgoFormatter: TimeAgoFormatter,
    private readonly slideshowState: SlideshowState,
    private readonly dateFormatter: DateFormatter,
  ) {
    super();
  }

  private toViewProfile(profile: ProfileModel): ProfileModel {
    const birthDate = this.dateFormatter.toLocaleDate(profile.birthDate);
    const deathDate = this.dateFormatter.toLocaleDate(profile.deathDate);
    const events = this.toViewEvents(profile.events);
    const honorRecords = this.toProfileHonorRecords(profile.honorRecords);
    return { ...profile, birthDate, deathDate, events, honorRecords };
  }

  private toViewEvents(events: ProfileEventModel[]): ProfileEventModel[] {
    return events.map(event => ({ ...event, date: this.dateFormatter.toLocaleDate(event.date) }));
  }

  private toProfileHonorRecords(records: ProfileHonorRecordModel[]): ProfileHonorRecordModel[] {
    return records.map(record => ({ ...record, date: this.toTimeAgo(record.date) }));
  }

  private toTimeAgo(date: string): string {
    return this.timeAgoFormatter.getTimeAgo(new Date(date));
  }
}
