import { Theme } from '@mui/material';
import { SxProps, SystemStyleObject } from '@mui/system';

export function resolveSx(sx: SxProps<Theme> | undefined, theme: Theme): SystemStyleObject<Theme> {
  if (typeof sx === 'function') {
    return sx(theme);
  }

  return (sx ?? {}) as SystemStyleObject<Theme>;
}
