import { Box, Fade, Modal, useTheme } from '@mui/material';
import { FC } from 'react';

import { SlideshowStatus } from '@vp/slideshow/core/interface/SlideshowState';
import { SlideshowContent } from '@vp/slideshow/ui/component/SlideshowContent';

export interface SlideshowModalProps {
  slideshowStatus: SlideshowStatus;
}

export const SlideshowModal: FC<SlideshowModalProps> = ({ slideshowStatus }) => {
  const { transitions } = useTheme();
  const fadeDuration = transitions.duration.modalFadeDuration;

  return (
    <Modal open={slideshowStatus === 'open'} aria-modal hideBackdrop>
      <Fade in={slideshowStatus === 'open'} timeout={fadeDuration} easing="ease-in-out">
        <Box
          width={1}
          height={1}
          autoFocus
          tabIndex={0}
          position="relative"
          sx={({ palette }) => ({ overflowY: 'scroll', outline: 'none', backgroundColor: palette.background.default })}
        >
          <SlideshowContent />
        </Box>
      </Fade>
    </Modal>
  );
};
