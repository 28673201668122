import { MediaFileDto } from '@vp/manager/gallery/core/dto/MediaFileDto';
import { UploadMediaType } from '@vp/manager/gallery/core/dto/UploadMediaType';

export class UploadMediaDto {
  readonly profileId!: string;
  readonly files!: MediaFileDto[];
  readonly type!: UploadMediaType;

  constructor(args: UploadMediaDto) {
    Object.assign(this, args);
  }
}
