import { Stack, useTheme } from '@mui/material';
import { FC } from 'react';

import { ProfileEventModel } from '@vp/profile/core/model/ProfileEventModel';
import { ProfileEventDescription } from '@vp/profile/ui/events/ProfileEventDescription';
import { ProfileEventImage } from '@vp/profile/ui/events/ProfileEventImage';

export interface ProfileEventProps {
  event: ProfileEventModel;
}

export const ProfileEvent: FC<ProfileEventProps> = ({ event }) => {
  const { smallImageHeightMobile } = useTheme();
  const imageTop = smallImageHeightMobile + 8;

  return (
    <Stack position="relative" flexDirection="row" width={1}>
      <ProfileEventImage event={event} sx={{ mr: 5, position: 'sticky', top: imageTop }} />
      <ProfileEventDescription event={event} />
    </Stack>
  );
};
