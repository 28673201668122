import { Box, Fade, Modal, useTheme } from '@mui/material';
import { FC } from 'react';

import { VpSystemThemeProvider } from '@vp/common/ui/component/VpSystemThemeProvider';
import { ProfileVideoModel } from '@vp/profile/core/model/ProfileVideoModel';
import { VideoViewerContent } from '@vp/profile/ui/gallery/video/VideoViewerContent';

export interface ProfileVideoViewerProps {
  open: boolean;
  video: ProfileVideoModel | null;
  close: () => void;
}

export const ProfileVideoViewer: FC<ProfileVideoViewerProps> = ({ video, open, close }) => {
  const { transitions } = useTheme();
  const fadeDuration = transitions.duration.modalFadeDuration;

  return (
    <Modal open={open} aria-modal hideBackdrop>
      <Fade in={open} timeout={fadeDuration} easing="ease-in-out">
        <Box width={1} height={1}>
          <VpSystemThemeProvider>{video && <VideoViewerContent video={video} close={close} />}</VpSystemThemeProvider>
        </Box>
      </Fade>
    </Modal>
  );
};
