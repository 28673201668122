import { Stack, Typography } from '@mui/material';
import { FC } from 'react';

import Close from '@vp/assets/icons/Close.svg?react';
import { VpIconButton } from '@vp/common/ui/component/VpIconButton';
import { VpProgressBar } from '@vp/common/ui/component/VpProgressBar';

export type GalleryManagerUploadProgressProps = {
  progress: number;
  onCancel: () => void;
};

export const GalleryManagerUploadProgress: FC<GalleryManagerUploadProgressProps> = ({ progress, onCancel }) => {
  return (
    <Stack width={1} height={62} gap={1} justifyContent="flex-end">
      <Stack width={1} direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body2Regular" color="text.disabled">{`${progress}%`}</Typography>
        <VpIconButton size={24} sx={{ color: 'text.disabled', p: 0 }} Icon={Close} onClick={onCancel} />
      </Stack>

      <VpProgressBar progress={progress} />
    </Stack>
  );
};
