import { Axios } from 'axios';
import { injectable } from 'inversify';

import { Analytics } from '@vp/common/core/Analytics';
import { Failure, OperationResult, Success } from '@vp/common/core/OperationResult';
import { ErrorExtractor } from '@vp/common/data/ErrorExtractor';
import { SubmitEventsResultDto } from '@vp/manager/events/core/dto/SubmitEventsResultDto';
import { SumbitEventsDto, UpdateEventDto } from '@vp/manager/events/core/dto/SumbitEventsDto';
import { EventsManagerRepository } from '@vp/manager/events/core/interface/EventsManagerRepository';
import { EventsManagerRecordModel } from '@vp/manager/events/core/model/EventsManagerRecordModel';
import { EventsManagerDataMapper } from '@vp/manager/events/data/EventsManagerDataMapper';
import { TimelineResponseDto } from '@vp/profile/data/dto/TimelineResponseDto';

@injectable()
export class RestEventsManagerRepository implements EventsManagerRepository {
  constructor(
    private readonly http: Axios,
    private readonly dataMapper: EventsManagerDataMapper,
    private readonly errorExtractor: ErrorExtractor,
    private readonly analytics: Analytics,
  ) {}

  async loadEvents(profileId: string, controller: AbortController): Promise<OperationResult<EventsManagerRecordModel[]>> {
    try {
      const { data } = await this.http.get(`/api/timelines`, {
        params: { profile_id: profileId },
        signal: controller.signal,
      });
      return Success.from(this.dataMapper.toEvents(data));
    } catch (error) {
      return Failure.from(this.errorExtractor.extract(error));
    }
  }

  async submitEvents(dto: SumbitEventsDto, controller: AbortController): Promise<OperationResult<SubmitEventsResultDto>> {
    const updatePromises = dto.toUpdate.map(event => this.updateEvent(event, controller));
    const createPromise = dto.toCreate.length > 0 ? this.createEvents(dto.toCreate, dto.profileId, controller) : Promise.resolve();
    const deletePromise = dto.toDelete.length > 0 ? this.deleteEvents(dto.toDelete, dto.profileId, controller) : Promise.resolve();

    try {
      const [createdEvents, _, ...updatedEvents] = await Promise.all([createPromise, deletePromise, ...updatePromises]);
      const dto = new SubmitEventsResultDto(createdEvents ?? [], updatedEvents ?? []);
      return Success.from(dto);
    } catch (error) {
      this.analytics.trackError(error);
      return Failure.from(this.errorExtractor.extract(error));
    }
  }

  private async createEvents(
    events: EventsManagerRecordModel[],
    profileId: string,
    controller: AbortController,
  ): Promise<EventsManagerRecordModel[]> {
    const formData = this.dataMapper.toCreateFormData(events, profileId);

    const { data } = await this.http.post<TimelineResponseDto[]>(`/api/timelines/batches_create`, formData, {
      timeout: 10 * 60_000,
      headers: { 'Content-Type': 'multipart/form-data' },
      signal: controller?.signal,
    });

    return this.dataMapper.toEvents(data);
  }

  private async updateEvent(dto: UpdateEventDto, controller: AbortController): Promise<EventsManagerRecordModel> {
    const body = { ...dto, image: dto.image.file, 'removed_ids[]': dto.imageIdToRemove };
    const { data } = await this.http.put<TimelineResponseDto>(`/api/timelines/${dto.id}`, body, {
      headers: { 'Content-Type': 'multipart/form-data' },
      signal: controller?.signal,
    });
    return this.dataMapper.toEvent(data);
  }

  private async deleteEvents(ids: string[], profileId: string, controller: AbortController): Promise<void> {
    const data = { timeline_ids: ids };
    await this.http.delete(`/api/timelines?profile_id=${profileId}`, { data, signal: controller?.signal });
  }
}
