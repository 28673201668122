import { AxiosError, isAxiosError } from 'axios';
import { injectable } from 'inversify';

@injectable()
export class ErrorExtractor {
  extract(error: unknown): string {
    if (isAxiosError(error)) {
      return this.extractAxiosError(error);
    }

    if (error instanceof Error) {
      return error.message;
    }

    return '';
  }

  private extractAxiosError(error: AxiosError<{ error: string }>): string {
    return error.response?.data?.error || '';
  }
}
