import { FormControl, Stack, Typography } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import { ViewPasswordIcon } from '@vp/auth/ui/register/ViewPasswordIcon';
import { RestorePasswordFormData, RestorePasswordViewModel } from '@vp/auth/ui/restore-password/RestorePasswordViewModel';
import { PasswordValidationOptions } from '@vp/auth/ui/validation/PasswordValidationOptions';
import { VpFancyButton } from '@vp/common/ui/component/VpFancyButton';
import { VpInput } from '@vp/common/ui/component/VpInput';
import { useViewModel } from '@vp/common/ui/hook/useViewModel';

export const RestorePasswordForm: FC = () => {
  const viewModel = useViewModel(RestorePasswordViewModel);

  const { register, handleSubmit, formState, watch } = useForm<RestorePasswordFormData>({
    defaultValues: { password: '', passwordConfirmation: '' },
    mode: 'onTouched',
  });

  const password = watch('password');
  const [showPassword, setShowPassword] = useState(false);
  const passwordInputType = showPassword ? 'text' : 'password';

  const handleClickShowPassword = useCallback(() => setShowPassword(state => !state), []);

  return (
    <Stack component="form" width={1} alignItems="center" gap={3} onSubmit={handleSubmit(viewModel.restorePassword)}>
      <FormControl fullWidth error={!!formState.errors.password} disabled={viewModel.loading.value}>
        <Typography htmlFor="form-password" component="label" variant="formLabelMedium" color="text.primary" gutterBottom>
          Пароль
        </Typography>
        <VpInput
          {...register('password', PasswordValidationOptions)}
          id="form-password"
          autoComplete="new-password"
          placeholder={'Введите ваш пароль'}
          type={passwordInputType}
          endAdornment={<ViewPasswordIcon showPassword={showPassword} onClick={handleClickShowPassword} />}
        />
        {formState.errors.password?.message && (
          <Typography variant="body2Regular" color="danger.500" mt={0.75}>
            {formState.errors.password.message}
          </Typography>
        )}
      </FormControl>

      <FormControl fullWidth sx={{ mb: 1 }} error={!!formState.errors.passwordConfirmation} disabled={viewModel.loading.value}>
        <Typography htmlFor="form-password-confirmation" component="label" variant="formLabelMedium" color="text.primary" gutterBottom>
          Подтвердите Пароль
        </Typography>
        <VpInput
          {...register('passwordConfirmation', {
            required: 'Пожалуйста, подтвердите пароль',
            validate: value => value === password || 'Пароли не совпадают',
          })}
          autoComplete="new-password"
          endAdornment={<ViewPasswordIcon showPassword={showPassword} onClick={handleClickShowPassword} />}
          id="form-password-confirmation"
          placeholder={'Подтвердите ваш пароль'}
          type={passwordInputType}
        />
        {formState.errors.passwordConfirmation?.message && (
          <Typography variant="body2Regular" color="danger.500" mt={0.75}>
            {formState.errors.passwordConfirmation.message}
          </Typography>
        )}
      </FormControl>

      <VpFancyButton text="Изменить" type="submit" disabled={!formState.isValid} loading={viewModel.loading.value} />
    </Stack>
  );
};
