import { MutableRefObject, useLayoutEffect } from 'react';

export const useRectOnResize = (ref: MutableRefObject<HTMLElement | null>, handleRectChange: (rect: DOMRect) => void): void => {
  useLayoutEffect(() => {
    const onResize = (): void => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        handleRectChange(rect);
      }
    };

    onResize();
    window.addEventListener('resize', onResize);

    return (): void => window.removeEventListener('resize', onResize);
  }, [handleRectChange, ref]);
};
