import { useTheme } from '@mui/material';
import { useMemo } from 'react';

type GradientStop = { percent: number; opacity: number };

export type UseLinearGradientProps = {
  angle: string;
  stops: GradientStop[];
  color?: string;
};

export const useLinearGradient = ({ angle, stops, color }: UseLinearGradientProps): string => {
  const { palette } = useTheme();
  color ??= palette.primary[500];

  return useMemo(() => {
    const colorStops = stops
      .map(({ percent, opacity }) => `color-mix(in srgb, ${color} ${opacity * 100}%, transparent) ${percent}%`)
      .join(', ');

    return `linear-gradient(${angle}, ${colorStops})`;
  }, [angle, color, stops]);
};
