import { injectable } from 'inversify';

import { MediaFileDto } from '@vp/manager/gallery/core/dto/MediaFileDto';
import { RemoveMediaDto } from '@vp/manager/gallery/core/dto/RemoveMediaDto';
import { UploadMediaDto } from '@vp/manager/gallery/core/dto/UploadMediaDto';
import { UploadMediaType } from '@vp/manager/gallery/core/dto/UploadMediaType';
import { HandleState, UploadHandleSnapshot } from '@vp/manager/gallery/core/interface/GalleryManagerUploadHandle';
import { FileUpload } from '@vp/manager/gallery/data/upload/context/FileUpload';
import { UploadContext } from '@vp/manager/gallery/data/upload/context/UploadContext';
import { UploadState } from '@vp/manager/gallery/data/upload/state/UploadState';
import { UploadStateMachine } from '@vp/manager/gallery/data/upload/UploadStateMachine';
import { ProfileDataMapper } from '@vp/profile/data/ProfileDataMapper';

export enum AttachmentType {
  Images = 'images',
  Videos = 'videos',
}

@injectable()
export class GalleryManagerDataMapper {
  constructor(private readonly profileMapper: ProfileDataMapper) {}

  toUploadStateMachineContext(dto: UploadMediaDto, chunkSize: number): UploadContext {
    return new UploadContext(dto.files, dto.type, chunkSize, dto.profileId);
  }

  toUploadHandleSnapshot(machine: UploadStateMachine): UploadHandleSnapshot {
    return {
      state: this.toUploadHandleState(machine),
      progress: machine.context.progress,
      error: machine.context.error,
      fileProgressTracker: this.toFileProgressTracker(machine),
      uploadedPhotos: machine.context.images.map(image => this.profileMapper.toPhoto(image)),
      uploadedVideos: machine.context.videos.map(video => this.profileMapper.toVideo(video)),
    };
  }

  toMultipartUploadFormData(fileUpload: FileUpload, profileId: string): FormData {
    const data = new FormData();

    data.set('key', fileUpload.uploadKey!);
    data.set('upload_id', fileUpload.uploadId!);
    data.set('profile_id', profileId);
    data.set('content_type', fileUpload.mediaFile.file.type);
    data.set('parts', this.toParts(fileUpload));

    return data;
  }

  toUploadFormData(files: MediaFileDto[], type: UploadMediaType): FormData {
    const attachment = this.toAttachmentType(type);

    const formData = new FormData();
    formData.append('attachment_type', attachment);

    for (const { id, file } of files) {
      formData.append(`${attachment}[${id}]`, file);
    }

    return formData;
  }

  toRemoveFormData(dto: RemoveMediaDto): FormData {
    const formData = new FormData();
    formData.append('attachment_type', this.toAttachmentType(dto.type));
    formData.append('removed_ids[]', dto.id);
    return formData;
  }

  private toAttachmentType(type: UploadMediaType): AttachmentType {
    return type === UploadMediaType.Photos ? AttachmentType.Images : AttachmentType.Videos;
  }

  private toUploadHandleState(machine: UploadStateMachine): UploadHandleSnapshot['state'] {
    const stateMap = new Map<UploadState, HandleState>([
      [machine.completedState, 'completed'],
      [machine.failedState, 'failed'],
    ]);

    return stateMap.get(machine.currentState!) ?? 'uploading';
  }

  private toFileProgressTracker(machine: UploadStateMachine): UploadHandleSnapshot['fileProgressTracker'] {
    const tracker: UploadHandleSnapshot['fileProgressTracker'] = {};

    machine.context.fileRegistry.forEach((fileUpload, index) => {
      tracker[index] = fileUpload.progress;
    });

    return tracker;
  }

  private toParts(fileUpload: FileUpload): string {
    const parts = fileUpload.chunks.map(chunk => ({ part_number: chunk.order, etag: chunk.eTag }));
    return JSON.stringify(parts);
  }
}
