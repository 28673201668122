import { Stack } from '@mui/material';
import { FC, useCallback } from 'react';

import { EventsManagerFormDate } from '@vp/manager/events/ui/form/EventsManagerFormDate';
import { EventsManagerFormDescription } from '@vp/manager/events/ui/form/EventsManagerFormDescription';
import { EventsManagerFormRemoveButton } from '@vp/manager/events/ui/form/EventsManagerFormRemoveButton';
import { EventsManagerFormImage } from '@vp/manager/events/ui/form/image/EventsManagerFormImage';

interface EventsManagerFormItemProps {
  index: number;
  id: string;
  disableControls: boolean;
  removeEvent: (index: number) => void;
}

export const EventsManagerFormItem: FC<EventsManagerFormItemProps> = ({ id, index, disableControls, removeEvent }) => {
  const onRemove = useCallback(() => removeEvent(index), [index, removeEvent]);

  return (
    <Stack px={3} gap={3}>
      <Stack direction="row" alignItems="center" gap={2}>
        <EventsManagerFormImage disableControls={disableControls} index={index} id={id} />

        <Stack direction="row" alignItems="center" gap={1}>
          <EventsManagerFormDate disableControls={disableControls} index={index} />
          <EventsManagerFormRemoveButton disabled={disableControls} onRemove={onRemove} />
        </Stack>
      </Stack>

      <EventsManagerFormDescription disableControls={disableControls} index={index} />
    </Stack>
  );
};
