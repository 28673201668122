import { FC, Fragment } from 'react';

import { VpFallback } from '@vp/common/ui/component/VpFallback';
import { useViewModel } from '@vp/common/ui/hook/useViewModel';
import { ProfileActivationPage } from '@vp/profile/ui/activation/ProfileActivationPage';
import { ProfileGatewayViewModel } from '@vp/profile/ui/gateway/ProfileGatewayViewModel';
import { ProfilePage } from '@vp/profile/ui/ProfilePage';

export const ProfileGateway: FC = () => {
  const viewModel = useViewModel(ProfileGatewayViewModel);
  const success = viewModel.state.value === 'success';
  const error = viewModel.state.value === 'error';

  if (viewModel.state.value === 'loading') {
    return null;
  }

  return (
    <Fragment>
      {error && <VpFallback title="Что-то пошло не так" text="Вероятно, возникла техническая проблема или профиль не найден" showRefresh />}
      {success && viewModel.profile.value.active && <ProfilePage />}
      {success && !viewModel.profile.value.active && <ProfileActivationPage />}
    </Fragment>
  );
};
