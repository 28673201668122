import { injectable } from 'inversify';

import { OperationResult, Success } from '@vp/common/core/OperationResult';
import { ProfileManagerState } from '@vp/manager/profile/core/interface/ProfileManagerState';
import { ThemingManagerRepository } from '@vp/manager/theming/core/interface/ThemingManagerRepository';
import { ProfileThemeModel } from '@vp/profile/core/model/ProfileThemeModel';

@injectable()
export class UpdateProfileThemeUsecase {
  constructor(
    private readonly profileManagerState: ProfileManagerState,
    private readonly repository: ThemingManagerRepository,
  ) {}

  async execute(theme: ProfileThemeModel, controller?: AbortController): Promise<OperationResult> {
    const id = this.profileManagerState.active.value!.id;
    const result = await this.repository.update(id, theme, controller);

    if (result instanceof Success) {
      this.profileManagerState.update(result.value);
    }

    return result as OperationResult;
  }
}
