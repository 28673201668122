import { ReadonlySignal } from '@preact/signals-react';
import { injectable } from 'inversify';

import { AuthPort } from '@vp/auth/core/interface/AuthPort';
import { AuthRepository } from '@vp/auth/core/interface/AuthRepository';
import { AuthState } from '@vp/auth/core/interface/AuthState';
import { TokensStorage } from '@vp/auth/core/interface/TokensStorage';
import { TokensModel } from '@vp/auth/core/model/TokensModel';
import { LoginUsecase } from '@vp/auth/core/usecase/LoginUsecase';
import { LogoutUsecase } from '@vp/auth/core/usecase/LogoutUsecase';
import { RefreshUsecase } from '@vp/auth/core/usecase/RefreshUsecase';
import { RegisterUsecase } from '@vp/auth/core/usecase/RegisterUsecase';
import { ValidateAuthUsecase } from '@vp/auth/core/usecase/ValidateAuthUsecase';
import { ValidateRecoveryTokenUsecase } from '@vp/auth/core/usecase/ValidateRecoveryTokenUsecase';
import { OperationResult } from '@vp/common/core/OperationResult';

@injectable()
export class AuthFacade implements AuthPort {
  readonly email: ReadonlySignal<string> = this.authState.email;

  constructor(
    private readonly loginUsecase: LoginUsecase,
    private readonly registerUsecase: RegisterUsecase,
    private readonly tokensStorage: TokensStorage,
    private readonly refreshUsecase: RefreshUsecase,
    private readonly validateAuthUsecase: ValidateAuthUsecase,
    private readonly logoutUsecase: LogoutUsecase,
    private readonly validateRecoveryTokenUsecase: ValidateRecoveryTokenUsecase,
    private readonly authState: AuthState,
    private readonly repository: AuthRepository,
  ) {}

  async register(email: string, password: string, controller?: AbortController): Promise<void> {
    await this.registerUsecase.execute(email, password, controller);
  }

  async login(email: string, password: string, controller?: AbortController): Promise<void> {
    await this.loginUsecase.execute(email, password, controller);
  }

  async getTokens(): Promise<TokensModel | null> {
    return this.tokensStorage.retrieve();
  }

  async refresh(tokens: TokensModel, controller?: AbortController): Promise<TokensModel> {
    return this.refreshUsecase.execute(tokens, controller);
  }

  async validate(controller?: AbortController): Promise<void> {
    await this.validateAuthUsecase.execute(controller);
  }

  async logout(): Promise<void> {
    await this.logoutUsecase.execute();
  }

  async sendResetPasswordLink(email: string, controller?: AbortController): Promise<OperationResult> {
    return await this.repository.sendResetPasswordLink(email, controller);
  }

  async restorePassword(password: string, recoveryToken: string, controller?: AbortController): Promise<OperationResult> {
    return await this.repository.restorePassword(password, recoveryToken, controller);
  }

  validateRecoveryToken(token: string): boolean {
    return this.validateRecoveryTokenUsecase.execute(token);
  }
}
