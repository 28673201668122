import { batch, Signal, signal } from '@preact/signals-react';
import { injectable } from 'inversify';

import { FinishedState } from '@vp/slideshow/core/engine/state/FinishedState';
import { PlayingState } from '@vp/slideshow/core/engine/state/PlayingState';
import { SlideshowEngineFactoryPort } from '@vp/slideshow/core/interface/SlideshowEngineFactoryPort';
import { SlideshowEnginePort } from '@vp/slideshow/core/interface/SlideshowEnginePort';

@injectable()
export class SlideshowEngineController {
  readonly progress: Signal<number> = signal(0);
  readonly currentSlide: Signal<number> = signal(0);
  readonly currentSlideProgress: Signal<number> = signal(0);
  readonly isPaused: Signal<boolean> = signal(true);
  readonly isFinished: Signal<boolean> = signal(false);

  private engine: SlideshowEnginePort | null = null;
  private frameId!: number;

  constructor(private readonly engineFactory: SlideshowEngineFactoryPort) {}

  start(slidesCount: number, slideTime: number): void {
    this.engine = this.engineFactory.create(slidesCount, slideTime);
    this.tick();
  }

  stop(): void {
    cancelAnimationFrame(this.frameId);
  }

  play(): void {
    this.engine?.play();
  }

  pause(): void {
    this.engine?.pause();
  }

  goTo(slide: number): void {
    this.engine?.goTo(slide);
  }

  private tick(): void {
    this.frameId = requestAnimationFrame(() => {
      this.engine?.tick();
      this.updateState();
      this.tick();
    });
  }

  private updateState(): void {
    batch(() => {
      this.progress.value = this.engine?.progress ?? 0;
      this.currentSlide.value = this.engine?.currentSlide ?? 0;
      this.currentSlideProgress.value = this.engine?.currentSlideProgress ?? 0;
      this.isPaused.value = !this.engine?.isIn(PlayingState);
      this.isFinished.value = this.engine?.isIn(FinishedState) ?? false;
    });
  }
}
