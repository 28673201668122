import { ReadonlySignal } from '@preact/signals-react';
import { injectable } from 'inversify';

import { AuthState } from '@vp/auth/core/interface/AuthState';
import { SplashService } from '@vp/common/ui/SplashService';
import { ViewModel, ViewModelInit } from '@vp/common/ui/ViewModel';
import { RouterService } from '@vp/routing/RouterService';

@injectable()
export class GuardViewModel extends ViewModel implements ViewModelInit {
  readonly authenticated: ReadonlySignal<boolean> = this.authState.authenticated;

  constructor(
    private readonly authState: AuthState,
    private readonly routerService: RouterService,
    private readonly splashService: SplashService,
  ) {
    super();
  }

  async init(): Promise<void> {
    if (!this.authenticated.value) {
      await this.routerService.navigate('/auth/login', { replace: true });
      await this.splashService.hide();
    }
  }
}
