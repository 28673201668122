import { FC, useMemo } from 'react';

import { VpFancyButton } from '@vp/common/ui/component/VpFancyButton';
import { AiOptionStatus } from '@vp/manager/biography/ui/BiographyManagerViewModel';

interface BiographyManagerFormSubmitButtonProps {
  aiOptionStatus: AiOptionStatus;
  loading: boolean;
  disabled: boolean;
  onClick: () => void;
}

export const BiographyManagerFormSubmitButton: FC<BiographyManagerFormSubmitButtonProps> = ({
  loading,
  disabled,
  aiOptionStatus,
  onClick,
}) => {
  const text = useMemo(() => {
    if (aiOptionStatus === AiOptionStatus.SupplementedDescription) {
      return 'Сгенерировать';
    }

    if (aiOptionStatus === AiOptionStatus.SpellingCheck) {
      return 'Корректировать';
    }

    return 'Сохранить';
  }, [aiOptionStatus]);

  return (
    <VpFancyButton
      onClick={onClick}
      disabled={disabled && aiOptionStatus === AiOptionStatus.None}
      type="submit"
      loading={loading}
      text={text}
    />
  );
};
