import { Axios } from 'axios';
import { injectable } from 'inversify';

import { ProfileManagerRepository } from '@vp/manager/profile/core/interface/ProfileManagerRepository';
import { ProfileModel } from '@vp/profile/core/model/ProfileModel';
import { ProfileResponseDto } from '@vp/profile/data/dto/ProfileResponseDto';
import { ProfileDataMapper } from '@vp/profile/data/ProfileDataMapper';

@injectable()
export class RestProfileManagerRepository implements ProfileManagerRepository {
  constructor(
    private readonly http: Axios,
    private readonly profileDataMapper: ProfileDataMapper,
  ) {}

  async getProfiles(controller?: AbortController): Promise<ProfileModel[]> {
    const { data } = await this.http.get<ProfileResponseDto[]>('/api/profiles', { signal: controller?.signal });
    return data.map(dto => this.profileDataMapper.toProfile(dto));
  }
}
