import { ReadonlySignal } from '@preact/signals-react';
import { injectable } from 'inversify';

import { AuthState } from '@vp/auth/core/interface/AuthState';
import { OperationResult } from '@vp/common/core/OperationResult';
import { ProfilePort } from '@vp/profile/core/interface/ProfilePort';
import { ProfileLoadingState, ProfileState } from '@vp/profile/core/interface/ProfileState';
import { ProfileModel } from '@vp/profile/core/model/ProfileModel';
import { ActivateProfileUsecase } from '@vp/profile/core/usecase/ActivateProfileUsecase';
import { LoadProfileUsecase } from '@vp/profile/core/usecase/LoadProfileUsecase';
import { ScheduleSlideshowUsecase } from '@vp/slideshow/core/usecase/ScheduleSlideshowUsecase';

@injectable()
export class ProfileFacade implements ProfilePort {
  readonly loadingState: ReadonlySignal<ProfileLoadingState> = this.profileState.loadingState;
  readonly profile: ReadonlySignal<ProfileModel> = this.profileState.profile;
  readonly canActivateProfile: ReadonlySignal<boolean> = this.authState.authenticated;
  readonly profileForActivation: ReadonlySignal<string | null> = this.profileState.profileForActivation;

  constructor(
    private readonly authState: AuthState,
    private readonly profileState: ProfileState,
    private readonly loadProfileUsecase: LoadProfileUsecase,
    private readonly activateProfileUsecase: ActivateProfileUsecase,
    private readonly scheduleSlideshowUsecase: ScheduleSlideshowUsecase,
  ) {}

  async loadProfile(id: string, controller?: AbortController): Promise<void> {
    await this.loadProfileUsecase.execute(id, controller);
    await this.scheduleSlideshowUsecase.execute(this.profileState.profile.value);
  }

  async activateProfile(id: string, controller?: AbortController): Promise<OperationResult> {
    return await this.activateProfileUsecase.execute(id, controller);
  }

  storeProfileForActivation(id: string): void {
    this.profileState.storeProfileForActivation(id);
  }

  resetProfileForActivation(): void {
    this.profileState.resetProfileForActivation();
  }
}
