import { injectable } from 'inversify';

import { AuthState } from '@vp/auth/core/interface/AuthState';
import { TokensStorage } from '@vp/auth/core/interface/TokensStorage';
import { Analytics } from '@vp/common/core/Analytics';
import { ProfileManagerState } from '@vp/manager/profile/core/interface/ProfileManagerState';

@injectable()
export class LogoutUsecase {
  constructor(
    private readonly authState: AuthState,
    private readonly tokensStorage: TokensStorage,
    private readonly profileManagerState: ProfileManagerState,
    private readonly analytics: Analytics,
  ) {}

  async execute(): Promise<void> {
    this.authState.reset();
    this.profileManagerState.reset();
    await this.tokensStorage.clear();
    this.analytics.clearUser();
  }
}
