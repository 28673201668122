import { Stack } from '@mui/material';
import { FC } from 'react';

import { AppMenuItemModel } from '@vp/menu/ui/AppMenuItemModel';
import { AppMenuListItem } from '@vp/menu/ui/AppMenuListItem';
import { AppMenuProfiles } from '@vp/menu/ui/profiles/AppMenuProfiles';

export interface AppMenuListProps {
  authenticated: boolean;
  items: AppMenuItemModel[];
  onItemClick: (item: AppMenuItemModel) => void;
}

export const AppMenuList: FC<AppMenuListProps> = ({ authenticated, items, onItemClick }) => {
  return (
    <Stack width={1}>
      {authenticated && <AppMenuProfiles />}

      {items.map(item => (
        <AppMenuListItem key={item.id} item={item} onItemClick={onItemClick} />
      ))}
    </Stack>
  );
};
