import { injectable } from 'inversify';

@injectable()
export class DateFormatter {
  toLocaleDate(date: string): string {
    if (!date) return '';

    return new Date(date).toLocaleDateString('ru-RU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  }
}
