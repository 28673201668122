import { Axios } from 'axios';
import { injectable } from 'inversify';

import { HonorSubmitRepository } from '@vp/honor/core/interface/HonorSubmitRepository';
import { HonorRecordModel } from '@vp/honor/core/model/HonorRecordModel';
import { ProfileHonorRecordModel } from '@vp/profile/core/model/ProfileHonorRecordModel';
import { ProfileDataMapper } from '@vp/profile/data/ProfileDataMapper';

@injectable()
export class RestHonorSubmitRepository implements HonorSubmitRepository {
  constructor(
    private readonly http: Axios,
    private readonly profileDataMapper: ProfileDataMapper,
  ) {}

  async submitHonorRecord(record: HonorRecordModel, profileId: string, controller?: AbortController): Promise<ProfileHonorRecordModel> {
    const body = { author: record.name, text: record.text };
    const { data } = await this.http.post(`/api/tributes?profile_id=${profileId}`, body, { signal: controller?.signal });
    return this.profileDataMapper.toHonorRecord(data);
  }
}
