import { Stack } from '@mui/material';
import { FC } from 'react';

import { AuthPageDescription } from '@vp/auth/ui/AuthPageDescription';
import { RegisterForm } from '@vp/auth/ui/register/RegisterForm';
import { VpFade } from '@vp/common/ui/component/VpFade';

export const RegisterPage: FC = () => {
  return (
    <VpFade>
      <Stack width="100%" px={3} pt={5} alignItems="center">
        <AuthPageDescription title="Регистрация" description="Ваша электронная почта будет использоваться для входа в аккаунт." />
        <RegisterForm />
      </Stack>
    </VpFade>
  );
};
