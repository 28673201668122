import { Stack, Typography } from '@mui/material';
import { FC } from 'react';

import ChatIcon from '@vp/assets/icons/Chat.svg?react';
import { VpIcon } from '@vp/common/ui/component/VpIcon';

export const NoRecordsMessage: FC = () => {
  return (
    <Stack alignItems="center" mt={12.5}>
      <VpIcon Icon={ChatIcon} size={64} glowing />

      <Typography
        mt={1}
        textAlign="center"
        color="text.secondary"
        variant="h4"
        sx={{ textShadow: '0px 0px 32px rgba(255, 255, 255, 0.5), 0px 0px 128px rgba(255, 255, 255, 0.5)' }}
      >
        Здесь пока ничего нет
      </Typography>

      <Typography mt={1} textAlign="center" color="text.secondaryDisabled" variant="body1Regular">
        Поделитесь с близкими возможностью оставить запись о вашем ушедшем близком, чтобы сохранить дорогие воспоминания.
      </Typography>
    </Stack>
  );
};
