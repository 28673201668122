import { injectable } from 'inversify';

import { HonorManagerRepository } from '@vp/manager/honor/core/interface/HonorManagerRepository';
import { HonorManagerState } from '@vp/manager/honor/core/interface/HonorManagerState';

@injectable()
export class RemoveHonorRecordUsecase {
  constructor(
    private readonly honorManagerRepository: HonorManagerRepository,
    private readonly honorManagerState: HonorManagerState,
  ) {}

  async execute(ids: string[], profileId: string, controller?: AbortController): Promise<void> {
    await this.honorManagerRepository.removeHonorRecords(ids, profileId, controller);
    this.honorManagerState.deleteRecords(ids);
  }
}
