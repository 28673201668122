import { useIsPresent } from 'framer-motion';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export function useSearchSectionCheck(name: string, defaultSection: string): void {
  const navigate = useNavigate();
  const location = useLocation();
  const isPresent = useIsPresent();

  useEffect(() => {
    if (!isPresent) return;

    const search = new URLSearchParams(location.search);

    if (!search.has(name)) {
      search.set(name, defaultSection);
      navigate(`${location.pathname}?${search.toString()}`, { replace: true });
    }
  }, [location.search, location.pathname, navigate, defaultSection, name, isPresent]);
}
