import { Box, Stack, Typography } from '@mui/material';
import { FC } from 'react';

import { VpFancyButton } from '@vp/common/ui/component/VpFancyButton';
import { VpLink } from '@vp/common/ui/component/VpLink';

export const LandingNavigation: FC = () => {
  return (
    <Stack>
      <VpFancyButton text="Зарегистрироваться" to="/auth/register" />

      <Box height={theme => theme.spacing(1)} />

      <Stack direction="row" justifyContent="center" spacing={1} py={1.625} mb={1}>
        <Typography variant="body1Regular" color="text.disabled">
          Уже есть аккаунт?
        </Typography>

        <VpLink
          text="Войти"
          to="/auth/login"
          sx={{ textShadow: '0px 0px 32px rgba(255, 255, 255, 0.5), 0px 0px 128px rgba(255, 255, 255, 0.5)' }}
        />
      </Stack>
    </Stack>
  );
};
