import { Axios } from 'axios';
import { injectable } from 'inversify';

import { HonorManagerRepository } from '@vp/manager/honor/core/interface/HonorManagerRepository';
import { ProfileHonorRecordModel } from '@vp/profile/core/model/ProfileHonorRecordModel';
import { TributeResponseDto } from '@vp/profile/data/dto/TributeResponseDto';
import { ProfileDataMapper } from '@vp/profile/data/ProfileDataMapper';

@injectable()
export class RestHonorManagerRepository implements HonorManagerRepository {
  constructor(
    private readonly http: Axios,
    private readonly profileDataMapper: ProfileDataMapper,
  ) {}

  async getHonorRecords(id: string, controller?: AbortController): Promise<ProfileHonorRecordModel[]> {
    const { data } = await this.http.get<TributeResponseDto[]>(`/api/tributes?profile_id=${id}`, {
      signal: controller?.signal,
    });
    return data.map(dto => this.profileDataMapper.toHonorRecord(dto));
  }

  async removeHonorRecords(ids: string[], profileId: string, controller?: AbortController): Promise<void> {
    const data = { tribute_ids: ids };
    await this.http.delete<TributeResponseDto[]>(`/api/tributes?profile_id=${profileId}`, { data, signal: controller?.signal });
  }

  async approveHonorRecords(ids: string[], profileId: string, controller?: AbortController): Promise<ProfileHonorRecordModel[]> {
    const body = { tribute_ids: ids };
    const { data } = await this.http.put<TributeResponseDto[]>(`/api/tributes/approve?profile_id=${profileId}`, body, {
      signal: controller?.signal,
    });
    return data.map(dto => this.profileDataMapper.toHonorRecord(dto));
  }

  async editHonorRecord(id: string, newText: string, controller?: AbortController): Promise<ProfileHonorRecordModel> {
    const { data } = await this.http.patch<TributeResponseDto>(`/api/tributes/${id}`, this.createFormData(newText), {
      signal: controller?.signal,
    });
    return this.profileDataMapper.toHonorRecord(data);
  }

  private createFormData(text: string): FormData {
    const formData = new FormData();
    formData.append('text', text);
    return formData;
  }
}
