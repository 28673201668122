import { computed, ReadonlySignal, signal, Signal } from '@preact/signals-react';
import { injectable } from 'inversify';

import { MediaGridItem } from '@vp/common/ui/component/VpMediaGrid';
import { ViewModel, ViewModelDispose } from '@vp/common/ui/ViewModel';
import { ProfilePhotoModel } from '@vp/profile/core/model/ProfilePhotoModel';
import { ProfileVideoModel } from '@vp/profile/core/model/ProfileVideoModel';
import { MediaItemsMapper } from '@vp/profile/ui/gallery/MediaItemsMapper';
import { ProfilePhotosProps } from '@vp/profile/ui/gallery/ProfileGallery';

export enum ProfileGalleryMode {
  Images = 'images',
  Videos = 'videos',
}

@injectable()
export class ProfileGalleryViewModel extends ViewModel<ProfilePhotosProps> implements ViewModelDispose {
  readonly imageViewerShown: Signal<boolean> = signal(false);
  readonly videoViewerShown: Signal<boolean> = signal(false);

  readonly selectedPhoto: Signal<ProfilePhotoModel | null> = signal(null);
  readonly selectedVideo: Signal<ProfileVideoModel | null> = signal(null);

  readonly photoItems: ReadonlySignal<MediaGridItem[]> = computed(() => this.toPhotoItems());
  readonly videoItems: ReadonlySignal<MediaGridItem[]> = computed(() => this.toVideoItems());

  constructor(private readonly mediaItemsMapper: MediaItemsMapper) {
    super();
  }

  dispose(): void {
    this.closeImageViewer();
    this.closeVideoViewer();
  }

  closeImageViewer = (): void => {
    this.imageViewerShown.value = false;
  };

  closeVideoViewer = (): void => {
    this.videoViewerShown.value = false;
  };

  handlePhotoClick = (photo: MediaGridItem): void => {
    this.closeVideoViewer();
    this.imageViewerShown.value = true;
    this.selectedPhoto.value = photo;
  };

  handleVideoClick = (video: MediaGridItem): void => {
    this.closeImageViewer();
    this.videoViewerShown.value = true;
    this.selectedVideo.value = this.toVideoModel(video);
  };

  private toPhotoItems(): MediaGridItem[] {
    if (!this.props.value?.profile) return [];
    return this.mediaItemsMapper.toPhotoItems(this.props.value.profile.photos);
  }

  private toVideoItems(): MediaGridItem[] {
    if (!this.props.value?.profile) return [];
    return this.mediaItemsMapper.toVideoItems(this.props.value.profile.videos);
  }

  private toVideoModel(item: MediaGridItem): ProfileVideoModel {
    return this.props.value!.profile.videos.find(video => video.id === item.id)!;
  }
}
