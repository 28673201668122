import { injectable } from 'inversify';

import { HonorManagerRepository } from '@vp/manager/honor/core/interface/HonorManagerRepository';
import { HonorManagerState } from '@vp/manager/honor/core/interface/HonorManagerState';

@injectable()
export class ApproveHonorRecordUsecase {
  constructor(
    private readonly honorManagerRepository: HonorManagerRepository,
    private readonly honorManagerState: HonorManagerState,
  ) {}

  async execute(ids: string[], profileId: string, controller?: AbortController): Promise<void> {
    const approvedRecords = await this.honorManagerRepository.approveHonorRecords(ids, profileId, controller);
    this.honorManagerState.updateRecords(approvedRecords);
  }
}
