import { IconButton, SvgIcon } from '@mui/material';
import { FC, SyntheticEvent, useCallback } from 'react';

import ExportIcon from '@vp/assets/icons/Export.svg?react';

export const ShareButton: FC = () => {
  const handleClick = useCallback((event: SyntheticEvent) => {
    event.stopPropagation();
  }, []);

  return (
    <IconButton
      onClick={handleClick}
      sx={theme => ({
        position: 'relative',
        // todo: implement share link
        display: 'none',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: theme.spacing(-2),
          right: theme.spacing(-1),
          bottom: theme.spacing(-2),
          left: theme.spacing(-2),
          background: 'transparent',
        },
      })}
    >
      <SvgIcon>
        <ExportIcon />
      </SvgIcon>
    </IconButton>
  );
};
