import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { FC, useCallback, useState } from 'react';

import { VpFancyButton } from '@vp/common/ui/component/VpFancyButton';
import { VpMediaGradientOverlay } from '@vp/common/ui/component/VpMediaGradientOverlay';

interface SlideshowIntroContentProps {
  name: string;
  image: string;
  imageScale: number;
  buttonText: string;
  birthDeathDates: string;
  startSlideshow: () => void;
  skipSlideshow: () => void;
}

const ButtonGradient =
  'linear-gradient(90.82deg, rgba(229, 229, 229, 0.35) -43.91%, rgba(229, 229, 229, 0.15) 48.8%, rgba(229, 229, 229, 0) 150.27%)';

const ImageOverlayGradient = 'linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.36) 54.57%, rgba(0, 0, 0, 0.18) 100%)';

export const SlideshowIntro: FC<SlideshowIntroContentProps> = ({
  startSlideshow,
  skipSlideshow,
  image,
  imageScale,
  buttonText,
  birthDeathDates,
  name,
}) => {
  const { spacing } = useTheme();
  const [lockInteractions, setLockInteractions] = useState(false);

  const handleButtonClick = useCallback(
    (callback: () => void) => {
      if (!lockInteractions) {
        setLockInteractions(true);
        callback();
      }
    },
    [lockInteractions],
  );

  const onStartSlideshow = useCallback(() => handleButtonClick(startSlideshow), [handleButtonClick, startSlideshow]);
  const onSkipSlideshow = useCallback(() => handleButtonClick(skipSlideshow), [handleButtonClick, skipSlideshow]);

  return (
    <Box sx={theme => ({ width: 1, height: 1, zIndex: theme.zIndex.slideshowIntro })}>
      <VpMediaGradientOverlay sx={{ width: '100%', height: '100%', overflow: 'hidden' }} gradient={ImageOverlayGradient}>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
          style={{ backgroundImage: `url(${image})`, transform: `scale(${imageScale})` }}
        />
      </VpMediaGradientOverlay>

      <Stack
        sx={theme => ({
          inset: 0,
          position: 'absolute',
          justifyContent: 'space-between',
          zIndex: theme.zIndex.galleryProfileInfo,
        })}
      >
        <Stack width={1} p={`${spacing(9)} ${spacing(5)} 0`}>
          <Typography variant="h1" color="text.secondary">
            {name}
          </Typography>
          <Typography variant="body1Medium" color="text.secondary">
            {birthDeathDates}
          </Typography>
        </Stack>

        <Stack width={1} pb={3} alignItems="center">
          <Button disableRipple sx={{ backgroundColor: 'transparent', textTransform: 'none', mb: spacing(1) }} onClick={onSkipSlideshow}>
            <Typography variant="buttonLarge" color="primary.500">
              Перейти к профилю
            </Typography>
          </Button>

          <VpFancyButton text={buttonText} sx={{ background: ButtonGradient }} onClick={onStartSlideshow}></VpFancyButton>
        </Stack>
      </Stack>
    </Box>
  );
};
