import { TypographyOptions } from '@mui/material/styles/createTypography';

import BluuNextOft from '@vp/assets/fonts/BluuNextCyrillic.otf';
import BluuNextWoff from '@vp/assets/fonts/BluuNextCyrillic.woff2';
import GolosText from '@vp/assets/fonts/GolosText.ttf';

export const fontFaces = `
  @font-face {
    font-family: 'Bluu Next';
    src: url(${BluuNextWoff}) format('woff2'), url(${BluuNextOft}) format('opentype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Golos Text';
    src: url(${GolosText}) format('truetype');
    font-weight: 400 600;
    font-style: normal;
  }
`;

export const typography: TypographyOptions = {
  display: {
    fontFamily: 'Bluu Next',
    fontSize: '66px',
    fontWeight: 700,
    lineHeight: '46px',
  },
  h1: {
    fontFamily: 'Bluu Next',
    fontSize: '42px',
    fontWeight: 700,
    lineHeight: '46px',
  },
  h2: {
    fontFamily: 'Bluu Next',
    fontSize: '34px',
    fontWeight: 700,
    lineHeight: '40px',
  },
  h3: {
    fontFamily: 'Bluu Next',
    fontSize: '30px',
    fontWeight: 700,
    lineHeight: '36px',
  },
  h4: {
    fontFamily: 'Bluu Next',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '28px',
  },
  h5: {
    fontFamily: 'Bluu Next',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '26px',
  },
  h6: {
    fontFamily: 'Golos Text',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
  },
  body1Regular: {
    fontFamily: 'Golos Text',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: '-0.15px',
  },
  body1Medium: {
    fontFamily: 'Golos Text',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '22px',
    letterSpacing: '-0.15px',
  },
  body1Bold: {
    fontFamily: 'Golos Text',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '22px',
    letterSpacing: '-0.15px',
  },
  body2Regular: {
    fontFamily: 'Golos Text',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '-0.15px',
  },
  body2Medium: {
    fontFamily: 'Golos Text',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '-0.15px',
  },
  body2Bold: {
    fontFamily: 'Golos Text',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '-0.15px',
  },
  paragraph1Regular: {
    fontFamily: 'Golos Text',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '28px',
    letterSpacing: '-0.15px',
  },
  paragraph1Medium: {
    fontFamily: 'Golos Text',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '28px',
    letterSpacing: '-0.15px',
  },
  paragraph1Bold: {
    fontFamily: 'Golos Text',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '28px',
    letterSpacing: '-0.15px',
  },
  paragraph2Regular: {
    fontFamily: 'Golos Text',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.15px',
  },
  paragraph2Medium: {
    fontFamily: 'Golos Text',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.15px',
  },
  paragraph2Bold: {
    fontFamily: 'Golos Text',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '-0.15px',
  },
  caption1Regular: {
    fontFamily: 'Golos Text',
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '18px',
  },
  caption1Medium: {
    fontFamily: 'Golos Text',
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: '18px',
  },
  caption1Bold: {
    fontFamily: 'Golos Text',
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: '18px',
  },
  caption2Regular: {
    fontFamily: 'Golos Text',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
  },
  caption2Medium: {
    fontFamily: 'Golos Text',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '16px',
  },
  caption2Bold: {
    fontFamily: 'Golos Text',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
  },
  overlineRegular: {
    fontFamily: 'Golos Text',
    fontSize: '11px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
  },
  overlineMedium: {
    fontFamily: 'Golos Text',
    fontSize: '11px',
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
  },
  overlineBold: {
    fontFamily: 'Golos Text',
    fontSize: '11px',
    fontWeight: 600,
    lineHeight: '16px',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
  },
  buttonSmall: {
    fontFamily: 'Golos Text',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '18px',
  },
  buttonMedium: {
    fontFamily: 'Golos Text',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
  },
  buttonLarge: {
    fontFamily: 'Golos Text',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '22px',
  },
  buttonGiant: {
    fontFamily: 'Golos Text',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '24px',
  },
  formCompletedMedium: {
    fontFamily: 'Golos Text',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.15px',
  },
  formCompletedSmall: {
    fontFamily: 'Golos Text',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '-0.15px',
  },
  formCompletedXSmall: {
    fontFamily: 'Golos Text',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '-0.15px',
  },
  formPlaceholderMedium: {
    fontFamily: 'Golos Text',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.15px',
  },
  formPlaceholderSmall: {
    fontFamily: 'Golos Text',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '-0.15px',
  },
  formPlaceholderXSmall: {
    fontFamily: 'Golos Text',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '-0.15px',
  },
  formLabelMedium: {
    fontFamily: 'Golos Text',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
  },
  formLabelSmall: {
    fontFamily: 'Golos Text',
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: '18px',
  },
};
