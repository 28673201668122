import { Stack, Typography } from '@mui/material';
import { FC, Fragment } from 'react';

import { useViewModel } from '@vp/common/ui/hook/useViewModel';
import { SubmitRecordForm } from '@vp/honor/ui/components/SubmitRecordForm';
import { HonorSubmitViewModel } from '@vp/honor/ui/HonorSubmitViewModel';
import { ProfileHonorRecordModel } from '@vp/profile/core/model/ProfileHonorRecordModel';
import { ProfileHonorNotificationStatus } from '@vp/profile/ui/honor/ProfileHonor';

export interface SubmitRecordBodyProps {
  submitButtonText: string;
  profileId: string;
  handleSubmissionResult: (status: ProfileHonorNotificationStatus, record: ProfileHonorRecordModel | null) => void;
  handleBeforeSubmission?: () => void;
}

export const SubmitRecordBody: FC<SubmitRecordBodyProps> = props => {
  const viewModel = useViewModel(HonorSubmitViewModel, props);

  return (
    <Fragment>
      <Typography variant="body2Regular" color="text.disabled" paragraph>
        Оставьте запись в память об ушедшем близком. Ваша запись будет отправлена на модерацию и появится в галерее почестей после одобрения
        владельца профиля.
      </Typography>
      <Typography variant="body2Bold" color="text.disabled">
        Инструкция:
      </Typography>
      <Stack>
        <Typography variant="body2Regular" color="text.disabled">
          1. Нажмите на кнопку &quot;Оставить запись&quot;.
        </Typography>
        <Typography variant="body2Regular" color="text.disabled">
          2. Укажите ваше имя.
        </Typography>
        <Typography variant="body2Regular" color="text.disabled">
          3. Напишите текст вашей записи, например, воспоминания, слова благодарности или просто мысли об ушедшем.
        </Typography>
        <Typography variant="body2Regular" color="text.disabled" paragraph>
          4. Нажмите &quot;Отправить на модерацию&quot;.
        </Typography>
      </Stack>
      <Typography variant="body2Regular" color="text.disabled" paragraph>
        После отправки материалы будут проверены владельцем профиля. Это необходимо для обеспечения уважительного отношения к памяти ваших
        близких.
      </Typography>

      <SubmitRecordForm
        handleFormSubmit={viewModel.handleFormSubmit}
        submittingRecord={viewModel.submitting.value}
        submitButtonText={props.submitButtonText}
      />
    </Fragment>
  );
};
