import { useInjection } from 'inversify-react';
import { FC, memo, useMemo } from 'react';

import { TimeAgoFormatter } from '@vp/common/ui/TimeAgoFormatter';
import { ProfileHonorRecordModel } from '@vp/profile/core/model/ProfileHonorRecordModel';
import { ProfileHonorRecords } from '@vp/profile/ui/honor/ProfileHonorRecords';

const Records: ProfileHonorRecordModel[] = [
  {
    id: '1',
    author: 'Ольга и Сергей Ивановы',
    date: '11/02/2023',
    text: 'Елена была светлым человеком, всегда готовая прийти на помощь и поддержать. Её улыбка озаряла каждый день, и память о ней будет жить в наших сердцах.',
    approved: true,
  },
  {
    id: '2',
    author: 'Мария Петрова',
    date: '07/23/2024',
    text: 'Невозможно забыть ту теплоту и доброту, с которыми Елена относилась ко всем вокруг. Она оставила неизгладимый след в нашей жизни.',
    approved: true,
  },
  {
    id: '3',
    author: 'Александр Сидоров',
    date: '08/23/2024',
    text: 'Елена была настоящим другом, чьё присутствие приносило мир и покой. Её доброта и мудрость останутся с нами навсегда.',
    approved: true,
  },
];

export const ProfileExampleHonor: FC = memo(() => {
  const timeAgoFormatter = useInjection(TimeAgoFormatter);

  const records = useMemo(
    () => Records.map(record => ({ ...record, date: timeAgoFormatter.getTimeAgo(new Date(record.date)) })),
    [timeAgoFormatter],
  );

  return <ProfileHonorRecords sx={{ pt: 0 }} records={records} />;
});

ProfileExampleHonor.displayName = ProfileExampleHonor.name;
