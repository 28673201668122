import { useEffect, useState } from 'react';

export function useFalsyStateDelay<T = unknown>(state: T, delay: number): T {
  const [delayedState, setDelayedState] = useState(state);

  useEffect(() => {
    let timeout: number;

    if (state) {
      setDelayedState(state);
    } else {
      timeout = setTimeout(() => setDelayedState(state), delay);
    }

    return (): void => clearTimeout(timeout);
  }, [delay, state]);

  return delayedState;
}
