import { ProfileThemeMode, ProfileThemeModel } from '@vp/profile/core/model/ProfileThemeModel';

interface ConfigAttributes {
  color: string;
  background_mode: ProfileThemeMode;
}

export class UpdateThemeDto {
  readonly config_attributes: ConfigAttributes;

  constructor({ mode, color }: ProfileThemeModel) {
    color ??= '';
    this.config_attributes = { color, background_mode: mode };
  }
}
