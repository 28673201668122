import { Axios } from 'axios';
import { ContainerModule } from 'inversify';

import { BaseModule } from '@vp/common/BaseModule';
import { Analytics } from '@vp/common/core/Analytics';
import { JwtParser } from '@vp/common/core/JwtParser';
import { ErrorExtractor } from '@vp/common/data/ErrorExtractor';
import { HttpClientFactory } from '@vp/common/data/HttpClientFactory';
import { SentryAnalytics } from '@vp/common/data/SentryAnalytics';
import { DateFormatter } from '@vp/common/ui/DateFormatter';
import { SplashService } from '@vp/common/ui/SplashService';
import { TimeAgoFormatter } from '@vp/common/ui/TimeAgoFormatter';

export class CommonModule extends BaseModule {
  readonly module: ContainerModule = new ContainerModule(bind => {
    bind(SplashService).toSelf().inSingletonScope();
    bind(SentryAnalytics).toSelf().inSingletonScope();

    bind(TimeAgoFormatter).toSelf().inSingletonScope();
    bind(DateFormatter).toSelf().inSingletonScope();
    bind(JwtParser).toSelf().inSingletonScope();

    bind(ErrorExtractor).toSelf().inSingletonScope();
    bind(Axios).toDynamicValue(HttpClientFactory).inSingletonScope();

    bind(Analytics)
      .toDynamicValue(context => context.container.get(SentryAnalytics))
      .inSingletonScope();
  });
}
