import { SnackbarProvider } from 'notistack';
import { FC } from 'react';

import { AppNotification } from '@vp/notification/AppNotification';

export const AppNotificationOutlet: FC = () => {
  return (
    <SnackbarProvider
      maxSnack={3}
      transitionDuration={250}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      Components={{
        success: AppNotification,
        info: AppNotification,
        warning: AppNotification,
        error: AppNotification,
        primary: AppNotification,
      }}
    />
  );
};
