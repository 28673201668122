import { UploadStateMachine } from '@vp/manager/gallery/data/upload/UploadStateMachine';

export abstract class UploadState {
  constructor(protected readonly stateMachine: UploadStateMachine) {}

  process?(): Promise<void>;

  onEnter(): void {
    this.stateMachine.notify();
  }

  protected handleError(error: unknown): void {
    const message = this.stateMachine.errorExtractor.extract(error);
    this.stateMachine.context.setError(message);
    this.stateMachine.analytics.trackError(error);
  }

  protected updateTotalProgress(): void {
    let progress = 0;
    const registrySize = this.stateMachine.context.fileRegistry.size;

    this.stateMachine.context.fileRegistry.forEach(fileUpload => {
      progress += Math.round(fileUpload.progress / registrySize);
    });

    this.stateMachine.context.updateProgress(Math.min(progress, 100));
  }
}
