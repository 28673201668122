import { computed, ReadonlySignal, signal, Signal } from '@preact/signals-react';
import { injectable } from 'inversify';

import { TimeAgoFormatter } from '@vp/common/ui/TimeAgoFormatter';
import { ViewModel, ViewModelDispose, ViewModelInit } from '@vp/common/ui/ViewModel';
import { HonorManagerPort } from '@vp/manager/honor/core/interface/HonorManagerPort';
import { HonorManagerState, HonorManagerStatus } from '@vp/manager/honor/core/interface/HonorManagerState';
import { HonorManagerNavigator } from '@vp/manager/honor/ui/HonorManagerNavigator';
import { HonorManagerSection } from '@vp/manager/honor/ui/HonorManagerSection';
import { ProfileManagerState } from '@vp/manager/profile/core/interface/ProfileManagerState';
import { ProfileHonorRecordModel } from '@vp/profile/core/model/ProfileHonorRecordModel';
import { ProfileModel } from '@vp/profile/core/model/ProfileModel';
import { ProfileHonorNotificationStatus } from '@vp/profile/ui/honor/ProfileHonor';

export type Status = ProfileHonorNotificationStatus;
export type Action = 'edit' | 'remove' | 'submit' | 'approve';

export interface RequestState {
  inProgress: boolean;
  action: Action | null;
  ids: string[];
}

@injectable()
export class HonorManagerViewModel extends ViewModel implements ViewModelInit, ViewModelDispose {
  readonly approvedRecords: ReadonlySignal<ProfileHonorRecordModel[]> = computed(() =>
    this.toViewHonorRecords(this.honorManagerState.approvedRecords.value),
  );
  readonly pendingRecords: ReadonlySignal<ProfileHonorRecordModel[]> = computed(() =>
    this.toViewHonorRecords(this.honorManagerState.pendingRecords.value),
  );
  readonly isActiveTabHasRecords: ReadonlySignal<boolean> = computed(() => this.hasRecords());

  readonly status: ReadonlySignal<HonorManagerStatus> = this.honorManagerState.status;
  readonly profile: ReadonlySignal<ProfileModel | null> = this.profileManagerState.active;

  readonly activeRequestState: Signal<RequestState> = signal({ inProgress: false, action: null, ids: [] });
  private controller?: AbortController;

  constructor(
    private readonly honorManagerState: HonorManagerState,
    private readonly timeAgoFormatter: TimeAgoFormatter,
    private readonly honorManagerPort: HonorManagerPort,
    private readonly profileManagerState: ProfileManagerState,
    private readonly honorManagerNavigator: HonorManagerNavigator,
  ) {
    super();
  }

  init(): void {
    this.controller = new AbortController();
    void this.honorManagerPort.loadHonorRecords(this.profile.value?.id ?? '', this.controller);
    this.honorManagerNavigator.attach();
  }

  dispose(): void {
    this.controller?.abort();
    this.honorManagerNavigator.detach();
    this.honorManagerPort.resetState();
  }

  editRecord = async (id: string, newText: string): Promise<Status> => {
    return await this.handleRequest('edit', [id], (controller: AbortController) =>
      this.honorManagerPort.editRecord(id, newText, controller),
    );
  };

  removeRecords = async (ids: string[]): Promise<Status> => {
    return await this.handleRequest('remove', ids, (controller: AbortController) =>
      this.honorManagerPort.removeHonorRecords(ids, this.profile.value?.id ?? '', controller),
    );
  };

  approveRecords = async (ids: string[]): Promise<Status> => {
    return await this.handleRequest('approve', ids, (controller: AbortController) =>
      this.honorManagerPort.approveHonorRecords(ids, this.profile.value?.id ?? '', controller),
    );
  };

  handleBeforeSubmission = (): void => {
    this.setActiveRequestState({ inProgress: true, action: 'submit', ids: [] });
  };

  handleSubmissionResult = (status: ProfileHonorNotificationStatus, record: ProfileHonorRecordModel | null): void => {
    if (status === 'success' && record) {
      this.honorManagerPort.handleSubmissionResult(record);
      this.honorManagerNavigator.toApprovedSection();
    }
    this.setActiveRequestState({ inProgress: false, action: 'submit', ids: [] });
  };

  private async handleRequest(
    action: Action,
    ids: string[],
    asyncOperation: (controller: AbortController) => Promise<void>,
  ): Promise<Status> {
    this.controller = new AbortController();

    const requestState = { inProgress: true, action, ids };
    this.setActiveRequestState(requestState);

    let status: Status = 'success';

    try {
      await asyncOperation(this.controller);
    } catch {
      status = 'error';
    }

    this.setActiveRequestState({ ...requestState, inProgress: false });
    return status;
  }

  private setActiveRequestState = (state: RequestState): void => {
    this.activeRequestState.value = state;
  };

  private hasRecords(): boolean {
    const hasApproved = this.honorManagerNavigator.isOn(HonorManagerSection.Approved) && !!this.approvedRecords.value.length;
    const hasPending = this.honorManagerNavigator.isOn(HonorManagerSection.Pending) && !!this.pendingRecords.value.length;
    return hasApproved || hasPending;
  }

  private toViewHonorRecords(records: ProfileHonorRecordModel[]): ProfileHonorRecordModel[] {
    return records.map(record => ({ ...record, date: this.toTimeAgo(record.date) }));
  }

  private toTimeAgo(date: string): string {
    return this.timeAgoFormatter.getTimeAgo(new Date(date));
  }
}
