import { Box, Fade, IconButton, Snackbar, SnackbarProps, Stack, styled, SvgIcon, Typography } from '@mui/material';
import { FC, useCallback } from 'react';

import CheckmarkFilled from '@vp/assets/icons/CheckmarkFilled.svg?react';
import Close from '@vp/assets/icons/Close.svg?react';
import CloseFilled from '@vp/assets/icons/CloseFilled.svg?react';
import InformationFilled from '@vp/assets/icons/InformationFilled.svg?react';
import Star from '@vp/assets/icons/Star.svg?react';
import WarningAltFilled from '@vp/assets/icons/WarningAltFilled.svg?react';
import { VpIcon } from '@vp/common/ui/component/VpIcon';
import { resolveSx } from '@vp/common/ui/util/resolveSx';

export type VpSnackbarProps = Parameters<typeof Snackbar>[0] & {
  primaryMessage: string;
  secondaryMessage?: string;
  status: 'success' | 'info' | 'warning' | 'error' | 'primary';
  close: () => void;
  open: boolean;
};

const Icons: Record<VpSnackbarProps['status'], FC> = {
  success: CheckmarkFilled,
  info: InformationFilled,
  warning: WarningAltFilled,
  error: CloseFilled,
  primary: Star,
};

type VpSnackbarRootProps = Pick<VpSnackbarProps, 'status'>;

const VpSnackbarRoot = styled(Box)<VpSnackbarRootProps>(({ theme }) => ({
  position: 'relative',
  width: '100%',
  padding: theme.spacing(1.5),
  display: 'flex',
  alignItems: 'flex-start',
  borderRadius: '8px',
  background: getBackground(),
  '--vp-snackbar-background': theme.palette.background[300],
  variants: [
    {
      props: { status: 'success' },
      style: { '--vp-snackbar-status-background': theme.palette.success.alpha24, '--vp-snackbar-action-color': theme.palette.success[500] },
    },
    {
      props: { status: 'info' },
      style: { '--vp-snackbar-status-background': theme.palette.info.alpha24, '--vp-snackbar-action-color': theme.palette.info[500] },
    },
    {
      props: { status: 'warning' },
      style: { '--vp-snackbar-status-background': theme.palette.warning.alpha24, '--vp-snackbar-action-color': theme.palette.warning[500] },
    },
    {
      props: { status: 'error' },
      style: { '--vp-snackbar-status-background': theme.palette.danger.alpha24, '--vp-snackbar-action-color': theme.palette.danger[500] },
    },
    {
      props: { status: 'primary' },
      style: {
        '--vp-snackbar-status-background': theme.palette.transparency.alpha8,
        '--vp-snackbar-action-color': theme.palette.primary[500],
      },
    },
  ],
}));

/**
 * Deprecated component.Use AppNotificationService instead.
 * TODO remove
 */
export const VpSnackbarLegacy: FC<VpSnackbarProps> = ({ status, primaryMessage, secondaryMessage = '', close, open, ...props }) => {
  const Icon = Icons[status];

  const onClose = useCallback<NonNullable<SnackbarProps['onClose']>>(
    (_, reason) => {
      if (reason != 'clickaway' && reason != 'escapeKeyDown') {
        close();
      }
    },
    [close],
  );

  return (
    <Snackbar
      open={open}
      onClose={onClose}
      TransitionComponent={Fade}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      {...props}
      sx={theme => ({
        bottom: theme.spacing(3),
        left: theme.spacing(3),
        right: theme.spacing(3),
        ...resolveSx(props.sx, theme),
      })}
    >
      <VpSnackbarRoot status={status}>
        <VpIcon Icon={Icon} size={24} color="var(--vp-snackbar-action-color)" sx={{ mr: 1 }} />

        <Stack flexGrow={1}>
          <Typography variant="body2Bold" color="text.primary" mb={0.25}>
            {primaryMessage}
          </Typography>
          <Typography variant="body2Regular" color="transparency.alpha48">
            {secondaryMessage}
          </Typography>
        </Stack>

        <Box width={16} ml={1}>
          <IconButton
            sx={theme => ({
              position: 'absolute',
              top: theme.spacing(1),
              right: theme.spacing(1),
              p: 1,
              '&::before': {
                content: '""',
                position: 'absolute',
                inset: theme.spacing(-2),
                background: 'transparent',
              },
            })}
            onClick={close}
          >
            <SvgIcon sx={theme => ({ fontSize: 16, color: theme.palette.transparency.alpha48 })}>
              <Close />
            </SvgIcon>
          </IconButton>
        </Box>
      </VpSnackbarRoot>
    </Snackbar>
  );
};

function getBackground(): string {
  const first = `linear-gradient(0deg, var(--vp-snackbar-status-background), var(--vp-snackbar-status-background))`;
  const second = `linear-gradient(0deg, var(--vp-snackbar-background), var(--vp-snackbar-background))`;
  return `${first}, ${second}`;
}
