import { Box, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { forwardRef, useState } from 'react';
import { Blurhash } from 'react-blurhash';

import { resolveSx } from '@vp/common/ui/util/resolveSx';

export interface VpImageProps {
  src: string;
  hash?: string;
  width?: string;
  height?: string;
  alt?: string;
  position?: string;
  sx?: SxProps<Theme>;
}

export const VpImage = forwardRef<HTMLElement, VpImageProps>(({ src, hash, width, height, alt, position, sx }, ref) => {
  const [loaded, setLoaded] = useState(false);
  const opacity = Number(loaded);
  position ??= 'center';
  hash ||= 'L5EV+:0000of00-;xu%M~qt7xuD%';

  return (
    <Box ref={ref} sx={theme => ({ position: 'relative', ...resolveSx(sx, theme) })} width={width ?? '100%'} height={height ?? '100%'}>
      <Blurhash hash={hash} width="100%" height="100%" />

      <Box
        component={'img'}
        alt={alt ?? 'image'}
        src={src}
        draggable="false"
        style={{ opacity, objectPosition: position }}
        sx={{
          transition: 'opacity 0.5s ease-in-out',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
        onLoad={() => setLoaded(true)}
      />
    </Box>
  );
});

VpImage.displayName = 'VpImage';
