import { Typography, FormControl } from '@mui/material';
import { ChangeEventHandler, FC, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import CalendarIcon from '@vp/assets/icons/Calendar.svg?react';
import { VpIcon } from '@vp/common/ui/component/VpIcon';
import { VpInput } from '@vp/common/ui/component/VpInput';
import { EventsFormValues } from '@vp/manager/events/ui/EventsManagerPage';

interface EventsManagerFormDateProps {
  index: number;
  disableControls: boolean;
}

const currentYear = new Date().getFullYear();

const formatDateInput = (value: string): string => {
  const cleaned = value.replace(/\D+/g, '');
  const day = cleaned.slice(0, 2);
  const month = cleaned.slice(2, 4);
  let year = cleaned.slice(4, 8);

  if (year && parseInt(year) > currentYear) {
    year = currentYear.toString();
  }

  return [day, month, year].filter(Boolean).join('.');
};

const isValidDate = (value: string | null): boolean => {
  if (typeof value !== 'string') return false;
  const cleaned = value.replace(/\D+/g, '');
  const day = parseInt(cleaned.slice(0, 2), 10);
  const month = parseInt(cleaned.slice(2, 4), 10);
  const year = parseInt(cleaned.slice(4, 8), 10);

  if (isNaN(day) || isNaN(month) || isNaN(year)) return false;

  const currentYear = new Date().getFullYear();
  const daysInMonth = new Date(year, month, 0).getDate();

  const isYearInvalid = year < 1000 || year > currentYear;
  const isMonthInvalid = month < 1 || month > 12;
  const isDayInvalid = day < 1 || day > daysInMonth;

  return !(isYearInvalid || isMonthInvalid || isDayInvalid);
};

export const EventsManagerFormDate: FC<EventsManagerFormDateProps> = ({ index, disableControls }) => {
  const {
    setValue,
    register,
    formState: { errors },
  } = useFormContext<EventsFormValues>();

  const dateError = errors?.events?.[index]?.date;

  const onValidateDate = useCallback((value: string) => isValidDate(value?.toString()) || 'Неправильная дата', []);
  const onChangeDate = useCallback<ChangeEventHandler<HTMLInputElement>>(
    event => setValue(`events.${index}.date`, formatDateInput(event.target.value.toString())),
    [index, setValue],
  );

  return (
    <FormControl disabled={disableControls} fullWidth error={!!dateError}>
      <VpInput
        {...register(`events.${index}.date`, { required: true, validate: onValidateDate, onChange: onChangeDate })}
        sx={{ width: 1 }}
        id={`events.${index}.date`}
        placeholder="ДД.ММ.ГГГГ"
        endAdornment={<VpIcon color="transparency.alpha16" size={24} Icon={CalendarIcon} sx={{ p: 0, mr: 1, alignSelf: 'center' }} />}
      />
      {dateError?.message && (
        <Typography variant="body2Regular" color="danger.500" mt={0.75} sx={{ position: 'absolute', top: '100%', left: 0, pt: 0.5 }}>
          {dateError.message}
        </Typography>
      )}
    </FormControl>
  );
};
