import { injectable } from 'inversify';

import { AvatarManagerRepository } from '@vp/manager/avatar/core/interfaces/AvatarManagerRepository';
import { ProfileManagerState } from '@vp/manager/profile/core/interface/ProfileManagerState';


@injectable()
export class RemoveAvatarUsecase {
  constructor(
    private readonly profileManagerState: ProfileManagerState,
    private readonly avatarManagerRepository: AvatarManagerRepository
  ) { }

  async execute(profileId: string, avatarId: string, controller?: AbortController): Promise<boolean> {
    try {
      const profile = await this.avatarManagerRepository.remove(profileId, avatarId, controller);
      this.profileManagerState.update(profile);
      return true;
    } catch {
      return false;
    }
  }
}
