import { Shadows } from '@mui/material';

export const shadows = ((): Shadows => {
  const shadows: string[] = new Array(33).fill('none');

  shadows[2] = '0px 2px 2px 0px rgba(34, 32, 31, 0.16)';
  shadows[4] = '0px 4px 8px 0px rgba(34, 32, 31, 0.16)';
  shadows[8] = '0px 8px 12px 0px rgba(34, 32, 31, 0.12)';
  shadows[16] = '0px 16px 32px 0px rgba(34, 32, 31, 0.16)';
  shadows[24] = '0px 24px 48px 0px rgba(34, 32, 31, 0.16)';
  shadows[32] = '0px 32px 96px 10px rgba(34, 32, 31, 0.32)';

  return shadows as Shadows;
})();
