import { injectable } from 'inversify';

import { OperationResult, Success } from '@vp/common/core/OperationResult';
import { ProfileRepository } from '@vp/profile/core/interface/ProfileRepository';
import { ProfileState } from '@vp/profile/core/interface/ProfileState';

@injectable()
export class LoadProfileUsecase {
  constructor(
    private readonly profileRepository: ProfileRepository,
    private readonly profileState: ProfileState,
  ) {}

  async execute(id: string, controller?: AbortController): Promise<OperationResult> {
    this.profileState.setLoadingState('loading');
    const result = await this.profileRepository.loadProfile(id, controller);

    if (result instanceof Success) {
      this.profileState.setProfile(result.value);
      this.profileState.setLoadingState('success');
    } else {
      this.profileState.setLoadingState('error');
    }

    return result as OperationResult;
  }
}
