import { injectable } from 'inversify';
import { enqueueSnackbar, SnackbarOrigin } from 'notistack';
import { FC } from 'react';

import { AppNotificationProps } from '@vp/notification/AppNotification';

export interface Notification {
  message: string;
  secondaryMessage?: string;
  variant: AppNotificationProps['variant'];
  Icon?: FC;
  anchorOrigin?: SnackbarOrigin;
  autoHideDuration?: number | null;
}

@injectable()
export class AppNotificationService {
  enqueue(notification: Notification): void {
    enqueueSnackbar(notification.message, {
      variant: notification.variant,
      secondaryMessage: notification.secondaryMessage,
      Icon: notification.Icon,
      anchorOrigin: notification.anchorOrigin,
      autoHideDuration: notification.autoHideDuration,
    });
  }
}
