import { Box, Stack, SvgIcon, Typography } from '@mui/material';
import { FC } from 'react';

import QrCode from '@vp/assets/icons/QrCode.svg?react';
import UserMultipleGradient from '@vp/assets/icons/UserMultipleGradient.svg?react';
import { VpFancyButton } from '@vp/common/ui/component/VpFancyButton';

const Title = 'У вас пока нет управляемых профилей';
const Caption =
  'Вы можете приобрести наш специальный блистер с QR-кодом, который будет хранить тёплые воспоминания и важные моменты о ваших близких.';

const ButtonText = 'Хочу запечатлеть Впамяти';

const ButtonGradient =
  'linear-gradient(90.82deg, rgba(229, 229, 229, 0.35) -43.91%, rgba(229, 229, 229, 0.15) 48.8%, rgba(229, 229, 229, 0) 150.27%)';

export const NoProfiles: FC = () => {
  return (
    <Stack height="100dvh" width={1} justifyContent="center" alignItems="center" px={3}>
      <Box position="relative" mb={3}>
        <SvgIcon sx={{ fontSize: 40, filter: 'blur(10px)' }}>
          <UserMultipleGradient />
        </SvgIcon>

        <SvgIcon sx={{ fontSize: 40, position: 'absolute', inset: 0 }}>
          <UserMultipleGradient />
        </SvgIcon>
      </Box>

      <Typography color="text.secondary" variant="h4" align="center" mb={1.5}>
        {Title}
      </Typography>

      <Typography color="text.secondaryDisabled" variant="body1Regular" align="center" mb={3}>
        {Caption}
      </Typography>

      <VpFancyButton
        text={ButtonText}
        {...{ component: 'a', href: 'https://впамяти.рф/catalog', target: '_blank' }}
        sx={{ background: ButtonGradient }}
        Icon={QrCode}
      />
    </Stack>
  );
};
