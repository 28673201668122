import { Box, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC, PropsWithChildren } from 'react';

import { resolveSx } from '@vp/common/ui/util/resolveSx';

export interface VpMediaOverlayProps extends PropsWithChildren {
  opacity?: number;
  gradient?: string;
  sx?: SxProps<Theme>;
}

export const VpMediaGradientOverlay: FC<VpMediaOverlayProps> = ({ children, sx, opacity, gradient }) => {
  opacity ??= 0.5;
  const defaultGradient = `linear-gradient(rgba(0, 0, 0, ${opacity}) 0%, rgba(0, 0, 0, 0) 48%, rgba(0, 0, 0, ${opacity}) 100%)`;

  return (
    <Box
      sx={theme => ({
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          inset: 0,
          zIndex: theme.zIndex.mediaOverlayGradient,
          pointerEvents: 'none',
          background: gradient ?? defaultGradient,
        },
        ...resolveSx(sx, theme),
      })}
    >
      {children}
    </Box>
  );
};
