import { injectable } from 'inversify';

import { JwtParser } from '@vp/common/core/JwtParser';

type TokenPayload = { exp: number };

@injectable()
export class ValidateRecoveryTokenUsecase {
  constructor(private readonly jwtParser: JwtParser) {}

  execute(token: string): boolean {
    try {
      const payload = this.jwtParser.parse<TokenPayload>(token);
      return !this.isExpired(payload);
    } catch {
      return false;
    }
  }

  private isExpired(token: TokenPayload): boolean {
    const expiration = new Date(token.exp * 1000);
    return Date.now() > expiration.getTime();
  }
}
