import { AxiosProgressEvent } from 'axios';

import { FileUpload } from '@vp/manager/gallery/data/upload/context/FileUpload';
import { UploadState } from '@vp/manager/gallery/data/upload/state/UploadState';
import { ProfileResponseDto } from '@vp/profile/data/dto/ProfileResponseDto';

export class BasicUploadingState extends UploadState {
  async process(): Promise<void> {
    try {
      await this.uploadFile();
      this.determineTransition();
    } catch (error) {
      this.handleError(error);
      this.stateMachine.changeState(this.stateMachine.failedState);
    }
  }

  private async uploadFile(): Promise<void> {
    const fileUpload = this.stateMachine.context.getCurrentFileUpload();
    const formData = this.stateMachine.mapper.toUploadFormData([fileUpload.mediaFile], this.stateMachine.context.type);

    const { data } = await this.stateMachine.http.put<ProfileResponseDto>(
      `/api/profiles/${this.stateMachine.context.profileId}/upload`,
      formData,
      {
        timeout: 3 * 60_000,
        signal: this.stateMachine.controller.signal,
        onUploadProgress: progressEvent => {
          this.updateFileProgress(progressEvent, fileUpload);
          this.updateTotalProgress();
          this.stateMachine.notify();
        },
      },
    );

    this.addToContextIfNeeded(data);
  }

  private updateFileProgress(progressEvent: AxiosProgressEvent, fileUpload: FileUpload): void {
    const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total!);
    fileUpload.updateProgress(percent);
  }

  private addToContextIfNeeded(data: ProfileResponseDto): void {
    if (this.stateMachine.context.isLastFile()) {
      data.videos?.map(video => this.stateMachine.context.addVideo(video));
      data.images?.map(image => this.stateMachine.context.addImage(image));
    }
  }

  private determineTransition(): void {
    if (!this.stateMachine.context.isLastFile()) {
      this.stateMachine.context.nextFile();
      this.stateMachine.changeState(this.stateMachine.basicUploadingState);
    } else {
      this.stateMachine.changeState(this.stateMachine.completedState);
    }
  }
}
