export const EmailValidationOptions = {
  required: 'Email обязателен',
  maxLength: {
    value: 50,
    message: 'Email слишком длинный, возможно Вы случайно вставили неверные данные',
  },
  pattern: {
    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    message: 'Некорректный email',
  },
};
