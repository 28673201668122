import { signal, Signal } from '@preact/signals-react';
import { injectable } from 'inversify';

import { AppMenuState } from '@vp/menu/core/interface/AppMenuState';

@injectable()
export class SignalsAppMenuState implements AppMenuState {
  readonly open: Signal<boolean> = signal(false);

  setOpen(open: boolean): void {
    this.open.value = open;
  }
}
