import { Box, ButtonBase, Theme, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC } from 'react';

import { resolveSx } from '@vp/common/ui/util/resolveSx';

export type VpToggleProps = {
  state: boolean;
  disabled?: boolean;
  onToggle: () => void;
  id?: string;
  sx?: SxProps<Theme>;
};

export const VpToggle: FC<VpToggleProps> = ({ state, onToggle, id, disabled, sx }) => {
  const { palette } = useTheme();

  return (
    <ButtonBase
      disabled={disabled}
      id={id}
      style={{ backgroundColor: state ? palette.primary[500] : palette.transparency.alpha16 }}
      sx={theme => ({
        p: 0.375,
        width: 41,
        height: 24,
        display: 'flex',
        borderRadius: '100px',
        justifyContent: 'flex-start',
        transition: 'background-color 0.25s ease-in-out',
        ...resolveSx(sx, theme),
      })}
      onClick={onToggle}
    >
      <Box
        style={{
          backgroundColor: state ? palette.background[100] : palette.text.primary,
          transform: `translateX(${Number(state) * 100}%)`,
        }}
        sx={{ width: 18, height: 18, borderRadius: '50%', transition: 'background-color 0.25s ease-in-out, transform 0.25s ease-in-out' }}
      />
    </ButtonBase>
  );
};
