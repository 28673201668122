import { OperationResult } from '@vp/common/core/OperationResult';
import { RemoveMediaDto } from '@vp/manager/gallery/core/dto/RemoveMediaDto';
import { UploadMediaDto } from '@vp/manager/gallery/core/dto/UploadMediaDto';
import { GalleryManagerUploadHandle } from '@vp/manager/gallery/core/interface/GalleryManagerUploadHandle';
import { ProfileModel } from '@vp/profile/core/model/ProfileModel';

export abstract class GalleryManagerRepository {
  abstract upload(dto: UploadMediaDto): GalleryManagerUploadHandle;
  abstract remove(dto: RemoveMediaDto, controller?: AbortController): Promise<OperationResult<ProfileModel>>;
}
