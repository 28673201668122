import { injectable } from 'inversify';

import { ProfileManagerRepository } from '@vp/manager/profile/core/interface/ProfileManagerRepository';
import { ProfileManagerState } from '@vp/manager/profile/core/interface/ProfileManagerState';

@injectable()
export class LoadProfilesUsecase {
  constructor(
    private readonly profileManagerRepository: ProfileManagerRepository,
    private readonly profileManagerState: ProfileManagerState,
  ) {}

  async execute(controller?: AbortController): Promise<void> {
    try {
      this.profileManagerState.loading();
      const profiles = await this.profileManagerRepository.getProfiles(controller);
      this.profileManagerState.completed('loaded', profiles);
    } catch {
      this.profileManagerState.completed('error');
    }
  }
}
