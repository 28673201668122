import { injectable } from 'inversify';

import { MediaGridItem } from '@vp/common/ui/component/VpMediaGrid';
import { ProfilePhotoModel } from '@vp/profile/core/model/ProfilePhotoModel';
import { ProfileVideoModel } from '@vp/profile/core/model/ProfileVideoModel';

@injectable()
export class MediaItemsMapper {
  toPhotoItems(photos: ProfilePhotoModel[]): MediaGridItem[] {
    return photos.map(photo => ({ ...photo, type: 'photo' }));
  }

  toVideoItems(videos: ProfileVideoModel[]): MediaGridItem[] {
    return videos.map(({ id, thumbnail, thumbnailHash }) => ({ id, url: thumbnail, hash: thumbnailHash, type: 'video' }));
  }
}
