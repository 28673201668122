import { injectable } from 'inversify';

import { OperationResult } from '@vp/common/core/OperationResult';
import { RemoveMediaDto } from '@vp/manager/gallery/core/dto/RemoveMediaDto';
import { UploadMediaDto } from '@vp/manager/gallery/core/dto/UploadMediaDto';
import { GalleryManagerPort } from '@vp/manager/gallery/core/interface/GalleryManagerPort';
import { GalleryManagerUploadHandle } from '@vp/manager/gallery/core/interface/GalleryManagerUploadHandle';
import { RemoveMediaUsecase } from '@vp/manager/gallery/core/usecase/RemoveMediaUsecase';
import { UploadMediaUsecase } from '@vp/manager/gallery/core/usecase/UploadMediaUsecase';

@injectable()
export class GalleryManagerFacade implements GalleryManagerPort {
  constructor(
    private readonly uploadMediaUsecase: UploadMediaUsecase,
    private readonly removeMediaUsecase: RemoveMediaUsecase,
  ) {}

  upload(dto: UploadMediaDto): GalleryManagerUploadHandle {
    return this.uploadMediaUsecase.execute(dto);
  }

  async remove(dto: RemoveMediaDto, controller?: AbortController): Promise<OperationResult> {
    return await this.removeMediaUsecase.execute(dto, controller);
  }
}
