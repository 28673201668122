import { Stack } from '@mui/material';
import { LayoutGroup, motion } from 'framer-motion';
import { FC, useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { VpButton } from '@vp/common/ui/component/VpButton';
import { EventsFormValues } from '@vp/manager/events/ui/EventsManagerPage';
import { EventsManagerViewModel } from '@vp/manager/events/ui/EventsManagerViewModel';
import { EventsManagerFormItem } from '@vp/manager/events/ui/form/EventsManagerFormItem';

interface EventsManagerFormListProps {
  disableControls: boolean;
}

export const EventsManagerFormList: FC<EventsManagerFormListProps> = ({ disableControls }) => {
  const { control } = useFormContext<EventsFormValues>();
  const { fields, append, remove } = useFieldArray({ control, name: 'events' });

  const removeEvent = useCallback((index: number) => remove(index), [remove]);
  const addEvent = useCallback(() => append(EventsManagerViewModel.EmptyEvent), [append]);

  return (
    <Stack pt={0.5} gap={3} alignItems="center">
      <LayoutGroup>
        {fields.map((field, index) => (
          <motion.div transition={{ duration: 0.25, ease: 'easeInOut' }} key={field.id} layout>
            <EventsManagerFormItem id={field.id} disableControls={disableControls} key={field.id} removeEvent={removeEvent} index={index} />
          </motion.div>
        ))}

        <motion.div transition={{ duration: 0.25, ease: 'easeInOut' }} layout>
          <VpButton sx={{ mb: 2 }} text="Добавить новое событие" status="primary" variant="soft" size="small" onClick={addEvent} />
        </motion.div>
      </LayoutGroup>
    </Stack>
  );
};
