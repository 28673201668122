import { Stack, Typography } from '@mui/material';
import { FC } from 'react';

import ImageGradient from '@vp/assets/icons/ImageGradient.svg?react';
import { VpIcon } from '@vp/common/ui/component/VpIcon';

const Text =
  'Поделитесь с близкими возможностью загрузить памятные фотографии и видео о вашем ушедшем близком, чтобы сохранить дорогие воспоминания.';

export const GalleryManagerNoMedia: FC = () => {
  return (
    <Stack width={1} mt={12.5} px={3} gap={2} alignItems="center">
      <VpIcon glowing Icon={ImageGradient} size={64} />

      <Typography
        variant="h4"
        color="text.secondary"
        align="center"
        sx={{ textShadow: '0px 0px 32px rgba(255, 255, 255, 0.5), 0px 0px 128px rgba(255, 255, 255, 0.5)' }}
      >
        Здесь пока ничего нет
      </Typography>

      <Typography variant="body1Regular" color="text.disabled" align="center">
        {Text}
      </Typography>
    </Stack>
  );
};
