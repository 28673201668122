import { Box, Stack, Typography } from '@mui/material';
import { FC, Fragment } from 'react';

import Logo from '@vp/assets/icons/Logo.svg?react';
import { VpFade } from '@vp/common/ui/component/VpFade';
import { VpFancyButton } from '@vp/common/ui/component/VpFancyButton';
import { VpIcon } from '@vp/common/ui/component/VpIcon';
import { useViewModel } from '@vp/common/ui/hook/useViewModel';
import { ProfileActivationViewModel } from '@vp/profile/ui/activation/ProfileActivationViewModel';

export const ProfileActivationPage: FC = () => {
  const viewModel = useViewModel(ProfileActivationViewModel);

  return (
    <VpFade>
      <Stack width={1} height="100dvh" alignItems="center" pt={7.5} px={3}>
        <VpIcon glowing Icon={Logo} size={130} />

        <Typography
          variant="h2"
          color="text.secondary"
          mt={1.25}
          align="center"
          sx={{ textShadow: '0px 0px 32px rgba(255, 255, 255, 0.5), 0px 0px 128px rgba(255, 255, 255, 0.5)' }}
        >
          Активация профиля
        </Typography>

        <Typography variant="body1Regular" color="text.disabled" mt={1.5} mb={4} textAlign="center">
          {viewModel.instructionText.value}
        </Typography>

        {viewModel.canActivateProfile.value && (
          <Fragment>
            <VpFancyButton text="Активировать" loading={viewModel.loading.value} onClick={viewModel.activate} />

            <Box flexGrow={1} />

            <Typography mb={3.5} variant="caption1Regular" color="text.secondaryDisabled" textAlign="center">
              {`Профиль будет привязан к вашему аккаунту ${viewModel.email.value}`}
            </Typography>
          </Fragment>
        )}

        {!viewModel.canActivateProfile.value && (
          <Stack gap={3} mt={4}>
            <VpFancyButton text="Войти в аккаунт" loading={viewModel.loading.value} onClick={viewModel.loginToActivate} />

            <Stack direction="row" gap={1}>
              <Typography variant="body1Regular" color="text.disabled">
                Нет аккаунта?
              </Typography>

              <Typography
                variant="body1Bold"
                color="text.secondary"
                sx={{ textShadow: '0px 0px 32px rgba(255, 255, 255, 0.5), 0px 0px 128px rgba(255, 255, 255, 0.5)' }}
                onClick={viewModel.registerToActivate}
              >
                Зарегистрироваться
              </Typography>
            </Stack>
          </Stack>
        )}
      </Stack>
    </VpFade>
  );
};
