import { Signal } from '@preact/signals-react';

import { SubmitEventsResultDto } from '@vp/manager/events/core/dto/SubmitEventsResultDto';
import { EventsManagerRecordModel } from '@vp/manager/events/core/model/EventsManagerRecordModel';

export type EventsManagerStatus = 'initial' | 'loading' | 'loaded' | 'error';

export abstract class EventsManagerState {
  abstract readonly events: Signal<EventsManagerRecordModel[]>;
  abstract readonly status: Signal<EventsManagerStatus>;
  abstract loading(): void;
  abstract update(dto: SubmitEventsResultDto, deletedEvents: string[]): void;
  abstract completed(status: EventsManagerStatus, events?: EventsManagerRecordModel[]): void;
  abstract reset(): void;
}
