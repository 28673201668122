import { injectable } from 'inversify';

import { ProfilePhotoModel } from '@vp/profile/core/model/ProfilePhotoModel';
import { SlideshowMediaLoader } from '@vp/slideshow/core/interface/SlideshowMediaLoader';

@injectable()
export class SlideshowMediaCacheLoader implements SlideshowMediaLoader {
  async loadImages(images: ProfilePhotoModel[]): Promise<ProfilePhotoModel[]> {
    const successfulImages: ProfilePhotoModel[] = [];

    await Promise.all(
      images.map(image => {
        return new Promise(resolve => {
          const img = new Image();
          img.src = image.url;
          img.onload = (): void => {
            successfulImages.push(image);
            resolve(null);
          };
          img.onerror = (): void => resolve(null);
        });
      }),
    );

    return successfulImages;
  }

  async loadAudio(audioUrl: string): Promise<boolean> {
    return new Promise(resolve => {
      const audio = new Audio(audioUrl);
      audio.preload = 'auto';
      audio.load();

      audio.onloadeddata = (): void => {
        resolve(true);
      };

      audio.onerror = (): void => {
        resolve(false);
      };
    });
  }
}
