import { Box, Fade, Modal, useTheme } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

export interface VpModalProps extends PropsWithChildren {
  open: boolean;
}

export const VpModal: FC<VpModalProps> = ({ open, children }) => {
  const { transitions, palette } = useTheme();
  const fadeDuration = transitions.duration.modalFadeDuration;

  return (
    <Modal open={open} slotProps={{ backdrop: { sx: { backdropFilter: 'blur(10px)', backgroundColor: 'transparent' } } }} aria-modal>
      <Fade in={open} timeout={fadeDuration} easing="ease-in-out">
        <Box width={1} height={1} px={2} display="flex" justifyContent="center" alignItems="center">
          <Box sx={{ width: 1, backgroundColor: palette.background[200], borderRadius: 1.5 }}>{children}</Box>
        </Box>
      </Fade>
    </Modal>
  );
};
