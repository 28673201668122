import { Box } from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import { FC, Fragment } from 'react';

import { VpFade } from '@vp/common/ui/component/VpFade';
import { useSearchSection } from '@vp/common/ui/hook/useSearchSection';
import { useSearchSectionCheck } from '@vp/common/ui/hook/useSearchSectionCheck';
import { useViewModel } from '@vp/common/ui/hook/useViewModel';
import { ProfileEvents } from '@vp/profile/ui/events/ProfileEvents';
import { ProfileGallery } from '@vp/profile/ui/gallery/ProfileGallery';
import { ProfilePageHeader } from '@vp/profile/ui/header/ProfilePageHeader';
import { ProfileHonor } from '@vp/profile/ui/honor/ProfileHonor';
import { ProfileInfo } from '@vp/profile/ui/navigation/ProfileInfo';
import { ProfileBio } from '@vp/profile/ui/ProfileBio';
import { ProfileImage } from '@vp/profile/ui/ProfileImage';
import { ProfileThemeProvider } from '@vp/profile/ui/ProfileThemeProvider';
import { ProfileSection, ProfileViewModel } from '@vp/profile/ui/ProfileViewModel';
import { SlideshowModal } from '@vp/profile/ui/SlideshowModal';

export const ProfilePage: FC = () => {
  useSearchSectionCheck('s', ProfileSection.Bio);

  const viewModel = useViewModel(ProfileViewModel);
  const section = useSearchSection<ProfileSection>('s', ProfileSection.Bio);

  return (
    <Fragment>
      <VpFade>
        <ProfilePageHeader />

        <ProfileThemeProvider>
          <Box sx={{ backgroundColor: 'background.300' }}>
            <ProfileImage image={viewModel.profile.value.image} />

            <Box position="relative">
              <ProfileInfo
                name={viewModel.profile.value.name}
                birthDate={viewModel.profile.value.birthDate}
                deathDate={viewModel.profile.value.deathDate}
              />

              <AnimatePresence mode="wait">
                <VpFade key={section} duration={0.25}>
                  {section === ProfileSection.Bio && <ProfileBio profile={viewModel.profile.value} />}
                  {section === ProfileSection.Events && <ProfileEvents profile={viewModel.profile.value} />}
                  {section === ProfileSection.Gallery && <ProfileGallery profile={viewModel.profile.value} />}
                  {section === ProfileSection.Honor && <ProfileHonor profile={viewModel.profile.value} />}
                </VpFade>
              </AnimatePresence>

              <Box
                sx={theme => ({
                  ...theme.applyStyles('light', {
                    inset: 0,
                    position: 'absolute',
                    pointerEvents: 'none',
                    backgroundImage: theme.lightNoise,
                  }),
                })}
              />
            </Box>
          </Box>
        </ProfileThemeProvider>
      </VpFade>

      <SlideshowModal slideshowStatus={viewModel.slideshowStatus.value} />
    </Fragment>
  );
};
