import { Box, IconButton, ImageList, ImageListItem, Stack, SvgIcon, Typography, useTheme } from '@mui/material';
import { FC, useCallback, useState } from 'react';

import ChevronLeftIcon from '@vp/assets/icons/ChevronLeft.svg?react';
import { ProfilePhotoModel } from '@vp/profile/core/model/ProfilePhotoModel';

interface SlideshowGalleryContentProps {
  close: (goToImageId?: string) => void;
  images: ProfilePhotoModel[];
  profileName: string;
}

export const SlideshowGalleryContent: FC<SlideshowGalleryContentProps> = ({ close, images, profileName }) => {
  const { spacing } = useTheme();
  const [lockInteractions, setLockInteractions] = useState(false);

  const handleClose = useCallback(
    (goToImageId?: string) => {
      if (!lockInteractions) {
        setLockInteractions(true);
        close(goToImageId);
      }
    },
    [close, lockInteractions],
  );

  return (
    <Box sx={{ position: 'relative', padding: `0 ${spacing(2)} ${spacing(2)}`, textAlign: 'left' }}>
      <Stack direction="row" py={2} justifyContent="space-between">
        <Box sx={{ position: 'relative' }}>
          <IconButton
            onClick={() => handleClose()}
            sx={theme => ({
              padding: 0,
              color: theme.palette.primary.main,
              '&::before': {
                content: '""',
                position: 'absolute',
                top: spacing(-2),
                right: spacing(-3),
                bottom: spacing(-2),
                left: spacing(-2),
                background: 'transparent',
              },
            })}
          >
            <SvgIcon>
              <ChevronLeftIcon />
            </SvgIcon>
          </IconButton>
        </Box>

        <Typography variant="h6" color="text.primary" textAlign="center">
          {profileName}
        </Typography>

        <Box width={24} />
      </Stack>

      <ImageList cols={3} gap={4} sx={{ px: 1, m: 0 }}>
        {images.map(image => (
          <ImageListItem onClick={() => handleClose(image.id)} sx={{ position: 'relative', pt: '133.65%' }} key={image.id}>
            <Box
              sx={{
                position: 'absolute',
                inset: 0,
                backgroundImage: `url(${image.url})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
};
