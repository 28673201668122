import { Box, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC, SyntheticEvent, useCallback, useRef, useState } from 'react';
import ReactPlayer from 'react-player/file';

import { VpImage } from '@vp/common/ui/component/VpImage';
import { useControlsVisible } from '@vp/common/ui/hook/useControlsVisible';
import { useProgressAnimation } from '@vp/common/ui/player/hook/useProgressAnimation';
import { VpPlayerControls } from '@vp/common/ui/player/VpPlayerControls';
import { VpPlayerPausePlayControl } from '@vp/common/ui/player/VpPlayerPausePlayControl';
import { resolveSx } from '@vp/common/ui/util/resolveSx';

export interface VpPlayerProps {
  url: string;
  thumbnail: string;
  thumbnailHash: string;
  sx?: SxProps<Theme>;
}

export const VpPlayer: FC<VpPlayerProps> = ({ url, thumbnail, thumbnailHash, sx }) => {
  const [initialized, setInitialize] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState(0);
  const playerRef = useRef<ReactPlayer>(null!);

  useProgressAnimation({ playing, playerRef, setProgress, duration });
  const { controlsVisible, setInteractionLock, heartbeat, toggleControlsVisibility } = useControlsVisible();

  const initializePlaying = useCallback((event: SyntheticEvent) => {
    event.stopPropagation();
    setPlaying(true);
    setInitialize(true);
  }, []);

  const onProgressChange = useCallback((value: number) => {
    setProgress(value);
    playerRef.current?.seekTo(value);
  }, []);

  const togglePlaying = useCallback(() => {
    heartbeat();
    setPlaying(prev => !prev);
  }, [heartbeat]);

  return (
    <Box sx={theme => ({ ...resolveSx(sx, theme), display: 'flex', flexDirection: 'column' })} onClick={toggleControlsVisibility}>
      <ReactPlayer
        ref={playerRef}
        url={url}
        width="100%"
        style={{ flexGrow: 1 }}
        playsinline
        playing={playing}
        playIcon={<VpPlayerPausePlayControl playing={false} sx={theme => ({ zIndex: theme.zIndex.mediaControls })} />}
        light={<VpImage src={thumbnail} hash={thumbnailHash} />}
        onClickPreview={initializePlaying}
        onDuration={setDuration}
      />
      {initialized && (
        <VpPlayerControls
          playing={playing}
          duration={duration}
          progress={progress}
          visible={controlsVisible}
          onProgressChange={onProgressChange}
          onInteractionLock={setInteractionLock}
          onPlayPauseClick={togglePlaying}
        />
      )}
    </Box>
  );
};
