import { batch, signal, Signal } from '@preact/signals-react';
import { injectable } from 'inversify';

import { ViewModel, ViewModelDispose } from '@vp/common/ui/ViewModel';
import { AvatarManagerPort } from '@vp/manager/avatar/core/interfaces/AvatarManagerPort';
import { ProfileManagerPort } from '@vp/manager/profile/core/interface/ProfileManagerPort';
import { AppNotificationService } from '@vp/notification/AppNotificationService';
import { ProfileModel } from '@vp/profile/core/model/ProfileModel';

enum AvatarOperation {
  Upload = 'upload',
  Remove = 'remove',
}

@injectable()
export class AvatarManagerViewModel extends ViewModel implements ViewModelDispose {
  readonly profile: Signal<ProfileModel | null> = this.profileManagerPort.active;
  readonly dialogShown: Signal<boolean> = signal(false);
  readonly removing: Signal<boolean> = signal(false);
  readonly uploading: Signal<boolean> = signal(false);

  private controller?: AbortController;

  constructor(
    private readonly profileManagerPort: ProfileManagerPort,
    private readonly notificationService: AppNotificationService,
    private readonly avatarManagerPort: AvatarManagerPort,
  ) {
    super();
  }

  dispose(): void {
    this.controller?.abort();
  }

  onFileUpload = async (files: File[]): Promise<void> => {
    this.uploading.value = true;
    await this.handleUpload(files[0]);
    this.uploading.value = false;
  };

  onRemoveButtonClick = (): void => {
    this.dialogShown.value = true;
  };

  removeImage = async (): Promise<void> => {
    this.removing.value = true;
    await this.handleRemove();
    batch(() => {
      this.removing.value = false;
      this.dialogShown.value = false;
    });
  };

  closeDialog = (): void => {
    this.dialogShown.value = false;
  };

  private async handleRemove(): Promise<void> {
    this.controller = new AbortController();

    if (await this.avatarManagerPort.remove(this.profile.value!.id, this.profile.value!.image.id, this.controller)) {
      this.showSuccessNotification(AvatarOperation.Remove);
    } else {
      this.showErrorNotification(AvatarOperation.Remove);
    }
  }

  private async handleUpload(avatarFile: File): Promise<void> {
    this.controller = new AbortController();

    if (await this.avatarManagerPort.upload(this.profile.value!.id, avatarFile, this.controller)) {
      this.showSuccessNotification(AvatarOperation.Upload);
    } else {
      this.showErrorNotification(AvatarOperation.Upload);
    }
  }

  private showSuccessNotification(operation: AvatarOperation): void {
    this.notificationService.enqueue({
      variant: 'success',
      message: `Фото было ${operation === AvatarOperation.Upload ? 'загружено' : 'удалено'}`,
    });
  }

  private showErrorNotification(operation: AvatarOperation): void {
    this.notificationService.enqueue({
      variant: 'error',
      message: 'Что-то пошло не так',
      secondaryMessage: `Не удалось ${operation === AvatarOperation.Upload ? 'загрузить' : 'удалить'} фото`,
    });
  }
}
