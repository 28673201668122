import { Slider, Stack, styled, Theme, Typography } from '@mui/material';
import { alpha } from '@mui/system';
import { FC, SyntheticEvent, useCallback, useState } from 'react';

export interface VpTimeSliderControlProps {
  duration: number;
  progress: number;
  onProgressChange: (progress: number) => void;
  onInteractionLock: (lock: boolean) => void;
}

export const VpTimeSliderControl: FC<VpTimeSliderControlProps> = ({ duration, progress, onProgressChange, onInteractionLock }) => {
  const [controlledProgress, setControlledProgress] = useState(0);
  const [dragging, setDragging] = useState(false);
  const sliderProgress = dragging ? controlledProgress : progress;

  const onDragStart = useCallback(() => {
    setDragging(true);
    onInteractionLock(true);
  }, [onInteractionLock]);

  const onDragEnd = useCallback(() => {
    setDragging(false);
    onInteractionLock(false);
    onProgressChange(controlledProgress);
  }, [controlledProgress, onInteractionLock, onProgressChange]);

  const onClick = useCallback((event: SyntheticEvent) => event?.stopPropagation(), []);
  const onChange = useCallback((_: Event, value: number | number[]) => setControlledProgress(value as number), []);

  return (
    <Stack px={4.25} width={1} position="absolute" bottom={theme => theme.spacing(3)} sx={{ pointerEvents: 'auto' }}>
      <TimeSlider
        min={0}
        step={0.01}
        max={duration}
        value={sliderProgress}
        onChange={onChange}
        onMouseDown={onDragStart}
        onMouseUp={onDragEnd}
        onTouchStart={onDragStart}
        onTouchEnd={onDragEnd}
        onClick={onClick}
      />

      <Stack
        sx={theme => ({
          flexDirection: 'row',
          justifyContent: 'space-between',
          position: 'absolute',
          bottom: 0,
          left: theme.spacing(4.25),
          right: theme.spacing(4.25),
        })}
      >
        <Typography variant="overlineRegular" color="transparency.alpha48">
          {formatDuration(progress)}
        </Typography>

        <Typography variant="overlineRegular" color="transparency.alpha48">
          {formatDuration(duration)}
        </Typography>
      </Stack>
    </Stack>
  );
};

const TimeSlider = styled(Slider)(({ theme }) => ({
  height: 5,
  width: '100%',
  borderRadius: 16,
  color: theme.palette.transparency.alpha8,
  padding: `${theme.spacing(2.5)} 0`,
  '@media (pointer: coarse)': { padding: `${theme.spacing(2.5)} 0` },
  '& .MuiSlider-track': {
    transition: 'none',
    height: 5,
    border: 0,
    background: getGradient(theme),
  },
  '& .MuiSlider-thumb': {
    transition: 'none',
    '&::before': { boxShadow: '0 2px 12px 0 rgba(0, 0, 0, 0.4)' },
    '&:hover, &.Mui-focusVisible': { boxShadow: '0px 0px 0px 8px rgb(255 255 255 / 16%)' },
    color: theme.palette.text.primary,
    width: 5,
    height: 5,
    '&.Mui-active': { width: 8, height: 8 },
  },
}));

function getGradient(theme: Theme): string {
  const whiteAlpha16 = theme.palette.transparency.alpha16;
  const primary = theme.palette.primary['500'] as string;
  const first = `linear-gradient(0deg, ${whiteAlpha16}, ${whiteAlpha16})`;
  const second = `linear-gradient(90deg, ${primary} 0%, ${alpha(primary, 0.5)} 64%, ${alpha(primary, 0)} 100%)`;
  return `${first}, ${second}`;
}

function formatDuration(duration: number): string {
  const minutes = Math.floor(duration / 60);
  const seconds = Math.ceil(duration % 60);
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
}
