import { Stack, Typography } from '@mui/material';
import { FC } from 'react';

import Logo from '@vp/assets/icons/Logo.svg?react';
import { VpIcon } from '@vp/common/ui/component/VpIcon';

export const DesktopPlaceholder: FC = () => {
  return (
    <Stack
      zIndex={theme => theme.zIndex.desktopPlaceholder}
      sx={{
        position: 'fixed',
        inset: 0,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'background.300',
      }}
    >
      <Stack maxWidth={800} gap={2} alignItems="center" textAlign="center">
        <VpIcon glowing Icon={Logo} size={160} />

        <Stack gap={2}>
          <Typography variant="h1" sx={{ textShadow: '0px 0px 32px rgba(255, 255, 255, 0.5), 0px 0px 64px rgba(255, 255, 255, 0.6)' }}>
            Веб-версия приложения пока находится в разработке
          </Typography>
          <Typography
            color="text.disabled"
            variant="body1Regular"
            sx={{ textShadow: '0px 0px 32px rgba(255, 255, 255, 0.5), 0px 0px 64px rgba(255, 255, 255, 0.6)' }}
          >
            Воспользуйтесь мобильным устройством для просмотра
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
