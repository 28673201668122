import { MediaFileDto } from '@vp/manager/gallery/core/dto/MediaFileDto';
import { UploadMediaType } from '@vp/manager/gallery/core/dto/UploadMediaType';
import { FileUpload } from '@vp/manager/gallery/data/upload/context/FileUpload';
import { ImageResponseDto } from '@vp/profile/data/dto/ImageResponseDto';
import { VideoResponseDto } from '@vp/profile/data/dto/VideoResponseDto';

export type FileUploadRegistry = Map<number, FileUpload>;

export class UploadContext {
  progress: number = 0;
  currentFileIndex: number = 0;
  fileRegistry: FileUploadRegistry = new Map();
  error: string = '';

  images: ImageResponseDto[] = [];
  videos: VideoResponseDto[] = [];

  constructor(
    readonly files: MediaFileDto[],
    readonly type: UploadMediaType,
    readonly chunkSize: number,
    readonly profileId: string,
  ) {}

  setError(error: string): void {
    this.error = error;
  }

  nextFile(): void {
    this.currentFileIndex++;
  }

  addImage(image: ImageResponseDto): void {
    this.images.push(image);
  }

  addVideo(video: VideoResponseDto): void {
    this.videos.push(video);
  }

  getCurrentFileUpload(): FileUpload {
    return this.fileRegistry.get(this.currentFileIndex)!;
  }

  updateProgress(progress: number): void {
    this.progress = progress;
  }

  setRegistry(fileRegistry: FileUploadRegistry): void {
    this.fileRegistry = fileRegistry;
  }

  isLastFile(): boolean {
    return this.currentFileIndex === this.files.length - 1;
  }

  reset(): void {
    this.progress = 0;
    this.currentFileIndex = 0;
    this.fileRegistry.clear();
    this.images = [];
    this.videos = [];
    this.error = '';
  }
}
