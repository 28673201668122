import { injectable } from 'inversify';

import { AuthPort } from '@vp/auth/core/interface/AuthPort';
import { AuthState } from '@vp/auth/core/interface/AuthState';
import { AppMenuService } from '@vp/menu/core/interface/AppMenuService';
import { RouterService } from '@vp/routing/RouterService';

@injectable()
export class AuthHandler {
  constructor(
    private readonly authState: AuthState,
    private readonly routerService: RouterService,
    private readonly authPort: AuthPort,
    private readonly appMenuService: AppMenuService,
  ) {}

  handle(): void {
    this.isAuthenticated() ? this.handleLogout() : this.toLogin();
    this.appMenuService.toggle();
  }

  private handleLogout(): void {
    this.toLoginPageIfNeeded();
    void this.authPort.logout();
  }

  private toLoginPageIfNeeded(): void {
    const isOnSettingsPage = this.routerService.match('/ps');
    if (isOnSettingsPage) this.toLogin();
  }

  private toLogin(): void {
    void this.routerService.navigate('/auth/login');
  }

  private isAuthenticated(): boolean {
    return this.authState.authenticated.value;
  }
}
