import { signal, Signal } from '@preact/signals-react';
import { injectable } from 'inversify';

import { AuthState } from '@vp/auth/core/interface/AuthState';

@injectable()
export class SignalsAuthState implements AuthState {
  readonly authenticated: Signal<boolean> = signal(false);
  readonly email: Signal<string> = signal('');

  setOwnerEmail(email: string): void {
    this.email.value = email;
    this.authenticated.value = !!email;
  }

  reset(): void {
    this.email.value = '';
    this.authenticated.value = false;
  }
}
