import { Box } from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import { FC, ReactNode } from 'react';

import { VpSpinner } from '@vp/common/ui/component/spinner/VpSpinner';
import { VpFade } from '@vp/common/ui/component/VpFade';
import { useFalsyStateDelay } from '@vp/common/ui/hook/useFalsyStateDelay';

interface VpLoadingWrapperProps {
  loading: boolean;
  children: ReactNode;
}

export const VpLoadingWrapper: FC<VpLoadingWrapperProps> = ({ loading, children }) => {
  const duration = 0.25;
  const delay = duration * 1000;
  const delayedLoading = useFalsyStateDelay(loading, delay);

  return (
    <AnimatePresence mode="wait">
      <VpFade key={delayedLoading.toString()} duration={duration}>
        {!delayedLoading && children}
        {delayedLoading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 1 }}>
            <VpSpinner />
          </Box>
        )}
      </VpFade>
    </AnimatePresence>
  );
};
