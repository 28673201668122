export class UpdateBiographyDto {
  readonly profileId!: string;
  readonly name!: string;
  readonly dateOfBirth!: string;
  readonly dateOfDeath!: string;
  readonly biography!: string;

  constructor(args: UpdateBiographyDto) {
    Object.assign(this, args);
  }
}
