import { ReadonlySignal } from '@preact/signals-react';

import { ProfileModel } from '@vp/profile/core/model/ProfileModel';

export abstract class ProfileManagerPort {
  abstract active: ReadonlySignal<ProfileModel | null>;

  abstract loadProfilesIfNeeded(controller?: AbortController): Promise<void>;
  abstract setActiveProfile(profile: ProfileModel): void;
}
