import { EngineState } from '@vp/slideshow/core/engine/state/EngineState';

export class FinishedState extends EngineState {
  play(): void {
    this.engine.changeState(this.engine.playingState);
  }

  exit(): void {
    this.engine.progress = 0;
    this.engine.currentSlide = 0;
    this.engine.currentSlideProgress = 0;
    this.engine.startTime = Date.now();
  }
}
