import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';

export const RegisterLoginNavigation: FC = () => (
  <Stack direction="row" gap={1}>
    <Typography variant="body1Regular" color="text.disabled">
      Уже есть аккаунт?
    </Typography>

    <Typography
      component={Link}
      to="/auth/login"
      variant="body1Bold"
      color="text.secondary"
      sx={{ textShadow: '0px 0px 32px rgba(255, 255, 255, 0.5), 0px 0px 128px rgba(255, 255, 255, 0.5)' }}
    >
      Войти
    </Typography>
  </Stack>
);
