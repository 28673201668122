import { IconButton } from '@mui/material';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { FileUploadProps, VpHiddenFileInput } from '@vp/common/ui/component/VpHiddenFileInput';
import { VpIcon } from '@vp/common/ui/component/VpIcon';
import { resolveSx } from '@vp/common/ui/util/resolveSx';

export type VpIconButtonProps = Omit<Parameters<typeof IconButton>[0], 'size'> & {
  Icon: FC;
  glowing?: boolean;
  to?: string;
  size?: number;
  fileUpload?: FileUploadProps;
};

export const VpIconButton: FC<VpIconButtonProps> = ({ Icon, glowing, sx, size = 24, fileUpload, ...props }) => {
  return (
    <IconButton
      sx={theme => ({
        px: 2,
        py: 1.25,
        pointerEvents: 'auto',
        color: theme.palette.primary[500],
        transition: 'color 0.25s ease-in-out',
        ...resolveSx(sx, theme),
      })}
      LinkComponent={props.to ? RouterLink : props.LinkComponent}
      {...props}
      {...(fileUpload ? { component: 'label' } : {})}
    >
      <VpIcon Icon={Icon} size={size} glowing={glowing} />
      {fileUpload && <VpHiddenFileInput {...fileUpload} />}
    </IconButton>
  );
};
