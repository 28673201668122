import { ContainerModule } from 'inversify';

import { BaseModule } from '@vp/common/BaseModule';
import { RouterService, RouterServiceImpl } from '@vp/routing/RouterService';

export class RoutingModule extends BaseModule {
  readonly module: ContainerModule = new ContainerModule(bind => {
    bind(RouterServiceImpl)
      .toDynamicValue(context => new RouterServiceImpl(context.container.get('RouterInstance')))
      .inSingletonScope();

    bind(RouterService)
      .toDynamicValue(context => context.container.get(RouterServiceImpl))
      .inSingletonScope();
  });
}
