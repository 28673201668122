import { Typography } from '@mui/material';
import { FC } from 'react';

import Edit from '@vp/assets/icons/Edit.svg?react';
import Menu from '@vp/assets/icons/Menu.svg?react';
import { VpHeader, VpHeaderColumn } from '@vp/common/ui/component/VpHeader';
import { VpIconButton } from '@vp/common/ui/component/VpIconButton';

export interface ProfileSettingsPageHeaderProps {
  noProfiles: boolean;
  onMenuClick: () => void;
}

export const ProfileSettingsPageHeader: FC<ProfileSettingsPageHeaderProps> = ({ noProfiles, onMenuClick }) => {
  return (
    <VpHeader sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
      <VpHeaderColumn align="left">
        <VpIconButton glowing Icon={Menu} onClick={onMenuClick} />
      </VpHeaderColumn>

      <VpHeaderColumn>
        {!noProfiles && (
          <Typography variant="h6" color="text.primary" textAlign="center">
            Настройки профиля
          </Typography>
        )}
      </VpHeaderColumn>

      <VpHeaderColumn align="right">{!noProfiles && <VpIconButton glowing Icon={Edit} to="avatar" />}</VpHeaderColumn>
    </VpHeader>
  );
};
