import { Box, Stack } from '@mui/material';
import { FC } from 'react';

import { ProfileSettingsMenuItem } from '@vp/manager/profile/ui/settings/menu/ProfileSettingsMenuItem';

export interface MenuItem {
  path: string;
  Icon: FC;
  text: string;
}

export interface ProfileSettingsMenuProps {
  items: MenuItem[];
}

export const ProfileSettingsMenu: FC<ProfileSettingsMenuProps> = ({ items }) => {
  return (
    <Box sx={{ px: 2, width: 1, position: 'relative' }}>
      <Stack sx={theme => ({ width: 1, backgroundColor: theme.palette.background['200'], borderRadius: 1.5 })}>
        {items.map((item, index) => {
          const isFirst = index === 0;
          const isLast = index === items.length - 1;
          return <ProfileSettingsMenuItem key={item.path} item={item} isFirst={isFirst} isLast={isLast} />;
        })}
      </Stack>
    </Box>
  );
};
