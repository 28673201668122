import { Typography } from '@mui/material';
import { FC } from 'react';

import CloseIcon from '@vp/assets/icons/Close.svg?react';
import { VpHeader, VpHeaderColumn } from '@vp/common/ui/component/VpHeader';
import { VpIconButton } from '@vp/common/ui/component/VpIconButton';

interface BiographyManagerEditHeaderProps {
  onClose: () => void;
}

export const BiographyManagerEditHeader: FC<BiographyManagerEditHeaderProps> = ({ onClose }) => {
  return (
    <VpHeader sx={{ position: 'static', backgroundColor: 'background.300' }}>
      <VpHeaderColumn align="left">
        <VpIconButton glowing Icon={CloseIcon} onClick={onClose} />
      </VpHeaderColumn>

      <VpHeaderColumn flex={3}>
        <Typography variant="h6" color="text.primary">
          Редактировать биографию
        </Typography>
      </VpHeaderColumn>

      <VpHeaderColumn align="right" />
    </VpHeader>
  );
};
