import { injectable } from 'inversify';

import { GenerateBioResponseDto } from '@vp/manager/biography/core/dto/GenerateBioResponseDto';
import { RefineBiographyDto } from '@vp/manager/biography/core/dto/RefineBiographyDto';
import { UpdateBiographyDto } from '@vp/manager/biography/core/dto/UpdateBiographyDto';

@injectable()
export class BiographyManagerDataMapper {
  toRefineFormData(dto: RefineBiographyDto): FormData {
    const formData = new FormData();

    formData.append('bio', dto.biography);
    formData.append('grammar_only', dto.grammarOnly.toString());

    return formData;
  }

  toUpdateFormData(dto: UpdateBiographyDto): FormData {
    const formData = new FormData();

    formData.append('name', dto.name);
    formData.append('date_of_birth', dto.dateOfBirth);
    formData.append('date_of_death', dto.dateOfDeath);
    formData.append('bio', dto.biography);

    return formData;
  }

  toGeneratedInfo(dto: GenerateBioResponseDto): string {
    return dto.generated_text;
  }
}
