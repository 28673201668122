import { signal, Signal } from '@preact/signals-react';
import { injectable } from 'inversify';

import { AuthPort } from '@vp/auth/core/interface/AuthPort';
import { Success } from '@vp/common/core/OperationResult';
import { ViewModel, ViewModelDispose } from '@vp/common/ui/ViewModel';
import { AppNotificationService } from '@vp/notification/AppNotificationService';

export type ForgotPasswordFormData = {
  email: string;
};

@injectable()
export class ForgotPasswordViewModel extends ViewModel implements ViewModelDispose {
  readonly loading: Signal<boolean> = signal(false);

  private controller?: AbortController;

  constructor(
    private readonly authPort: AuthPort,
    private readonly notificationService: AppNotificationService,
  ) {
    super();
  }

  dispose(): void {
    this.controller?.abort();
  }

  sendPasswordResetLink = async ({ email }: ForgotPasswordFormData): Promise<void> => {
    this.controller = new AbortController();
    this.loading.value = true;

    const result = await this.authPort.sendResetPasswordLink(email, this.controller);

    if (result instanceof Success) {
      this.showSuccessNotification();
    } else {
      this.showErrorNotification(result.message);
    }

    this.loading.value = false;
  };

  private showSuccessNotification(): void {
    this.notificationService.enqueue({
      variant: 'success',
      message: 'Ссылка для восстановления пароля была отправлена на вашу почту',
      secondaryMessage: 'Проверьте папку "Спам", если вы не получили письмо.',
      autoHideDuration: 10_000,
    });
  }

  private showErrorNotification(message: string): void {
    this.notificationService.enqueue({
      variant: 'error',
      message: 'Что-то пошло не так',
      secondaryMessage: message || 'Не удалось отправить письмо, попробуйте позже.',
    });
  }
}
