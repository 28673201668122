import { computed, ReadonlySignal } from '@preact/signals-react';
import { injectable } from 'inversify';

import Headset from '@vp/assets/icons/Headset.svg?react';
import Login from '@vp/assets/icons/Login.svg?react';
import { AuthState } from '@vp/auth/core/interface/AuthState';
import { ViewModel } from '@vp/common/ui/ViewModel';
import { AppMenuService } from '@vp/menu/core/interface/AppMenuService';
import { AppMenuState } from '@vp/menu/core/interface/AppMenuState';
import { AppMenuItemId, AppMenuItemModel } from '@vp/menu/ui/AppMenuItemModel';
import { HandlerRegistry } from '@vp/menu/ui/handler/HandlerRegistry';

@injectable()
export class AppMenuViewModel extends ViewModel {
  readonly open: ReadonlySignal<boolean> = this.appMenuState.open;
  readonly authenticated: ReadonlySignal<boolean> = this.authState.authenticated;
  readonly email: ReadonlySignal<string> = this.authState.email;
  readonly items: ReadonlySignal<AppMenuItemModel[]> = computed(() => this.getMenuItems(this.authenticated.value));

  constructor(
    private readonly appMenuState: AppMenuState,
    private readonly appMenuService: AppMenuService,
    private readonly authState: AuthState,
    private readonly handlerRegistry: HandlerRegistry,
  ) {
    super();
  }

  close = (): void => {
    this.appMenuService.toggle();
  };

  onItemClick = (item: AppMenuItemModel): void => {
    const handler = this.handlerRegistry.get(item.id);
    handler?.();
  };

  private getMenuItems(authenticated: boolean): AppMenuItemModel[] {
    const authItem = this.getAuthItem(authenticated);
    return [{ id: AppMenuItemId.Support, text: 'Написать в поддержку', Icon: Headset }, authItem];
  }

  private getAuthItem(authenticated: boolean): AppMenuItemModel {
    const text = authenticated ? 'Выйти из аккаунта' : 'Войти в аккаунт';
    return { id: AppMenuItemId.Auth, text, Icon: Login };
  }
}
