import { ContainerModule } from 'inversify';

import { BaseModule } from '@vp/common/BaseModule';
import { AppNotificationService } from '@vp/notification/AppNotificationService';

export class AppNotificationModule extends BaseModule {
  readonly module: ContainerModule = new ContainerModule(bind => {
    bind(AppNotificationService).toSelf().inSingletonScope();
  });
}
