import { SxProps, Theme } from '@mui/system';
import { FC } from 'react';

import { VpTab, VpTabs } from '@vp/common/ui/component/VpTabs';
import { useSearchSection } from '@vp/common/ui/hook/useSearchSection';
import { resolveSx } from '@vp/common/ui/util/resolveSx';
import { ProfileSection } from '@vp/profile/ui/ProfileViewModel';

const Tabs: VpTab[] = [
  { id: 'bio', name: 'Биография', url: '.?s=bio' },
  { id: 'events', name: 'События', url: '.?s=events' },
  { id: 'gallery', name: 'Галерея', url: '.?s=gallery&m=images' },
  { id: 'honor', name: 'Почести', url: '.?s=honor' },
];

export type ProfileNavigationProps = { sx?: SxProps<Theme>; defaultSection?: ProfileSection };

export const ProfileNavigation: FC<ProfileNavigationProps> = ({ sx, defaultSection = ProfileSection.Bio }) => {
  const section = useSearchSection('s', defaultSection);
  return <VpTabs tabs={Tabs} activeTabId={section} sx={theme => ({ px: 3, ...resolveSx(sx, theme) })} />;
};
