import { Box, Stack } from '@mui/material';
import { FC } from 'react';

import { VpFade } from '@vp/common/ui/component/VpFade';
import { LandingDescription } from '@vp/landing/LandingDescription';
import { LandingNavigation } from '@vp/landing/LandingNavigation';
import { LandingSlogan } from '@vp/landing/LandingSlogan';

export const LandingPage: FC = () => {
  return (
    <VpFade>
      <Stack height="100dvh">
        <Box flexGrow={1} />

        <Stack px={3} pb={1} alignItems="center">
          <LandingSlogan />

          <Box height={theme => theme.spacing(2)} />

          <LandingDescription />

          <Box height={theme => theme.spacing(7)} />

          <LandingNavigation />
        </Stack>
      </Stack>
    </VpFade>
  );
};
