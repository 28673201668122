import { createTheme } from '@mui/material';

import { lightNoise } from './lightNoise';
import { primaryColorCssProperty, systemPalette } from './palette';
import { shadows } from './shadows';
import { transitions } from './transitions';
import { fontFaces, typography } from './typography';
import { zIndex } from './zIndex';

export const theme = createTheme({
  fullImageHeightMobile: 436,
  smallImageHeightMobile: 286,
  profileSettingsMenuOffsetMobile: 38,
  palette: systemPalette,
  shadows,
  typography,
  spacing: 8,
  transitions,
  zIndex,
  lightNoise,
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        ${fontFaces}
        ${primaryColorCssProperty}

        body, html { width: 100%; height: 100%; margin: 0; padding: 0; box-sizing: border-box; }

        a, a:focus, a:active {
          text-decoration: none;
          color: inherit;
          outline: none;
          user-select: none;
          -webkit-tap-highlight-color: transparent;
        }
      `,
    },
  },
});
