import { Box, useTheme } from '@mui/material';
import { FC } from 'react';

import { VpImage } from '@vp/common/ui/component/VpImage';
import { ProfileModel } from '@vp/profile/core/model/ProfileModel';

export interface ProfileSettingsImageProps {
  profile: ProfileModel;
}

const Gradient = 'linear-gradient(rgba(0, 0, 0, 0.18) 0%, rgba(0, 0, 0, 0.72) 56.8%, rgba(0, 0, 0, 0.9) 100%)';

export const ProfileSettingsImage: FC<ProfileSettingsImageProps> = ({ profile }) => {
  const { smallImageHeightMobile } = useTheme();

  return (
    <Box width={1} height={smallImageHeightMobile} position="sticky" top={0}>
      <VpImage src={profile.image.url} hash={profile.image.hash} />
      <Box sx={{ position: 'absolute', inset: 0, background: Gradient }} />
    </Box>
  );
};
