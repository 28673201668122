import { signal, Signal } from '@preact/signals-react';
import { injectable } from 'inversify';

import { AuthPort } from '@vp/auth/core/interface/AuthPort';
import { SplashService } from '@vp/common/ui/SplashService';
import { ViewModel, ViewModelInit } from '@vp/common/ui/ViewModel';
import { ProfileManagerPort } from '@vp/manager/profile/core/interface/ProfileManagerPort';
import { RouterService } from '@vp/routing/RouterService';

@injectable()
export class AppViewModel extends ViewModel implements ViewModelInit {
  readonly initialized: Signal<boolean> = signal(false);
  private isInitializeCalled: boolean = false;

  constructor(
    private readonly splashService: SplashService,
    private readonly router: RouterService,
    private readonly authPort: AuthPort,
    private readonly profileManagerPort: ProfileManagerPort,
  ) {
    super();
  }

  init(): void {
    if (!this.isInitializeCalled) {
      void this.initializeApp();
      this.isInitializeCalled = true;
    }
  }

  private async initializeApp(): Promise<void> {
    await this.authPort.validate();
    await this.profileManagerPort.loadProfilesIfNeeded();
    this.initialized.value = true;
    this.hideSplashIfNeeded();
  }

  private hideSplashIfNeeded(): void {
    const isProfilePage = this.router.match('/p/');
    if (!isProfilePage) void this.splashService.hide();
  }
}
