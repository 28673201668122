import { Box, IconButton, Stack, styled, SvgIcon, Typography } from '@mui/material';
import { CustomContentProps, SnackbarContent, useSnackbar } from 'notistack';
import { forwardRef, FC, useCallback, Ref } from 'react';

import CheckmarkFilled from '@vp/assets/icons/CheckmarkFilled.svg?react';
import Close from '@vp/assets/icons/Close.svg?react';
import CloseFilled from '@vp/assets/icons/CloseFilled.svg?react';
import InformationFilled from '@vp/assets/icons/InformationFilled.svg?react';
import Star from '@vp/assets/icons/Star.svg?react';
import WarningAltFilled from '@vp/assets/icons/WarningAltFilled.svg?react';
import { VpIcon } from '@vp/common/ui/component/VpIcon';

export interface AppNotificationProps extends CustomContentProps {
  primaryMessage: string;
  secondaryMessage?: string;
  Icon?: FC;
}

const Icons: Record<AppNotificationProps['variant'], FC> = {
  success: CheckmarkFilled,
  info: InformationFilled,
  warning: WarningAltFilled,
  error: CloseFilled,
  primary: Star,
};

type AppNotificationRootProps = Pick<AppNotificationProps, 'variant'>;

const AppNotificationRoot = styled(Box)<AppNotificationRootProps>(({ theme }) => ({
  position: 'relative',
  width: '100%',
  padding: theme.spacing(1.5),
  display: 'flex',
  alignItems: 'flex-start',
  borderRadius: '8px',
  background: getBackground(),
  '--vp-snackbar-background': theme.palette.background[300],
  variants: [
    {
      props: { variant: 'success' },
      style: { '--vp-snackbar-status-background': theme.palette.success.alpha24, '--vp-snackbar-action-color': theme.palette.success[500] },
    },
    {
      props: { variant: 'info' },
      style: { '--vp-snackbar-status-background': theme.palette.info.alpha24, '--vp-snackbar-action-color': theme.palette.info[500] },
    },
    {
      props: { variant: 'warning' },
      style: { '--vp-snackbar-status-background': theme.palette.warning.alpha24, '--vp-snackbar-action-color': theme.palette.warning[500] },
    },
    {
      props: { variant: 'error' },
      style: { '--vp-snackbar-status-background': theme.palette.danger.alpha24, '--vp-snackbar-action-color': theme.palette.danger[500] },
    },
    {
      props: { variant: 'primary' },
      style: {
        '--vp-snackbar-status-background': theme.palette.transparency.alpha8,
        '--vp-snackbar-action-color': theme.palette.primary[500],
      },
    },
  ],
}));

export const AppNotification = forwardRef<HTMLDivElement, AppNotificationProps>(function AppNotification(
  props: AppNotificationProps,
  ref: Ref<HTMLDivElement>,
) {
  const { closeSnackbar } = useSnackbar();
  const { id, variant, message, secondaryMessage = '', Icon } = props;

  const close = useCallback(() => closeSnackbar(id), [closeSnackbar, id]);

  return (
    <SnackbarContent ref={ref}>
      <AppNotificationRoot variant={variant}>
        <VpIcon Icon={Icon ?? Icons[variant]} size={24} color="var(--vp-snackbar-action-color)" sx={{ mr: 1 }} />

        <Stack flexGrow={1}>
          <Typography variant="body2Bold" color="text.primary" mb={0.25}>
            {message}
          </Typography>
          <Typography variant="body2Regular" color="transparency.alpha48">
            {secondaryMessage}
          </Typography>
        </Stack>

        <Box width={16} ml={1}>
          <IconButton
            sx={theme => ({
              position: 'absolute',
              top: theme.spacing(1),
              right: theme.spacing(1),
              p: 1,
              '&::before': {
                content: '""',
                position: 'absolute',
                inset: theme.spacing(-2),
                background: 'transparent',
              },
            })}
            onClick={close}
          >
            <SvgIcon sx={theme => ({ fontSize: 16, color: theme.palette.transparency.alpha48 })}>
              <Close />
            </SvgIcon>
          </IconButton>
        </Box>
      </AppNotificationRoot>
    </SnackbarContent>
  );
});

AppNotification.displayName = 'AppNotification';

function getBackground(): string {
  const first = `linear-gradient(0deg, var(--vp-snackbar-status-background), var(--vp-snackbar-status-background))`;
  const second = `linear-gradient(0deg, var(--vp-snackbar-background), var(--vp-snackbar-background))`;
  return `${first}, ${second}`;
}
