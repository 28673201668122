import { Typography } from '@mui/material';
import { FC } from 'react';

import ChevronLeft from '@vp/assets/icons/ChevronLeft.svg?react';
import { VpHeader, VpHeaderColumn } from '@vp/common/ui/component/VpHeader';
import { VpIconButton } from '@vp/common/ui/component/VpIconButton';

export const ThemingManagerPageHeader: FC = () => {
  return (
    <VpHeader sx={theme => ({ position: 'static', background: theme.palette.background[300], mb: 1 })}>
      <VpHeaderColumn align="left">
        <VpIconButton glowing Icon={ChevronLeft} to=".." />
      </VpHeaderColumn>

      <VpHeaderColumn>
        <Typography variant="h6" color="text.primary">
          Выбор темы
        </Typography>
      </VpHeaderColumn>

      <VpHeaderColumn align="right" />
    </VpHeader>
  );
};
