import axios, { Axios, isCancel } from 'axios';

import { AppConfig } from '@vp/config/AppConfig';

export function HttpClientFactory(): Axios {
  const instance = axios.create({ baseURL: AppConfig.apiUrl, timeout: 60_000 });

  /**
   * This proxy wraps all Axios methods in a try-catch to prevent throwing cancellation errors.
   * The goal is to ensure that, when a request is cancelled, no code following the 'await axios.request(...)' is executed.
   */
  return new Proxy(instance, {
    get: (target, prop, receiver): unknown => {
      const originalProperty = Reflect.get(target, prop, receiver);

      if (typeof originalProperty === 'function') {
        return async (...args: unknown[]): Promise<unknown> => {
          try {
            return await originalProperty.apply(target, args);
          } catch (error) {
            if (!isCancel(error)) throw error;
            return new Promise(() => {});
          }
        };
      }

      return originalProperty;
    },
  });
}
