import { injectable } from 'inversify';

import { EventsManagerRecordModel } from '@vp/manager/events/core/model/EventsManagerRecordModel';

type UpdatedEvent = EventsManagerRecordModel & { imageIdToRemove: string | null };
type DeletedEvent = EventsManagerRecordModel;
type CreatedEvent = EventsManagerRecordModel;

export class EventsDiff {
  readonly created: CreatedEvent[] = [];
  readonly deleted: DeletedEvent[] = [];
  readonly updated: UpdatedEvent[] = [];

  addCreated(event: CreatedEvent): void {
    this.created.push(event);
  }

  addUpdated(event: UpdatedEvent): void {
    this.updated.push(event);
  }

  addDeleted(event: DeletedEvent[]): void {
    this.deleted.push(...event);
  }
}

@injectable()
export class EventsManagerDiffer {
  getDiff(current: EventsManagerRecordModel[], previous: EventsManagerRecordModel[]): EventsDiff {
    const diff = new EventsDiff();
    const previousMap = this.toEventsMap(previous);

    this.processCurrent(current, previousMap, diff);
    this.collectDeletedEvents(previousMap, diff);

    return diff;
  }

  private processCurrent(current: EventsManagerRecordModel[], previousMap: Map<string, EventsManagerRecordModel>, diff: EventsDiff): void {
    for (const event of current) {
      if (this.isCreated(event)) {
        diff.addCreated(event);
      } else {
        this.updateIfNeeded(event, previousMap, diff);
        this.markUpdated(previousMap, event);
      }
    }
  }

  private updateIfNeeded(current: EventsManagerRecordModel, previousMap: Map<string, EventsManagerRecordModel>, diff: EventsDiff): void {
    const previous = previousMap.get(current.id);
    if (previous && this.hasEventChanged(current, previous)) {
      const imageIdToRemove = this.getImageIdToRemove(current, previous);
      diff.addUpdated({ ...current, imageIdToRemove });
    }
  }

  private collectDeletedEvents(previousMap: Map<string, EventsManagerRecordModel>, diff: EventsDiff): void {
    diff.addDeleted([...previousMap.values()]);
  }

  private markUpdated(eventsMap: Map<string, EventsManagerRecordModel>, event: EventsManagerRecordModel): void {
    eventsMap.delete(event.id);
  }

  private toEventsMap(events: EventsManagerRecordModel[]): Map<string, EventsManagerRecordModel> {
    return new Map(events.map(event => [event.id, event]));
  }

  private isCreated(event: EventsManagerRecordModel): boolean {
    return !event.id;
  }

  private getImageIdToRemove(current: EventsManagerRecordModel, previous: EventsManagerRecordModel): string | null {
    return this.hasImageBeenRemoved(current, previous) ? previous.image.id : null;
  }

  private hasEventChanged(current: EventsManagerRecordModel, previous: EventsManagerRecordModel): boolean {
    return JSON.stringify(current) !== JSON.stringify(previous);
  }

  private hasImageBeenRemoved(current: EventsManagerRecordModel, previous: EventsManagerRecordModel): boolean {
    return !!previous.image?.id && !current.image?.id;
  }
}
