import { signal, Signal } from '@preact/signals-react';
import { injectable } from 'inversify';

import { HonorManagerSection } from '@vp/manager/honor/ui/HonorManagerSection';
import { RouterService } from '@vp/routing/RouterService';
import { Unsubscribe } from '@vp/routing/RouterSubscribers';

@injectable()
export class HonorManagerNavigator {
  readonly activeSection: Signal<string> = signal(HonorManagerSection.Approved);

  private unsubscribe?: Unsubscribe;

  constructor(private readonly routerService: RouterService) {}

  attach(): void {
    this.unsubscribe = this.routerService.subscribe(({ search }) => {
      if (this.routerService.isOn('/ps/:id/honor')) {
        this.setActiveSection(search);
      }
    });
  }

  detach(): void {
    this.unsubscribe?.();
  }

  toApprovedSection(): void {
    const search = new URLSearchParams(location.search);
    search.set('s', HonorManagerSection.Approved);
    void this.routerService.navigate(`${location.pathname}?${search.toString()}`);
  }

  isOn(section: string): boolean {
    return this.activeSection.value === section;
  }

  private setActiveSection(search: string): void {
    const params = new URLSearchParams(search);
    this.activeSection.value = params.get('s') ?? HonorManagerSection.Approved;
  }
}
