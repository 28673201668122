import { signal, Signal } from '@preact/signals-react';
import { injectable } from 'inversify';

import { ViewModel, ViewModelDispose } from '@vp/common/ui/ViewModel';
import { HonorSubmitPort } from '@vp/honor/core/interface/HonorSubmitPort';
import { HonorRecordModel } from '@vp/honor/core/model/HonorRecordModel';
import { SubmitRecordBodyProps } from '@vp/honor/ui/components/SubmitRecordBody';
import { ProfileHonorRecordModel } from '@vp/profile/core/model/ProfileHonorRecordModel';
import { ProfileHonorNotificationStatus } from '@vp/profile/ui/honor/ProfileHonor';

@injectable()
export class HonorSubmitViewModel extends ViewModel<SubmitRecordBodyProps> implements ViewModelDispose {
  readonly submitting: Signal<boolean> = signal(false);
  private controller?: AbortController;

  constructor(private readonly honorPort: HonorSubmitPort) {
    super();
  }

  handleFormSubmit = async (record: HonorRecordModel): Promise<ProfileHonorNotificationStatus> => {
    this.props.value?.handleBeforeSubmission && this.props.value.handleBeforeSubmission();
    const { status, record: newRecord } = await this.submit(record, this.props.value!.profileId);
    this.props.value?.handleSubmissionResult(status, newRecord);

    return status;
  };

  private async submit(
    record: HonorRecordModel,
    profileId: string,
  ): Promise<{ status: ProfileHonorNotificationStatus; record: ProfileHonorRecordModel | null }> {
    this.controller = new AbortController();

    this.submitting.value = true;
    let status: ProfileHonorNotificationStatus = 'success';
    let newRecord: ProfileHonorRecordModel | null = null;

    try {
      newRecord = await this.honorPort.submitHonorRecord(record, profileId, this.controller);
    } catch {
      status = 'error';
    }

    this.submitting.value = false;
    return { status, record: newRecord };
  }

  dispose(): void {
    this.controller?.abort();
  }
}
