import { injectable } from 'inversify';

import { HonorManagerPort } from '@vp/manager/honor/core/interface/HonorManagerPort';
import { HonorManagerState } from '@vp/manager/honor/core/interface/HonorManagerState';
import { ApproveHonorRecordUsecase } from '@vp/manager/honor/core/usecase/ApproveHonorRecordUsecase';
import { EditHonorRecordUsecase } from '@vp/manager/honor/core/usecase/EditHonorRecordUsecase';
import { HandleSubmissionResultUsecase } from '@vp/manager/honor/core/usecase/HandleSubmissionResultUsecase';
import { LoadHonorRecordsUsecase } from '@vp/manager/honor/core/usecase/LoadHonorRecordsUsecase';
import { RemoveHonorRecordUsecase } from '@vp/manager/honor/core/usecase/RemoveHonorRecordUsecase';
import { ProfileHonorRecordModel } from '@vp/profile/core/model/ProfileHonorRecordModel';

@injectable()
export class HonorManagerFacade implements HonorManagerPort {
  constructor(
    private readonly loadHonorRecordsUsecase: LoadHonorRecordsUsecase,
    private readonly removeHonorRecordUsecase: RemoveHonorRecordUsecase,
    private readonly approveHonorRecordUsecase: ApproveHonorRecordUsecase,
    private readonly handleSubmissionResultUsecase: HandleSubmissionResultUsecase,
    private readonly editHonorRecordUsecase: EditHonorRecordUsecase,
    private readonly honorManagerState: HonorManagerState,
  ) {}

  async loadHonorRecords(id: string, controller?: AbortController): Promise<void> {
    await this.loadHonorRecordsUsecase.execute(id, controller);
  }

  async removeHonorRecords(ids: string[], profileId: string, controller?: AbortController): Promise<void> {
    await this.removeHonorRecordUsecase.execute(ids, profileId, controller);
  }

  async approveHonorRecords(ids: string[], profileId: string, controller?: AbortController): Promise<void> {
    await this.approveHonorRecordUsecase.execute(ids, profileId, controller);
  }

  async editRecord(id: string, newText: string, controller?: AbortController): Promise<void> {
    await this.editHonorRecordUsecase.execute(id, newText, controller);
  }

  resetState(): void {
    this.honorManagerState.reset();
  }

  handleSubmissionResult(record: ProfileHonorRecordModel): void {
    this.handleSubmissionResultUsecase.execute(record);
  }
}
