import { Box, IconButton, Stack, SvgIcon, useTheme } from '@mui/material';
import { Theme } from '@mui/system';
import { FC, useCallback, useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import ImageIcon from '@vp/assets/icons/Image.svg?react';
import VideoIcon from '@vp/assets/icons/Video.svg?react';

const Tabs = [
  { icon: ImageIcon, mode: 'images' },
  { icon: VideoIcon, mode: 'videos' },
];

export interface GalleryTabsProps {
  mode: string;
}

export const GalleryTabs: FC<GalleryTabsProps> = ({ mode: activeMode }) => {
  const theme = useTheme();
  const location = useLocation();

  const activeIndex = useMemo(() => {
    const index = Tabs.findIndex(tab => tab.mode === activeMode);
    return index === -1 ? 0 : index;
  }, [activeMode]);

  const getHref = useCallback(
    (mode: string) => {
      const search = new URLSearchParams(location.search);
      search.set('m', mode);
      return `${location.pathname}?${search.toString()}`;
    },
    [location.search, location.pathname],
  );

  return (
    <Stack
      display="inline-flex"
      flexDirection="row"
      position="sticky"
      bottom={36}
      left="50%"
      p={0.5}
      borderRadius={100}
      zIndex={theme => theme.zIndex.galleryContentSwitcher}
      sx={{
        transform: 'translateX(-50%)',
        '&::before': {
          content: '""',
          position: 'absolute',
          borderRadius: 100,
          zIndex: -1,
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: theme.palette.primary.alpha8,
          backdropFilter: 'blur(10px)',
        },
      }}
    >
      <Box
        position="absolute"
        width={52}
        height={40}
        borderRadius={100}
        style={{ transform: `translateX(${activeIndex * 100}%)` }}
        sx={{ backgroundColor: theme.palette.primary['500'], transition: 'transform 0.25s' }}
      />

      {Tabs.map(({ icon: TabIcon, mode }, index) => {
        const color = mode === activeMode ? theme.palette.text[6] : resolveInactiveColor(theme);
        const to = getHref(mode);

        return (
          <IconButton
            component={NavLink}
            key={index}
            to={to}
            style={{ color }}
            sx={{ borderRadius: 100, py: 1.25, px: 2, transition: 'color 0.25s' }}
          >
            <SvgIcon sx={{ fontSize: 20 }}>
              <TabIcon />
            </SvgIcon>
          </IconButton>
        );
      })}
    </Stack>
  );
};

function resolveInactiveColor(theme: Theme): string {
  return theme.palette.mode === 'dark' ? theme.palette.text.secondary : theme.palette.text.primary;
}
