import { useTheme } from '@mui/material';
import { FC } from 'react';

import { VpOverlayPage } from '@vp/common/ui/component/VpOverlayPage';
import { useFalsyStateDelay } from '@vp/common/ui/hook/useFalsyStateDelay';
import { useSearchSection } from '@vp/common/ui/hook/useSearchSection';
import { GalleryManagerSection } from '@vp/manager/gallery/ui/GalleryManagerSection';
import { GalleryManagerPreview, GalleryManagerPreviewProps } from '@vp/manager/gallery/ui/preview/GalleryManagerPreview';

export interface GalleryManagerPreviewModalProps {
  open: boolean;
  item: GalleryManagerPreviewProps['item'] | null;
  close: () => void;
}

export const GalleryManagerPreviewModal: FC<GalleryManagerPreviewModalProps> = ({ open, item, close }) => {
  const { transitions } = useTheme();
  const section = useSearchSection<GalleryManagerSection>('s', GalleryManagerSection.Photos);

  const fadeDuration = transitions.duration.modalFadeDuration;
  const delayedItem = useFalsyStateDelay(item, fadeDuration);

  return (
    <VpOverlayPage open={open}>{delayedItem && <GalleryManagerPreview section={section} item={delayedItem} close={close} />}</VpOverlayPage>
  );
};
