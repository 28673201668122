import { injectable } from 'inversify';

import { MediaGridItem } from '@vp/common/ui/component/VpMediaGrid';
import { GalleryManagerSection } from '@vp/manager/gallery/ui/GalleryManagerSection';
import { GalleryManagerPreviewProps, PhotoPreview, VideoPreview } from '@vp/manager/gallery/ui/preview/GalleryManagerPreview';
import { ProfileModel } from '@vp/profile/core/model/ProfileModel';

@injectable()
export class GalleryManagerMapper {
  toPreviewItem(item: MediaGridItem, section: GalleryManagerSection, profile: ProfileModel): GalleryManagerPreviewProps['item'] {
    return section === GalleryManagerSection.Photos ? this.toPhoto(item, profile) : this.toVideo(item, profile);
  }

  private toPhoto(item: MediaGridItem, profile: ProfileModel): PhotoPreview {
    const photo = profile.photos.find(photo => photo.id === item.id)!;
    return { id: photo.id, url: photo.url, hash: photo.hash };
  }

  private toVideo(item: MediaGridItem, profile: ProfileModel): VideoPreview {
    const video = profile.videos.find(video => video.id === item.id)!;
    return { id: video.id, url: video.url, thumbnail: video.thumbnail, thumbnailHash: video.thumbnailHash };
  }
}
