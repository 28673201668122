import { ReadonlySignal } from '@preact/signals-react';
import { injectable } from 'inversify';

import { AuthState } from '@vp/auth/core/interface/AuthState';
import { ProfileManagerPort } from '@vp/manager/profile/core/interface/ProfileManagerPort';
import { ProfileManagerState } from '@vp/manager/profile/core/interface/ProfileManagerState';
import { LoadProfilesUsecase } from '@vp/manager/profile/core/usecase/LoadProfilesUsecase';
import { ProfileModel } from '@vp/profile/core/model/ProfileModel';

@injectable()
export class ProfileManagerFacade implements ProfileManagerPort {
  readonly active: ReadonlySignal<ProfileModel | null> = this.state.active;

  constructor(
    private readonly loadProfilesUsecase: LoadProfilesUsecase,
    private readonly state: ProfileManagerState,
    private readonly authState: AuthState,
  ) {}

  async loadProfilesIfNeeded(controller?: AbortController): Promise<void> {
    if (this.authState.authenticated.value) {
      await this.loadProfilesUsecase.execute(controller);
    }
  }

  setActiveProfile(profile: ProfileModel): void {
    this.state.setActive(profile);
  }
}
