import { ReadonlySignal } from '@preact/signals-react';

import { OperationResult } from '@vp/common/core/OperationResult';
import { ProfileLoadingState } from '@vp/profile/core/interface/ProfileState';
import { ProfileModel } from '@vp/profile/core/model/ProfileModel';

export abstract class ProfilePort {
  abstract readonly profile: ReadonlySignal<ProfileModel>;
  abstract readonly loadingState: ReadonlySignal<ProfileLoadingState>;
  abstract readonly canActivateProfile: ReadonlySignal<boolean>;
  abstract readonly profileForActivation: ReadonlySignal<string | null>;

  abstract loadProfile(id: string, controller?: AbortController): Promise<void>;
  abstract activateProfile(id: string, controller?: AbortController): Promise<OperationResult>;
  abstract storeProfileForActivation(id: string): void;
  abstract resetProfileForActivation(): void;
}
