import { IconButton, InputAdornment } from '@mui/material';
import { FC, MouseEvent, useCallback } from 'react';

import View from '@vp/assets/icons/View.svg?react';
import ViewOff from '@vp/assets/icons/ViewOff.svg?react';

export interface ViewPasswordIconProps {
  showPassword: boolean;
  onClick: () => void;
}

export const ViewPasswordIcon: FC<ViewPasswordIconProps> = ({ showPassword, onClick }) => {
  const handleMouseDownPassword = useCallback((event: MouseEvent) => event.preventDefault(), []);

  return (
    <InputAdornment position="end" sx={theme => ({ height: 'auto', alignSelf: 'center', marginLeft: 0, marginRight: theme.spacing(1.5) })}>
      <IconButton
        sx={({ spacing }) => ({
          padding: 0,
          position: 'relative',
          color: 'inherit',
          '&::before': {
            content: '""',
            position: 'absolute',
            zIndex: 1,
            inset: `-${spacing(2)} -${spacing(1.5)} -${spacing(2)} -${spacing(1.5)}`,
          },
        })}
        onClick={onClick}
        onMouseDown={handleMouseDownPassword}
      >
        {showPassword ? <View /> : <ViewOff />}
      </IconButton>
    </InputAdornment>
  );
};
