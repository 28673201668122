import { ReadonlySignal } from '@preact/signals-react';
import { injectable } from 'inversify';

import { OperationResult } from '@vp/common/core/OperationResult';
import { SumbitEventsDto } from '@vp/manager/events/core/dto/SumbitEventsDto';
import { EventsManagerPort } from '@vp/manager/events/core/interface/EventsManagerPort';
import { EventsManagerState, EventsManagerStatus } from '@vp/manager/events/core/interface/EventsManagerState';
import { EventsManagerRecordModel } from '@vp/manager/events/core/model/EventsManagerRecordModel';
import { LoadEventsUsecase } from '@vp/manager/events/core/usecase/LoadEventsUsecase';
import { SubmitEventsUsecase } from '@vp/manager/events/core/usecase/SubmitEventsUsecase';

@injectable()
export class EventsManagerFacade implements EventsManagerPort {
  readonly events: ReadonlySignal<EventsManagerRecordModel[]> = this.eventsState.events;
  readonly status: ReadonlySignal<EventsManagerStatus> = this.eventsState.status;

  constructor(
    private readonly eventsState: EventsManagerState,
    private readonly loadEventsUsecase: LoadEventsUsecase,
    private readonly submitEventsUsecase: SubmitEventsUsecase,
  ) {}

  async loadEvents(profileId: string, controller: AbortController): Promise<OperationResult> {
    return await this.loadEventsUsecase.execute(profileId, controller);
  }

  async submitEvents(dto: SumbitEventsDto, controller: AbortController): Promise<OperationResult> {
    return await this.submitEventsUsecase.execute(dto, controller);
  }

  resetState(): void {
    this.eventsState.reset();
  }
}
