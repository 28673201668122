import { ContainerModule } from 'inversify';

import { BaseModule } from '@vp/common/BaseModule';
import { AvatarManagerModule } from '@vp/manager/avatar/AvatarManagerModule';
import { BiographyManagerModule } from '@vp/manager/biography/BiographyManagerModule';
import { EventsManagerModule } from '@vp/manager/events/EventsManagerModule';
import { GalleryManagerModule } from '@vp/manager/gallery/GalleryManagerModule';
import { HonorManagerModule } from '@vp/manager/honor/HonorManagerModule';
import { ProfileManagerModule } from '@vp/manager/profile/ProfileManagerModule';
import { ThemingManagerModule } from '@vp/manager/theming/ThemingManagerModule';

export class ManagerModule extends BaseModule {
  readonly module: ContainerModule = new ContainerModule((...registry) => {
    this.import(
      [
        ProfileManagerModule,
        GalleryManagerModule,
        HonorManagerModule,
        AvatarManagerModule,
        EventsManagerModule,
        BiographyManagerModule,
        ThemingManagerModule,
      ],
      ...registry,
    );
  });
}
