import { Axios, AxiosError, AxiosRequestConfig, HttpStatusCode, isAxiosError } from 'axios';
import { injectable } from 'inversify';

import { AuthPort } from '@vp/auth/core/interface/AuthPort';
import { TokensModel } from '@vp/auth/core/model/TokensModel';
import { AuthEndpoint } from '@vp/auth/data/RestAuthRepository';
import { RouterService } from '@vp/routing/RouterService';

@injectable()
export class AuthInterceptorManager {
  constructor(
    private readonly instance: Axios,
    private readonly authPort: AuthPort,
    private readonly routerService: RouterService,
  ) {}

  attach(): void {
    this.attachTokenInterceptor();
    this.attachRetryInterceptor();
  }

  private attachTokenInterceptor(): void {
    this.instance.interceptors.request.use(async config => {
      const tokens = await this.authPort.getTokens();
      if (tokens && !config.skipAuth) this.attachTokenToRequest(config, tokens);
      return config;
    });
  }

  private attachRetryInterceptor(): void {
    this.instance.interceptors.response.use(
      response => response,
      async error => {
        const tokens = await this.authPort.getTokens();

        if (!this.shouldRefreshAndRetry(error) || !tokens) {
          this.navigateToLoginIfNeeded(error);
          return Promise.reject(error);
        }

        const refresh = await this.tryToRefresh(tokens);

        if (!(refresh instanceof TokensModel)) {
          return Promise.reject(refresh);
        }

        const config = this.createRetryConfig(error, refresh);
        return this.instance.request(config);
      },
    );
  }

  private async tryToRefresh(tokens: TokensModel): Promise<TokensModel | unknown> {
    try {
      return await this.authPort.refresh(tokens);
    } catch (error) {
      void this.routerService.navigate('/auth/login');
      return error;
    }
  }

  private createRetryConfig(error: AxiosError, tokens: TokensModel): AxiosRequestConfig {
    const config: AxiosRequestConfig = {
      ...error.config,
      retried: true,
      headers: { ...error.config?.headers },
    };
    this.attachTokenToRequest(config, tokens);
    return config;
  }

  private shouldRefreshAndRetry(error: unknown): boolean {
    if (!isAxiosError(error)) return false;
    const isUnauthorized = error.response?.status === HttpStatusCode.Unauthorized;
    const isRetried = error.config?.retried;
    const isRefreshRequest = error.config?.url?.includes(AuthEndpoint.Refresh);
    const isLoginRequest = error.config?.url?.includes(AuthEndpoint.Login);
    return isUnauthorized && !isRetried && !isRefreshRequest && !isLoginRequest;
  }

  private attachTokenToRequest(config: AxiosRequestConfig, tokens: TokensModel): void {
    if (!tokens.isExpired()) {
      config.headers = { ...config.headers, ...this.toBearerTokenAuthorization(tokens) };
    }
  }

  private toBearerTokenAuthorization(tokens: TokensModel): Record<string, string> {
    return { Authorization: `Bearer ${tokens.access}` };
  }

  private navigateToLoginIfNeeded(error: unknown): void {
    if (!isAxiosError(error)) {
      return;
    }

    const isUnauthorized = error.response?.status === HttpStatusCode.Unauthorized;
    const isGetOwnerRequest = error.config?.url?.includes(AuthEndpoint.GetOwner);
    const isLoginRequest = error.config?.url?.includes(AuthEndpoint.Login);

    if (isUnauthorized && !isGetOwnerRequest && !isLoginRequest) {
      void this.routerService.navigate('/auth/login');
    }
  }
}
