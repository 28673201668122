import { injectable } from 'inversify';

import { EventsManagerRecordModel } from '@vp/manager/events/core/model/EventsManagerRecordModel';
import { TimelineResponseDto } from '@vp/profile/data/dto/TimelineResponseDto';

@injectable()
export class EventsManagerDataMapper {
  toCreateFormData(events: EventsManagerRecordModel[], profileId: string): FormData {
    const formData = new FormData();
    formData.append('profile_id', profileId);

    events.forEach((event, index) => {
      formData.append(`timelines[${index}][id]`, event.id);
      formData.append(`timelines[${index}][date]`, event.date);
      formData.append(`timelines[${index}][description]`, event.description);

      if (event.image.file) {
        formData.append(`timelines[${index}][image][file]`, event.image.file);
      }
    });

    return formData;
  }

  toEvents(dto: TimelineResponseDto[]): EventsManagerRecordModel[] {
    return dto.map(timeline => this.toEvent(timeline));
  }

  toEvent(dto: TimelineResponseDto): EventsManagerRecordModel {
    const date = this.convertDateFormat(dto.date);
    const imageUrl = dto.image?.url ?? '';
    const imageHash = dto.image?.blurhash ?? '';
    const imageId = dto.image?.id.toString() ?? '';
    return { ...dto, date, id: dto.id.toString(), image: { id: imageId, url: imageUrl, hash: imageHash, file: null } };
  }

  private convertDateFormat(date: string): string {
    const [year, month, day] = date.split('-');
    return [day, month, year].join('.');
  }
}
