import Box from '@mui/material/Box';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import SvgIcon from '@mui/material/SvgIcon';
import { FC } from 'react';
import type { UseFormRegisterReturn } from 'react-hook-form';

import Checkmark from '@vp/assets/icons/Checkmark.svg?react';

const CheckedIcon: FC = () => {
  return (
    <Box sx={theme => ({ width: 20, height: 20, backgroundColor: theme.palette.primary[500] })}>
      <SvgIcon sx={theme => ({ width: 16, height: 16, color: theme.palette.background[300], m: 0.25 })}>
        <Checkmark />
      </SvgIcon>
    </Box>
  );
};

const UncheckedIcon: FC = () => {
  return (
    <Box
      component={'span'}
      sx={theme => ({
        width: 20,
        height: 20,
        boxSizing: 'border-box',
        border: '1px solid',
        borderColor: theme.palette.transparency.alpha24,
      })}
    />
  );
};

export type VpCheckboxProps = CheckboxProps & {
  registerReturn?: UseFormRegisterReturn;
};

export const VpCheckbox: FC<VpCheckboxProps> = ({ registerReturn, ...props }) => {
  return <Checkbox icon={<UncheckedIcon />} checkedIcon={<CheckedIcon />} {...props} {...registerReturn}></Checkbox>;
};
