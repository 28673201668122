import { Typography } from '@mui/material';
import { FC, memo } from 'react';

import { ProfileEventModel } from '@vp/profile/core/model/ProfileEventModel';

export type ProfileEventDescriptionProps = {
  event: ProfileEventModel;
};

export const ProfileEventDescription: FC<ProfileEventDescriptionProps> = memo(({ event }) => {
  return (
    <Typography
      display="inline-block"
      color="text.disabled"
      variant="body2Regular"
      flexGrow={1}
      overflow="hidden"
      sx={{ wordBreak: 'break-word', transition: 'color 0.25s ease-in-out' }}
    >
      {event.description}
    </Typography>
  );
});

ProfileEventDescription.displayName = ProfileEventDescription.name;
