import { injectable } from 'inversify';

import { AppMenuItemId } from '@vp/menu/ui/AppMenuItemModel';
import { AuthHandler } from '@vp/menu/ui/handler/AuthHandler';
import { SupportHandler } from '@vp/menu/ui/handler/SupportHandler';

export type Handler = () => void;

@injectable()
export class HandlerRegistry {
  private readonly handlerRegistry: Map<AppMenuItemId, () => void> = new Map([
    [AppMenuItemId.Support, this.supportHandler.handle.bind(this.supportHandler)],
    [AppMenuItemId.Auth, this.authHandler.handle.bind(this.authHandler)],
  ]);

  constructor(
    private readonly authHandler: AuthHandler,
    private readonly supportHandler: SupportHandler,
  ) {}

  get(id: AppMenuItemId): Handler | null {
    return this.handlerRegistry.get(id) ?? null;
  }
}
