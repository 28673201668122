import { computed, ReadonlySignal } from '@preact/signals-react';
import { injectable } from 'inversify';

import Calendar from '@vp/assets/icons/Calendar.svg?react';
import ColorPalette from '@vp/assets/icons/ColorPalette.svg?react';
import Image from '@vp/assets/icons/Image.svg?react';
import PenFountain from '@vp/assets/icons/PenFountain.svg?react';
import TextAnnotationToggle from '@vp/assets/icons/TextAnnotationToggle.svg?react';
import View from '@vp/assets/icons/View.svg?react';
import { DateFormatter } from '@vp/common/ui/DateFormatter';
import { ViewModel } from '@vp/common/ui/ViewModel';
import { ProfileManagerState } from '@vp/manager/profile/core/interface/ProfileManagerState';
import { MenuItem } from '@vp/manager/profile/ui/settings/menu/ProfileSettingsMenu';
import { AppMenuService } from '@vp/menu/core/interface/AppMenuService';
import { ProfileModel } from '@vp/profile/core/model/ProfileModel';

@injectable()
export class ProfileSettingsViewModel extends ViewModel {
  readonly profile: ReadonlySignal<ProfileModel | null> = computed(() => this.toViewProfile());
  readonly noProfiles: ReadonlySignal<boolean> = computed(() => this.hasNoProfiles());
  readonly isError: ReadonlySignal<boolean> = computed(() => this.hasError());

  readonly mainItems: ReadonlySignal<MenuItem[]> = computed(() => this.toMainItems());
  readonly additionalItems: ReadonlySignal<MenuItem[]> = computed(() => this.toAdditionalItems());

  constructor(
    private readonly profileManagerState: ProfileManagerState,
    private readonly dateFormatter: DateFormatter,
    private readonly appMenuService: AppMenuService,
  ) {
    super();
  }

  toggleMenu = (): void => {
    this.appMenuService.toggle();
  };

  private toViewProfile(): ProfileModel | null {
    const profile = this.profileManagerState.active.value;
    if (!profile) return null;
    const birthDate = this.dateFormatter.toLocaleDate(profile.birthDate);
    const deathDate = this.dateFormatter.toLocaleDate(profile.deathDate);
    return { ...profile, birthDate, deathDate };
  }

  private hasNoProfiles(): boolean {
    return this.profileManagerState.profiles.value.length === 0;
  }

  private hasError(): boolean {
    const hasProfiles = !this.hasNoProfiles();
    return hasProfiles && !this.profile.value;
  }

  private toMainItems(): MenuItem[] {
    const id = this.profile.value?.id;

    return [
      { path: `/ps/${id}/bio`, Icon: TextAnnotationToggle, text: 'Биография' },
      { path: `/ps/${id}/events`, Icon: Calendar, text: 'События' },
      { path: `/ps/${id}/gallery`, Icon: Image, text: 'Галерея' },
      { path: `/ps/${id}/honor`, Icon: PenFountain, text: 'Почести' },
    ];
  }

  private toAdditionalItems(): MenuItem[] {
    const id = this.profile.value?.id;

    return [
      { path: `/ps/${id}/theming`, Icon: ColorPalette, text: 'Тема' },
      { path: `/p/${id}`, text: 'Просмотр профиля', Icon: View },
    ];
  }
}
