import { StyledComponent } from '@emotion/styled';
import { OutlinedInput, OutlinedInputProps, styled } from '@mui/material';

export const VpInput: StyledComponent<OutlinedInputProps> = styled(OutlinedInput)<OutlinedInputProps>(({ theme }) => ({
  alignItems: 'flex-start',
  padding: 0,
  '& *': {
    transition: `border-color 0.15s ${theme.transitions.easing.easeInOut}`,
  },
  '& .MuiInputAdornment-root': {
    color: theme.palette.transparency.alpha48,
  },
  '&.Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
    },
    '& .MuiInputAdornment-root': {
      color: theme.palette.primary['500'],
    },
  },
  '&.Mui-error': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.danger['500'],
    },
    '& .MuiInputAdornment-root': {
      color: theme.palette.danger['500'],
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: 0,
    borderColor: theme.palette.transparency.alpha16,
  },
  '&:hover:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary['500'],
  },
  '&.MuiInputBase-multiline': {
    padding: 0,
    minHeight: theme.spacing(12),
  },
  '& .MuiOutlinedInput-input': {
    ...theme.typography.formCompletedMedium,
    color: theme.palette.primary['500'],
    padding: `${theme.spacing(2)} ${theme.spacing(1.5)} ${theme.spacing(2)} ${theme.spacing(2.5)}`,

    '&::placeholder': {
      ...theme.typography.formPlaceholderMedium,
      color: theme.palette.transparency.alpha48,
      opacity: 1,
    },
    '&:-webkit-autofill': {
      WebkitBoxShadow: 'none',
      WebkitTextFillColor: theme.palette.primary['500'],
      backgroundClip: 'text',
    },
  },
}));
