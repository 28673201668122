import { injectable } from 'inversify';

import { OperationResult, Success } from '@vp/common/core/OperationResult';
import { RemoveMediaDto } from '@vp/manager/gallery/core/dto/RemoveMediaDto';
import { GalleryManagerRepository } from '@vp/manager/gallery/core/interface/GalleryManagerRepository';
import { ProfileManagerState } from '@vp/manager/profile/core/interface/ProfileManagerState';

@injectable()
export class RemoveMediaUsecase {
  constructor(
    private readonly galleryManagerRepository: GalleryManagerRepository,
    private readonly profileManagerState: ProfileManagerState,
  ) {}

  async execute(dto: RemoveMediaDto, controller?: AbortController): Promise<OperationResult> {
    const result = await this.galleryManagerRepository.remove(dto, controller);

    if (result instanceof Success) {
      this.profileManagerState.update(result.value);
    }

    return result as OperationResult;
  }
}
