import { Box, Collapse, Stack } from '@mui/material';
import { FC, Fragment } from 'react';

import UserMultiple from '@vp/assets/icons/UserMultiple.svg?react';
import { useViewModel } from '@vp/common/ui/hook/useViewModel';
import { AppMenuItemId, AppMenuItemModel } from '@vp/menu/ui/AppMenuItemModel';
import { AppMenuListItem } from '@vp/menu/ui/AppMenuListItem';
import { AppMenuProfileItem } from '@vp/menu/ui/profiles/AppMenuProfileItem';
import { AppMenuProfilesViewModel } from '@vp/menu/ui/profiles/AppMenuProfilesViewModel';
import { NoProfiles } from '@vp/menu/ui/profiles/NoProfiles';

const ProfilesItem: AppMenuItemModel = {
  id: AppMenuItemId.Profiles,
  text: 'Мои профили',
  Icon: UserMultiple,
};

export const AppMenuProfiles: FC = () => {
  const viewModel = useViewModel(AppMenuProfilesViewModel);
  const maxHeight = viewModel.profiles.value.length ? 250 : undefined;

  return (
    <Fragment>
      <AppMenuListItem open={viewModel.open.value} item={ProfilesItem} onItemClick={viewModel.toggle} />

      <Collapse in={viewModel.open.value}>
        <Box width={1} overflow="hidden" style={{ maxHeight }}>
          {!!viewModel.profiles.value.length && (
            <Stack width={1} overflow="scroll" style={{ maxHeight }}>
              {viewModel.profiles.value.map(profile => (
                <AppMenuProfileItem key={profile.id} profile={profile} onProfileClick={viewModel.selectProfile} />
              ))}
            </Stack>
          )}

          {!viewModel.profiles.value.length && <NoProfiles />}
        </Box>
      </Collapse>
    </Fragment>
  );
};
