import { signal, Signal } from '@preact/signals-react';
import { injectable } from 'inversify';

import { ProfileState } from '@vp/profile/core/interface/ProfileState';

@injectable()
export class SlideshowAudioController {
  readonly muted: Signal<boolean> = signal(false);
  private readonly audio: HTMLAudioElement;

  constructor(profileState: ProfileState) {
    this.audio = new Audio(profileState.profile.value.slideshowAudio);
    this.audio.volume = 0.1;
    this.audio.loop = true;
  }

  play(): void {
    void this.audio.play();
  }

  pause(): void {
    this.audio.pause();
  }

  toggleMuted(): void {
    this.muted.value = !this.muted.value;
    this.audio.muted = this.muted.value;
  }
}
