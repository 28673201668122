import { ReadonlySignal } from '@preact/signals-react';

import { TokensModel } from '@vp/auth/core/model/TokensModel';
import { OperationResult } from '@vp/common/core/OperationResult';

export abstract class AuthPort {
  abstract readonly email: ReadonlySignal<string>;

  abstract register(email: string, password: string, controller?: AbortController): Promise<void>;
  abstract login(email: string, password: string, controller?: AbortController): Promise<void>;
  abstract sendResetPasswordLink(email: string, controller?: AbortController): Promise<OperationResult>;
  abstract logout(): Promise<void>;
  abstract getTokens(): Promise<TokensModel | null>;
  abstract refresh(tokens: TokensModel, controller?: AbortController): Promise<TokensModel>;
  abstract validate(controller?: AbortController): Promise<void>;
  abstract restorePassword(password: string, recoveryToken: string, controller?: AbortController): Promise<OperationResult>;
  abstract validateRecoveryToken(token: string): boolean;
}
