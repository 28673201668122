import { injectable } from 'inversify';

import { AuthRepository } from '@vp/auth/core/interface/AuthRepository';
import { TokensStorage } from '@vp/auth/core/interface/TokensStorage';
import { LoadOwnerUsecase } from '@vp/auth/core/usecase/LoadOwnerUsecase';

@injectable()
export class ValidateAuthUsecase {
  constructor(
    private readonly tokensStorage: TokensStorage,
    private readonly authRepository: AuthRepository,
    private readonly loadOwnerUsecase: LoadOwnerUsecase,
  ) {}

  async execute(controller?: AbortController): Promise<void> {
    try {
      await this.validateToken(controller);
      await this.loadOwnerIfNeeded();
    } catch {
      await this.tokensStorage.clear();
    }
  }

  private async validateToken(controller?: AbortController): Promise<void> {
    const tokens = await this.tokensStorage.retrieve();

    if (tokens?.isExpired()) {
      const refresh = await this.authRepository.refresh(tokens, controller);
      await this.tokensStorage.store(refresh);
    }
  }

  private async loadOwnerIfNeeded(controller?: AbortController): Promise<void> {
    if (await this.tokensStorage.retrieve()) {
      await this.loadOwnerUsecase.execute(controller);
    }
  }
}
