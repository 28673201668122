import { injectable } from 'inversify';

import { ProfileEventModel } from '@vp/profile/core/model/ProfileEventModel';
import { ProfileHonorRecordModel } from '@vp/profile/core/model/ProfileHonorRecordModel';
import { ProfileModel } from '@vp/profile/core/model/ProfileModel';
import { ProfilePhotoModel } from '@vp/profile/core/model/ProfilePhotoModel';
import { ProfileThemeMode, ProfileThemeModel } from '@vp/profile/core/model/ProfileThemeModel';
import { ProfileVideoModel } from '@vp/profile/core/model/ProfileVideoModel';
import { ImageResponseDto } from '@vp/profile/data/dto/ImageResponseDto';
import { ProfileResponseDto } from '@vp/profile/data/dto/ProfileResponseDto';
import { TributeResponseDto } from '@vp/profile/data/dto/TributeResponseDto';
import { VideoResponseDto } from '@vp/profile/data/dto/VideoResponseDto';

@injectable()
export class ProfileDataMapper {
  private themeModes: Set<ProfileThemeMode> = new Set(['dark', 'light']);

  toProfile(dto: ProfileResponseDto): ProfileModel {
    return {
      id: dto.id,
      name: dto.name,
      bio: dto.bio ?? '',
      birthDate: dto.date_of_birth ?? '',
      deathDate: dto.date_of_death ?? '',
      image: this.toImage(dto),
      photos: this.toPhotos(dto),
      videos: this.toVideos(dto),
      events: this.toEvents(dto),
      honorRecords: this.toHonorRecords(dto),
      theme: this.toTheme(dto),
      slideshowAudio: dto.settings?.slideshow_melody ?? '',
      active: dto.active,
      premium: dto.premium ?? false,
    };
  }

  toHonorRecord(tribute: TributeResponseDto): ProfileHonorRecordModel {
    return {
      id: tribute.id.toString(),
      author: tribute.author,
      text: tribute.text,
      date: tribute.created_at,
      approved: tribute.approved,
    };
  }

  toPhoto(image: ImageResponseDto): ProfilePhotoModel {
    return {
      id: image.id.toString(),
      hash: image.blurhash,
      url: image.url,
    };
  }

  toVideo(video: VideoResponseDto): ProfileVideoModel {
    return {
      id: video.id.toString(),
      url: video.url,
      thumbnail: video.thumbnail,
      thumbnailHash: video.blurhash,
    };
  }

  private toPhotos({ images = [] }: ProfileResponseDto): ProfilePhotoModel[] {
    return images.map(image => this.toPhoto(image));
  }

  private toEvents({ timelines = [] }: ProfileResponseDto): ProfileEventModel[] {
    return timelines.map(timeline => {
      const id = timeline.id.toString();
      const image = timeline.image?.url ?? '';
      const hash = timeline.image?.blurhash ?? '';

      return { id, date: timeline.date, description: timeline.description, hash, image };
    });
  }

  private toVideos({ videos = [] }: ProfileResponseDto): ProfileVideoModel[] {
    return videos.map(video => this.toVideo(video));
  }

  private toHonorRecords({ tributes = [] }: ProfileResponseDto): ProfileHonorRecordModel[] {
    return tributes.filter(({ approved }) => approved).map(tribute => this.toHonorRecord(tribute));
  }

  private toImage(dto: ProfileResponseDto): ProfilePhotoModel {
    return {
      id: dto.avatar?.id?.toString() ?? '',
      hash: dto.avatar?.blurhash ?? '',
      url: dto.avatar?.url ?? '',
    };
  }

  private toTheme(dto: ProfileResponseDto): ProfileThemeModel {
    const isValid = this.themeModes.has(dto.settings?.background_mode as ProfileThemeMode);
    const mode = isValid ? (dto.settings?.background_mode as ProfileThemeMode) : 'dark';
    return { color: null, mode };
  }
}
