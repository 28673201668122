import { ContainerModule } from 'inversify';

import { BaseModule } from '@vp/common/BaseModule';
import { EventsManagerFacade } from '@vp/manager/events/core/EventsManagerFacade';
import { EventsManagerPort } from '@vp/manager/events/core/interface/EventsManagerPort';
import { EventsManagerRepository } from '@vp/manager/events/core/interface/EventsManagerRepository';
import { EventsManagerState } from '@vp/manager/events/core/interface/EventsManagerState';
import { LoadEventsUsecase } from '@vp/manager/events/core/usecase/LoadEventsUsecase';
import { SubmitEventsUsecase } from '@vp/manager/events/core/usecase/SubmitEventsUsecase';
import { EventsManagerDataMapper } from '@vp/manager/events/data/EventsManagerDataMapper';
import { RestEventsManagerRepository } from '@vp/manager/events/data/RestEventsManagerRepository';
import { SignalsEventsManagerState } from '@vp/manager/events/data/SignalsEventsManagerState';
import { EventsManagerDiffer } from '@vp/manager/events/ui/EventsManagerDiffer';
import { EventsManagerViewModel } from '@vp/manager/events/ui/EventsManagerViewModel';

export class EventsManagerModule extends BaseModule {
  readonly module: ContainerModule = new ContainerModule(bind => {
    bind(RestEventsManagerRepository).toSelf().inSingletonScope();
    bind(SignalsEventsManagerState).toSelf().inSingletonScope();
    bind(EventsManagerFacade).toSelf().inSingletonScope();
    bind(EventsManagerDataMapper).toSelf().inSingletonScope();

    bind(LoadEventsUsecase).toSelf().inSingletonScope();
    bind(SubmitEventsUsecase).toSelf().inSingletonScope();

    bind(EventsManagerViewModel).toSelf().inTransientScope();
    bind(EventsManagerDiffer).toSelf().inTransientScope();

    bind(EventsManagerRepository)
      .toDynamicValue(context => context.container.get(RestEventsManagerRepository))
      .inSingletonScope();

    bind(EventsManagerState)
      .toDynamicValue(context => context.container.get(SignalsEventsManagerState))
      .inSingletonScope();

    bind(EventsManagerPort)
      .toDynamicValue(context => context.container.get(EventsManagerFacade))
      .inSingletonScope();
  });
}
