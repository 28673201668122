import { ReadonlySignal } from '@preact/signals-react';

import { ProfileHonorRecordModel } from '@vp/profile/core/model/ProfileHonorRecordModel';

export type HonorManagerStatus = 'initial' | 'loading' | 'loaded' | 'error';

export abstract class HonorManagerState {
  abstract readonly approvedRecords: ReadonlySignal<ProfileHonorRecordModel[]>;
  abstract readonly pendingRecords: ReadonlySignal<ProfileHonorRecordModel[]>;
  abstract readonly status: ReadonlySignal<HonorManagerStatus>;

  abstract loading(): void;
  abstract completed(status: HonorManagerStatus): void;
  abstract setRecords(records: ProfileHonorRecordModel[]): void;
  abstract updateRecords(records: ProfileHonorRecordModel[]): void;
  abstract setRecord(record: ProfileHonorRecordModel): void;
  abstract deleteRecords(ids: string[]): void;
  abstract reset(): void;
}
