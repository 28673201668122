import { signal, Signal } from '@preact/signals-react';
import { injectable } from 'inversify';

export interface ViewModelInit {
  init(): void;
}

export interface ViewModelDispose {
  dispose(): void;
}

@injectable()
export abstract class ViewModel<P extends object = object> {
  protected props: Signal<P | null> = signal(null);
  private lastProps: P | null = null;

  bindProps(props?: P): void {
    if (props && !this.compareProps(this.lastProps, props)) {
      this.lastProps = props;
      this.props.value = props;
    }
  }

  private compareProps(a: P | null, b: P | null): boolean {
    if (a === b) return true;
    if (a === null || b === null) return false;

    const keysA = Reflect.ownKeys(a);
    const keysB = Reflect.ownKeys(b);

    return keysA.length === keysB.length && keysA.every(key => Reflect.has(b, key) && Reflect.get(a, key) === Reflect.get(b, key));
  }
}
