import { injectable } from 'inversify';

import { UploadMediaDto } from '@vp/manager/gallery/core/dto/UploadMediaDto';
import { GalleryManagerRepository } from '@vp/manager/gallery/core/interface/GalleryManagerRepository';
import { GalleryManagerUploadHandle, UploadHandleSnapshot } from '@vp/manager/gallery/core/interface/GalleryManagerUploadHandle';
import { ProfileManagerState } from '@vp/manager/profile/core/interface/ProfileManagerState';

@injectable()
export class UploadMediaUsecase {
  constructor(
    private readonly galleryManagerRepository: GalleryManagerRepository,
    private readonly managerState: ProfileManagerState,
  ) {}

  execute(dto: UploadMediaDto): GalleryManagerUploadHandle {
    const handle = this.galleryManagerRepository.upload(dto);
    this.updateProfileOnCompleted(handle);
    return handle;
  }

  private updateProfileOnCompleted(handle: GalleryManagerUploadHandle): void {
    handle.subscribe(snapshot => {
      if (snapshot.state === 'completed') {
        this.updateProfileIfNeeded(snapshot);
      }
    });
  }

  private updateProfileIfNeeded(snapshot: UploadHandleSnapshot): void {
    if (this.shouldBeUpdated(snapshot)) {
      const profile = this.managerState.active.value!;
      const photos = [...new Map([...profile.photos, ...snapshot.uploadedPhotos].map(item => [item.id, item])).values()];
      const videos = [...new Map([...profile.videos, ...snapshot.uploadedVideos].map(item => [item.id, item])).values()];
      this.managerState.update({ ...profile, photos, videos });
    }
  }

  private shouldBeUpdated(snapshot: UploadHandleSnapshot): boolean {
    return !!this.managerState.active.value && !!(snapshot.uploadedVideos.length || snapshot.uploadedPhotos.length);
  }
}
