import { ContainerModule } from 'inversify';

import { BaseModule } from '@vp/common/BaseModule';
import { BiographyManagerFacade } from '@vp/manager/biography/core/BiographyManagerFacade';
import { BiographyManagerPort } from '@vp/manager/biography/core/interface/BiographyManagerPort';
import { BiographyManagerRepository } from '@vp/manager/biography/core/interface/BiographyManagerRepository';
import { UpdateBiographyUsecase } from '@vp/manager/biography/core/usecase/UpdateBiographyUsecase';
import { BiographyManagerDataMapper } from '@vp/manager/biography/data/BiographyManagerDataMapper';
import { RestBiographyManagerRepository } from '@vp/manager/biography/data/RestBiographyManagerRepository';
import { BiographyManagerViewModel } from '@vp/manager/biography/ui/BiographyManagerViewModel';

export class BiographyManagerModule extends BaseModule {
  readonly module: ContainerModule = new ContainerModule(bind => {
    bind(BiographyManagerViewModel).toSelf().inTransientScope();

    bind(RestBiographyManagerRepository).toSelf().inSingletonScope();
    bind(BiographyManagerDataMapper).toSelf().inSingletonScope();

    bind(UpdateBiographyUsecase).toSelf().inSingletonScope();

    bind(BiographyManagerRepository)
      .toDynamicValue(context => context.container.get(RestBiographyManagerRepository))
      .inSingletonScope();

    bind(BiographyManagerFacade).toSelf().inSingletonScope();
    bind(BiographyManagerPort)
      .toDynamicValue(context => context.container.get(BiographyManagerFacade))
      .inSingletonScope();
  });
}
