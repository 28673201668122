import { Box } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, memo } from 'react';

import { VpPaper } from '@vp/common/ui/component/VpPaper';
import { useSearchSection } from '@vp/common/ui/hook/useSearchSection';
import { useSearchSectionCheck } from '@vp/common/ui/hook/useSearchSectionCheck';
import { ProfileExampleBio } from '@vp/manager/theming/ui/example/ProfileExampleBio';
import { ProfileExampleEvents } from '@vp/manager/theming/ui/example/ProfileExampleEvents';
import { ProfileExampleGallery } from '@vp/manager/theming/ui/example/ProfileExampleGallery';
import { ProfileExampleHonor } from '@vp/manager/theming/ui/example/ProfileExampleHonor';
import { ProfileNavigation } from '@vp/profile/ui/navigation/ProfileNavigation';
import { ProfileSection } from '@vp/profile/ui/ProfileViewModel';

export const ThemingManagerProfileExample: FC = memo(() => {
  useSearchSectionCheck('s', ProfileSection.Events);

  const section = useSearchSection<ProfileSection>('s', ProfileSection.Events);

  return (
    <Box sx={{ position: 'relative' }}>
      <VpPaper sx={{ gap: 2, transition: 'background-color 0.25s ease-in-out' }}>
        <ProfileNavigation defaultSection={ProfileSection.Events} sx={{ p: 0 }} />

        <AnimatePresence mode="wait">
          <motion.div
            key={section}
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.25 }}
            style={{ overflow: 'hidden' }}
          >
            {section === ProfileSection.Bio && <ProfileExampleBio />}
            {section === ProfileSection.Events && <ProfileExampleEvents />}
            {section === ProfileSection.Gallery && <ProfileExampleGallery />}
            {section === ProfileSection.Honor && <ProfileExampleHonor />}
          </motion.div>
        </AnimatePresence>
      </VpPaper>

      <Box
        sx={theme => ({
          ...theme.applyStyles('light', {
            inset: 0,
            position: 'absolute',
            pointerEvents: 'none',
            backgroundImage: theme.lightNoise,
          }),
        })}
      />
    </Box>
  );
});

ThemingManagerProfileExample.displayName = ThemingManagerProfileExample.name;
