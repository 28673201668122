import { injectable } from 'inversify';

@injectable()
export class SupportHandler {
  private readonly supportHref: string = 'https://впамяти.рф/#contacts';

  handle(): void {
    window.open(this.supportHref, '_blank');
  }
}
