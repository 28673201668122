import { UploadMediaType } from '@vp/manager/gallery/core/dto/UploadMediaType';
import { FileUpload } from '@vp/manager/gallery/data/upload/context/FileUpload';
import { FileUploadRegistry } from '@vp/manager/gallery/data/upload/context/UploadContext';
import { UploadState } from '@vp/manager/gallery/data/upload/state/UploadState';

export class InitialState extends UploadState {
  async process(): Promise<void> {
    const registry: FileUploadRegistry = new Map();

    for (let index = 0; index < this.stateMachine.context.files.length; index++) {
      const mediaFile = this.stateMachine.context.files[index];
      registry.set(index, new FileUpload(mediaFile));
    }

    this.stateMachine.context.setRegistry(registry);

    this.determineTransition();
  }

  private determineTransition(): void {
    if (this.stateMachine.context.type === UploadMediaType.Videos) {
      this.stateMachine.changeState(this.stateMachine.initializationState);
    } else {
      this.stateMachine.changeState(this.stateMachine.basicUploadingState);
    }
  }
}
