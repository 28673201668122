import { injectable } from 'inversify';

import { AuthRepository } from '@vp/auth/core/interface/AuthRepository';
import { AuthState } from '@vp/auth/core/interface/AuthState';
import { Analytics } from '@vp/common/core/Analytics';

@injectable()
export class LoadOwnerUsecase {
  constructor(
    private readonly authState: AuthState,
    private readonly authRepository: AuthRepository,
    private readonly analytics: Analytics,
  ) {}

  async execute(controller?: AbortController): Promise<void> {
    const owner = await this.authRepository.getOwner(controller);
    this.authState.setOwnerEmail(owner.email);
    this.analytics.setUser(owner);
  }
}
