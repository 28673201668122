import { EngineState } from '@vp/slideshow/core/engine/state/EngineState';

export class PlayingState extends EngineState {
  private timePercent: number = 0;

  enter(): void {
    this.timePercent = this.engine.totalTime / 100;
  }

  pause(): void {
    this.engine.changeState(this.engine.pausedState);
  }

  goTo(slide: number): void {
    this.engine.currentSlide = slide;
    this.engine.currentSlideProgress = 0;
    const updatedPlayedTime = slide * this.engine.slideTime;
    this.engine.progress = (updatedPlayedTime / this.engine.totalTime) * 100;
    this.engine.startTime -= updatedPlayedTime - (Date.now() - this.engine.startTime);
  }

  tick(): void {
    this.updateProgress();
    this.defineSlide();
    this.updateSlideProgress();
    this.finishIfNeeded();
  }

  private updateProgress(): void {
    const progress = (Date.now() - this.engine.startTime) / this.engine.totalTime;
    this.engine.progress = Math.min(progress * 100, 100);
  }

  private defineSlide(): void {
    const timePlayed = this.engine.progress * this.timePercent;
    const currentSlide = Math.floor(timePlayed / this.engine.slideTime);
    this.engine.currentSlide = Math.min(currentSlide, this.engine.slidesCount - 1);
  }

  private updateSlideProgress(): void {
    if (this.engine.progress >= 100) {
      return void (this.engine.currentSlideProgress = 100);
    }

    const timePlayed = this.engine.progress * this.timePercent;
    const slideTimePlayed = timePlayed % this.engine.slideTime;
    this.engine.currentSlideProgress = (slideTimePlayed / this.engine.slideTime) * 100;
  }

  private finishIfNeeded(): void {
    if (this.engine.progress >= 100) {
      this.engine.changeState(this.engine.finishedState);
    }
  }
}
