export class FileChunk {
  eTag?: string;

  constructor(
    public blob: Blob,
    public order: number,
  ) {}

  setEtag(etag: string): void {
    this.eTag = etag;
  }
}
