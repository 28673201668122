import { Box, Stack, useTheme } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';

import { VpButton } from '@vp/common/ui/component/VpButton';
import { RequestState } from '@vp/manager/honor/ui/HonorManagerViewModel';
import { ProfileHonorRecordModel } from '@vp/profile/core/model/ProfileHonorRecordModel';
import { ProfileHonorRecord } from '@vp/profile/ui/honor/ProfileHonorRecord';

interface HonorManagerListItemProps {
  record: ProfileHonorRecordModel;
  actionButtonText: string;
  disableInteractions: boolean;
  activeRequestState: RequestState;
  actionHandler: (record: ProfileHonorRecordModel) => void;
  removeHandler: (id: string) => void;
}

const Gradient =
  'linear-gradient(93.17deg, rgba(229, 229, 229, 0.7) 1.37%, rgba(229, 229, 229, 0.5) 50.9%, rgba(229, 229, 229, 0.1) 105.12%);';

export const HonorManagerListItem: FC<HonorManagerListItemProps> = ({
  actionHandler,
  removeHandler,
  record,
  actionButtonText,
  disableInteractions,
  activeRequestState,
}) => {
  const { spacing } = useTheme();

  const actionLoading = useMemo(
    () => activeRequestState.inProgress && activeRequestState.ids.some(id => id === record.id),
    [activeRequestState.ids, activeRequestState.inProgress, record.id],
  );

  const onRemove = useCallback(() => {
    removeHandler(record.id);
  }, [record.id, removeHandler]);

  const onAction = useCallback(() => {
    actionHandler(record);
  }, [actionHandler, record]);

  return (
    <Stack
      sx={{
        padding: spacing(1.5),
        border: '1px solid transparent',
        borderImageSource: Gradient,
        borderImageSlice: 1,
      }}
    >
      <ProfileHonorRecord record={record} />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: spacing(1),
          marginTop: spacing(2),
        }}
      >
        <VpButton
          text="Удалить"
          disabled={disableInteractions}
          onClick={onRemove}
          sx={{ flex: 1 }}
          status="primary"
          variant="soft"
          size="small"
        />
        <VpButton
          text={actionButtonText}
          disabled={disableInteractions}
          loading={actionLoading}
          onClick={onAction}
          status="overlay"
          variant="outlined"
          size="small"
          sx={{ flex: 1 }}
        />
      </Box>
    </Stack>
  );
};
