import { ReadonlySignal, signal, Signal } from '@preact/signals-react';
import { injectable } from 'inversify';

import { ViewModel } from '@vp/common/ui/ViewModel';
import { ProfileManagerState } from '@vp/manager/profile/core/interface/ProfileManagerState';
import { AppMenuService } from '@vp/menu/core/interface/AppMenuService';
import { ProfileModel } from '@vp/profile/core/model/ProfileModel';
import { RouterService } from '@vp/routing/RouterService';

@injectable()
export class AppMenuProfilesViewModel extends ViewModel {
  readonly profiles: ReadonlySignal<ProfileModel[]> = this.profileManagerState.profiles;
  readonly open: Signal<boolean> = signal(true);

  constructor(
    private readonly profileManagerState: ProfileManagerState,
    private readonly routerService: RouterService,
    private readonly appMenuService: AppMenuService,
  ) {
    super();
  }

  toggle = (): void => {
    this.open.value = !this.open.value;
  };

  selectProfile = (profile: ProfileModel): void => {
    const path = `/ps/${profile.id}`;
    void this.routerService.navigate(path);
    this.appMenuService.toggle();
  };
}
