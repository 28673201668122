import { ReadonlySignal, computed, Signal, signal, effect, batch } from '@preact/signals-react';
import { injectable } from 'inversify';

import { DateFormatter } from '@vp/common/ui/DateFormatter';
import { ViewModel, ViewModelDispose, ViewModelInit } from '@vp/common/ui/ViewModel';
import { ProfileState } from '@vp/profile/core/interface/ProfileState';
import { ProfileModel } from '@vp/profile/core/model/ProfileModel';
import { ProfilePhotoModel } from '@vp/profile/core/model/ProfilePhotoModel';
import { SlideshowState, SlideshowStatus } from '@vp/slideshow/core/interface/SlideshowState';
import { SlideshowAudioController } from '@vp/slideshow/ui/SlideshowAudioController';
import { SlideshowEngineController } from '@vp/slideshow/ui/SlideshowEngineController';

@injectable()
export class SlideshowViewModel extends ViewModel implements ViewModelInit, ViewModelDispose {
  readonly birthDeathDates: ReadonlySignal<string> = computed(() => this.toBirthDeathDates(this.profileState.profile.value));
  readonly profileName: ReadonlySignal<string> = computed(() => this.toProfileNameView());
  readonly images: ReadonlySignal<ProfilePhotoModel[]> = this.slideshowState.images;

  readonly isPaused: ReadonlySignal<boolean> = this.engineController.isPaused;
  readonly isFinished: ReadonlySignal<boolean> = this.engineController.isFinished;
  readonly progress: Signal<number> = this.engineController.progress;
  readonly currentSlide: Signal<number> = this.engineController.currentSlide;
  readonly currentSlideProgress: Signal<number> = this.engineController.currentSlideProgress;

  readonly muted: Signal<boolean> = this.audioController.muted;
  readonly galleryShown: Signal<boolean> = signal(false);

  readonly introShown: Signal<boolean> = signal(true);
  readonly introImage: Signal<string> = signal(this.images.value[0].url);
  readonly introImageScale: Signal<number> = signal(1);
  readonly introButtonText: Signal<string> = signal('Показать слайдшоу');

  private disposeEffect!: () => void;

  constructor(
    private readonly profileState: ProfileState,
    private readonly slideshowState: SlideshowState,
    private readonly dateFormatter: DateFormatter,
    private readonly engineController: SlideshowEngineController,
    private readonly audioController: SlideshowAudioController,
  ) {
    super();
  }

  init(): void {
    this.engineController.start(this.images.value.length, 3000);
    this.onSlideshowFinished();
  }

  dispose(): void {
    this.engineController.stop();
    this.audioController.pause();
    this.disposeEffect();
  }

  closeSlideshow = (status: SlideshowStatus): void => {
    this.slideshowState.setSlideshowStatus(status);
  };

  pause = (): void => {
    this.engineController.pause();
    this.audioController.pause();
  };

  togglePause = (): void => {
    if (this.isPaused.value) {
      this.engineController.play();
      this.audioController.play();
    } else {
      this.engineController.pause();
      this.audioController.pause();
    }
  };

  toggleMuted = (): void => {
    this.audioController.toggleMuted();
  };

  openGallery = (): void => {
    if (!this.isPaused.value) this.engineController.pause();
    this.galleryShown.value = true;
  };

  closeGallery = (imageId?: string): void => {
    this.goToSlideIfNeeded(imageId);
    this.engineController.play();
    this.audioController.play();
    this.galleryShown.value = false;
  };

  startSlideshow = (): void => {
    this.introShown.value = false;
    this.engineController.play();
    this.audioController.play();
  };

  private onSlideshowFinished(): void {
    this.disposeEffect = effect(() => {
      if (this.isFinished.value) {
        batch(() => {
          this.introShown.value = true;
          this.introImage.value = this.images.value[this.images.value.length - 1].url;
          this.introImageScale.value = 1.05;
          this.introButtonText.value = 'Смотерть еще раз';
        });
      }
    });
  }

  private goToSlideIfNeeded(imageId?: string): void {
    if (!imageId) return;

    const slide = this.images.value.findIndex(image => image.id === imageId);
    this.engineController.goTo(slide);
  }

  private toLocaleDate(date: string): string {
    return this.dateFormatter.toLocaleDate(date);
  }

  private toBirthDeathDates(profile: ProfileModel): string {
    const birthDate = this.toLocaleDate(profile.birthDate);
    const deathDate = this.toLocaleDate(profile.deathDate);
    return birthDate && deathDate ? `${birthDate} - ${deathDate}` : '';
  }

  private toProfileNameView(): string {
    return this.profileState.profile.value.name || 'Нет имени';
  }
}
