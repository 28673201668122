import { FormControl, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { VpInput } from '@vp/common/ui/component/VpInput';
import { BiographyFormValues } from '@vp/manager/biography/ui/BiographyManagerViewModel';

interface BiographyManagerFormDescriptionProps {
  disabled: boolean;
}

export const BiographyManagerFormDescription: FC<BiographyManagerFormDescriptionProps> = ({ disabled }) => {
  const { spacing } = useTheme();
  const { register, formState } = useFormContext<BiographyFormValues>();

  return (
    <FormControl disabled={disabled} fullWidth error={!!formState.errors.biography}>
      <Typography component="label" htmlFor="form-text" variant="formLabelMedium" color="text.primary" mb={spacing(0.75)}>
        Описание
      </Typography>
      <VpInput
        {...register('biography', { required: true, minLength: 4, maxLength: 10000 })}
        id="form-text"
        multiline
        placeholder="Напишите биографию человека, включая ключевые моменты его жизни, достижения и наследие, которое он оставил для будущих поколений."
      />
    </FormControl>
  );
};
