import { Stack } from '@mui/material';
import { FC } from 'react';

import { AuthPageDescription } from '@vp/auth/ui/AuthPageDescription';
import { RestorePasswordForm } from '@vp/auth/ui/restore-password/RestorePasswordForm';
import { VpFade } from '@vp/common/ui/component/VpFade';

export const RestorePasswordPage: FC = () => {
  return (
    <VpFade>
      <Stack width={1} px={3} pt={5} alignItems="center">
        <AuthPageDescription title="Восстановление Доступа" description="Введите новый пароль" />
        <RestorePasswordForm />
      </Stack>
    </VpFade>
  );
};
