import { signal, Signal } from '@preact/signals-react';
import { injectable } from 'inversify';

import { AuthPort } from '@vp/auth/core/interface/AuthPort';
import { Success } from '@vp/common/core/OperationResult';
import { ViewModel, ViewModelDispose, ViewModelInit } from '@vp/common/ui/ViewModel';
import { AppNotificationService } from '@vp/notification/AppNotificationService';
import { RouterService } from '@vp/routing/RouterService';

export type RestorePasswordFormData = {
  password: string;
  passwordConfirmation: string;
};

@injectable()
export class RestorePasswordViewModel extends ViewModel implements ViewModelInit, ViewModelDispose {
  readonly loading: Signal<boolean> = signal(false);

  private recoveryToken?: string | null;
  private controller?: AbortController;
  private validated: boolean = false;

  constructor(
    private readonly router: RouterService,
    private readonly authPort: AuthPort,
    private readonly notificationService: AppNotificationService,
  ) {
    super();
  }

  init(): void {
    if (!this.validated) {
      this.extractRecoveryToken();
      this.validateRecoveryToken();
      this.validated = true;
    }
  }

  dispose(): void {
    this.controller?.abort();
  }

  restorePassword = async ({ password }: RestorePasswordFormData): Promise<void> => {
    if (!this.recoveryToken) return;

    this.loading.value = true;
    this.controller = new AbortController();

    const result = await this.authPort.restorePassword(password, this.recoveryToken, this.controller);

    if (result instanceof Success) {
      this.showSuccessNotification();
      this.navigateToProfilesManager();
    } else {
      this.showErrorNotification(result.message);
    }

    this.loading.value = false;
  };

  private extractRecoveryToken(): void {
    const search = new URLSearchParams(window.location.search);
    this.recoveryToken = search.get('rt');
  }

  private validateRecoveryToken(): void {
    if (!this.recoveryToken || !this.authPort.validateRecoveryToken(this.recoveryToken)) {
      this.showTokenErrorNotification();
      void this.router.navigate('/auth/login', { replace: true });
    }
  }

  private showSuccessNotification(): void {
    this.notificationService.enqueue({
      variant: 'success',
      message: 'Пароль успешно изменен',
    });
  }

  private showErrorNotification(message: string): void {
    this.notificationService.enqueue({
      variant: 'error',
      message: 'Что-то пошло не так',
      secondaryMessage: message || 'Не удалось установить новый пароль.',
    });
  }

  private showTokenErrorNotification(): void {
    this.notificationService.enqueue({
      variant: 'error',
      message: 'Что-то пошло не так',
      secondaryMessage: 'Срок действия токена для восстановления пароля истек, либо он недействителен.',
      autoHideDuration: 10_000,
    });
  }

  private navigateToProfilesManager(): void {
    void this.router.navigate('/ps');
  }
}
