import { Box, Stack, useTheme } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import { CSSProperties, FC } from 'react';

import { useLinearGradient } from '@vp/common/ui/hook/useLinearGradient';
import { ProfileHonorRecordModel } from '@vp/profile/core/model/ProfileHonorRecordModel';
import { ProfileHonorRecord } from '@vp/profile/ui/honor/ProfileHonorRecord';

export interface ProfileHonorRecordsProps {
  records: ProfileHonorRecordModel[];
  sx?: SxProps<Theme>;
}

export const ProfileHonorRecords: FC<ProfileHonorRecordsProps> = ({ records, sx }) => {
  const theme = useTheme();
  const divider = useDivider();

  return (
    <Stack pt={2} gap={3} sx={sx} style={{ '--vp-primary-color': theme.palette.primary[500] } as CSSProperties}>
      {records.map((record, index, records) => {
        const isLastRecord = index + 1 === records.length;

        return (
          <Stack key={record.id}>
            <ProfileHonorRecord record={record} />
            {!isLastRecord && (
              <Box
                sx={{
                  transition: 'opacity 0.25s ease-in-out',
                  ...theme.applyStyles('dark', {
                    '&::before': { ...divider, filter: 'blur(10px)' },
                  }),
                  '&::after': divider,
                }}
                position="relative"
              />
            )}
          </Stack>
        );
      })}
    </Stack>
  );
};

const useDivider = (): CSSProperties => {
  const { spacing } = useTheme();

  const gradient = useLinearGradient({
    angle: '90deg',
    color: 'var(--vp-primary-color)',
    stops: [
      { percent: 0, opacity: 1 },
      { percent: 64, opacity: 0.5 },
      { percent: 100, opacity: 0 },
    ],
  });

  return {
    content: '""',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    transition: '--vp-primary-color 0.25s ease-in-out',
    background: gradient,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '1px',
    marginTop: spacing(2),
  };
};
