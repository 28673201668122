import { IconButton, SvgIcon } from '@mui/material';
import { FC } from 'react';

import CloseIcon from '@vp/assets/icons/Close.svg?react';

interface CloseButtonProps {
  close: () => void;
}

export const CloseButton: FC<CloseButtonProps> = ({ close }) => {
  return (
    <IconButton
      onClick={close}
      sx={theme => ({
        color: theme.palette.primary[500],
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: theme.spacing(-2),
          right: theme.spacing(-1),
          bottom: theme.spacing(-2),
          left: theme.spacing(-2),
          background: 'transparent',
        },
      })}
    >
      <SvgIcon>
        <CloseIcon />
      </SvgIcon>
    </IconButton>
  );
};
