import { injectable } from 'inversify';

import { AuthRepository } from '@vp/auth/core/interface/AuthRepository';
import { TokensStorage } from '@vp/auth/core/interface/TokensStorage';
import { LoadOwnerUsecase } from '@vp/auth/core/usecase/LoadOwnerUsecase';
import { LoadProfilesUsecase } from '@vp/manager/profile/core/usecase/LoadProfilesUsecase';

@injectable()
export class RegisterUsecase {
  constructor(
    private readonly authRepository: AuthRepository,
    private readonly tokensStorage: TokensStorage,
    private readonly loadOwnerUsecase: LoadOwnerUsecase,
    private readonly loadProfilesUsecase: LoadProfilesUsecase,
  ) {}

  async execute(email: string, password: string, controller?: AbortController): Promise<void> {
    const tokens = await this.authRepository.register(email, password, controller);
    await this.tokensStorage.store(tokens);
    await this.loadOwnerUsecase.execute(controller);
    await this.loadProfilesUsecase.execute(controller);
  }
}
