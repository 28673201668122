export type Subscriber = (state: RouterState) => void;
export type Unsubscribe = () => void;
export type SubscriberId = number;

export interface RouterState {
  pathname: string;
  search: string;
  hash: string;
}

export class RouterSubscribers {
  private readonly subscribers: Map<SubscriberId, Subscriber> = new Map();
  private readonly generator: Generator<number, number> = this.idGenerator();

  add(subscriber: Subscriber, state: RouterState): Unsubscribe {
    const id = this.generator.next().value;
    this.subscribers.set(id, subscriber);
    subscriber(state);
    return () => this.subscribers.delete(id);
  }

  notify(state: RouterState): void {
    this.subscribers.forEach(subscriber => {
      subscriber(state);
    });
  }

  private *idGenerator(): Generator<number, number> {
    let number = 0;
    while (true) yield number++;
  }
}
