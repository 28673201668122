import { Box, styled } from '@mui/material';
import { ChangeEventHandler, FC, Fragment, useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { EventsFormValues } from '@vp/manager/events/ui/EventsManagerPage';
import { EventsManagerViewModel } from '@vp/manager/events/ui/EventsManagerViewModel';
import { EventsManagerFormImageContent } from '@vp/manager/events/ui/form/image/EventsManagerFormImageContent';
import { EventsManagerImageControlButton } from '@vp/manager/events/ui/form/image/EventsManagerImageControlButton';

interface EventsManagerFormImageProps {
  index: number;
  id: string;
  disableControls: boolean;
}

const Gradient = 'linear-gradient(133.62deg, #E5E5E5 -18.03%, rgba(229, 229, 229, 0.5) 52.31%, rgba(229, 229, 229, 0) 91.87%)';
const HiddenFileControl = styled('input')({ display: 'none' });

export const EventsManagerFormImage: FC<EventsManagerFormImageProps> = ({ index, id, disableControls }) => {
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const { watch, register, setValue } = useFormContext<EventsFormValues>();
  const imageValue = watch(`events.${index}.image`);

  const handleImageRemove = useCallback(() => {
    setValue(`events.${index}.image`, EventsManagerViewModel.EmptyEvent.image, { shouldDirty: true });
    if (fileUrl) {
      URL.revokeObjectURL(fileUrl);
    }
  }, [fileUrl, index, setValue]);

  const handleImageUpload = useCallback(
    (files: File[]): void => {
      const file = files[0];
      const url = URL.createObjectURL(file);
      setFileUrl(url);
      setValue(`events.${index}.image`, { url, file, hash: '', id: '' }, { shouldDirty: true });
    },
    [index, setValue],
  );

  const onImageChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    event => handleImageUpload(Array.from(event.target.files ?? [])),
    [handleImageUpload],
  );

  return (
    <Fragment>
      <Box
        display="flex"
        position="relative"
        justifyContent="center"
        alignItems="center"
        flexShrink={0}
        width={84}
        height={84}
        borderRadius="50%"
      >
        <Box flexShrink={0} width={76} height={76} borderRadius="50%" sx={{ background: Gradient, filter: 'blur(5px)' }} />
        <EventsManagerFormImageContent id={id} image={imageValue} />
        <EventsManagerImageControlButton
          disabled={disableControls}
          id={id}
          image={imageValue}
          removeImage={handleImageRemove}
          uploadImage={handleImageUpload}
        />
      </Box>

      <HiddenFileControl
        {...register(`events.${index}.image`, { required: false })}
        onChange={onImageChange}
        accept="image/*"
        id={`image-upload-${index}`}
        type="file"
      />
    </Fragment>
  );
};
