import { injectable } from 'inversify';

import { HonorManagerRepository } from '@vp/manager/honor/core/interface/HonorManagerRepository';
import { HonorManagerState } from '@vp/manager/honor/core/interface/HonorManagerState';

@injectable()
export class LoadHonorRecordsUsecase {
  constructor(
    private readonly honorManagerRepository: HonorManagerRepository,
    private readonly honorManagerState: HonorManagerState,
  ) {}

  async execute(id: string, controller?: AbortController): Promise<void> {
    try {
      this.honorManagerState.loading();
      const records = await this.honorManagerRepository.getHonorRecords(id, controller);
      this.honorManagerState.setRecords(records);
      this.honorManagerState.completed('loaded');
    } catch {
      this.honorManagerState.completed('error');
    }
  }
}
