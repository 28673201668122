import { EngineState } from '@vp/slideshow/core/engine/state/EngineState';
import { FinishedState } from '@vp/slideshow/core/engine/state/FinishedState';
import { InitialState } from '@vp/slideshow/core/engine/state/InitialState';
import { PausedState } from '@vp/slideshow/core/engine/state/PausedState';
import { PlayingState } from '@vp/slideshow/core/engine/state/PlayingState';
import { SlideshowEnginePort } from '@vp/slideshow/core/interface/SlideshowEnginePort';

export class SlideshowEngine implements SlideshowEnginePort {
  totalTime: number = 0;
  startTime: number = 0;

  progress: number = 0;
  currentSlide: number = 0;
  currentSlideProgress: number = 0;

  readonly initialState: EngineState = new InitialState(this);
  readonly playingState: EngineState = new PlayingState(this);
  readonly pausedState: EngineState = new PausedState(this);
  readonly finishedState: EngineState = new FinishedState(this);

  private state: EngineState | null = null;

  constructor(
    readonly slidesCount: number,
    readonly slideTime: number,
  ) {}

  play(): void {
    this.state?.play?.();
  }

  pause(): void {
    this.state?.pause?.();
  }

  tick(): void {
    this.state?.tick?.();
  }

  goTo(slide: number): void {
    this.state?.goTo?.(slide);
  }

  isIn(State: typeof EngineState): boolean {
    return this.state instanceof State;
  }

  changeState(state: EngineState): void {
    this.state?.exit?.();
    this.state = state;
    this.state.enter?.();
  }
}
