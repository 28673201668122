import { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';

import { LandingPage } from '@vp/landing/LandingPage';

export const LandingRouter: RouteObject = {
  path: '',
  children: [
    {
      path: 'landing',
      element: <LandingPage />,
    },
    {
      path: '',
      element: <Navigate replace to="landing" />,
    },
  ],
};
