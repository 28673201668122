import { Typography } from '@mui/material';
import { FC } from 'react';

export const LandingSlogan: FC = () => {
  return (
    <Typography variant="h2" color="text.secondary" textAlign="center">
      Вдохновляя Будущее{' '}
      <Typography
        component="span"
        variant="h2"
        color="text.secondary"
        sx={{ textShadow: '0px 0px 32px rgba(255, 255, 255, 0.5), 0px 0px 128px rgba(255, 255, 255, 0.5)' }}
      >
        Прошлым
      </Typography>
    </Typography>
  );
};
