import { injectable } from 'inversify';

import { AuthState } from '@vp/auth/core/interface/AuthState';
import { OperationResult, Success } from '@vp/common/core/OperationResult';
import { ProfileManagerState } from '@vp/manager/profile/core/interface/ProfileManagerState';
import { ProfileRepository } from '@vp/profile/core/interface/ProfileRepository';

@injectable()
export class ActivateProfileUsecase {
  constructor(
    private readonly authState: AuthState,
    private readonly profileRepository: ProfileRepository,
    private readonly profileManagerState: ProfileManagerState,
  ) {}

  async execute(id: string, controller?: AbortController): Promise<OperationResult> {
    this.ensureUserAuthenticated();
    const result = await this.profileRepository.activateProfile(id, controller);

    if (result instanceof Success) {
      this.profileManagerState.update(result.value);
    }

    return result as OperationResult;
  }

  private ensureUserAuthenticated(): void {
    if (!this.authState.authenticated.value) {
      throw new Error('Users have to be authenticated to activate profiles.');
    }
  }
}
