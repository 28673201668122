import { Typography } from '@mui/material';
import { FC } from 'react';

import ChevronLeft from '@vp/assets/icons/ChevronLeft.svg?react';
import { VpHeader, VpHeaderColumn } from '@vp/common/ui/component/VpHeader';
import { VpIconButton } from '@vp/common/ui/component/VpIconButton';

export const AvatarManagerHeader: FC = () => {
  return (
    <VpHeader sx={{ position: 'sticky', pb: 1, backgroundColor: 'background.300' }}>
      <VpHeaderColumn align="left">
        <VpIconButton glowing Icon={ChevronLeft} to=".." />
      </VpHeaderColumn>
      <VpHeaderColumn>
        <Typography variant="h6" color="text.primary" textAlign="center">
          Фото профиля
        </Typography>
      </VpHeaderColumn>
      <VpHeaderColumn align="right" />
    </VpHeader>
  );
};
