import { injectable } from 'inversify';

@injectable()
export class AvatarManagerDataMapper {
  toUploadFormData(file: File): FormData {
    const formData = new FormData();

    formData.append('attachment_type', 'avatar');
    formData.append('avatar', file);

    return formData;
  }

  toRemoveFormData(id: string): FormData {
    const formData = new FormData();

    formData.append('attachment_type', 'avatar');
    formData.append('removed_ids[]', id);

    return formData;
  }
}
