
import { injectable } from 'inversify';

import { AvatarManagerPort } from '@vp/manager/avatar/core/interfaces/AvatarManagerPort';
import { RemoveAvatarUsecase } from '@vp/manager/avatar/core/usecase/RemoveAvatarUsecase';
import { UploadAvatarUsecase } from '@vp/manager/avatar/core/usecase/UploadAvatarUsecase';

@injectable()
export class AvatarManagerFacade implements AvatarManagerPort {
  constructor(
    private readonly removeAvatarUsecase: RemoveAvatarUsecase,
    private readonly uploadAvatarUsecase: UploadAvatarUsecase,
  ) { }

  async remove(profileId: string, avatarId: string, controller?: AbortController): Promise<boolean> {
    return await this.removeAvatarUsecase.execute(profileId, avatarId, controller);
  }

  async upload(profileId: string, avatarFile: File, controller?: AbortController): Promise<boolean> {
    return await this.uploadAvatarUsecase.execute(profileId, avatarFile, controller);
  }
}
