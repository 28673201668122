import { MutableRefObject, useCallback, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player/file';

export interface UseProgressAnimationProps {
  playerRef: MutableRefObject<ReactPlayer>;
  setProgress: (progress: number) => void;
  playing: boolean;
  duration: number;
}

export function useProgressAnimation({ playerRef, setProgress, playing, duration }: UseProgressAnimationProps): void {
  const animationRef = useRef<number>(null!);

  const animateProgress = useCallback(() => {
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime() ?? 0;
      setProgress(currentTime);
    }
    animationRef.current = requestAnimationFrame(animateProgress);
  }, [playerRef, setProgress]);

  useEffect(() => {
    if (playing) {
      animateProgress();
    } else {
      cancelAnimationFrame(animationRef.current);
    }

    return (): void => cancelAnimationFrame(animationRef.current);
  }, [animateProgress, duration, playing]);
}
