import { injectable } from 'inversify';

import { HonorManagerRepository } from '@vp/manager/honor/core/interface/HonorManagerRepository';
import { HonorManagerState } from '@vp/manager/honor/core/interface/HonorManagerState';

@injectable()
export class EditHonorRecordUsecase {
  constructor(
    private readonly honorManagerRepository: HonorManagerRepository,
    private readonly honorManagerState: HonorManagerState,
  ) {}

  async execute(id: string, newText: string, controller?: AbortController): Promise<void> {
    const updatedRecord = await this.honorManagerRepository.editHonorRecord(id, newText, controller);
    this.honorManagerState.setRecord(updatedRecord);
  }
}
