import { ContainerModule } from 'inversify';

import { BaseModule } from '@vp/common/BaseModule';
import { SlideshowEngineFactory } from '@vp/slideshow/core/engine/SlideshowEngineFactory';
import { SlideshowEngineFactoryPort } from '@vp/slideshow/core/interface/SlideshowEngineFactoryPort';
import { SlideshowMediaLoader } from '@vp/slideshow/core/interface/SlideshowMediaLoader';
import { SlideshowState } from '@vp/slideshow/core/interface/SlideshowState';
import { ScheduleSlideshowUsecase } from '@vp/slideshow/core/usecase/ScheduleSlideshowUsecase';
import { SignalsSlideshowState } from '@vp/slideshow/data/SignalsSlideshowState';
import { SlideshowMediaCacheLoader } from '@vp/slideshow/data/SlideshowMediaCacheLoader';
import { SlideshowAudioController } from '@vp/slideshow/ui/SlideshowAudioController';
import { SlideshowEngineController } from '@vp/slideshow/ui/SlideshowEngineController';
import { SlideshowViewModel } from '@vp/slideshow/ui/SlideshowViewModel';

export class SlideshowModule extends BaseModule {
  readonly module: ContainerModule = new ContainerModule(bind => {
    bind(ScheduleSlideshowUsecase).toSelf().inSingletonScope();
    bind(SlideshowMediaCacheLoader).toSelf().inSingletonScope();
    bind(SignalsSlideshowState).toSelf().inSingletonScope();

    bind(SlideshowViewModel).toSelf().inTransientScope();
    bind(SlideshowEngineController).toSelf().inTransientScope();
    bind(SlideshowAudioController).toSelf().inTransientScope();

    bind(SlideshowState)
      .toDynamicValue(context => context.container.get(SignalsSlideshowState))
      .inSingletonScope();

    bind(SlideshowMediaLoader)
      .toDynamicValue(context => context.container.get(SlideshowMediaCacheLoader))
      .inSingletonScope();

    bind(SlideshowEngineFactory).toSelf().inSingletonScope();

    bind(SlideshowEngineFactoryPort)
      .toDynamicValue(context => context.container.get(SlideshowEngineFactory))
      .inSingletonScope();
  });
}
