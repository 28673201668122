import { FormControl, Typography, useTheme } from '@mui/material';
import { ChangeEventHandler, FC, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import CalendarIcon from '@vp/assets/icons/Calendar.svg?react';
import { VpIcon } from '@vp/common/ui/component/VpIcon';
import { VpInput } from '@vp/common/ui/component/VpInput';
import { BiographyFormValues } from '@vp/manager/biography/ui/BiographyManagerViewModel';

interface BiographyManagerFormDateProps {
  disabled: boolean;
  field: keyof BiographyFormValues;
  label: string;
}

const currentYear = new Date().getFullYear();

const formatDateInput = (value: string): string => {
  const cleaned = value.replace(/\D+/g, '');
  const day = cleaned.slice(0, 2);
  const month = cleaned.slice(2, 4);
  let year = cleaned.slice(4, 8);

  if (year && parseInt(year) > currentYear) {
    year = currentYear.toString();
  }

  return [day, month, year].filter(Boolean).join('.');
};

const isValidDate = (value: string | null): boolean => {
  if (typeof value !== 'string') return false;
  const cleaned = value.replace(/\D+/g, '');
  const day = parseInt(cleaned.slice(0, 2), 10);
  const month = parseInt(cleaned.slice(2, 4), 10);
  const year = parseInt(cleaned.slice(4, 8), 10);

  if (isNaN(day) || isNaN(month) || isNaN(year)) return false;

  const currentYear = new Date().getFullYear();
  const daysInMonth = new Date(year, month, 0).getDate();

  const isYearInvalid = year < 1000 || year > currentYear;
  const isMonthInvalid = month < 1 || month > 12;
  const isDayInvalid = day < 1 || day > daysInMonth;

  return !(isYearInvalid || isMonthInvalid || isDayInvalid);
};

export const BiographyManagerFormDate: FC<BiographyManagerFormDateProps> = ({ disabled, field, label }) => {
  const { spacing } = useTheme();
  const { setValue, register, formState } = useFormContext<BiographyFormValues>();

  const onValidateDate = useCallback((value: string) => isValidDate(value?.toString()) || 'Неправильная дата', []);
  const onChangeDate = useCallback<ChangeEventHandler<HTMLInputElement>>(
    event => setValue(field, formatDateInput(event.target.value.toString())),
    [field, setValue],
  );

  return (
    <FormControl disabled={disabled} fullWidth error={!!formState.errors[field]}>
      <Typography htmlFor={field} component="label" variant="formLabelMedium" color="text.primary" mb={spacing(0.75)}>
        {label}
      </Typography>
      <VpInput
        {...register(field, {
          required: true,
          validate: onValidateDate,
          onChange: onChangeDate,
        })}
        sx={{ width: 1 }}
        id={field}
        placeholder="ДД.ММ.ГГГГ"
        endAdornment={<VpIcon color="transparency.alpha16" size={24} Icon={CalendarIcon} sx={{ p: 0, mr: 1, alignSelf: 'center' }} />}
      />
      {formState.errors[field]?.message && (
        <Typography variant="body2Regular" color="danger.500" mt={0.75} sx={{ position: 'absolute', top: '100%', left: 0, pt: 0.5 }}>
          {formState.errors[field]!.message}
        </Typography>
      )}
    </FormControl>
  );
};
