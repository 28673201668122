import { Stack } from '@mui/material';
import { FC, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { VpFade } from '@vp/common/ui/component/VpFade';
import { VpFancyButton } from '@vp/common/ui/component/VpFancyButton';
import { VpLoadingWrapper } from '@vp/common/ui/component/VpLoadingWrapper';
import { useViewModel } from '@vp/common/ui/hook/useViewModel';
import { EventsManagerRecordModel } from '@vp/manager/events/core/model/EventsManagerRecordModel';
import { EventsManagerHeader } from '@vp/manager/events/ui/EventsManagerHeader';
import { EventsManagerViewModel } from '@vp/manager/events/ui/EventsManagerViewModel';
import { EventsManagerFormList } from '@vp/manager/events/ui/form/EventsManagerFormList';

export interface EventsFormValues {
  events: EventsManagerRecordModel[];
}

export const EventsManagerPage: FC = () => {
  const viewModel = useViewModel(EventsManagerViewModel);
  const methods = useForm<EventsFormValues>({ defaultValues: { events: viewModel.events.value }, mode: 'onTouched' });
  const { handleSubmit, formState, reset } = methods;

  const resetForm = useCallback(() => reset({ events: viewModel.events.value }), [reset, viewModel.events.value]);
  useEffect(() => reset({ events: viewModel.events.value }), [viewModel.events.value, reset]);

  return (
    <VpFade>
      <Stack width={1} height="100dvh">
        <EventsManagerHeader onCancel={resetForm} disableCancel={!formState.isDirty} />

        <Stack overflow="scroll" flexGrow={1}>
          <VpLoadingWrapper loading={viewModel.recordsStatus.value !== 'loaded'}>
            <FormProvider {...methods}>
              <EventsManagerFormList disableControls={viewModel.submitting.value} />
            </FormProvider>
          </VpLoadingWrapper>
        </Stack>

        <Stack alignItems="center" width={1} py={2} px={3}>
          <VpFancyButton
            onClick={handleSubmit(viewModel.submitForm)}
            disabled={!formState.isValid || !formState.isDirty}
            loading={viewModel.submitting.value}
            text="Сохранить"
          />
        </Stack>
      </Stack>
    </VpFade>
  );
};
