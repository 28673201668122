import { Stack, Typography } from '@mui/material';
import { FC } from 'react';

import { VpToggle } from '@vp/common/ui/component/VpToggle';

interface BiographyManagerToggle {
  onToggle: () => void;
  state: boolean;
  label: string;
  field: string;
  disabled: boolean;
}

export const BiographyManagerToggle: FC<BiographyManagerToggle> = ({ field, onToggle, label, state, disabled }) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" py={1.5}>
      <Typography htmlFor={field} component="label" variant="body1">
        {label}
      </Typography>
      <Stack pl={2}>
        <VpToggle disabled={disabled} id={field} state={state} onToggle={onToggle} />
      </Stack>
    </Stack>
  );
};
