import { useState, useCallback } from 'react';

import { Status } from '@vp/manager/honor/ui/HonorManagerViewModel';
import { ProfileHonorRecordModel } from '@vp/profile/core/model/ProfileHonorRecordModel';
import { ProfileHonorNotificationStatus } from '@vp/profile/ui/honor/ProfileHonor';

interface UseRecordEditProps {
  editRecord: (id: string, newText: string) => Promise<Status>;
  showNotification: (status: ProfileHonorNotificationStatus) => void;
}

export interface RecordEditState {
  openRecordEditModal: (record: ProfileHonorRecordModel) => void;
  closeRecordEditModal: () => void;
  handleRecordEdit: (id: string, newText: string) => Promise<void>;
  recordToEdit: ProfileHonorRecordModel | null;
}

export const useRecordEdit = ({ editRecord, showNotification }: UseRecordEditProps): RecordEditState => {
  const [recordToEdit, setRecordToEdit] = useState<ProfileHonorRecordModel | null>(null);

  const openRecordEditModal = useCallback((record: ProfileHonorRecordModel) => setRecordToEdit(record), []);
  const closeRecordEditModal = useCallback(() => setRecordToEdit(null), []);

  const handleRecordEdit = useCallback(
    async (id: string, newText: string) => {
      if (recordToEdit) {
        const status = await editRecord(id, newText);
        showNotification(status);

        if (status === 'success') {
          setRecordToEdit(null);
          closeRecordEditModal();
        }
      }
    },
    [closeRecordEditModal, editRecord, recordToEdit, showNotification],
  );

  return {
    recordToEdit,
    openRecordEditModal,
    closeRecordEditModal,
    handleRecordEdit,
  };
};
