import { Box, Stack } from '@mui/material';
import { FC, Fragment } from 'react';

import Close from '@vp/assets/icons/Close.svg?react';
import { VpButton } from '@vp/common/ui/component/VpButton';
import { VpDialog } from '@vp/common/ui/component/VpDialog';
import { VpHeader } from '@vp/common/ui/component/VpHeader';
import { VpIconButton } from '@vp/common/ui/component/VpIconButton';
import { VpImage } from '@vp/common/ui/component/VpImage';
import { VpMediaGradientOverlay } from '@vp/common/ui/component/VpMediaGradientOverlay';
import { useFalsyStateDelay } from '@vp/common/ui/hook/useFalsyStateDelay';
import { useViewModel } from '@vp/common/ui/hook/useViewModel';
import { VpPlayer } from '@vp/common/ui/player/VpPlayer';
import { GalleryManagerSection } from '@vp/manager/gallery/ui/GalleryManagerSection';
import { GalleryManagerPreviewViewModel } from '@vp/manager/gallery/ui/preview/GalleryManagerPreviewViewModel';

export interface PhotoPreview {
  id: string;
  url: string;
  hash: string;
}

export interface VideoPreview {
  id: string;
  url: string;
  thumbnail: string;
  thumbnailHash: string;
}

export interface GalleryManagerPreviewProps {
  item: PhotoPreview | VideoPreview;
  section: GalleryManagerSection;
  close: () => void;
}

export const GalleryManagerPreview: FC<GalleryManagerPreviewProps> = props => {
  const viewModel = useViewModel(GalleryManagerPreviewViewModel, props);
  const delayedRemoving = useFalsyStateDelay(viewModel.removing.value, 250);
  const sectionName = props.section === GalleryManagerSection.Photos ? 'фото' : 'видео';

  return (
    <Fragment>
      <Stack width={1} height={1}>
        <VpHeader>
          <VpIconButton disabled={delayedRemoving} Icon={Close} onClick={props.close} />
        </VpHeader>

        <VpMediaGradientOverlay sx={{ display: 'flex', flexGrow: 1 }}>
          {viewModel.isPhotoPreview(props.item) && <VpImage src={props.item.url} hash={props.item.hash} />}

          {!viewModel.isPhotoPreview(props.item) && (
            <VpPlayer url={props.item.url} thumbnail={props.item.thumbnail} thumbnailHash={props.item.thumbnailHash} sx={{ flexGrow: 1 }} />
          )}
        </VpMediaGradientOverlay>

        <Box width={1} px={3} py={2}>
          <VpButton text="Удалить" status="primary" variant="soft" size="small" sx={{ width: 1 }} onClick={viewModel.showDialog} />
        </Box>
      </Stack>

      <VpDialog
        open={viewModel.dialogShown.value}
        title={`Удалить ${sectionName}`}
        text={`Вы уверены, что хотите удалить ${sectionName}? Это действие нельзя отменить.`}
        actionText="Удалить"
        cancelText="Отменить"
        actionLoading={delayedRemoving}
        cancelDisabled={delayedRemoving}
        onAction={viewModel.remove}
        onCancel={viewModel.closeDialog}
      />
    </Fragment>
  );
};
