import { ReadonlySignal } from '@preact/signals-react';
import { injectable } from 'inversify';

import { SplashService } from '@vp/common/ui/SplashService';
import { ViewModel, ViewModelDispose, ViewModelInit } from '@vp/common/ui/ViewModel';
import { ProfilePort } from '@vp/profile/core/interface/ProfilePort';
import { ProfileLoadingState } from '@vp/profile/core/interface/ProfileState';
import { ProfileModel } from '@vp/profile/core/model/ProfileModel';
import { RouterService } from '@vp/routing/RouterService';

@injectable()
export class ProfileGatewayViewModel extends ViewModel implements ViewModelInit, ViewModelDispose {
  readonly state: ReadonlySignal<ProfileLoadingState> = this.profilePort.loadingState;
  readonly profile: ReadonlySignal<ProfileModel> = this.profilePort.profile;

  private controller?: AbortController;

  constructor(
    private readonly profilePort: ProfilePort,
    private readonly router: RouterService,
    private readonly splashService: SplashService,
  ) {
    super();
  }

  async init(): Promise<void> {
    this.splashService.show();
    this.controller = new AbortController();
    const { id } = this.router.getParams('p/:id/*');
    await this.profilePort.loadProfile(id, this.controller);
    await this.splashService.hide();
  }

  dispose(): void {
    this.controller?.abort();
  }
}
