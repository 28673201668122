import { Box, ButtonBase, Stack, SvgIcon, Typography } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import ChevronRight from '@vp/assets/icons/ChevronRight.svg?react';
import { MenuItem } from '@vp/manager/profile/ui/settings/menu/ProfileSettingsMenu';

export interface ProfileSettingsMenuItemProps {
  item: MenuItem;
  isFirst: boolean;
  isLast: boolean;
}

export const ProfileSettingsMenuItem: FC<ProfileSettingsMenuItemProps> = ({ item, isLast }) => {
  const Icon = item.Icon;

  return (
    <ButtonBase
      sx={theme => ({
        backgroundColor: 'transparent',
        textTransform: 'none',
        pt: 0.5,
        pb: isLast ? 0.5 : 0,
        pl: 3,
        pr: 2,
        width: 1,
        borderRadius: 0,
        color: theme.palette.text.secondary,
      })}
      LinkComponent={Link}
      {...{ to: item.path }}
    >
      <Stack
        direction="row"
        sx={theme => ({
          width: 1,
          px: 2,
          pt: 2.25,
          pb: `calc(${theme.spacing(2.25)} - 1px)`,
          borderBottom: '1px solid',
          alignItems: 'center',
          borderColor: !isLast ? theme.palette.transparency.alpha16 : 'transparent',
        })}
      >
        <SvgIcon sx={{ fontSize: 24, mr: 1 }}>
          <Icon />
        </SvgIcon>

        <Typography variant="body1Medium">{item.text}</Typography>

        <Box flexGrow={1} />

        <Box position="relative" width={24} height={24}>
          <SvgIcon sx={theme => ({ fontSize: 24, color: theme.palette.text.disabled, filter: 'blur(5px)' })}>
            <ChevronRight />
          </SvgIcon>

          <SvgIcon sx={theme => ({ fontSize: 24, color: theme.palette.text.disabled, position: 'absolute', inset: 0 })}>
            <ChevronRight />
          </SvgIcon>
        </Box>
      </Stack>
    </ButtonBase>
  );
};
