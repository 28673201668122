import { FormControl, Stack, Typography } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import { RegisterLoginNavigation } from '@vp/auth/ui/register/RegisterLoginNavigation';
import { RegisterFormData, RegisterViewModel } from '@vp/auth/ui/register/RegisterViewModel';
import { ViewPasswordIcon } from '@vp/auth/ui/register/ViewPasswordIcon';
import { EmailValidationOptions } from '@vp/auth/ui/validation/EmailValidationOptions';
import { PasswordValidationOptions } from '@vp/auth/ui/validation/PasswordValidationOptions';
import { VpCheckbox } from '@vp/common/ui/component/VpCheckbox';
import { VpFancyButton } from '@vp/common/ui/component/VpFancyButton';
import { VpInput } from '@vp/common/ui/component/VpInput';
import { useViewModel } from '@vp/common/ui/hook/useViewModel';

export const RegisterForm: FC = () => {
  const viewModel = useViewModel(RegisterViewModel);

  const { register, handleSubmit, formState, watch } = useForm<RegisterFormData>({
    defaultValues: { email: '', password: '', passwordConfirmation: '', termsAndCondition: false, rights: false },
    mode: 'onTouched',
  });

  const password = watch('password');
  const [showPassword, setShowPassword] = useState(false);
  const passwordInputType = showPassword ? 'text' : 'password';

  const handleClickShowPassword = useCallback(() => setShowPassword(state => !state), []);

  return (
    <Stack component="form" width="100%" alignItems="center" gap={3} onSubmit={handleSubmit(viewModel.register)}>
      <FormControl fullWidth error={!!formState.errors.email} disabled={viewModel.registering.value}>
        <Typography htmlFor="form-email" component="label" variant="formLabelMedium" color="text.primary" gutterBottom>
          Почта
        </Typography>
        <VpInput autoComplete="email" {...register('email', EmailValidationOptions)} id="form-email" placeholder={'example@mail.com'} />
        {formState.errors.email?.message && (
          <Typography variant="body2Regular" color="danger.500" mt={0.75}>
            {formState.errors.email.message}
          </Typography>
        )}
      </FormControl>

      <FormControl fullWidth error={!!formState.errors.password} disabled={viewModel.registering.value}>
        <Typography htmlFor="form-password" component="label" variant="formLabelMedium" color="text.primary" gutterBottom>
          Пароль
        </Typography>
        <VpInput
          {...register('password', PasswordValidationOptions)}
          id="form-password"
          autoComplete="new-password"
          placeholder={'Введите ваш пароль'}
          type={passwordInputType}
          endAdornment={<ViewPasswordIcon showPassword={showPassword} onClick={handleClickShowPassword} />}
        />
        {formState.errors.password?.message && (
          <Typography variant="body2Regular" color="danger.500" mt={0.75}>
            {formState.errors.password.message}
          </Typography>
        )}
      </FormControl>

      <FormControl fullWidth error={!!formState.errors.passwordConfirmation} disabled={viewModel.registering.value}>
        <Typography htmlFor="form-password-confirmation" component="label" variant="formLabelMedium" color="text.primary" gutterBottom>
          Подтвердите Пароль
        </Typography>
        <VpInput
          {...register('passwordConfirmation', {
            required: 'Пожалуйста, подтвердите пароль',
            validate: value => value === password || 'Пароли не совпадают',
          })}
          autoComplete="new-password"
          endAdornment={<ViewPasswordIcon showPassword={showPassword} onClick={handleClickShowPassword} />}
          id="form-password-confirmation"
          placeholder={'Подтвердите ваш пароль'}
          type={passwordInputType}
        />
        {formState.errors.passwordConfirmation?.message && (
          <Typography variant="body2Regular" color="danger.500" mt={0.75}>
            {formState.errors.passwordConfirmation.message}
          </Typography>
        )}
      </FormControl>

      <FormControl fullWidth error={!!formState.errors.termsAndCondition} disabled={viewModel.registering.value}>
        <Stack width={1} direction="row" gap={1.25} alignItems="flex-start">
          <VpCheckbox
            id="form-terms-and-conditions"
            registerReturn={register('termsAndCondition', { required: 'Для регистрации необходимо ваше согласие' })}
            sx={{ p: 0, width: 20, height: 20, mt: 0.5 }}
          />

          <Typography variant="body2Regular" color="text.disabled">
            Я соглашаюсь с{' '}
            <Typography
              component="a"
              {...{ href: 'https://впамяти.рф/agreement', target: '_blank' }}
              variant="body2Regular"
              sx={{ textDecoration: 'underline', textDecorationSkipInk: 'none' }}
            >
              условиями использования
            </Typography>{' '}
            и{' '}
            <Typography
              component="a"
              {...{ href: 'https://впамяти.рф/privacy', target: '_blank' }}
              variant="body2Regular"
              sx={{ textDecoration: 'underline', textDecorationSkipInk: 'none' }}
            >
              политикой конфиденциальности
            </Typography>
            .
          </Typography>
        </Stack>

        {formState.errors.termsAndCondition?.message && (
          <Typography variant="body2Regular" color="danger.500" mt={0.75}>
            {formState.errors.termsAndCondition.message}
          </Typography>
        )}
      </FormControl>

      <FormControl fullWidth error={!!formState.errors.rights} disabled={viewModel.registering.value}>
        <Stack width={1} direction="row" gap={1.25} alignItems="flex-start">
          <VpCheckbox
            id="form-rights"
            registerReturn={register('rights', { required: 'Для регистрации необходимо подтверждение' })}
            sx={{ p: 0, width: 20, height: 20, mt: 0.5 }}
          />

          <Typography variant="body2Regular" color="text.disabled">
            Пользователь подтверждает, что обладает всеми необходимыми правами на размещение фото-, видео-, аудио- и текстовых материалов в
            создаваемом профиле и несёт полную ответственность за их размещение.
          </Typography>
        </Stack>

        {formState.errors.rights?.message && (
          <Typography variant="body2Regular" color="danger.500" mt={0.75}>
            {formState.errors.rights.message}
          </Typography>
        )}
      </FormControl>

      <Stack sx={{ position: 'sticky', bottom: 0, width: 1, gap: 2, pb: 2, alignItems: 'center', backgroundColor: 'background.300' }}>
        <VpFancyButton text="Зарегистрироваться" type="submit" disabled={!formState.isValid} loading={viewModel.registering.value} />
        <RegisterLoginNavigation />
      </Stack>
    </Stack>
  );
};
