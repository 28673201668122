import { ReadonlySignal, Signal, signal } from '@preact/signals-react';
import { injectable } from 'inversify';

import { Failure, Success } from '@vp/common/core/OperationResult';
import { ViewModel, ViewModelDispose, ViewModelInit } from '@vp/common/ui/ViewModel';
import { SumbitEventsDto } from '@vp/manager/events/core/dto/SumbitEventsDto';
import { EventsManagerPort } from '@vp/manager/events/core/interface/EventsManagerPort';
import { EventsManagerStatus } from '@vp/manager/events/core/interface/EventsManagerState';
import { EventsManagerRecordModel } from '@vp/manager/events/core/model/EventsManagerRecordModel';
import { EventsDiff, EventsManagerDiffer } from '@vp/manager/events/ui/EventsManagerDiffer';
import { EventsFormValues } from '@vp/manager/events/ui/EventsManagerPage';
import { ProfileManagerPort } from '@vp/manager/profile/core/interface/ProfileManagerPort';
import { AppNotificationService } from '@vp/notification/AppNotificationService';

@injectable()
export class EventsManagerViewModel extends ViewModel implements ViewModelInit, ViewModelDispose {
  static readonly EmptyEvent: EventsManagerRecordModel = {
    id: '',
    date: '',
    image: { url: '', hash: '', file: null, id: '' },
    description: '',
  };

  readonly events: ReadonlySignal<EventsManagerRecordModel[]> = this.eventsManagerPort.events;
  readonly recordsStatus: ReadonlySignal<EventsManagerStatus> = this.eventsManagerPort.status;
  readonly submitting: Signal<boolean> = signal(false);

  private controller?: AbortController;

  constructor(
    private readonly eventsManagerPort: EventsManagerPort,
    private readonly profileManagerPort: ProfileManagerPort,
    private readonly notificationService: AppNotificationService,
    private readonly eventsManagerDiffer: EventsManagerDiffer,
  ) {
    super();
  }

  async init(): Promise<void> {
    const profileId = this.profileManagerPort.active.value!.id;
    this.controller = new AbortController();
    const result = await this.eventsManagerPort.loadEvents(profileId, this.controller);

    if (result instanceof Failure) {
      this.showErrorNotification(result.message);
    }
  }

  dispose(): void {
    this.controller?.abort();
    this.eventsManagerPort.resetState();
  }

  submitForm = async (data: EventsFormValues): Promise<void> => {
    const diff = this.eventsManagerDiffer.getDiff(data.events, this.events.value);
    const dto = this.toDto(diff);
    await this.submit(dto);
  };

  private async submit(dto: SumbitEventsDto): Promise<void> {
    this.setSubmitting(true);
    this.controller = new AbortController();
    const result = await this.eventsManagerPort.submitEvents(dto, this.controller);

    if (result instanceof Success) {
      this.showSuccessNotification();
      this.cleanupImages(dto);
    } else {
      this.showErrorNotification();
    }
    this.setSubmitting(false);
  }

  private toDto(diff: EventsDiff): SumbitEventsDto {
    const profileId = this.profileManagerPort.active.value!.id;
    const idsToDelete = diff.deleted.map(({ id }) => id);
    return new SumbitEventsDto({ toCreate: diff.created, toUpdate: diff.updated, toDelete: idsToDelete, profileId });
  }

  private setSubmitting(isSubmitting: boolean): void {
    this.submitting.value = isSubmitting;
  }

  private cleanupImages(dto: SumbitEventsDto): void {
    dto.toUpdate.forEach(event => event.image.url && URL.revokeObjectURL(event.image.url));
  }

  private showSuccessNotification(): void {
    this.notificationService.enqueue({
      variant: 'success',
      message: 'События были загружены в профиль',
      secondaryMessage: 'Вы можете отредактировать их в любой момент',
    });
  }

  private showErrorNotification(message?: string): void {
    this.notificationService.enqueue({
      variant: 'error',
      message: 'Что-то пошло не так',
      secondaryMessage: message || 'Обновите страницу и попробуйте ещё раз.',
    });
  }
}
