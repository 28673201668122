import { EngineState } from '@vp/slideshow/core/engine/state/EngineState';

export class InitialState extends EngineState {
  enter(): void {
    this.engine.totalTime = this.engine.slidesCount * this.engine.slideTime;
  }

  exit(): void {
    this.engine.startTime = Date.now();
  }

  play(): void {
    this.engine.changeState(this.engine.playingState);
  }
}
