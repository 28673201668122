import { CardMedia } from '@mui/material';
import { FC, useMemo } from 'react';

interface SlideshowImageProps {
  url: string;
  isActiveSlide: boolean;
  slideProgress: number;
}

export const SlideshowImage: FC<SlideshowImageProps> = ({ url, isActiveSlide, slideProgress }) => {
  const scale = useMemo(() => 1 + slideProgress / 2000, [slideProgress]);

  return (
    <CardMedia
      component="img"
      image={url}
      sx={{
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        willChange: 'transform',
        position: 'absolute',
        inset: 0,
        zIndex: isActiveSlide ? 0 : -1,
      }}
      style={{ transform: `scale(${scale})` }}
    />
  );
};
