import { Box, Fade } from '@mui/material';
import { FC } from 'react';

import { VpPlayerPausePlayControl } from '@vp/common/ui/player/VpPlayerPausePlayControl';
import { VpTimeSliderControl } from '@vp/common/ui/player/VpTimeSliderControl';

export interface VpPlayerControlsProps {
  playing: boolean;
  duration: number;
  progress: number;
  visible: boolean;
  onProgressChange: (progress: number) => void;
  onInteractionLock: (lock: boolean) => void;
  onPlayPauseClick: () => void;
}

export const VpPlayerControls: FC<VpPlayerControlsProps> = ({
  playing,
  duration,
  progress,
  visible,
  onProgressChange,
  onInteractionLock,
  onPlayPauseClick,
}) => {
  return (
    <Fade in={visible} timeout={500}>
      <Box
        position="absolute"
        left={0}
        top={0}
        width="100%"
        height="100%"
        zIndex={theme => theme.zIndex.mediaControls}
        sx={{ pointerEvents: 'none' }}
      >
        <VpPlayerPausePlayControl playing={playing} onPlayPauseClick={onPlayPauseClick} />
        <VpTimeSliderControl
          duration={duration}
          progress={progress}
          onProgressChange={onProgressChange}
          onInteractionLock={onInteractionLock}
        />
      </Box>
    </Fade>
  );
};
