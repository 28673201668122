import { Stack } from '@mui/material';
import { FC, Fragment } from 'react';
import { useForm } from 'react-hook-form';

import { VpFade } from '@vp/common/ui/component/VpFade';
import { useViewModel } from '@vp/common/ui/hook/useViewModel';
import { BiographyManagerBody } from '@vp/manager/biography/ui/BiographyManagerBody';
import { BiographyManagerHeader } from '@vp/manager/biography/ui/BiographyManagerHeader';
import { BiographyFormValues, BiographyManagerViewModel } from '@vp/manager/biography/ui/BiographyManagerViewModel';
import { BiographyManagerEditModal } from '@vp/manager/biography/ui/edit/BiographyManagerEditModal';

export const BiographyManagerPage: FC = () => {
  const viewModel = useViewModel(BiographyManagerViewModel);

  const methods = useForm<BiographyFormValues>({
    defaultValues: viewModel.toFormValues(viewModel.profile.value),
    mode: 'onTouched',
  });

  viewModel.bindProps({ methods });

  return (
    <Fragment>
      <VpFade>
        <Stack width={1} height="100dvh">
          <BiographyManagerHeader />

          <BiographyManagerBody
            submit={methods.handleSubmit(viewModel.submit)}
            changeAiOptionStatus={viewModel.changeAiOptionStatus}
            loading={viewModel.loading.value}
            aiOptionStatus={viewModel.aiOptionStatus.value}
            methods={methods}
          />
        </Stack>
      </VpFade>

      <BiographyManagerEditModal
        open={viewModel.editModalShown.value}
        onClose={viewModel.closeEditModal}
        onSubmit={viewModel.onSaveSuggestedBiography}
        suggestedText={viewModel.generatedBiography.value}
      />
    </Fragment>
  );
};
