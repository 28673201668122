import { ContainerModule } from 'inversify';

import { BaseModule } from '@vp/common/BaseModule';
import { HonorManagerFacade } from '@vp/manager/honor/core/HonorManagerFacade';
import { HonorManagerPort } from '@vp/manager/honor/core/interface/HonorManagerPort';
import { HonorManagerRepository } from '@vp/manager/honor/core/interface/HonorManagerRepository';
import { HonorManagerState } from '@vp/manager/honor/core/interface/HonorManagerState';
import { ApproveHonorRecordUsecase } from '@vp/manager/honor/core/usecase/ApproveHonorRecordUsecase';
import { EditHonorRecordUsecase } from '@vp/manager/honor/core/usecase/EditHonorRecordUsecase';
import { HandleSubmissionResultUsecase } from '@vp/manager/honor/core/usecase/HandleSubmissionResultUsecase';
import { LoadHonorRecordsUsecase } from '@vp/manager/honor/core/usecase/LoadHonorRecordsUsecase';
import { RemoveHonorRecordUsecase } from '@vp/manager/honor/core/usecase/RemoveHonorRecordUsecase';
import { RestHonorManagerRepository } from '@vp/manager/honor/data/RestHonorManagerRepository';
import { SignalsHonorManagerState } from '@vp/manager/honor/data/SignalsHonorManagerState';
import { HonorManagerNavigator } from '@vp/manager/honor/ui/HonorManagerNavigator';
import { HonorManagerViewModel } from '@vp/manager/honor/ui/HonorManagerViewModel';

export class HonorManagerModule extends BaseModule {
  readonly module: ContainerModule = new ContainerModule(bind => {
    bind(RestHonorManagerRepository).toSelf().inSingletonScope();
    bind(SignalsHonorManagerState).toSelf().inSingletonScope();
    bind(HonorManagerFacade).toSelf().inSingletonScope();

    bind(LoadHonorRecordsUsecase).toSelf().inSingletonScope();
    bind(RemoveHonorRecordUsecase).toSelf().inSingletonScope();
    bind(HandleSubmissionResultUsecase).toSelf().inSingletonScope();
    bind(EditHonorRecordUsecase).toSelf().inSingletonScope();
    bind(ApproveHonorRecordUsecase).toSelf().inSingletonScope();

    bind(HonorManagerNavigator).toSelf().inTransientScope();
    bind(HonorManagerViewModel).toSelf().inTransientScope();

    bind(HonorManagerState)
      .toDynamicValue(context => context.container.get(SignalsHonorManagerState))
      .inSingletonScope();

    bind(HonorManagerRepository)
      .toDynamicValue(context => context.container.get(RestHonorManagerRepository))
      .inSingletonScope();

    bind(HonorManagerPort)
      .toDynamicValue(context => context.container.get(HonorManagerFacade))
      .inSingletonScope();
  });
}
