import { Axios } from 'axios';
import { injectable } from 'inversify';

import { Analytics } from '@vp/common/core/Analytics';
import { Failure, OperationResult, Success } from '@vp/common/core/OperationResult';
import { ErrorExtractor } from '@vp/common/data/ErrorExtractor';
import { RemoveMediaDto } from '@vp/manager/gallery/core/dto/RemoveMediaDto';
import { UploadMediaDto } from '@vp/manager/gallery/core/dto/UploadMediaDto';
import { GalleryManagerRepository } from '@vp/manager/gallery/core/interface/GalleryManagerRepository';
import { GalleryManagerUploadHandle } from '@vp/manager/gallery/core/interface/GalleryManagerUploadHandle';
import { GalleryManagerDataMapper } from '@vp/manager/gallery/data/GalleryManagerDataMapper';
import { UploadStateMachine } from '@vp/manager/gallery/data/upload/UploadStateMachine';
import { ProfileModel } from '@vp/profile/core/model/ProfileModel';
import { ProfileResponseDto } from '@vp/profile/data/dto/ProfileResponseDto';
import { ProfileDataMapper } from '@vp/profile/data/ProfileDataMapper';

@injectable()
export class RestGalleryManagerRepository implements GalleryManagerRepository {
  private readonly ChunkSize: number = 5 * 1024 * 1024;

  constructor(
    private readonly http: Axios,
    private readonly profileDataMapper: ProfileDataMapper,
    private readonly galleryManagerMapper: GalleryManagerDataMapper,
    private readonly errorExtractor: ErrorExtractor,
    private readonly analytics: Analytics,
  ) {}

  upload(dto: UploadMediaDto): GalleryManagerUploadHandle {
    const context = this.galleryManagerMapper.toUploadStateMachineContext(dto, this.ChunkSize);

    const machine = new UploadStateMachine(context, this.http, this.errorExtractor, this.galleryManagerMapper, this.analytics);
    machine.start();

    return machine;
  }

  async remove(dto: RemoveMediaDto, controller?: AbortController): Promise<OperationResult<ProfileModel>> {
    try {
      const formData = this.galleryManagerMapper.toRemoveFormData(dto);

      const { data } = await this.http.put<ProfileResponseDto>(`/api/profiles/${dto.profileId}/upload`, formData, {
        signal: controller?.signal,
      });

      return Success.from(this.profileDataMapper.toProfile(data));
    } catch (error) {
      this.analytics.trackError(error);
      const message = this.errorExtractor.extract(error);
      return Failure.from(message);
    }
  }
}
