import { FC } from 'react';

import { VpSnackbarLegacy } from '@vp/common/ui/component/VpSnackbarLegacy';

export interface RecordSubmitNotificationProps {
  status: 'success' | 'error' | 'info';
  close: () => void;
  open: boolean;
}

const NotificationText = {
  success: {
    primary: 'Ваша запись была отправлена',
    secondary: 'Если администратор одобрит запись, она появятся на вкладке “Почести”',
  },
  error: {
    primary: 'Что-то пошло не так.',
    secondary: 'Обновите страницу и попробуйте ещё раз.',
  },
  info: {
    primary: '',
    secondary: '',
  },
};

export const RecordSubmitNotification: FC<RecordSubmitNotificationProps> = ({ close, status, open }) => {
  const { primary, secondary } = NotificationText[status];

  return (
    <VpSnackbarLegacy
      open={open}
      autoHideDuration={5000}
      close={close}
      status={status}
      primaryMessage={primary}
      secondaryMessage={secondary}
    />
  );
};
