import { computed, ReadonlySignal, signal, Signal } from '@preact/signals-react';
import { injectable } from 'inversify';

import { Success } from '@vp/common/core/OperationResult';
import { ViewModel, ViewModelDispose } from '@vp/common/ui/ViewModel';
import { ThemingManagerPort } from '@vp/manager/theming/core/interface/ThemingManagerPort';
import { AppNotificationService } from '@vp/notification/AppNotificationService';
import { ProfileThemeModel } from '@vp/profile/core/model/ProfileThemeModel';

type ModeSwitcher = Record<ProfileThemeModel['mode'], ProfileThemeModel['mode']>;

@injectable()
export class ThemeManagerViewModel extends ViewModel implements ViewModelDispose {
  readonly theme: Signal<ProfileThemeModel> = signal(this.themingManagerPort.theme.value);
  readonly dirty: ReadonlySignal<boolean> = computed(() => this.checkDirty());
  readonly loading: Signal<boolean> = signal(false);

  private readonly modeSwitcher: ModeSwitcher = { dark: 'light', light: 'dark' };
  private controller?: AbortController;

  constructor(
    private readonly themingManagerPort: ThemingManagerPort,
    private readonly notificationService: AppNotificationService,
  ) {
    super();
  }

  dispose(): void {
    this.controller?.abort();
  }

  cancel = (): void => {
    this.controller?.abort();
    this.theme.value = this.themingManagerPort.theme.value;
    this.loading.value = false;
  };

  save = async (): Promise<void> => {
    if (!this.dirty.value) return;

    this.controller = new AbortController();
    this.loading.value = true;
    const result = await this.themingManagerPort.updateTheme(this.theme.value, this.controller);

    if (result instanceof Success) {
      this.showSuccessNotification();
    } else {
      this.showErrorNotification(result.message);
    }

    this.loading.value = false;
  };

  switchTheme = (): void => {
    const mode = this.modeSwitcher[this.theme.value.mode];
    this.theme.value = { ...this.theme.value, mode };
  };

  private checkDirty(): boolean {
    const current = this.theme.value;
    const persisted = this.themingManagerPort.theme.value;
    return current.mode !== persisted.mode || current.color !== persisted.color;
  }

  private showSuccessNotification(): void {
    this.notificationService.enqueue({
      variant: 'success',
      message: 'Тема профиля была успешно применена',
    });
  }

  private showErrorNotification(message: string): void {
    this.notificationService.enqueue({
      variant: 'error',
      message: 'Что-то пошло не так',
      secondaryMessage: message || 'Не удалось применить тему',
    });
  }
}
