import { injectable } from 'inversify';

import { ProfileManagerState } from '@vp/manager/profile/core/interface/ProfileManagerState';

import { AvatarManagerRepository } from '../interfaces/AvatarManagerRepository';

@injectable()
export class UploadAvatarUsecase {
  constructor(
    private readonly profileManagerState: ProfileManagerState,
    private readonly avatarManagerRepository: AvatarManagerRepository,
  ) { }

  async execute(profileId: string, avatarFile: File, controller?: AbortController): Promise<boolean> {
    try {
      const profile = await this.avatarManagerRepository.upload(profileId, avatarFile, controller);
      this.profileManagerState.update(profile);
      return true;
    } catch {
      return false;
    }
  }
}
