import { Stack, Typography } from '@mui/material';
import { FC, Fragment } from 'react';

export const BiographyManagerFormText: FC = () => {
  return (
    <Fragment>
      <Typography variant="h5">Что можно написать в описании</Typography>
      <Typography variant="body2Regular" color="text.disabled">
        В описании можно охватить несколько важных аспектов жизни человека, которые помогут сделать текст более полным и глубоким:
      </Typography>
      <Stack>
        <Typography variant="body2Regular" color="text.disabled">
          1. Ранние годы: Расскажите о детстве, месте рождения, семье и важных событиях, которые сформировали личность.
        </Typography>
        <Typography variant="body2Regular" color="text.disabled">
          2. Образование: Укажите учебные заведения, курсы, увлечения в школьные или студенческие годы, а также полученные награды или
          дипломы.
        </Typography>
        <Typography variant="body2Regular" color="text.disabled">
          3. Карьера: Описание профессионального пути, достижения на работе, влияние на коллег или отрасль, важные проекты и роли.
        </Typography>
        <Typography variant="body2Regular" color="text.disabled">
          4. Личная жизнь: Поделитесь значимыми моментами личной жизни, такими как семья, дружба, интересы, хобби.
        </Typography>
        <Typography variant="body2Regular" color="text.disabled">
          5. Социальная деятельность: Обратите внимание на общественную работу, участие в благотворительных проектах, волонтёрскую
          деятельность.
        </Typography>
        <Typography variant="body2Regular" color="text.disabled">
          6. Наследие и память: Напишите, что человек оставил после себя: его вклад, достижения, память, которую о нём сохранили.
        </Typography>
        <Typography variant="body2Regular" color="text.disabled">
          7. Личностные качества: Опишите черты характера, что его выделяло среди других, его ценности и принципы.
        </Typography>
      </Stack>
      <Typography variant="body2Regular" color="text.disabled">
        После того, как вы напишете текст, вы можете воспользоваться ИИ для проверки орфографии и грамматики. ИИ также может помочь вам
        дополнить описание важными деталями.
      </Typography>
    </Fragment>
  );
};
