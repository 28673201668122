import { injectable } from 'inversify';

import { HonorSubmitPort } from '@vp/honor/core/interface/HonorSubmitPort';
import { HonorRecordModel } from '@vp/honor/core/model/HonorRecordModel';
import { SubmitHonorRecordUsecase } from '@vp/honor/core/usecase/SubmitHonorRecordUsecase';
import { ProfileHonorRecordModel } from '@vp/profile/core/model/ProfileHonorRecordModel';

@injectable()
export class HonorSubmitFacade implements HonorSubmitPort {
  constructor(private submitHonorRecordUsecase: SubmitHonorRecordUsecase) {}

  async submitHonorRecord(record: HonorRecordModel, profileId: string, controller?: AbortController): Promise<ProfileHonorRecordModel> {
    return await this.submitHonorRecordUsecase.execute(record, profileId, controller);
  }
}
