import { injectable } from 'inversify';

@injectable()
export class TimeAgoFormatter {
  getTimeAgo(date: Date): string {
    const timestamp = date.getTime();
    const now = Date.now();
    const secondsPast = Math.floor((now - timestamp) / 1000);

    if (secondsPast < 60) {
      return `только что`;
    }
    if (secondsPast < 3600) {
      const minutes = Math.floor(secondsPast / 60);
      return `${minutes} ${this.getRussianNumeralForm(minutes, 'минуту', 'минуты', 'минут')} назад`;
    }
    if (secondsPast < 86400) {
      const hours = Math.floor(secondsPast / 3600);
      return `${hours} ${this.getRussianNumeralForm(hours, 'час', 'часа', 'часов')} назад`;
    }
    if (secondsPast < 604800) {
      const days = Math.floor(secondsPast / 86400);
      return `${days} ${this.getRussianNumeralForm(days, 'день', 'дня', 'дней')} назад`;
    }
    if (secondsPast < 2592000) {
      const days = Math.floor(secondsPast / 86400);
      return `${days} ${this.getRussianNumeralForm(days, 'день', 'дня', 'дней')} назад`;
    }
    if (secondsPast < 31536000) {
      const months = Math.floor(secondsPast / 2592000);
      return `${months} ${this.getRussianNumeralForm(months, 'месяц', 'месяца', 'месяцев')} назад`;
    }

    const years = Math.floor(secondsPast / 31536000);
    return `${years} ${this.getRussianNumeralForm(years, 'год', 'года', 'лет')} назад`;
  }

  private getRussianNumeralForm(number: number, singleForm: string, fewForm: string, pluralForm: string): string {
    const lastTwoDigits = Math.abs(number) % 100;
    const lastDigit = lastTwoDigits % 10;

    if (lastTwoDigits > 10 && lastTwoDigits < 20) {
      return pluralForm;
    }
    if (lastDigit > 1 && lastDigit < 5) {
      return fewForm;
    }
    if (lastDigit == 1) {
      return singleForm;
    }

    return pluralForm;
  }
}
