import { FC } from 'react';

export enum AppMenuItemId {
  Support,
  Auth,
  Profiles,
}

export interface AppMenuItemModel {
  id: AppMenuItemId;
  text: string;
  Icon: FC;
}
