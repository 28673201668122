import { injectable } from 'inversify';

import { TokensStorage } from '@vp/auth/core/interface/TokensStorage';
import { TokensModel } from '@vp/auth/core/model/TokensModel';

@injectable()
export class LocalTokensStorage implements TokensStorage {
  private readonly accessTokenKey: string = 'accessToken';
  private readonly refreshTokenKey: string = 'refreshToken';
  private readonly expiresInKey: string = 'expiresIn';

  async store(tokens: TokensModel): Promise<void> {
    localStorage.setItem(this.accessTokenKey, tokens.access);
    localStorage.setItem(this.refreshTokenKey, tokens.refresh);
    localStorage.setItem(this.expiresInKey, tokens.expiresIn.toString());
  }

  async retrieve(): Promise<TokensModel | null> {
    const access = localStorage.getItem(this.accessTokenKey);
    const refresh = localStorage.getItem(this.refreshTokenKey);
    const expiresIn = localStorage.getItem(this.expiresInKey);

    if (!access || !refresh || !expiresIn) {
      return null;
    }

    return new TokensModel(access, refresh, parseInt(expiresIn, 10));
  }

  async clear(): Promise<void> {
    localStorage.removeItem(this.accessTokenKey);
    localStorage.removeItem(this.refreshTokenKey);
    localStorage.removeItem(this.expiresInKey);
  }
}
