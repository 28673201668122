import { ContainerModule } from 'inversify';

import { BaseModule } from '@vp/common/BaseModule';
import { ProfilePort } from '@vp/profile/core/interface/ProfilePort';
import { ProfileRepository } from '@vp/profile/core/interface/ProfileRepository';
import { ProfileState } from '@vp/profile/core/interface/ProfileState';
import { ProfileFacade } from '@vp/profile/core/ProfileFacade';
import { ActivateProfileUsecase } from '@vp/profile/core/usecase/ActivateProfileUsecase';
import { LoadProfileUsecase } from '@vp/profile/core/usecase/LoadProfileUsecase';
import { ProfileDataMapper } from '@vp/profile/data/ProfileDataMapper';
import { RestProfileRepository } from '@vp/profile/data/RestProfileRepository';
import { SignalsProfileState } from '@vp/profile/data/SignalsProfileState';
import { ProfileActivationViewModel } from '@vp/profile/ui/activation/ProfileActivationViewModel';
import { MediaItemsMapper } from '@vp/profile/ui/gallery/MediaItemsMapper';
import { ProfileGalleryViewModel } from '@vp/profile/ui/gallery/ProfileGalleryViewModel';
import { ProfileGatewayViewModel } from '@vp/profile/ui/gateway/ProfileGatewayViewModel';
import { ProfilePageHeaderViewModel } from '@vp/profile/ui/header/ProfilePageHeaderViewModel';
import { ProfileViewModel } from '@vp/profile/ui/ProfileViewModel';

export class ProfileModule extends BaseModule {
  readonly module: ContainerModule = new ContainerModule(bind => {
    bind(LoadProfileUsecase).toSelf().inSingletonScope();
    bind(ProfileFacade).toSelf().inSingletonScope();
    bind(RestProfileRepository).toSelf().inSingletonScope();
    bind(ProfileDataMapper).toSelf().inSingletonScope();
    bind(SignalsProfileState).toSelf().inSingletonScope();
    bind(ActivateProfileUsecase).toSelf().inSingletonScope();

    bind(MediaItemsMapper).toSelf().inSingletonScope();
    bind(ProfileViewModel).toSelf().inTransientScope();
    bind(ProfilePageHeaderViewModel).toSelf().inTransientScope();
    bind(ProfileGalleryViewModel).toSelf().inTransientScope();
    bind(ProfileGatewayViewModel).toSelf().inTransientScope();
    bind(ProfileActivationViewModel).toSelf().inTransientScope();

    bind(ProfileState)
      .toDynamicValue(context => context.container.get(SignalsProfileState))
      .inSingletonScope();

    bind(ProfileRepository)
      .toDynamicValue(context => context.container.get(RestProfileRepository))
      .inSingletonScope();

    bind(ProfilePort)
      .toDynamicValue(context => context.container.get(ProfileFacade))
      .inSingletonScope();
  });
}
