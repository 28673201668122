import { PaletteOptions } from '@mui/material/styles/createPalette';

export const paletteDark: PaletteOptions = {
  mode: 'dark',
  primary: {
    400: 'rgba(245, 245, 245, 1)',
    500: 'rgba(237, 237, 237, 1)',
    600: 'rgba(217, 217, 217, 1)',
    700: 'rgba(181, 181, 181, 1)',
    alpha8: 'rgba(237, 237, 237, 0.08)',
    alpha16: 'rgba(237, 237, 237, 0.16)',
    alpha24: 'rgba(237, 237, 237, 0.24)',
    alpha32: 'rgba(237, 237, 237, 0.32)',
  },
  transparency: {
    alpha8: 'rgba(255, 255, 255, 0.08)',
    alpha16: 'rgba(255, 255, 255, 0.16)',
    alpha24: 'rgba(255, 255, 255, 0.24)',
    alpha32: 'rgba(255, 255, 255, 0.32)',
    alpha48: 'rgba(255, 255, 255, 0.48)',
  },
  background: {
    100: 'rgba(46, 46, 46, 1)',
    200: 'rgba(30, 30, 30, 1)',
    300: 'rgba(18, 18, 18, 1)',
    default: 'rgba(18, 18, 18, 1)',
  },
  text: {
    primary: 'rgba(255, 255, 255, 1)',
    secondary: 'rgba(229, 229, 229, 1)',
    disabled: 'rgba(159, 159, 159, 1)',
    secondaryDisabled: 'rgba(124, 124, 124, 1)',
    5: 'rgba(78, 78, 78, 1)',
    6: 'rgba(18, 18, 18, 1)',
  },
};

export const paletteLight: PaletteOptions = {
  mode: 'light',
  primary: {
    400: 'rgba(30, 30, 30, 1)',
    500: 'rgba(18, 18, 18, 1)',
    600: 'rgba(19, 19, 19, 1)',
    700: 'rgba(0, 0, 0, 1)',
    alpha8: 'rgba(18, 18, 18, 0.08)',
    alpha16: 'rgba(18, 18, 18, 0.16)',
    alpha24: 'rgba(18, 18, 18, 0.24)',
    alpha32: 'rgba(18, 18, 18, 0.32)',
  },
  background: {
    100: 'rgba(249, 246, 242, 1)',
    200: 'rgba(243, 240, 235, 1)',
    300: 'rgba(236, 231, 226, 1)',
    default: 'rgba(236, 231, 226, 1)',
  },
  text: {
    primary: 'rgba(18, 18, 18, 1)',
    secondary: 'rgba(78, 78, 78, 1)',
    disabled: 'rgba(78, 78, 78, 1)',
    secondaryDisabled: 'rgba(159, 159, 159, 1)',
    5: 'rgba(229, 229, 299, 1)',
    6: 'rgba(255, 255, 255, 1)',
  },
  transparency: {
    alpha8: 'rgba(18, 18, 18, 0.08)',
    alpha16: 'rgba(18, 18, 18, 0.16)',
    alpha24: 'rgba(18, 18, 18, 0.24)',
    alpha32: 'rgba(18, 18, 18, 0.32)',
    alpha48: 'rgba(18, 18, 18, 0.48)',
  },
};

export const systemPalette: PaletteOptions = {
  secondary: {
    main: 'rgba(158, 141, 184, 1)',
    400: 'rgba(191, 175, 212, 1)',
    500: 'rgba(158, 141, 184, 1)',
    600: 'rgba(122, 103, 158, 1)',
    700: 'rgba(90, 71, 132, 1)',
    alpha8: 'rgba(158, 141, 184, 0.08)',
    alpha16: 'rgba(158, 141, 184, 0.16)',
    alpha24: 'rgba(158, 141, 184, 0.24)',
    alpha32: 'rgba(158, 141, 184, 0.32)',
  },
  info: {
    400: 'rgba(152, 199, 255, 1)',
    500: 'rgba(126, 180, 255, 1)',
    600: 'rgba(84, 149, 255, 1)',
    700: 'rgba(61, 115, 219, 1)',
    alpha8: 'rgba(126, 180, 255, 0.08)',
    alpha16: 'rgba(126, 180, 255, 0.16)',
    alpha24: 'rgba(126, 180, 255, 0.24)',
    alpha32: 'rgba(126, 180, 255, 0.32)',
  },
  success: {
    400: 'rgba(188, 228, 121, 1)',
    500: 'rgba(156, 211, 79, 1)',
    600: 'rgba(124, 181, 57, 1)',
    700: 'rgba(95, 151, 39, 1)',
    alpha8: 'rgba(156, 211, 79, 0.08)',
    alpha16: 'rgba(156, 211, 79, 0.16)',
    alpha24: 'rgba(156, 211, 79, 0.24)',
    alpha32: 'rgba(156, 211, 79, 0.32)',
  },
  warning: {
    400: 'rgba(234, 212, 128, 1)',
    500: 'rgba(221, 190, 88, 1)',
    600: 'rgba(190, 157, 64, 1)',
    700: 'rgba(159, 126, 44, 1)',
    alpha8: 'rgba(221, 190, 88, 0.08)',
    alpha16: 'rgba(221, 190, 88, 0.16)',
    alpha24: 'rgba(221, 190, 88, 0.24)',
    alpha32: 'rgba(221, 190, 88, 0.32)',
  },
  danger: {
    400: 'rgba(239, 138, 140, 1)',
    500: 'rgba(223, 106, 119, 1)',
    600: 'rgba(202, 61, 90, 1)',
    700: 'rgba(173, 44, 83, 1)',
    alpha8: 'rgba(223, 106, 119, 0.08)',
    alpha16: 'rgba(223, 106, 119, 0.16)',
    alpha24: 'rgba(223, 106, 119, 0.24)',
    alpha32: 'rgba(223, 106, 119, 0.32)',
  },
  basic: {
    alpha8: 'rgba(18, 18, 18, 0.08)',
    alpha16: 'rgba(18, 18, 18, 0.16)',
    alpha24: 'rgba(18, 18, 18, 0.24)',
    alpha32: 'rgba(18, 18, 18, 0.32)',
    alpha48: 'rgba(18, 18, 18, 0.48)',
  },
  ...paletteDark,
};

export const primaryColorCssProperty = `
  @property --vp-primary-color {
    syntax: '<color>';
    inherits: true;
    initial-value: transparent;
  }
`;
