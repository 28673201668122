import { FC } from 'react';
import { RouterProvider } from 'react-router-dom';

import { AuthRouter } from '@vp/auth/AuthRouter';
import { VpFallback } from '@vp/common/ui/component/VpFallback';
import { LandingRouter } from '@vp/landing/LandingRouter';
import { ManagerRouter } from '@vp/manager/ManagerRouter';
import { ProfileRouter } from '@vp/profile/ProfileRouter';
import { createRouter } from '@vp/routing/createRouter';

export const Router = createRouter([
  LandingRouter,
  ProfileRouter,
  AuthRouter,
  ManagerRouter,
  { path: '*', element: <VpFallback title="Страница не найдена" /> },
]);

export const AppRouter: FC = () => <RouterProvider router={Router} />;
