import { ContainerModule } from 'inversify';

import { BaseModule } from '@vp/common/BaseModule';
import { AppMenuFacade } from '@vp/menu/core/AppMenuFacade';
import { AppMenuService } from '@vp/menu/core/interface/AppMenuService';
import { AppMenuState } from '@vp/menu/core/interface/AppMenuState';
import { SignalsAppMenuState } from '@vp/menu/data/SignalsAppMenuState';
import { AppMenuViewModel } from '@vp/menu/ui/AppMenuViewModel';
import { AuthHandler } from '@vp/menu/ui/handler/AuthHandler';
import { HandlerRegistry } from '@vp/menu/ui/handler/HandlerRegistry';
import { SupportHandler } from '@vp/menu/ui/handler/SupportHandler';
import { AppMenuProfilesViewModel } from '@vp/menu/ui/profiles/AppMenuProfilesViewModel';

export class AppMenuModule extends BaseModule {
  readonly module: ContainerModule = new ContainerModule(bind => {
    bind(SignalsAppMenuState).toSelf().inSingletonScope();

    bind(AuthHandler).toSelf().inSingletonScope();
    bind(SupportHandler).toSelf().inSingletonScope();
    bind(HandlerRegistry).toSelf().inSingletonScope();

    bind(AppMenuState)
      .toDynamicValue(context => context.container.get(SignalsAppMenuState))
      .inSingletonScope();

    bind(AppMenuFacade).toSelf().inSingletonScope();

    bind(AppMenuService)
      .toDynamicValue(context => context.container.get(AppMenuFacade))
      .inSingletonScope();

    bind(AppMenuViewModel).toSelf().inTransientScope();
    bind(AppMenuProfilesViewModel).toSelf().inTransientScope();
  });
}
