import { Stack } from '@mui/material';
import { FC, memo } from 'react';

import DemoEventImage from '@vp/assets/demo/DemoEvent.jpg';
import { ProfileEventModel } from '@vp/profile/core/model/ProfileEventModel';
import { ProfileEventDescription } from '@vp/profile/ui/events/ProfileEventDescription';
import { ProfileEventImage } from '@vp/profile/ui/events/ProfileEventImage';

const Event: ProfileEventModel = {
  id: '1',
  date: '02.09.1934',
  image: DemoEventImage,
  hash: 'LPEV+:D%IUj[00t7oft7xuj[ofRj',
  description:
    'В этот день родилась Елена, человек с большим сердцем и мудрой душой. С самого детства она отличалась добротой и заботой о близких, что стало основой её жизненного пути.',
};

export const ProfileExampleEvents: FC = memo(() => {
  return (
    <Stack direction="row" width={1} position="relative">
      <ProfileEventImage event={Event} sx={{ m: 1, mr: 5 }} />
      <ProfileEventDescription event={Event} />
    </Stack>
  );
});

ProfileExampleEvents.displayName = ProfileExampleEvents.name;
