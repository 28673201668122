import { FC } from 'react';

import { VpSnackbarLegacy } from '@vp/common/ui/component/VpSnackbarLegacy';
import { Action } from '@vp/manager/honor/ui/HonorManagerViewModel';
import { ProfileHonorNotificationStatus } from '@vp/profile/ui/honor/ProfileHonor';

export interface HonorManagerNotificationProps {
  status: ProfileHonorNotificationStatus;
  close: () => void;
  open: boolean;
  action: Action;
}

interface NotificationMessage {
  primary: string;
  secondary: string;
}

interface NotificationStatusMessages {
  success: NotificationMessage;
  error: NotificationMessage;
  info: NotificationMessage;
}

const EditNotificationText: NotificationStatusMessages = {
  success: {
    primary: 'Почесть была отредактирована',
    secondary: 'Вы можете изменить её в любой момент',
  },
  error: {
    primary: 'Что-то пошло не так.',
    secondary: 'Обновите страницу и попробуйте ещё раз.',
  },
  info: {
    primary: '',
    secondary: '',
  },
};

const ApproveNotificationText: NotificationStatusMessages = {
  success: {
    primary: 'Почесть была одобрена',
    secondary: 'Вы можете найти её во вкладке Одобренные',
  },
  error: {
    primary: 'Что-то пошло не так.',
    secondary: 'Обновите страницу и попробуйте ещё раз.',
  },
  info: {
    primary: '',
    secondary: '',
  },
};

const RemoveNotificationText: NotificationStatusMessages = {
  success: {
    primary: 'Почесть была удалена',
    secondary: 'Это действие нельзя отменить',
  },
  error: {
    primary: 'Что-то пошло не так.',
    secondary: 'Обновите страницу и попробуйте ещё раз.',
  },
  info: {
    primary: '',
    secondary: '',
  },
};

const SubmitNotificationText: NotificationStatusMessages = {
  success: {
    primary: 'Ваша запись была отправлена',
    secondary: 'Вы можете найти её во вкладке Одобренные',
  },
  error: {
    primary: 'Что-то пошло не так.',
    secondary: 'Обновите страницу и попробуйте ещё раз.',
  },
  info: {
    primary: '',
    secondary: '',
  },
};

const NotificationText: Record<Action, NotificationStatusMessages> = {
  edit: EditNotificationText,
  approve: ApproveNotificationText,
  remove: RemoveNotificationText,
  submit: SubmitNotificationText,
};

export const HonorManagerNotification: FC<HonorManagerNotificationProps> = ({ close, status, open, action }) => {
  const { primary, secondary } = NotificationText[action][status];

  return (
    <VpSnackbarLegacy
      key={action}
      open={open}
      autoHideDuration={5000}
      close={close}
      status={status}
      primaryMessage={primary}
      secondaryMessage={secondary}
    />
  );
};
