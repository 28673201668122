import { Box, SvgIcon, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC, Fragment } from 'react';

export type VpIconProps = Omit<Parameters<typeof SvgIcon>[0], 'size' | 'color'> & {
  Icon: FC;
  size: number;
  color?: string;
  glowing?: boolean;
  sx?: SxProps<Theme>;
};

export const VpIcon: FC<VpIconProps> = ({ Icon, sx, size, color, glowing = false, ...props }) => {
  return (
    <Box position="relative" width={size} height={size} sx={sx}>
      {glowing && (
        <Fragment>
          <SvgIcon sx={{ fontSize: size, color, filter: 'blur(10px)', willChange: 'opacity' }}>
            <Icon />
          </SvgIcon>

          <SvgIcon sx={{ fontSize: size, color, position: 'absolute', inset: 0 }} {...props}>
            <Icon />
          </SvgIcon>
        </Fragment>
      )}

      {!glowing && (
        <SvgIcon sx={{ fontSize: size, color }} {...props}>
          <Icon />
        </SvgIcon>
      )}
    </Box>
  );
};
