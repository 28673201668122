import { injectable } from 'inversify';
import { createBrowserRouter, matchPath } from 'react-router-dom';

import { RouterState, RouterSubscribers, Subscriber, Unsubscribe } from '@vp/routing/RouterSubscribers';

type Router = ReturnType<typeof createBrowserRouter>;

export abstract class RouterService {
  abstract getParams(pattern: string): Record<string, string>;
  abstract match(path: string): boolean;
  abstract isOn(pattern: string): boolean;
  abstract subscribe(subscriber: Subscriber): Unsubscribe;
  abstract notifySubscribers(): void;
  abstract navigate(...args: Parameters<Router['navigate']>): Promise<void>;
  abstract getCurrentState(): RouterState;
}

@injectable()
export class RouterServiceImpl implements RouterService {
  private readonly subscribers: RouterSubscribers;

  constructor(private readonly router: Router) {
    this.subscribers = new RouterSubscribers();
  }

  getParams(pattern: string): Record<string, string> {
    const match = matchPath(pattern, window.location.pathname);
    return (match?.params as Record<string, string>) ?? {};
  }

  match(path: string): boolean {
    const isPattern = path.includes(':');
    if (!isPattern) return window.location.pathname.includes(path);
    return !!matchPath(path, window.location.pathname);
  }

  isOn(pattern: string): boolean {
    const match = matchPath(pattern, window.location.pathname);
    return !!match;
  }

  subscribe(subscriber: Subscriber): Unsubscribe {
    return this.subscribers.add(subscriber, this.getCurrentState());
  }

  notifySubscribers(): void {
    this.subscribers.notify(this.getCurrentState());
  }

  async navigate(...args: Parameters<Router['navigate']>): Promise<void> {
    return this.router.navigate(...args);
  }

  getCurrentState(): RouterState {
    const { pathname, search, hash } = window.location;
    return { pathname, search, hash };
  }
}
