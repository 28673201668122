import { ContainerModule } from 'inversify';

import { BaseModule } from '@vp/common/BaseModule';
import { ThemingManagerPort } from '@vp/manager/theming/core/interface/ThemingManagerPort';
import { ThemingManagerRepository } from '@vp/manager/theming/core/interface/ThemingManagerRepository';
import { ThemingManagerFacade } from '@vp/manager/theming/core/ThemingManagerFacade';
import { UpdateProfileThemeUsecase } from '@vp/manager/theming/core/usecase/UpdateProfileThemeUsecase';
import { RestThemingManagerRepository } from '@vp/manager/theming/data/RestThemingManagerRepository';
import { ThemeManagerViewModel } from '@vp/manager/theming/ui/ThemeManagerViewModel';

export class ThemingManagerModule extends BaseModule {
  readonly module: ContainerModule = new ContainerModule(bind => {
    bind(ThemeManagerViewModel).toSelf().inTransientScope();

    bind(UpdateProfileThemeUsecase).toSelf().inSingletonScope();
    bind(ThemingManagerFacade).toSelf().inSingletonScope();
    bind(RestThemingManagerRepository).toSelf().inSingletonScope();

    bind(ThemingManagerRepository)
      .toDynamicValue(context => context.container.get(RestThemingManagerRepository))
      .inSingletonScope();

    bind(ThemingManagerPort)
      .toDynamicValue(context => context.container.get(ThemingManagerFacade))
      .inSingletonScope();
  });
}
