import { ContainerModule } from 'inversify';

import { BaseModule } from '@vp/common/BaseModule';
import { AvatarManagerFacade } from '@vp/manager/avatar/core/AvatarManagerFacade';
import { AvatarManagerPort } from '@vp/manager/avatar/core/interfaces/AvatarManagerPort';
import { AvatarManagerRepository } from '@vp/manager/avatar/core/interfaces/AvatarManagerRepository';
import { RemoveAvatarUsecase } from '@vp/manager/avatar/core/usecase/RemoveAvatarUsecase';
import { UploadAvatarUsecase } from '@vp/manager/avatar/core/usecase/UploadAvatarUsecase';
import { AvatarManagerDataMapper } from '@vp/manager/avatar/data/AvatarManagerDataMapper';
import { RestAvatarManagerRepository } from '@vp/manager/avatar/data/RestAvatarManagerRepository';
import { AvatarManagerViewModel } from '@vp/manager/avatar/ui/AvatarManagerViewModel';

export class AvatarManagerModule extends BaseModule {
  readonly module: ContainerModule = new ContainerModule(bind => {
    bind(AvatarManagerViewModel).toSelf().inTransientScope();
    bind(RestAvatarManagerRepository).toSelf().inSingletonScope();
    bind(AvatarManagerDataMapper).toSelf().inSingletonScope();

    bind(AvatarManagerRepository)
      .toDynamicValue(context => context.container.get(RestAvatarManagerRepository))
      .inSingletonScope();

    bind(UploadAvatarUsecase).toSelf().inSingletonScope();
    bind(RemoveAvatarUsecase).toSelf().inSingletonScope();

    bind(AvatarManagerFacade).toSelf().inSingletonScope();
    bind(AvatarManagerPort)
      .toDynamicValue(context => context.container.get(AvatarManagerFacade))
      .inSingletonScope();
  });
}
