import { injectable } from 'inversify';

import { AuthRepository } from '@vp/auth/core/interface/AuthRepository';
import { TokensStorage } from '@vp/auth/core/interface/TokensStorage';
import { TokensModel } from '@vp/auth/core/model/TokensModel';

@injectable()
export class RefreshUsecase {
  constructor(
    private readonly tokensStorage: TokensStorage,
    private readonly authRepository: AuthRepository,
  ) {}

  async execute(tokens: TokensModel, controller?: AbortController): Promise<TokensModel> {
    const refresh = await this.authRepository.refresh(tokens, controller);
    await this.tokensStorage.store(refresh);
    return refresh;
  }
}
