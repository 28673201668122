import { injectable } from 'inversify';

import { OwnerModel } from '@vp/auth/core/model/OwnerModel';
import { TokensModel } from '@vp/auth/core/model/TokensModel';
import { OwnerResponseDto } from '@vp/auth/data/dto/OwnerResponseDto';
import { TokenDto } from '@vp/auth/data/dto/TokenDto';
import { TokensResponseDto } from '@vp/auth/data/dto/TokensResponseDto';
import { JwtParser } from '@vp/common/core/JwtParser';

@injectable()
export class AuthDataMapper {
  constructor(private jwtParser: JwtParser) {}

  toOwner(dto: OwnerResponseDto): OwnerModel {
    return { email: dto.email, createdAt: dto.created_at };
  }

  toTokens(dto: TokensResponseDto): TokensModel {
    const { exp } = this.jwtParser.parse<TokenDto>(dto.access_token);
    return new TokensModel(dto.access_token, dto.refresh_token, exp);
  }

  toForgotPasswordDto(email: string): FormData {
    const data = new FormData();
    data.append('email', email);
    return data;
  }

  toRestorePasswordDto(password: string): FormData {
    const data = new FormData();
    data.append('password', password);
    return data;
  }
}
