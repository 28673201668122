import { Box, Stack, Typography, useTheme } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import { CSSProperties, FC, memo } from 'react';

import { VpImage } from '@vp/common/ui/component/VpImage';
import { useLinearGradient } from '@vp/common/ui/hook/useLinearGradient';
import { ProfileEventModel } from '@vp/profile/core/model/ProfileEventModel';

export type ProfileEventImageProps = { event: ProfileEventModel; sx?: SxProps<Theme> };

export const ProfileEventImage: FC<ProfileEventImageProps> = memo(({ event, sx }) => {
  const { palette } = useTheme();

  const gradient = useLinearGradient({
    angle: '133.62deg',
    color: 'var(--vp-primary-color)',
    stops: [
      { percent: -18.03, opacity: 1 },
      { percent: 52.31, opacity: 0.5 },
      { percent: 91.87, opacity: 0 },
    ],
  });

  return (
    <Stack
      alignSelf="flex-start"
      gap={1}
      alignItems="center"
      sx={sx}
      style={{ '--vp-primary-color': palette.primary[500] } as CSSProperties}
    >
      <Box display="flex" justifyContent="center" alignItems="center" width={84} height={84} borderRadius="50%">
        <Box
          width={70}
          height={70}
          borderRadius="50%"
          sx={theme => ({
            ...theme.applyStyles('dark', {
              transition: '--vp-primary-color 0.25s ease-in-out',
              background: gradient,
              filter: 'blur(8px)',
            }),
          })}
        />

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="absolute"
          width={84}
          height={84}
          borderRadius="50%"
          sx={{ transition: '--vp-primary-color 0.25s ease-in-out', background: gradient }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width={80}
            height={80}
            borderRadius="50%"
            sx={{ backgroundColor: 'background.300', transition: 'background-color 0.25s ease-in-out' }}
          >
            <Box width={74} height={74} borderRadius="50%" overflow="hidden">
              <VpImage src={event.image} hash={event.hash} width="100%" height="100%" />
            </Box>
          </Box>
        </Box>
      </Box>

      <Typography color="text.disabled" variant="caption1Regular" sx={{ transition: 'color 0.25s ease-in-out' }}>
        {event.date}
      </Typography>
    </Stack>
  );
});

ProfileEventImage.displayName = ProfileEventImage.name;
