import { batch, Signal, signal } from '@preact/signals-react';
import { injectable } from 'inversify';

import { SubmitEventsResultDto } from '@vp/manager/events/core/dto/SubmitEventsResultDto';
import { EventsManagerState, EventsManagerStatus } from '@vp/manager/events/core/interface/EventsManagerState';
import { EventsManagerRecordModel } from '@vp/manager/events/core/model/EventsManagerRecordModel';

@injectable()
export class SignalsEventsManagerState implements EventsManagerState {
  readonly events: Signal<EventsManagerRecordModel[]> = signal([]);
  readonly status: Signal<EventsManagerStatus> = signal('initial');

  private eventsMap: Map<string, EventsManagerRecordModel> = new Map();

  completed(status: EventsManagerStatus, events?: EventsManagerRecordModel[]): void {
    batch(() => {
      this.status.value = status;
      events && this.setEvents(events);
    });
  }

  loading(): void {
    this.status.value = 'loading';
  }

  update(dto: SubmitEventsResultDto, deletedEvents: string[]): void {
    batch(() => {
      this.deleteEvents(deletedEvents);
      this.updateEvents(dto.updatedEvents);
      this.setEvents([...this.events.value, ...dto.createdEvents]);
    });
  }

  reset(): void {
    batch(() => {
      this.setEvents([]);
      this.status.value = 'initial';
    });
  }

  private setEvents(events: EventsManagerRecordModel[]): void {
    this.eventsMap = new Map();
    this.updateEvents(events);
  }

  private updateEvents(events: EventsManagerRecordModel[]): void {
    for (const event of events) {
      this.eventsMap.set(event.id, event);
    }

    this.emitUpdate();
  }

  private deleteEvents(ids: string[]): void {
    for (const id of ids) {
      this.eventsMap.delete(id);
    }

    this.emitUpdate();
  }

  private emitUpdate(): void {
    this.events.value = [...this.eventsMap.values()];
  }
}
