import { Typography } from '@mui/material';
import { FC } from 'react';

const Text =
  'Соберите и сохраните память \nо своих близких для поколений. Проживайте заветные воспоминания \nс помощью фотографий, видео и историй при каждом сканировании наших мемориалов с QR-кодом.';

export const LandingDescription: FC = () => {
  return (
    <Typography component="p" variant="body1Regular" color="text.disabled" display="inline-block" textAlign="center" whiteSpace="pre-line">
      {Text}
    </Typography>
  );
};
