import { EngineState } from '@vp/slideshow/core/engine/state/EngineState';

export class PausedState extends EngineState {
  private pauseStartTime!: number;

  enter(): void {
    this.pauseStartTime = Date.now();
  }

  play(): void {
    this.engine.changeState(this.engine.playingState);
  }

  goTo(slide: number): void {
    this.engine.currentSlide = slide;
    this.engine.currentSlideProgress = 0;
    const updatedPlayedTime = slide * this.engine.slideTime;
    this.engine.progress = (updatedPlayedTime / this.engine.totalTime) * 100;
    this.engine.startTime -= updatedPlayedTime - (this.pauseStartTime - this.engine.startTime);
  }

  exit(): void {
    const pausedTime = Date.now() - this.pauseStartTime;
    this.engine.startTime += pausedTime;
  }
}
