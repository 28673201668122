import { Box } from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import { FC, useCallback } from 'react';

import { VpFade } from '@vp/common/ui/component/VpFade';
import { MediaGridItem, VpMediaGrid } from '@vp/common/ui/component/VpMediaGrid';
import { useSearchSection } from '@vp/common/ui/hook/useSearchSection';
import { GalleryManagerNoMedia } from '@vp/manager/gallery/ui/GalleryManagerNoMedia';
import { GalleryManagerSection } from '@vp/manager/gallery/ui/GalleryManagerSection';

export interface GalleryManagerMediaGridProps {
  photos: MediaGridItem[];
  videos: MediaGridItem[];
  onClick: (item: MediaGridItem, section: GalleryManagerSection) => void;
}

export const GalleryManagerMediaGrid: FC<GalleryManagerMediaGridProps> = ({ photos, videos, onClick }) => {
  const section = useSearchSection<GalleryManagerSection>('s', GalleryManagerSection.Photos);
  const onItemClick = useCallback((item: MediaGridItem) => onClick(item, section), [onClick, section]);

  const photosSection = section === GalleryManagerSection.Photos;
  const videosSection = section === GalleryManagerSection.Videos;

  const showPhotos = photosSection && !!photos.length;
  const showVideos = videosSection && !!videos.length;

  const noMedia = (photosSection && !photos.length) || (videosSection && !videos.length);

  return (
    <Box overflow="scroll">
      <AnimatePresence mode="wait">
        <VpFade key={section} duration={0.25}>
          {showPhotos && <VpMediaGrid items={photos} onClick={onItemClick} />}
          {showVideos && <VpMediaGrid items={videos} onClick={onItemClick} />}
          {noMedia && <GalleryManagerNoMedia />}
        </VpFade>
      </AnimatePresence>
    </Box>
  );
};
