import { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';

import { ForgotPasswordPage } from '@vp/auth/ui/forgot-password/ForgotPasswordPage';
import { LoginPage } from '@vp/auth/ui/login/LoginPage';
import { RegisterPage } from '@vp/auth/ui/register/RegisterPage';
import { RestorePasswordPage } from '@vp/auth/ui/restore-password/RestorePasswordPage';

export const AuthRouter: RouteObject = {
  path: 'auth',
  children: [
    {
      path: 'register',
      element: <RegisterPage />,
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'forgot-password',
      element: <ForgotPasswordPage />,
    },
    {
      path: 'restore-password',
      element: <RestorePasswordPage />,
    },
    {
      path: '',
      element: <Navigate replace to="login" />,
    },
  ],
};
