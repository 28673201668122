import { EventsManagerRecordModel } from '@vp/manager/events/core/model/EventsManagerRecordModel';

export type UpdateEventDto = EventsManagerRecordModel & { imageIdToRemove: string | null };

export class SumbitEventsDto {
  readonly toUpdate!: UpdateEventDto[];
  readonly toCreate!: EventsManagerRecordModel[];
  readonly toDelete!: string[];
  readonly profileId!: string;

  constructor(args: SumbitEventsDto) {
    Object.assign(this, args);
  }
}
