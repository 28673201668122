import { debounce } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

export interface ControlsVisibilityState {
  controlsVisible: boolean;
  heartbeat: () => void;
  setInteractionLock: (lock: boolean) => void;
  toggleControlsVisibility: () => void;
}

export function useControlsVisible(): ControlsVisibilityState {
  const [controlsVisible, setControlsVisible] = useState(true);
  const [interactionLock, setInteractionLock] = useState(false);

  const heartbeat = useCallback(() => setControlsVisible(true), []);
  const toggleControlsVisibility = useCallback(() => setControlsVisible(state => !state), []);

  useEffect(() => {
    const hideControls = debounce(() => {
      if (!interactionLock) {
        setControlsVisible(false);
      }
    }, 2000);

    if (controlsVisible) {
      hideControls();
    }

    return (): void => {
      hideControls.clear();
    };
  }, [controlsVisible, interactionLock]);

  return { controlsVisible, setInteractionLock, heartbeat, toggleControlsVisibility };
}
