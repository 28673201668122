import { Box } from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import { FC } from 'react';

import AddIcon from '@vp/assets/icons/Add.svg?react';
import CloseIcon from '@vp/assets/icons/Close.svg?react';
import { VpFade } from '@vp/common/ui/component/VpFade';
import { VpIconButton } from '@vp/common/ui/component/VpIconButton';
import { EventsManagerRecordModel } from '@vp/manager/events/core/model/EventsManagerRecordModel';

interface EventsManagerImageControlButtonProps {
  id: string;
  image: EventsManagerRecordModel['image'];
  disabled: boolean;
  removeImage: () => void;
  uploadImage: (files: File[]) => void;
}

export const EventsManagerImageControlButton: FC<EventsManagerImageControlButtonProps> = ({
  id,
  image,
  disabled,
  removeImage,
  uploadImage,
}) => {
  return (
    <Box sx={{ zIndex: 1, position: 'absolute', inset: 0 }}>
      <AnimatePresence mode="wait">
        <VpFade sx={{ width: 1, height: 1 }} key={`${image.url}.${id}`} duration={0.25}>
          {image.url && <VpIconButton Icon={CloseIcon} disabled={disabled} size={24} sx={{ width: 1, height: 1 }} onClick={removeImage} />}
          {!image.url && (
            <VpIconButton
              Icon={AddIcon}
              disabled={disabled}
              size={32}
              sx={{ width: 1, height: 1 }}
              fileUpload={{ accept: 'image', onFileUpload: uploadImage }}
            />
          )}
        </VpFade>
      </AnimatePresence>
    </Box>
  );
};
