import { Box } from '@mui/material';
import { FC, Fragment } from 'react';

import { VpFade } from '@vp/common/ui/component/VpFade';
import { VpLoadingWrapper } from '@vp/common/ui/component/VpLoadingWrapper';
import { useSearchSectionCheck } from '@vp/common/ui/hook/useSearchSectionCheck';
import { useViewModel } from '@vp/common/ui/hook/useViewModel';
import { HonorManagerHeader } from '@vp/manager/honor/ui/component/header/HonorManagerHeader';
import { HonorManagerBody } from '@vp/manager/honor/ui/component/HonorManagerBody';
import { HonorManagerNotification } from '@vp/manager/honor/ui/component/notification/HonorManagerNotification';
import { HonorManagerSection } from '@vp/manager/honor/ui/HonorManagerSection';
import { HonorManagerViewModel } from '@vp/manager/honor/ui/HonorManagerViewModel';
import { useNotification } from '@vp/manager/honor/ui/hook/useNotification';

export const HonorManagerPage: FC = () => {
  const viewModel = useViewModel(HonorManagerViewModel);
  const { onShowNotification, onCloseNotification, showNotification, notificationStatus } = useNotification();

  useSearchSectionCheck('s', HonorManagerSection.Approved);

  return (
    <Fragment>
      <VpFade>
        <HonorManagerHeader
          showSelectButton={viewModel.isActiveTabHasRecords.value && viewModel.status.value === 'loaded'}
          disableInteractions={viewModel.activeRequestState.value.inProgress}
        />

        <Box px={3}>
          <VpLoadingWrapper loading={viewModel.status.value !== 'loaded'}>
            <HonorManagerBody
              showNotification={onShowNotification}
              removeRecords={viewModel.removeRecords}
              approveRecords={viewModel.approveRecords}
              editRecord={viewModel.editRecord}
              handleBeforeSubmission={viewModel.handleBeforeSubmission}
              handleSubmissionResult={viewModel.handleSubmissionResult}
              profile={viewModel.profile.value!}
              pendingRecords={viewModel.pendingRecords.value}
              approvedRecords={viewModel.approvedRecords.value}
              activeRequestState={viewModel.activeRequestState.value}
            />
          </VpLoadingWrapper>
        </Box>
      </VpFade>

      {viewModel.activeRequestState.value.action && (
        <HonorManagerNotification
          open={showNotification}
          close={onCloseNotification}
          status={notificationStatus}
          action={viewModel.activeRequestState.value.action}
        />
      )}
    </Fragment>
  );
};
