import { Box, Drawer, Stack, SvgIcon, Typography } from '@mui/material';
import { FC } from 'react';

import Close from '@vp/assets/icons/Close.svg?react';
import Email from '@vp/assets/icons/Email.svg?react';
import Logo from '@vp/assets/icons/Logo.svg?react';
import { VpIconButton } from '@vp/common/ui/component/VpIconButton';
import { useViewModel } from '@vp/common/ui/hook/useViewModel';
import { AppMenuList } from '@vp/menu/ui/AppMenuList';
import { AppMenuViewModel } from '@vp/menu/ui/AppMenuViewModel';

export const AppMenu: FC = () => {
  const viewModel = useViewModel(AppMenuViewModel);

  return (
    <Drawer
      disableEscapeKeyDown
      open={viewModel.open.value}
      PaperProps={{ sx: theme => ({ width: 1, background: theme.palette.background['200'] }) }}
      onClose={viewModel.close}
    >
      <Stack alignItems="center">
        <VpIconButton Icon={Close} sx={{ mt: 1, position: 'absolute', top: 0, left: 0 }} onClick={viewModel.close} />

        <Stack
          style={{ justifyContent: viewModel.authenticated.value ? 'space-between' : 'center' }}
          sx={{ width: 200, height: 200, mb: 2, py: 2, alignItems: 'center' }}
        >
          {viewModel.authenticated.value && <Box height={20} />}

          <Logo style={{ width: 110, height: 86 }} />

          {viewModel.authenticated.value && (
            <Stack height={20} direction="row" alignItems="center" sx={theme => ({ color: theme.palette.text.primary })}>
              <SvgIcon sx={{ fontSize: 14, mr: 0.5 }}>
                <Email />
              </SvgIcon>

              <Typography variant="caption1Regular" maxWidth={250} noWrap>
                {viewModel.email.value}
              </Typography>
            </Stack>
          )}
        </Stack>

        <AppMenuList authenticated={viewModel.authenticated.value} items={viewModel.items.value} onItemClick={viewModel.onItemClick} />
      </Stack>
    </Drawer>
  );
};
