import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import { AuthPageDescription } from '@vp/auth/ui/AuthPageDescription';
import { LoginForm } from '@vp/auth/ui/login/LoginForm';
import { VpFade } from '@vp/common/ui/component/VpFade';

export const LoginPage: FC = () => {
  return (
    <VpFade>
      <Stack width="100%" px={3} pt={5} alignItems="center">
        <AuthPageDescription title="Войти в Аккаунт" description={'Введите вашу электронную почту \nи пароль'} />
        <LoginForm />

        <Stack direction="row" gap={1}>
          <Typography variant="body1Regular" color="text.disabled">
            Нет аккаунта?
          </Typography>

          <Typography
            component={Link}
            to="/auth/register"
            variant="body1Bold"
            color="text.secondary"
            sx={{ textShadow: '0px 0px 32px rgba(255, 255, 255, 0.5), 0px 0px 128px rgba(255, 255, 255, 0.5)' }}
          >
            Зарегистрироваться
          </Typography>
        </Stack>
      </Stack>
    </VpFade>
  );
};
