import { ReadonlySignal } from '@preact/signals-react';

import { OperationResult } from '@vp/common/core/OperationResult';
import { SumbitEventsDto } from '@vp/manager/events/core/dto/SumbitEventsDto';
import { EventsManagerStatus } from '@vp/manager/events/core/interface/EventsManagerState';
import { EventsManagerRecordModel } from '@vp/manager/events/core/model/EventsManagerRecordModel';

export abstract class EventsManagerPort {
  abstract events: ReadonlySignal<EventsManagerRecordModel[]>;
  abstract status: ReadonlySignal<EventsManagerStatus>;
  abstract loadEvents(profileId: string, controller: AbortController): Promise<OperationResult>;
  abstract submitEvents(events: SumbitEventsDto, controller: AbortController): Promise<OperationResult>;
  abstract resetState(): void;
}
