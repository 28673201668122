import { injectable } from 'inversify';

import { HonorManagerState } from '@vp/manager/honor/core/interface/HonorManagerState';
import { ProfileHonorRecordModel } from '@vp/profile/core/model/ProfileHonorRecordModel';

@injectable()
export class HandleSubmissionResultUsecase {
  constructor(private readonly honorManagerState: HonorManagerState) {}

  execute(record: ProfileHonorRecordModel): void {
    this.honorManagerState.setRecord(record);
  }
}
