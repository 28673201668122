import { Typography } from '@mui/material';
import { FC } from 'react';

import ChevronLeftIcon from '@vp/assets/icons/ChevronLeft.svg?react';
import { VpButton } from '@vp/common/ui/component/VpButton';
import { VpHeader, VpHeaderColumn } from '@vp/common/ui/component/VpHeader';
import { VpIconButton } from '@vp/common/ui/component/VpIconButton';

interface EventsManagerHeaderProps {
  disableCancel: boolean;
  onCancel: () => void;
}

export const EventsManagerHeader: FC<EventsManagerHeaderProps> = ({ disableCancel, onCancel }) => {
  return (
    <VpHeader sx={theme => ({ position: 'static', background: theme.palette.background[300] })}>
      <VpHeaderColumn align="left">
        <VpIconButton glowing Icon={ChevronLeftIcon} to=".." />
      </VpHeaderColumn>

      <VpHeaderColumn>
        <Typography variant="h6" color="text.primary">
          События
        </Typography>
      </VpHeaderColumn>

      <VpHeaderColumn align="right">
        <VpButton text="Отмена" status="primary" variant="soft" size="x-small" onClick={onCancel} disabled={disableCancel} sx={{ mr: 3 }} />
      </VpHeaderColumn>
    </VpHeader>
  );
};
