import { useCallback } from 'react';

import { Status } from '@vp/manager/honor/ui/HonorManagerViewModel';
import { ProfileHonorRecordModel } from '@vp/profile/core/model/ProfileHonorRecordModel';
import { ProfileHonorNotificationStatus } from '@vp/profile/ui/honor/ProfileHonor';

interface UseRecordApproveProps {
  approveRecords: (id: string[]) => Promise<Status>;
  showNotification: (status: ProfileHonorNotificationStatus) => void;
}

export interface RecordEditState {
  handleRecordApprove: (record: ProfileHonorRecordModel) => Promise<void>;
}

export const useRecordApprove = ({ approveRecords, showNotification }: UseRecordApproveProps): RecordEditState => {
  const handleRecordApprove = useCallback(
    async (record: ProfileHonorRecordModel) => {
      const status = await approveRecords([record.id]);
      showNotification(status);
    },
    [approveRecords, showNotification],
  );

  return {
    handleRecordApprove,
  };
};
