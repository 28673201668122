import { injectable } from 'inversify';

import { AppMenuService } from '@vp/menu/core/interface/AppMenuService';
import { AppMenuState } from '@vp/menu/core/interface/AppMenuState';

@injectable()
export class AppMenuFacade implements AppMenuService {
  constructor(private readonly appMenuState: AppMenuState) {}

  toggle(): void {
    const state = !this.appMenuState.open.value;
    this.appMenuState.setOpen(state);
  }
}
