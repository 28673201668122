import { injectable } from 'inversify';

import { SlideshowEngine } from '@vp/slideshow/core/engine/SlideshowEngine';
import { SlideshowEngineFactoryPort } from '@vp/slideshow/core/interface/SlideshowEngineFactoryPort';
import { SlideshowEnginePort } from '@vp/slideshow/core/interface/SlideshowEnginePort';

@injectable()
export class SlideshowEngineFactory implements SlideshowEngineFactoryPort {
  create(slidesCount: number, slideTime: number): SlideshowEnginePort {
    const engine = new SlideshowEngine(slidesCount, slideTime);
    engine.changeState(engine.initialState);
    return engine;
  }
}
