import { batch, signal, Signal } from '@preact/signals-react';
import { injectable } from 'inversify';

import { ProfileManagerState, ProfileManagerStatus } from '@vp/manager/profile/core/interface/ProfileManagerState';
import { ProfileModel } from '@vp/profile/core/model/ProfileModel';

@injectable()
export class SignalsProfileManagerState implements ProfileManagerState {
  readonly profiles: Signal<ProfileModel[]> = signal([]);
  readonly status: Signal<ProfileManagerStatus> = signal('initial');
  readonly active: Signal<ProfileModel | null> = signal(null);

  completed(status: ProfileManagerStatus, profiles?: ProfileModel[]): void {
    this.status.value = status;
    this.profiles.value = profiles ?? this.profiles.value;
  }

  loading(): void {
    this.status.value = 'loading';
  }

  getById(id: string): ProfileModel | null {
    return this.profiles.value.find(profile => profile.id === id) ?? null;
  }

  setActive(profile: ProfileModel): void {
    this.active.value = profile;
  }

  update(updatedProfile: ProfileModel): void {
    if (!this.getById(updatedProfile.id)) {
      return void this.profiles.value.push(updatedProfile);
    }

    this.profiles.value = this.profiles.value.map(profile => {
      return profile.id === updatedProfile.id ? updatedProfile : profile;
    });

    if (updatedProfile.id === this.active.value?.id) {
      this.active.value = updatedProfile;
    }
  }

  reset(): void {
    batch(() => {
      this.profiles.value = [];
      this.status.value = 'initial';
      this.active.value = null;
    });
  }
}
