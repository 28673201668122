import { computed, ReadonlySignal, signal, Signal } from '@preact/signals-react';
import { injectable } from 'inversify';

import { AuthPort } from '@vp/auth/core/interface/AuthPort';
import { Success } from '@vp/common/core/OperationResult';
import { ViewModel, ViewModelDispose, ViewModelInit } from '@vp/common/ui/ViewModel';
import { AppNotificationService } from '@vp/notification/AppNotificationService';
import { ProfilePort } from '@vp/profile/core/interface/ProfilePort';
import { RouterService } from '@vp/routing/RouterService';

@injectable()
export class ProfileActivationViewModel extends ViewModel implements ViewModelDispose, ViewModelInit {
  readonly loading: Signal<boolean> = signal(false);
  readonly email: ReadonlySignal<string> = this.authPort.email;
  readonly canActivateProfile: ReadonlySignal<boolean> = this.profilePort.canActivateProfile;
  readonly instructionText: ReadonlySignal<string> = computed(() => this.toInstructionText());

  private controller?: AbortController;

  constructor(
    private readonly authPort: AuthPort,
    private readonly profilePort: ProfilePort,
    private readonly routerService: RouterService,
    private readonly notificationService: AppNotificationService,
  ) {
    super();
  }

  init(): void {
    this.profilePort.resetProfileForActivation();
  }

  dispose(): void {
    this.controller?.abort();
  }

  activate = async (): Promise<void> => {
    if (this.canActivateProfile.value) {
      this.loading.value = true;
      await this.activateProfile();
      this.loading.value = false;
    }
  };

  loginToActivate = (): void => {
    const id = this.profilePort.profile.value.id;
    this.profilePort.storeProfileForActivation(id);
    void this.routerService.navigate('/auth/login');
  };

  registerToActivate = (): void => {
    const id = this.profilePort.profile.value.id;
    this.profilePort.storeProfileForActivation(id);
    void this.routerService.navigate('/auth/register');
  };

  private async activateProfile(): Promise<void> {
    this.controller = new AbortController();
    const id = this.profilePort.profile.value.id;
    const result = await this.profilePort.activateProfile(id, this.controller);

    if (result instanceof Success) {
      this.toProfileSettings(id);
      this.showSuccessNotification();
    } else {
      this.showErrorNotification();
    }
  }

  private toProfileSettings(id: string): void {
    void this.routerService.navigate(`/ps/${id}`);
  }

  private showSuccessNotification(): void {
    this.notificationService.enqueue({
      variant: 'success',
      message: 'Профиль успешно активирован',
    });
  }

  private showErrorNotification(): void {
    this.notificationService.enqueue({
      variant: 'error',
      message: 'Что-то пошло не так',
      secondaryMessage: 'Не удалось активировать профиль.',
    });
  }

  private toInstructionText(): string {
    if (this.canActivateProfile.value) return 'Этот профиль пока еще не активирован.';
    return 'Этот профиль пока еще не активирован. Чтобы активировать его, вам необходимо сначала войти или зарегистрироваться.';
  }
}
