import { Typography } from '@mui/material';
import { FC } from 'react';

const Text =
  'В этот день, 2 сентября 1934 года, на свет появилась Елена — женщина с большим сердцем и глубокой мудростью. Её жизнь с самого начала была наполнена теплотой и заботой о тех, кто её окружал, и именно эти качества стали краеугольным камнем её жизненного пути. С раннего детства она выделялась искренней добротой, чуткостью и стремлением поддержать близких, что стало для неё неизменным ориентиром на протяжении всей её жизни.';

export const ProfileExampleBio: FC = () => {
  return (
    <Typography width={1} variant="body1Regular" color="text.disabled" sx={{ transition: 'color 0.25s ease-in-out' }}>
      {Text}
    </Typography>
  );
};
