import { FC, Fragment } from 'react';

import { useViewModel } from '@vp/common/ui/hook/useViewModel';
import { SubmitRecordBody } from '@vp/honor/ui/components/SubmitRecordBody';
import { SubmitRecordHeader } from '@vp/honor/ui/components/SubmitRecordHeader';
import { HonorSubmitViewModel } from '@vp/honor/ui/HonorSubmitViewModel';
import { ProfileHonorRecordModel } from '@vp/profile/core/model/ProfileHonorRecordModel';
import { ProfileHonorNotificationStatus } from '@vp/profile/ui/honor/ProfileHonor';

interface SubmitRecordContentProps {
  submitButtonText: string;
  profileId: string;
  close: () => void;
  handleSubmissionResult: (status: ProfileHonorNotificationStatus, record: ProfileHonorRecordModel | null) => void;
  handleBeforeSubmission?: () => void;
}

export const SubmitRecordContent: FC<SubmitRecordContentProps> = ({
  handleSubmissionResult,
  handleBeforeSubmission,
  close,
  submitButtonText,
  profileId,
}) => {
  const viewModel = useViewModel(HonorSubmitViewModel);

  return (
    <Fragment>
      <SubmitRecordHeader close={close} disableCloseIcon={viewModel.submitting.value} />
      <SubmitRecordBody
        profileId={profileId}
        handleBeforeSubmission={handleBeforeSubmission}
        handleSubmissionResult={handleSubmissionResult}
        submitButtonText={submitButtonText}
      />
    </Fragment>
  );
};
