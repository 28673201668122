import { signal, Signal } from '@preact/signals-react';
import { injectable } from 'inversify';

import { Success } from '@vp/common/core/OperationResult';
import { ViewModel, ViewModelDispose } from '@vp/common/ui/ViewModel';
import { RemoveMediaDto } from '@vp/manager/gallery/core/dto/RemoveMediaDto';
import { UploadMediaType } from '@vp/manager/gallery/core/dto/UploadMediaType';
import { GalleryManagerPort } from '@vp/manager/gallery/core/interface/GalleryManagerPort';
import { GalleryManagerSection } from '@vp/manager/gallery/ui/GalleryManagerSection';
import { GalleryManagerPreviewProps, PhotoPreview, VideoPreview } from '@vp/manager/gallery/ui/preview/GalleryManagerPreview';
import { ProfileManagerPort } from '@vp/manager/profile/core/interface/ProfileManagerPort';
import { AppNotificationService } from '@vp/notification/AppNotificationService';

@injectable()
export class GalleryManagerPreviewViewModel extends ViewModel<GalleryManagerPreviewProps> implements ViewModelDispose {
  readonly dialogShown: Signal<boolean> = signal(false);
  readonly removing: Signal<boolean> = signal(false);

  private controller?: AbortController;

  constructor(
    private readonly galleryManagerPort: GalleryManagerPort,
    private readonly profileManagerPort: ProfileManagerPort,
    private readonly notificationService: AppNotificationService,
  ) {
    super();
  }

  dispose(): void {
    this.controller?.abort();
  }

  showDialog = (): void => {
    this.dialogShown.value = true;
  };

  closeDialog = (): void => {
    this.dialogShown.value = false;
  };

  remove = async (): Promise<void> => {
    this.removing.value = true;
    await this.handleRemove();
    this.removing.value = false;
  };

  isPhotoPreview = (item: PhotoPreview | VideoPreview): item is PhotoPreview => {
    return !Reflect.has(item, 'thumbnail');
  };

  private async handleRemove(): Promise<void> {
    this.controller = new AbortController();
    const result = await this.galleryManagerPort.remove(this.toRemoveDto(), this.controller);

    if (result instanceof Success) {
      this.closeDialog();
      this.props.value?.close();
      this.showSuccessMessage();
    } else {
      this.showErrorMessage(result.message);
    }
  }

  private showSuccessMessage(): void {
    const name = this.isPhotoPreview(this.props.value!.item) ? 'Фото' : 'Видео';
    this.notificationService.enqueue({ variant: 'success', message: `${name} было удалено` });
  }

  private showErrorMessage(message: string): void {
    const name = this.isPhotoPreview(this.props.value!.item) ? 'фото' : 'видео';
    const secondaryMessage = message || `Не удалось удалить ${name}`;
    this.notificationService.enqueue({ variant: 'error', message: 'Что-то пошло не так', secondaryMessage });
  }

  private toRemoveDto(): RemoveMediaDto {
    const id = this.props.value!.item.id;
    const profileId = this.profileManagerPort.active.value!.id;
    const type = this.props.value!.section === GalleryManagerSection.Photos ? UploadMediaType.Photos : UploadMediaType.Videos;
    return new RemoveMediaDto({ id, type, profileId });
  }
}
