import { Typography } from '@mui/material';
import { FC } from 'react';

import ChevronLeftIcon from '@vp/assets/icons/ChevronLeft.svg?react';
import { VpButton } from '@vp/common/ui/component/VpButton';
import { VpHeader, VpHeaderColumn } from '@vp/common/ui/component/VpHeader';
import { VpIconButton } from '@vp/common/ui/component/VpIconButton';

interface HonorManagerTopBarProps {
  showSelectButton: boolean;
  disableInteractions: boolean;
}

export const HonorManagerTopBar: FC<HonorManagerTopBarProps> = ({ showSelectButton, disableInteractions }) => {
  return (
    <VpHeader sx={theme => ({ position: 'static', background: theme.palette.background[300] })}>
      <VpHeaderColumn align="left">
        <VpIconButton glowing Icon={ChevronLeftIcon} to=".." />
      </VpHeaderColumn>

      <VpHeaderColumn>
        <Typography variant="h6" color="text.primary">
          Почести
        </Typography>
      </VpHeaderColumn>

      <VpHeaderColumn align="right">
        <VpButton
          disabled={disableInteractions}
          text="Выбрать"
          sx={{ mr: 3, visibility: showSelectButton ? 'hidden' : 'hidden' }}
          status="primary"
          variant="soft"
          size="x-small"
        />
      </VpHeaderColumn>
    </VpHeader>
  );
};
