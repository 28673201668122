import { AxiosProgressEvent, AxiosResponse } from 'axios';

import { FileChunk } from '@vp/manager/gallery/data/upload/context/FileChunk';
import { FileUpload } from '@vp/manager/gallery/data/upload/context/FileUpload';
import { UploadState } from '@vp/manager/gallery/data/upload/state/UploadState';

type PresignedUrlDto = { presigned_url: string };

export class UploadingState extends UploadState {
  async process(): Promise<void> {
    try {
      await this.uploadChunks();
      this.stateMachine.changeState(this.stateMachine.finalizingState);
    } catch (error) {
      this.handleError(error);
      this.stateMachine.changeState(this.stateMachine.failedState);
    }
  }

  private async uploadChunks(): Promise<void> {
    const fileUpload = this.stateMachine.context.getCurrentFileUpload();

    for (const chunk of fileUpload.chunks) {
      const uploadUrl = await this.getUploadUrl(chunk, fileUpload);
      await this.uploadChunk(uploadUrl, chunk, fileUpload);
    }
  }

  private async getUploadUrl(chunk: FileChunk, fileUpload: FileUpload): Promise<string> {
    const params = new URLSearchParams({
      upload_id: fileUpload.uploadId!,
      key: fileUpload.uploadKey!,
      part_number: chunk.order.toString(),
    });

    const { data } = await this.stateMachine.http.get<PresignedUrlDto>('/api/multipart_uploads/presigned_url_for_chunk', {
      signal: this.stateMachine.controller.signal,
      params,
    });

    return data.presigned_url;
  }

  private async uploadChunk(url: string, chunk: FileChunk, fileUpload: FileUpload): Promise<void> {
    const response = await this.stateMachine.http.put(url, chunk.blob, {
      signal: this.stateMachine.controller.signal,
      timeout: 15 * 60 * 1000,
      headers: { 'Content-Type': 'application/octet-stream' },
      onUploadProgress: progressEvent => {
        this.updateFileProgress(progressEvent, fileUpload, chunk);
        this.updateTotalProgress();
        this.stateMachine.notify();
      },
    });

    chunk.setEtag(this.extractEtag(response));
  }

  private updateFileProgress(progressEvent: AxiosProgressEvent, fileUpload: FileUpload, chunk: FileChunk): void {
    const chunkPercent = Math.round((progressEvent.loaded * 100) / progressEvent.total!) / fileUpload.chunks.length;
    const basePercent = ((chunk.order - 1) * 100) / fileUpload.chunks.length;
    fileUpload.updateProgress(basePercent + chunkPercent);
  }

  private extractEtag(response: AxiosResponse): string {
    const etag = response.headers['etag'] as string;
    return etag.replace(/"/g, '');
  }
}
