import { MediaFileDto } from '@vp/manager/gallery/core/dto/MediaFileDto';
import { FileChunk } from '@vp/manager/gallery/data/upload/context/FileChunk';

export class FileUpload {
  uploadId?: string;
  uploadKey?: string;
  progress: number = 0;
  readonly chunks: FileChunk[] = [];

  constructor(readonly mediaFile: MediaFileDto) {}

  addChunk(blob: Blob): void {
    const order = this.chunks.length + 1;
    const chunk = new FileChunk(blob, order);
    this.chunks.push(chunk);
  }

  setUploadMeta(uploadId: string, uploadKey: string): void {
    this.uploadId = uploadId;
    this.uploadKey = uploadKey;
  }

  updateProgress(progress: number): void {
    this.progress = Math.min(progress, 100);
  }
}
