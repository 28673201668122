import { Button, SvgIcon, Typography } from '@mui/material';
import { FC, useCallback } from 'react';

import SettingsAdjust from '@vp/assets/icons/SettingsAdjust.svg?react';
import { VpImage } from '@vp/common/ui/component/VpImage';
import { ProfileModel } from '@vp/profile/core/model/ProfileModel';

export interface AppMenuProfileItemProps {
  profile: ProfileModel;
  onProfileClick: (profile: ProfileModel) => void;
}

export const AppMenuProfileItem: FC<AppMenuProfileItemProps> = ({ profile, onProfileClick }) => {
  const onClick = useCallback(() => onProfileClick(profile), [onProfileClick, profile]);

  return (
    <Button
      sx={theme => ({
        backgroundColor: 'transparent',
        textTransform: 'none',
        width: 1,
        pl: 6,
        pr: 2.5,
        pt: 1.25,
        pb: `calc(${theme.spacing(1.25)} - 1px)`,
        justifyContent: 'flex-start',
        alignItems: 'center',
        borderBottom: '1px solid',
        borderRadius: 0,
        borderColor: theme.palette.transparency.alpha16,
      })}
      onClick={onClick}
    >
      <VpImage
        src={profile.image.url}
        hash={profile.image.hash}
        sx={{ width: 40, height: 40, mr: 1, borderRadius: '50%', overflow: 'hidden', flexShrink: 0 }}
      />

      <Typography variant="body1Medium" color="text.secondary" mr="auto" textAlign="left">
        {profile.name || 'Имя не указано'}
      </Typography>

      <SvgIcon sx={theme => ({ fontSize: 24, color: theme.palette.transparency.alpha48, ml: 2 })}>
        <SettingsAdjust />
      </SvgIcon>
    </Button>
  );
};
