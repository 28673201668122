import { injectable } from 'inversify';

import { OperationResult, Success } from '@vp/common/core/OperationResult';
import { SumbitEventsDto } from '@vp/manager/events/core/dto/SumbitEventsDto';
import { EventsManagerRepository } from '@vp/manager/events/core/interface/EventsManagerRepository';
import { EventsManagerState } from '@vp/manager/events/core/interface/EventsManagerState';

@injectable()
export class SubmitEventsUsecase {
  constructor(
    private readonly repository: EventsManagerRepository,
    private readonly state: EventsManagerState,
  ) {}

  async execute(dto: SumbitEventsDto, controller: AbortController): Promise<OperationResult> {
    const result = await this.repository.submitEvents(dto, controller);

    if (result instanceof Success) {
      this.state.update(result.value, dto.toDelete);
    }

    return result as OperationResult;
  }
}
