import { Stack } from '@mui/material';
import { FC } from 'react';

import { AuthPageDescription } from '@vp/auth/ui/AuthPageDescription';
import { ForgotPasswordForm } from '@vp/auth/ui/forgot-password/ForgotPasswordForm';
import { VpFade } from '@vp/common/ui/component/VpFade';

export const ForgotPasswordPage: FC = () => {
  return (
    <VpFade>
      <Stack width={1} px={3} pt={5} alignItems="center">
        <AuthPageDescription
          title="Восстановление Доступа"
          description="Введите свой адрес электронной почты, и мы отправим вам ссылку для сброса пароля."
        />

        <ForgotPasswordForm />
      </Stack>
    </VpFade>
  );
};
