import { Box, CircularProgress, IconButton, SvgIcon } from '@mui/material';
import { FC, SyntheticEvent, useCallback, useMemo } from 'react';

import PauseIcon from '@vp/assets/icons/PauseFilled.svg?react';
import PlayIcon from '@vp/assets/icons/PlayFilled.svg?react';

interface SlideshowPlaybackButtonProps {
  progress: number;
  isPaused: boolean;
  togglePause: () => void;
}

export const SlideshowPlaybackButton: FC<SlideshowPlaybackButtonProps> = ({ progress, isPaused, togglePause }) => {
  const Icon = useMemo(() => (isPaused ? <PlayIcon /> : <PauseIcon />), [isPaused]);
  const handleClick = useCallback(
    (event: SyntheticEvent) => {
      togglePause();
      event.stopPropagation();
    },
    [togglePause],
  );

  return (
    <Box
      sx={theme => ({
        width: 64,
        height: 64,
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.transparency.alpha8,
        backdropFilter: 'blur(10px)',
        borderRadius: '50%',
      })}
      onClick={handleClick}
    >
      <CircularProgress
        sx={{ '.MuiCircularProgress-circle': { transition: 'none' } }}
        thickness={2}
        variant="determinate"
        value={progress}
        size={64}
      />
      <IconButton
        sx={theme => ({
          position: 'absolute',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: theme.spacing(-3),
            right: theme.spacing(-3),
            bottom: theme.spacing(-3),
            left: theme.spacing(-3),
            background: 'transparent',
          },
        })}
      >
        <SvgIcon sx={{ fontSize: 32 }}>{Icon}</SvgIcon>
      </IconButton>

      <IconButton
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          zIndex: -1,
          filter: 'blur(10px)',
        }}
      >
        <SvgIcon sx={{ fontSize: 32 }}>{Icon}</SvgIcon>
      </IconButton>
    </Box>
  );
};
