import { Container, interfaces } from 'inversify';

import { BaseModule } from '@vp/common/BaseModule';

import { Router } from './AppRouter';
import { AppViewModel } from './AppViewModel';

export class AppContainer {
  readonly container: Container = new Container();
  private readonly modules: BaseModule[] = [];

  registerModules(Modules: interfaces.Newable<BaseModule>[]): void {
    for (const Module of Modules) {
      const module = new Module();
      this.modules.push(module);
      this.container.load(module.module);
    }
  }

  initialize(): void {
    this.container.bind('RouterInstance').toConstantValue(Router);

    for (const module of this.modules) {
      module.initialize(this.container);
    }

    this.container.bind(AppViewModel).toSelf().inTransientScope();
  }
}
