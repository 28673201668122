import { FormControl, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useForm } from 'react-hook-form';

import { ForgotPasswordFormData, ForgotPasswordViewModel } from '@vp/auth/ui/forgot-password/ForgotPasswordViewModel';
import { EmailValidationOptions } from '@vp/auth/ui/validation/EmailValidationOptions';
import { VpFancyButton } from '@vp/common/ui/component/VpFancyButton';
import { VpInput } from '@vp/common/ui/component/VpInput';
import { useViewModel } from '@vp/common/ui/hook/useViewModel';

export const ForgotPasswordForm: FC = () => {
  const viewModel = useViewModel(ForgotPasswordViewModel);

  const { register, handleSubmit, formState } = useForm<ForgotPasswordFormData>({
    defaultValues: { email: '' },
    mode: 'onTouched',
  });

  return (
    <Stack component="form" width={1} alignItems="center" onSubmit={handleSubmit(viewModel.sendPasswordResetLink)}>
      <FormControl sx={{ mb: 4 }} fullWidth error={!!formState.errors.email} disabled={viewModel.loading.value}>
        <Typography htmlFor="form-email" component="label" variant="formLabelMedium" color="text.primary" gutterBottom>
          Почта
        </Typography>
        <VpInput {...register('email', EmailValidationOptions)} autoComplete="email" id="form-email" placeholder="example@mail.com" />
        {formState.errors.email?.message && (
          <Typography variant="body2Regular" color="danger.500" mt={0.75}>
            {formState.errors.email.message}
          </Typography>
        )}
      </FormControl>

      <VpFancyButton text="Восстановить доступ" type="submit" disabled={!formState.isValid} loading={viewModel.loading.value} />
    </Stack>
  );
};
