import { OwnerModel } from '@vp/auth/core/model/OwnerModel';
import { TokensModel } from '@vp/auth/core/model/TokensModel';
import { OperationResult } from '@vp/common/core/OperationResult';

export abstract class AuthRepository {
  abstract register(email: string, password: string, controller?: AbortController): Promise<TokensModel>;
  abstract login(email: string, password: string, controller?: AbortController): Promise<TokensModel>;
  abstract refresh(tokens: TokensModel, controller?: AbortController): Promise<TokensModel>;
  abstract getOwner(controller?: AbortController): Promise<OwnerModel>;
  abstract sendResetPasswordLink(email: string, controller?: AbortController): Promise<OperationResult>;
  abstract restorePassword(password: string, recoveryToken: string, controller?: AbortController): Promise<OperationResult>;
}
