import { SlideshowEngine } from '@vp/slideshow/core/engine/SlideshowEngine';

export abstract class EngineState {
  constructor(protected readonly engine: SlideshowEngine) {}

  play?(): void;
  pause?(): void;
  goTo?(slide: number): void;
  enter?(): void;
  tick?(): void;
  exit?(): void;
}
