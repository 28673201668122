import { IconButton, SvgIcon } from '@mui/material';
import { FC, SyntheticEvent, useCallback } from 'react';

import VolumeIcon from '@vp/assets/icons/Volume.svg?react';
import VolumeMutedIcon from '@vp/assets/icons/VolumeMute.svg?react';

interface VolumeButtonProps {
  muted: boolean;
  toggleAudioMuted: () => void;
}

export const VolumeButton: FC<VolumeButtonProps> = ({ muted, toggleAudioMuted }) => {
  const handleClick = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();
      toggleAudioMuted();
    },
    [toggleAudioMuted],
  );

  return (
    <IconButton
      onClick={handleClick}
      sx={theme => ({
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: theme.spacing(-2),
          right: theme.spacing(-2),
          bottom: theme.spacing(-2),
          left: theme.spacing(-0.5),
          background: 'transparent',
        },
      })}
    >
      <SvgIcon>{muted ? <VolumeMutedIcon /> : <VolumeIcon />}</SvgIcon>
    </IconButton>
  );
};
