import { Container, ContainerModule } from 'inversify';

import { AuthFacade } from '@vp/auth/core/AuthFacade';
import { AuthPort } from '@vp/auth/core/interface/AuthPort';
import { AuthRepository } from '@vp/auth/core/interface/AuthRepository';
import { AuthState } from '@vp/auth/core/interface/AuthState';
import { TokensStorage } from '@vp/auth/core/interface/TokensStorage';
import { LoadOwnerUsecase } from '@vp/auth/core/usecase/LoadOwnerUsecase';
import { LoginUsecase } from '@vp/auth/core/usecase/LoginUsecase';
import { LogoutUsecase } from '@vp/auth/core/usecase/LogoutUsecase';
import { RefreshUsecase } from '@vp/auth/core/usecase/RefreshUsecase';
import { RegisterUsecase } from '@vp/auth/core/usecase/RegisterUsecase';
import { ValidateAuthUsecase } from '@vp/auth/core/usecase/ValidateAuthUsecase';
import { ValidateRecoveryTokenUsecase } from '@vp/auth/core/usecase/ValidateRecoveryTokenUsecase';
import { AuthDataMapper } from '@vp/auth/data/AuthDataMapper';
import { AuthInterceptorManager } from '@vp/auth/data/AuthInterceptorManager';
import { LocalTokensStorage } from '@vp/auth/data/LocalTokensStorage';
import { RestAuthRepository } from '@vp/auth/data/RestAuthRepository';
import { SignalsAuthState } from '@vp/auth/data/SignalsAuthState';
import { ForgotPasswordViewModel } from '@vp/auth/ui/forgot-password/ForgotPasswordViewModel';
import { GuardViewModel } from '@vp/auth/ui/guard/GuardViewModel';
import { LoginViewModel } from '@vp/auth/ui/login/LoginViewModel';
import { RegisterViewModel } from '@vp/auth/ui/register/RegisterViewModel';
import { RestorePasswordViewModel } from '@vp/auth/ui/restore-password/RestorePasswordViewModel';
import { BaseModule } from '@vp/common/BaseModule';

export class AuthModule extends BaseModule {
  readonly module: ContainerModule = new ContainerModule(bind => {
    bind(RestAuthRepository).toSelf().inSingletonScope();

    bind(AuthRepository)
      .toDynamicValue(context => context.container.get(RestAuthRepository))
      .inSingletonScope();

    bind(AuthFacade).toSelf().inSingletonScope();

    bind(AuthPort)
      .toDynamicValue(context => context.container.get(AuthFacade))
      .inSingletonScope();

    bind(RegisterViewModel).toSelf().inTransientScope();
    bind(LoginViewModel).toSelf().inTransientScope();
    bind(GuardViewModel).toSelf().inTransientScope();
    bind(ForgotPasswordViewModel).toSelf().inTransientScope();
    bind(RestorePasswordViewModel).toSelf().inTransientScope();

    bind(LoginUsecase).toSelf().inSingletonScope();
    bind(RefreshUsecase).toSelf().inSingletonScope();
    bind(RegisterUsecase).toSelf().inSingletonScope();
    bind(AuthInterceptorManager).toSelf().inSingletonScope();
    bind(LocalTokensStorage).toSelf().inSingletonScope();
    bind(AuthDataMapper).toSelf().inSingletonScope();

    bind(TokensStorage)
      .toDynamicValue(context => context.container.get(LocalTokensStorage))
      .inSingletonScope();

    bind(SignalsAuthState).toSelf().inSingletonScope();

    bind(AuthState)
      .toDynamicValue(context => context.container.get(SignalsAuthState))
      .inSingletonScope();

    bind(ValidateAuthUsecase).toSelf().inSingletonScope();
    bind(LoadOwnerUsecase).toSelf().inSingletonScope();
    bind(LogoutUsecase).toSelf().inSingletonScope();
    bind(ValidateRecoveryTokenUsecase).toSelf().inSingletonScope();
  });

  override initialize(container: Container): void {
    super.initialize(container);
    container.get(AuthInterceptorManager).attach();
  }
}
