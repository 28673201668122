import { Typography } from '@mui/material';
import { FC } from 'react';

import { ProfileModel } from '@vp/profile/core/model/ProfileModel';
import { useSectionMinHeight } from '@vp/profile/ui/hook/useSectionMinHeight';

export interface ProfileBioProps {
  profile: ProfileModel;
}

export const ProfileBio: FC<ProfileBioProps> = ({ profile }) => {
  const minHeight = useSectionMinHeight();

  return (
    <Typography
      px={3}
      py={2}
      width={1}
      display="inline-block"
      whiteSpace="pre-wrap"
      minHeight={minHeight}
      variant="body1Regular"
      color="text.disabled"
    >
      {profile.bio}
    </Typography>
  );
};
