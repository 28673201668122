import { Typography, FormControl } from '@mui/material';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { VpInput } from '@vp/common/ui/component/VpInput';
import { EventsFormValues } from '@vp/manager/events/ui/EventsManagerPage';

interface EventsManagerFormDescriptionProps {
  index: number;
  disableControls: boolean;
}

export const EventsManagerFormDescription: FC<EventsManagerFormDescriptionProps> = ({ index, disableControls }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<EventsFormValues>();

  const descriptionError = errors?.events?.[index]?.description;

  return (
    <FormControl disabled={disableControls} fullWidth error={!!descriptionError}>
      <Typography component="label" htmlFor={`events.${index}.description`} variant="formLabelMedium" color="text.primary" mb={1}>
        Описание
      </Typography>
      <VpInput
        {...register(`events.${index}.description`, {
          required: true,
          minLength: 4,
          maxLength: 1000,
        })}
        id={`events.${index}.description`}
        multiline
        minRows={4}
        placeholder="Введите описание события из жизни человека, включая его ключевые моменты, влияние на его жизнь и наследие, которое оно оставило."
      />
    </FormControl>
  );
};
