import { Typography } from '@mui/material';
import { FC, memo } from 'react';

import ColorPalette from '@vp/assets/icons/ColorPalette.svg?react';
import { VpIcon } from '@vp/common/ui/component/VpIcon';
import { VpPaper } from '@vp/common/ui/component/VpPaper';
import { VpToggle } from '@vp/common/ui/component/VpToggle';
import { ProfileThemeModel } from '@vp/profile/core/model/ProfileThemeModel';

export type ThemingManagerSettingsProps = { theme: ProfileThemeModel; switchTheme: () => void };

export const ThemingManagerSettings: FC<ThemingManagerSettingsProps> = memo(({ theme, switchTheme }) => {
  return (
    <VpPaper sx={{ px: 3, flexDirection: 'row', alignItems: 'center', py: 3.25 }}>
      <VpIcon Icon={ColorPalette} size={24} />
      <Typography ml={1} variant="body1Medium" color="text.secondary">
        Светлая тема
      </Typography>
      <VpToggle sx={{ ml: 'auto' }} state={theme.mode === 'light'} onToggle={switchTheme} />
    </VpPaper>
  );
});

ThemingManagerSettings.displayName = ThemingManagerSettings.name;
