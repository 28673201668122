import { Box, IconButton, SvgIcon } from '@mui/material';
import { FC, SyntheticEvent, useCallback } from 'react';

import GridIcon from '@vp/assets/icons/Grid.svg?react';

interface SlideshowGalleryButtonProps {
  openGallery: () => void;
}

export const SlideshowGalleryButton: FC<SlideshowGalleryButtonProps> = ({ openGallery }) => {
  const handleClick = useCallback(
    (event: SyntheticEvent) => {
      openGallery();
      event.stopPropagation();
    },
    [openGallery],
  );

  return (
    <Box
      sx={theme => ({
        width: 64,
        height: 64,
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.transparency.alpha8,
        backdropFilter: 'blur(10px)',
        borderRadius: '50%',
      })}
      onClick={handleClick}
    >
      <IconButton
        sx={theme => ({
          '&::before': {
            content: '""',
            position: 'absolute',
            top: theme.spacing(-3),
            right: theme.spacing(-3),
            bottom: theme.spacing(-3),
            left: theme.spacing(-3),
            background: 'transparent',
          },
        })}
      >
        <SvgIcon sx={{ fontSize: 26 }}>
          <GridIcon />
        </SvgIcon>
      </IconButton>

      <IconButton
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          zIndex: -1,
          filter: 'blur(10px)',
        }}
      >
        <SvgIcon sx={{ fontSize: 26 }}>
          <GridIcon />
        </SvgIcon>
      </IconButton>
    </Box>
  );
};
