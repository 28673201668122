import { batch, computed, ReadonlySignal, signal, Signal } from '@preact/signals-react';
import { injectable } from 'inversify';

import { MediaGridItem } from '@vp/common/ui/component/VpMediaGrid';
import { ViewModel } from '@vp/common/ui/ViewModel';
import { GalleryManagerMapper } from '@vp/manager/gallery/ui/GalleryManagerMapper';
import { GalleryManagerSection } from '@vp/manager/gallery/ui/GalleryManagerSection';
import { GalleryManagerPreviewProps } from '@vp/manager/gallery/ui/preview/GalleryManagerPreview';
import { ProfileManagerPort } from '@vp/manager/profile/core/interface/ProfileManagerPort';
import { ProfileModel } from '@vp/profile/core/model/ProfileModel';
import { MediaItemsMapper } from '@vp/profile/ui/gallery/MediaItemsMapper';

@injectable()
export class GalleryManagerViewModel extends ViewModel {
  readonly profile: ReadonlySignal<ProfileModel | null> = this.profileManagerPort.active;
  readonly photos: ReadonlySignal<MediaGridItem[]> = computed(() => this.toViewPhotos());
  readonly videos: ReadonlySignal<MediaGridItem[]> = computed(() => this.toViewVideos());

  readonly filesToUpload: Signal<File[]> = signal([]);
  readonly uploadShown: Signal<boolean> = signal(false);
  readonly previewShown: Signal<boolean> = signal(false);
  readonly previewItem: Signal<GalleryManagerPreviewProps['item'] | null> = signal(null);

  constructor(
    private readonly profileManagerPort: ProfileManagerPort,
    private readonly mediaItemsMapper: MediaItemsMapper,
    private readonly galleryManagerMapper: GalleryManagerMapper,
  ) {
    super();
  }

  handleFiles = (files: File[]): void => {
    batch(() => {
      this.filesToUpload.value = files;
      this.uploadShown.value = true;
    });
  };

  closeUpload = (): void => {
    batch(() => {
      this.uploadShown.value = false;
      this.filesToUpload.value = [];
    });
  };

  showPreview = (item: MediaGridItem, section: GalleryManagerSection): void => {
    const previewItem = this.galleryManagerMapper.toPreviewItem(item, section, this.profile.value!);

    batch(() => {
      this.previewItem.value = previewItem;
      this.previewShown.value = true;
    });
  };

  closePreview = (): void => {
    batch(() => {
      this.previewShown.value = false;
      this.previewItem.value = null;
    });
  };

  private toViewPhotos(): MediaGridItem[] {
    return this.mediaItemsMapper.toPhotoItems(this.profile.value?.photos ?? []);
  }

  private toViewVideos(): MediaGridItem[] {
    return this.mediaItemsMapper.toVideoItems(this.profile.value?.videos ?? []);
  }
}
