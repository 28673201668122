import { ZIndex } from '@mui/material';

export const zIndex = {
  mediaOverlayGradient: 1,
  mediaControls: 2,
  galleryContentSwitcher: 5,
  galleryProfileImage: 4,
  galleryProfileInfo: 5,
  slideshowIntro: 3,
  header: 6,
  footer: 6,
  desktopPlaceholder: 1400,
} as ZIndex;
