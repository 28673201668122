import * as Sentry from '@sentry/react';
import { useInjection } from 'inversify-react';
import { FC, useEffect } from 'react';
import { RouteObject } from 'react-router';
import { createBrowserRouter, useLocation, useRoutes } from 'react-router-dom';

import { RouterService } from '@vp/routing/RouterService';

const routerFactory = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export function createRouter(routes: RouteObject[]): ReturnType<typeof createBrowserRouter> {
  const RouterConfig: FC = () => {
    const element = useRoutes(routes);
    const location = useLocation();
    const routerService = useInjection(RouterService);

    useEffect(() => {
      routerService.notifySubscribers();
    }, [routerService, location.pathname, location.hash, location.search]);

    return element;
  };

  return routerFactory([{ path: '*', element: <RouterConfig /> }]);
}
