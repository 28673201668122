import { Box, styled } from '@mui/material';
import { FC } from 'react';

import { SpinnerBorderCalculator, SpinnerResolver } from '@vp/common/ui/component/spinner/SpinnerResolver';

export interface VpSpinnerProps {
  size?: 'x-small' | 'small' | 'medium' | 'large' | 'x-large' | 'giant' | number;
}

const spinAnimation = {
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
};

const borderCalculator = new SpinnerBorderCalculator([
  { min: 0, max: 24, border: 3 },
  { min: 24, max: 48, border: 4 },
  { min: 48, max: Infinity, border: 6 },
]);

const resolver = new SpinnerResolver(borderCalculator);

const VpSpinnerRoot = styled(Box)<VpSpinnerProps>(() => ({
  display: 'inline-block',
  borderRadius: '50%',
  background: 'conic-gradient(from 180deg at 50% 50%, rgba(255, 255, 255, 0) 0deg, var(--vp-text-color, #FBF1E8) 360deg)',
  animation: 'spin 1s linear infinite',
  ...spinAnimation,
  variants: [
    { props: { size: 'x-small' }, style: resolver.resolveWith(16, 3) },
    { props: { size: 'small' }, style: resolver.resolveWith(20, 3) },
    { props: { size: 'medium' }, style: resolver.resolveWith(24, 4) },
    { props: { size: 'large' }, style: resolver.resolveWith(28, 4) },
    { props: { size: 'x-large' }, style: resolver.resolveWith(32, 4) },
    { props: { size: 'giant' }, style: resolver.resolveWith(48, 6) },
    ...resolver.getVariantsRange(16, 48),
  ],
}));

export const VpSpinner: FC<VpSpinnerProps> = ({ size = 'small' }) => {
  return <VpSpinnerRoot size={size} />;
};
