import { injectable } from 'inversify';

import { OperationResult } from '@vp/common/core/OperationResult';
import { RefineBiographyDto } from '@vp/manager/biography/core/dto/RefineBiographyDto';
import { UpdateBiographyDto } from '@vp/manager/biography/core/dto/UpdateBiographyDto';
import { BiographyManagerPort } from '@vp/manager/biography/core/interface/BiographyManagerPort';
import { BiographyManagerRepository } from '@vp/manager/biography/core/interface/BiographyManagerRepository';
import { UpdateBiographyUsecase } from '@vp/manager/biography/core/usecase/UpdateBiographyUsecase';

@injectable()
export class BiographyManagerFacade implements BiographyManagerPort {
  constructor(
    private readonly biographyManagerRepository: BiographyManagerRepository,
    private readonly updateBiographyUsecase: UpdateBiographyUsecase,
  ) {}

  async refine(dto: RefineBiographyDto, controller?: AbortController): Promise<OperationResult<string>> {
    return await this.biographyManagerRepository.refine(dto, controller);
  }

  async update(dto: UpdateBiographyDto, controller?: AbortController): Promise<OperationResult> {
    return await this.updateBiographyUsecase.execute(dto, controller);
  }
}
