import { ProfileHonorRecordModel } from '@vp/profile/core/model/ProfileHonorRecordModel';

export abstract class HonorManagerPort {
  abstract loadHonorRecords(id: string, controller?: AbortController): Promise<void>;
  abstract editRecord(id: string, newText: string, controller?: AbortController): Promise<void>;
  abstract removeHonorRecords(ids: string[], profileId: string, controller?: AbortController): Promise<void>;
  abstract approveHonorRecords(ids: string[], profileId: string, controller?: AbortController): Promise<void>;
  abstract handleSubmissionResult(record: ProfileHonorRecordModel): void;
  abstract resetState(): void;
}
