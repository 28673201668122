import { Axios } from 'axios';
import { injectable } from 'inversify';

import { AuthRepository } from '@vp/auth/core/interface/AuthRepository';
import { OwnerModel } from '@vp/auth/core/model/OwnerModel';
import { TokensModel } from '@vp/auth/core/model/TokensModel';
import { AuthDataMapper } from '@vp/auth/data/AuthDataMapper';
import { OwnerResponseDto } from '@vp/auth/data/dto/OwnerResponseDto';
import { TokensResponseDto } from '@vp/auth/data/dto/TokensResponseDto';
import { Failure, OperationResult, Success } from '@vp/common/core/OperationResult';
import { ErrorExtractor } from '@vp/common/data/ErrorExtractor';

export enum AuthEndpoint {
  Register = '/api/admin/auth/sign_up',
  Login = '/api/admin/auth/sign_in',
  Refresh = '/api/admin/auth/refresh',
  GetOwner = '/api/admin/me',
  Passwords = '/api/admin/passwords',
}

@injectable()
export class RestAuthRepository implements AuthRepository {
  constructor(
    private readonly http: Axios,
    private readonly authDataMapper: AuthDataMapper,
    private readonly errorExtractor: ErrorExtractor,
  ) {}

  async register(email: string, password: string, controller?: AbortController): Promise<TokensModel> {
    const { data } = await this.http.post<TokensResponseDto>(AuthEndpoint.Register, { email, password }, { signal: controller?.signal });
    return this.authDataMapper.toTokens(data);
  }

  async login(email: string, password: string, controller?: AbortController): Promise<TokensModel> {
    const { data } = await this.http.post<TokensResponseDto>(AuthEndpoint.Login, { email, password }, { signal: controller?.signal });
    return this.authDataMapper.toTokens(data);
  }

  async refresh(tokens: TokensModel, controller?: AbortController): Promise<TokensModel> {
    const headers = { Authorization: `Bearer ${tokens.refresh}` };
    const options = { skipAuth: true, headers, signal: controller?.signal };
    const { data } = await this.http.put<TokensResponseDto>(AuthEndpoint.Refresh, {}, options);
    return this.authDataMapper.toTokens(data);
  }

  async getOwner(controller?: AbortController): Promise<OwnerModel> {
    const { data } = await this.http.get<OwnerResponseDto>(AuthEndpoint.GetOwner, { signal: controller?.signal });
    return this.authDataMapper.toOwner(data);
  }

  async sendResetPasswordLink(email: string, controller?: AbortController): Promise<OperationResult> {
    try {
      const data = this.authDataMapper.toForgotPasswordDto(email);
      await this.http.post(AuthEndpoint.Passwords, data, { skipAuth: true, signal: controller?.signal });
      return Success.empty();
    } catch (error) {
      const message = this.errorExtractor.extract(error);
      return Failure.from(message);
    }
  }

  async restorePassword(password: string, recoveryToken: string, controller?: AbortController): Promise<OperationResult> {
    try {
      const data = this.authDataMapper.toRestorePasswordDto(password);
      await this.http.put(`${AuthEndpoint.Passwords}?token=${recoveryToken}`, data, { skipAuth: true, signal: controller?.signal });
      return Success.empty();
    } catch (error) {
      const message = this.errorExtractor.extract(error);
      return Failure.from(message);
    }
  }
}
