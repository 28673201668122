import { Axios } from 'axios';
import { injectable } from 'inversify';

import { Failure, OperationResult, Success } from '@vp/common/core/OperationResult';
import { ErrorExtractor } from '@vp/common/data/ErrorExtractor';
import { GenerateBioResponseDto } from '@vp/manager/biography/core/dto/GenerateBioResponseDto';
import { RefineBiographyDto } from '@vp/manager/biography/core/dto/RefineBiographyDto';
import { UpdateBiographyDto } from '@vp/manager/biography/core/dto/UpdateBiographyDto';
import { BiographyManagerRepository } from '@vp/manager/biography/core/interface/BiographyManagerRepository';
import { BiographyManagerDataMapper } from '@vp/manager/biography/data/BiographyManagerDataMapper';
import { ProfileModel } from '@vp/profile/core/model/ProfileModel';
import { ProfileResponseDto } from '@vp/profile/data/dto/ProfileResponseDto';
import { ProfileDataMapper } from '@vp/profile/data/ProfileDataMapper';

@injectable()
export class RestBiographyManagerRepository implements BiographyManagerRepository {
  constructor(
    private readonly http: Axios,
    private readonly profileDataMapper: ProfileDataMapper,
    private readonly errorExtractor: ErrorExtractor,
    private readonly biographyManagerDataMapper: BiographyManagerDataMapper,
  ) {}

  async refine(dto: RefineBiographyDto, controller?: AbortController): Promise<OperationResult<string>> {
    try {
      const formData = this.biographyManagerDataMapper.toRefineFormData(dto);

      const { data } = await this.http.post<GenerateBioResponseDto>(`/api/profiles/${dto.profileId}/generate_bio`, formData, {
        signal: controller?.signal,
      });

      return Success.from(this.biographyManagerDataMapper.toGeneratedInfo(data));
    } catch (error) {
      const message = this.errorExtractor.extract(error);
      return Failure.from(message);
    }
  }

  async update(dto: UpdateBiographyDto, controller?: AbortController): Promise<OperationResult<ProfileModel>> {
    try {
      const formData = this.biographyManagerDataMapper.toUpdateFormData(dto);

      const { data } = await this.http.put<ProfileResponseDto>(`/api/profiles/${dto.profileId}`, formData, {
        signal: controller?.signal,
      });

      return Success.from(this.profileDataMapper.toProfile(data));
    } catch (error) {
      const message = this.errorExtractor.extract(error);
      return Failure.from(message);
    }
  }
}
